export enum lang {
  en = 'en',
  mn = 'mn',
  jp = 'jp',
}

export const HelpPage = {
  employee_list: {
    [`${lang.en}`]: '29',
    [`${lang.mn}`]: '34',
    [`${lang.jp}`]: '32',
  },
  division_list: {
    [`${lang.en}`]: '42',
    [`${lang.mn}`]: '44',
    [`${lang.jp}`]: '46',
  },
  position_list: {
    [`${lang.en}`]: '53',
    [`${lang.mn}`]: '51',
    [`${lang.jp}`]: '48',
  },
  // TODO:
  position_order: {
    [`${lang.en}`]: '53',
    [`${lang.mn}`]: '51',
    [`${lang.jp}`]: '48',
  },
  department_list: {
    [`${lang.en}`]: '55',
    [`${lang.mn}`]: '58',
    [`${lang.jp}`]: '60',
  },
  holiday_list: {
    [`${lang.en}`]: '62',
    [`${lang.mn}`]: '64',
    [`${lang.jp}`]: '66',
  },
  role_list: {
    [`${lang.en}`]: '68',
    [`${lang.mn}`]: '70',
    [`${lang.jp}`]: '72',
  },
  resource_employee_list: {
    [`${lang.en}`]: '74',
    [`${lang.mn}`]: '76',
    [`${lang.jp}`]: '78',
  },
  project_list: {
    [`${lang.en}`]: '12',
    [`${lang.mn}`]: '26',
    [`${lang.jp}`]: '24',
  },
  company_overview: {
    [`${lang.en}`]: '80',
    [`${lang.mn}`]: '82',
    [`${lang.jp}`]: '84',
  },
  create_employee: {
    [`${lang.en}`]: '86',
    [`${lang.mn}`]: '88',
    [`${lang.jp}`]: '90',
  },
  edit_employee: {
    [`${lang.en}`]: '92',
    [`${lang.mn}`]: '94',
    [`${lang.jp}`]: '96',
  },
  edit_project: {
    [`${lang.en}`]: '98',
    [`${lang.mn}`]: '101',
    [`${lang.jp}`]: '103',
  },
  project_member: {
    [`${lang.en}`]: '105',
    [`${lang.mn}`]: '107',
    [`${lang.jp}`]: '109',
  },
  project_report: {
    [`${lang.en}`]: '111',
    [`${lang.mn}`]: '113',
    [`${lang.jp}`]: '115',
  },
  project_partner_purchase_order: {
    [`${lang.en}`]: '251',
    [`${lang.mn}`]: '249',
    [`${lang.jp}`]: '253',
  },
  skill_report: {
    [`${lang.en}`]: '174',
    [`${lang.mn}`]: '178',
    [`${lang.jp}`]: '176',
  },
  user_skill: {
    [`${lang.en}`]: '197',
    [`${lang.mn}`]: '200',
    [`${lang.jp}`]: '202',
  },
  meeting_room: {
    [`${lang.en}`]: '1112',
    [`${lang.mn}`]: '1111',
    [`${lang.jp}`]: '1113',
  },
  book_meeting_room: {
    [`${lang.en}`]: '216',
    [`${lang.mn}`]: '214',
    [`${lang.jp}`]: '218',
  },
  meeting_room_order: {
    [`${lang.en}`]: '245',
    [`${lang.mn}`]: '243',
    [`${lang.jp}`]: '247',
  },
  email_send: {
    [`${lang.en}`]: '223',
    [`${lang.mn}`]: '225',
    [`${lang.jp}`]: '227',
  },
  email_campaign: {
    [`${lang.en}`]: '229',
    [`${lang.mn}`]: '231',
    [`${lang.jp}`]: '233',
  },
  email_template: {
    [`${lang.en}`]: '235',
    [`${lang.mn}`]: '237',
    [`${lang.jp}`]: '239',
  },
  email_content: {
    [`${lang.en}`]: '268',
    [`${lang.mn}`]: '270',
    [`${lang.jp}`]: '272',
  },
  my_time_off_list: {
    [`${lang.en}`]: '279',
    [`${lang.mn}`]: '284',
    [`${lang.jp}`]: '282',
  },
  time_sheet: {
    [`${lang.en}`]: '306',
    [`${lang.mn}`]: '310',
    [`${lang.jp}`]: '308',
  },
  my_time_correction_list: {
    [`${lang.en}`]: '326',
    [`${lang.mn}`]: '323',
    [`${lang.jp}`]: '328',
  },
  dashboard: {
    [`${lang.en}`]: '349',
    [`${lang.mn}`]: '347',
    [`${lang.jp}`]: '351',
  },
  my_daily_report: {
    [`${lang.en}`]: '357',
    [`${lang.mn}`]: '355',
    [`${lang.jp}`]: '359',
  },
  privacy_policy: {
    [`${lang.en}`]: '301',
    [`${lang.mn}`]: '298',
    [`${lang.jp}`]: '303',
  },
  terms_of_condition: {
    [`${lang.en}`]: '294',
    [`${lang.mn}`]: '292',
    [`${lang.jp}`]: '296',
  },
  support: {
    [`${lang.en}`]: '370',
    [`${lang.mn}`]: '399',
    [`${lang.jp}`]: '401',
  },
  event: {
    [`${lang.en}`]: '1041',
    [`${lang.mn}`]: '512',
    [`${lang.jp}`]: '1039',
  },
  actual_cost_report: {
    [`${lang.en}`]: '1043',
    [`${lang.mn}`]: '828',
    [`${lang.jp}`]: '1045',
  },
  team_daily_report: {
    [`${lang.en}`]: '1048',
    [`${lang.mn}`]: '563',
    [`${lang.jp}`]: '1050',
  },
  all_daily_report: {
    [`${lang.en}`]: '1052',
    [`${lang.mn}`]: '585',
    [`${lang.jp}`]: '1055',
  },
  approve_time_correction: {
    [`${lang.en}`]: '1060',
    [`${lang.mn}`]: '908',
    [`${lang.jp}`]: '1063',
  },
  vacation_balance: {
    [`${lang.en}`]: '1062',
    [`${lang.mn}`]: '1068',
    [`${lang.jp}`]: '1072',
  },
  time_report: {
    [`${lang.en}`]: '1076',
    [`${lang.mn}`]: '661',
    [`${lang.jp}`]: '1075',
  },
  approve_time_off: {
    [`${lang.en}`]: '1080',
    [`${lang.mn}`]: '929',
    [`${lang.jp}`]: '1082',
  },
  info_log: {
    [`${lang.en}`]: '1085',
    [`${lang.mn}`]: '981',
    [`${lang.jp}`]: '1084',
  },
  quit_users: {
    [`${lang.en}`]: '1089',
    [`${lang.mn}`]: '989',
    [`${lang.jp}`]: '1088',
  },
  skill_list: {
    [`${lang.en}`]: '1098',
    [`${lang.mn}`]: '1020',
    [`${lang.jp}`]: '1099',
  },
  skill_category: {
    [`${lang.en}`]: '1105',
    [`${lang.mn}`]: '1024',
    [`${lang.jp}`]: '1104',
  },
  workspace: {
    [`${lang.en}`]: '1118',
    [`${lang.mn}`]: '788',
    [`${lang.jp}`]: '1119',
  },
  company_settings: {
    [`${lang.en}`]: '1125',
    [`${lang.mn}`]: '818',
    [`${lang.jp}`]: '1126',
  },
  notifications: {
    [`${lang.en}`]: '1129',
    [`${lang.mn}`]: '877',
    [`${lang.jp}`]: '1130',
  },
  time_off_templates: {
    [`${lang.en}`]: '1139',
    [`${lang.mn}`]: '1141',
    [`${lang.jp}`]: '1143',
  },
  time_correction_templates: {
    [`${lang.en}`]: '1146',
    [`${lang.mn}`]: '1148',
    [`${lang.jp}`]: '1150',
  },
  approval_templates: {
    [`${lang.en}`]: '1154',
    [`${lang.mn}`]: '1156',
    [`${lang.jp}`]: '1152',
  },
  about: {
    [`${lang.en}`]: '1180',
    [`${lang.mn}`]: '1182',
    [`${lang.jp}`]: '1184',
  },
  demo: {
    [`${lang.en}`]: '1186',
    [`${lang.mn}`]: '1188',
    [`${lang.jp}`]: '1190',
  },
  pricing: {
    [`${lang.en}`]: '1192',
    [`${lang.mn}`]: '1194',
    [`${lang.jp}`]: '1196',
  },
  demo_login: {
    [`${lang.en}`]: '1219',
    [`${lang.mn}`]: '1222',
    [`${lang.jp}`]: '1225',
  },
}
