import React, { ReactElement } from 'react'
import { Dayjs } from 'dayjs'
import CustomDatePicker from '../CustomDatePicker'
import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material'
import { CustomLabel } from '../StyledTextField'
import { red } from '@mui/material/colors'

interface YearMonthPickerProps {
  value: Dayjs
  onChange: (date: Dayjs) => void
  helperText?: string
  labelPrimary?: string
  isHelperShow?: boolean
  labelSecondary?: ReactElement
  required?: boolean
  error: boolean
  shouldDisableMonth?: (month: string | Dayjs) => boolean
  shouldDisableYear?: (year: string | Dayjs) => boolean
}

const YearMonthPicker: React.FC<YearMonthPickerProps> = ({
  value,
  onChange,
  labelPrimary,
  labelSecondary,
  isHelperShow = true,
  required = false,
  error,
  helperText,
  shouldDisableMonth,
  shouldDisableYear,
}) => {
  const handleYearChange = (date: any) => {
    const updatedDate = value.set('year', date?.year())
    onChange(updatedDate)
  }

  const handleMonthChange = (date: any) => {
    const updatedDate = value.set('month', date?.month())
    onChange(updatedDate)
  }

  return (
    <FormControl fullWidth>
      {(labelPrimary || labelSecondary) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: 0 }}
        >
          <Box sx={{ padding: 0 }} display="flex" alignItems="center">
            <CustomLabel text={labelPrimary ? labelPrimary : ''} />
            {required && (
              <Typography
                component="span"
                sx={{
                  paddingLeft: (theme) => theme.spacing(1 / 2),
                  paddingRight: (theme) => theme.spacing(1 / 2),
                  height: 16,
                  fontSize: 10,
                  marginLeft: '5px',
                  color: red[400],
                }}
              >
                *
              </Typography>
            )}
          </Box>
          {labelSecondary}
        </Box>
      )}
      <Stack direction="row" spacing={1}>
        <Box sx={{ width: '50%' }}>
          <CustomDatePicker
            inputFormat="YYYY"
            views={['year']}
            isHelperShow={false}
            value={value}
            onChange={handleYearChange}
            shouldDisableYear={shouldDisableYear}
            error={false}
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <CustomDatePicker
            inputFormat="MM"
            isHelperShow={false}
            views={['month']}
            value={value}
            shouldDisableMonth={shouldDisableMonth}
            onChange={handleMonthChange}
            error={false}
          />
        </Box>
      </Stack>
      {isHelperShow &&
        (helperText ? (
          <FormHelperText
            sx={{
              height: 12,
              m: 0,
            }}
            error={error}
          >
            {helperText}
          </FormHelperText>
        ) : (
          <Box sx={{ height: 12 }} />
        ))}
    </FormControl>
  )
}

export default YearMonthPicker
