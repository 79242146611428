import { AlertColor, SnackbarOrigin } from '@mui/material'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/common.services'
import { COMMON_ACTION_TYPE } from './types'

export type toastParams = {
  message: string
  severity?: AlertColor
  anchorOrigin?: SnackbarOrigin
  autoHideDuration?: number | null
}

export type routeParams = {
  path: string | undefined
}

export const addToast = createAction<toastParams>('toast/addToast')
export const removeToast = createAction<string>('toast/removeToast')
export const cleanToasts = createAction('toast/cleanToasts')
export const helperHide = createAction('helper/helperHide')
export const helperShow = createAction('helper/helperShow')
export const previousRoute = createAction<routeParams>('route/saveRoute')

export const appInit = createAsyncThunk<services.AppInitResponse>(
  COMMON_ACTION_TYPE.INIT,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.initService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
