import { NOTIFY_TYPE } from '@constants/notify.constants'
import { ROUTE } from '@constants/route.constants'
import { NotifyContent } from '@services/notification.services'
import _ from 'lodash'

export const renderLink = (content: NotifyContent): string | null => {
  const actionType = _.get(content, 'attributes.target_type')

  if (content) {
    switch (actionType) {
      case NOTIFY_TYPE.PROJECT:
        return `/project/${_.get(content, 'attributes.target_content.data.id')}`
      case NOTIFY_TYPE.TIMEOFF_APPROVER:
        return '/timeoff/approve_list'
      case NOTIFY_TYPE.TIMEOFF_CREATOR:
        return '/timeoff/requests'
      case NOTIFY_TYPE.TIME_CORRECTION_APPROVER:
        return '/timecorrection/approve'
      case NOTIFY_TYPE.TIME_CORRECTION_CREATOR:
        return '/timecorrection/requests'
      case NOTIFY_TYPE.REMOTE_WORK_APPROVER:
        return ROUTE.REMOTE_WORK.APPROVE
      case NOTIFY_TYPE.REMOTE_WORK_CREATOR:
        return ROUTE.REMOTE_WORK.REQUEST
      case NOTIFY_TYPE.REMOTE_WORK_DISABLED:
        return ROUTE.REMOTE_WORK.DISABLED_LOG
      default:
        return null
    }
  }
  return null
}
