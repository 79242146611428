import { createReducer } from '@reduxjs/toolkit'
import {
  CompanyRuleItem,
  ContentResponse,
  EventResponse,
  MeetingRoomItem,
  MeetingRoomOrderResponse,
  SystemSettingsItem,
  TimeTableItem,
  WorkspaceItem,
} from '@services/settings.services'
import _ from 'lodash'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import { appInit } from '@store/common/actions'
import { AppInit } from '@services/common.services'

type StateType = {
  help?: ContentResponse
  init?: AppInit
  eventList?: EventResponse
  eventListPublic?: EventResponse

  meetingRoomList?: MeetingRoomItem[]
  meetingRoomDetail?: MeetingRoomItem

  meetingRoomOrderList?: MeetingRoomOrderResponse[]
  meetingRoomOrderDetail?: MeetingRoomOrderResponse
  timeTableList?: TimeTableItem[]
  timeTableDetail?: TimeTableItem

  systemSettingsList?: SystemSettingsItem[]
  systemSettingsListPaging?: MetaPage

  companyRules?: CompanyRuleItem
  workspaceList?: WorkspaceItem[]
  workspaceListPaging?: MetaPage
  workspaceDetail?: WorkspaceItem
  workspaceAllList?: WorkspaceItem[]
}

const initialState: StateType = {
  help: undefined,
  eventList: undefined,
  init: undefined,
  eventListPublic: undefined,

  meetingRoomList: undefined,
  meetingRoomDetail: undefined,
  meetingRoomOrderList: undefined,
  meetingRoomOrderDetail: undefined,

  timeTableList: undefined,
  timeTableDetail: undefined,

  systemSettingsList: undefined,
  companyRules: undefined,
  workspaceList: undefined,
  workspaceListPaging: undefined,
  workspaceDetail: undefined,
  workspaceAllList: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchEventListAll.fulfilled, (state, action) => {
    state.eventList = action.payload
  })

  builder.addCase(actions.fetchEventListPublic.fulfilled, (state, action) => {
    state.eventListPublic = action.payload
  })

  builder.addCase(actions.fetchPageContent.fulfilled, (state, action) => {
    state.help = action.payload
  })

  builder.addCase(actions.eventUpdate.fulfilled, (state, action) => {
    state.eventList = state.eventList
      ? {
          ...state.eventList,
          birthday: state.eventList.birthday,
          events: {
            data: _.map(state.eventList.events.data, (item) => {
              if (item.id === action.payload.data.id) {
                return action.payload.data
              }
              return item
            }),
          },
        }
      : undefined
  })

  builder.addCase(actions.eventDelete.fulfilled, (state, action) => {
    state.eventList = state.eventList
      ? {
          ...state.eventList,
          birthday: state.eventList.birthday,
          events: {
            data: _.remove(state.eventList.events.data, (n) => {
              return n.id !== action.meta.arg
            }),
          },
        }
      : undefined
  })

  builder.addCase(actions.clearHelp, (state) => {
    state.help = undefined
  })
  //Meeting room
  builder.addCase(actions.fetchMeetingRoomList.fulfilled, (state, action) => {
    state.meetingRoomList = action.payload.data
  })
  builder.addCase(actions.meetingRoomDetail.fulfilled, (state, action) => {
    state.meetingRoomDetail = action.payload.data
  })
  builder.addCase(actions.meetingRoomUpdate.fulfilled, (state, action) => {
    state.meetingRoomList = state.meetingRoomList
      ? _.map(state.meetingRoomList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  //Meeting room order
  builder.addCase(
    actions.fetchMeetingRoomOrderList.fulfilled,
    (state, action) => {
      state.meetingRoomOrderList = action.payload.data
    }
  )
  builder.addCase(actions.meetingRoomOrderDelete.fulfilled, (state, action) => {
    if (state.meetingRoomOrderList) {
      state.meetingRoomOrderList = state.meetingRoomOrderList.filter(
        (item) => item.id !== action.meta.arg
      )
    }
  })
  //Time table
  builder.addCase(actions.fetchTimeTableList.fulfilled, (state, action) => {
    state.timeTableList = action.payload.data
  })
  builder.addCase(actions.timeTableDetail.fulfilled, (state, action) => {
    state.timeTableDetail = action.payload.data
  })
  builder.addCase(actions.timeTableUpdate.fulfilled, (state, action) => {
    state.timeTableList = state.timeTableList
      ? _.map(state.timeTableList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })

  //System settings
  builder.addCase(actions.fetchSystemSettings.fulfilled, (state, action) => {
    state.systemSettingsList = action.payload.data
    state.systemSettingsListPaging = action.payload.meta
  })
  //Company rules
  builder.addCase(actions.fetchCompanyRulesList.fulfilled, (state, action) => {
    state.companyRules = action.payload.data
  })

  builder.addCase(actions.updateCompanyRules.fulfilled, (state, action) => {
    state.companyRules = action.payload.data
  })
  //Workspace
  builder.addCase(actions.fetchWorkspace.fulfilled, (state, action) => {
    state.workspaceList = action.payload.data
    state.workspaceListPaging = action.payload.meta
  })
  builder.addCase(actions.fetchAllWorkspace.fulfilled, (state, action) => {
    state.workspaceAllList = action.payload.data
  })
  builder.addCase(actions.updateWorkspace.fulfilled, (state, action) => {
    state.workspaceList = state.workspaceList
      ? _.map(state.workspaceList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
    state.workspaceDetail = action.payload.data
  })
  builder.addCase(actions.fetchWorkspaceDetail.fulfilled, (state, action) => {
    state.workspaceDetail = action.payload.data
  })
  builder.addCase(actions.regenerateWorkspaceUid.fulfilled, (state, action) => {
    state.workspaceList = state.workspaceList
      ? _.map(state.workspaceList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
    state.workspaceDetail = action.payload.data
    state.workspaceDetail = action.payload.data
  })
  builder.addCase(appInit.fulfilled, (state, action) => {
    state.init = action.payload.data
  })
})
