import { createSelector } from '@reduxjs/toolkit'
import { SkillCategoryItem } from '@services/skill.services'
import { RootState } from '@store/store'
import _ from 'lodash'

const getRoot = (state: RootState) => state.skill

//SKILL CATEGORY SELECTOR
export const getSkillCategoryList = createSelector(
  getRoot,
  (state) => state.skillCategoryList
)

export const getAllCategories = createSelector(getRoot, (state) => {
  if (state.allSkillCategoryList) {
    return _.orderBy(
      state.allSkillCategoryList,
      'attributes.display_order',
      'asc'
    )
  } else {
    return state.allSkillCategoryList
  }
})

export const getSkillCategoryOptions = createSelector(getRoot, (state) =>
  state.allSkillCategoryList
    ? _.map(state.allSkillCategoryList, (item: SkillCategoryItem) => {
        return { id: item.id, name: item.attributes.name }
      })
    : state.allSkillCategoryList
)

export const getSkillCategoryListMeta = createSelector(
  getRoot,
  (state) => state.skillCategoryListMeta
)

//SKILL SELECTOR
export const getAllSkills = createSelector(getRoot, (state) => state.allSkills)

export const getSkillList = createSelector(getRoot, (state) => state.skillList)

export const getSkillListMeta = createSelector(
  getRoot,
  (state) => state.skillListMeta
)

//REPORT SELECTOR
export const getSkillReportList = createSelector(
  getRoot,
  (state) => state.skillReportList
)

// USER SKILL

export const getGroupedSkills = createSelector(getRoot, (state) => {
  const groupedByCategoryId = _.groupBy(
    state.userSkillsAll,
    (item) => item.attributes.category_id
  )
  const transformedArray = _.map(groupedByCategoryId, (group, category_id) => ({
    category_id: parseInt(category_id, 10),
    category_name: group[0].attributes.category_name,
    data: group,
  }))

  return transformedArray
})

export const getUserSkillsAll = createSelector(
  getRoot,
  (state) => state.userSkillsAll
)

export const getAddableSkills = createSelector(
  getRoot,
  (state) => state.addableSkillList
)
