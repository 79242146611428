import { useDispatch, useSelector } from 'react-redux'
import {
  allFilters,
  selectListFilterByName,
} from '@store/localsettings/selectors'
import { clearListFilter, setListFilter } from '@store/localsettings/actions'
import { previousPath } from '@store/common/selectors'

interface UsePageSettingsReturnType {
  filter: any
  setFilter: (newFilter: any) => void
  clearAllFilter: () => void
  filters: { [listName: string]: any | undefined }
  prevPath?: string
}

export function usePageSettings(
  listName: string,
  defaultFilter?: any
): UsePageSettingsReturnType {
  const filter = useSelector(selectListFilterByName(listName, defaultFilter))
  const prevPath = useSelector(previousPath)
  // eslint-disable-next-line no-console

  // Get the Redux dispatch function
  const dispatch = useDispatch()

  const setFilter = (newFilter: any) => {
    dispatch(setListFilter({ listName, filter: newFilter }))
  }
  const clearAllFilter = () => dispatch(clearListFilter())
  const filters = useSelector(allFilters())

  return {
    filter,
    filters,
    clearAllFilter,
    setFilter,
    prevPath,
  }
}
