import { FilterParams } from '@constants/common.constants'
import { TimeOffListFilter } from '@constants/timeoff.constants'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/timeoff.services'
import { TIME_OFF_ACTION_TYPE } from './types'

// TimeOffTemplates
export const fetchTimeOffTemplateList = createAsyncThunk<
  services.TimeOffTemplateListResponse,
  FilterParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffTemplateListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTimeOffTemplateAll = createAsyncThunk<
  services.TimeOffTemplateAllResponse,
  void
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_ALL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffTemplateAllService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createTimeOffTemplate = createAsyncThunk<
  services.TimeOffTemplateResponse,
  services.TimeOffTemplateCreateParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffTemplateCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getTimeOffTemplateDetail = createAsyncThunk<
  services.TimeOffTemplateResponse,
  services.TimeOffTemplateIdParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffTemplateDetailsService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateTimeOffTemplate = createAsyncThunk<
  services.TimeOffTemplateResponse,
  services.TimeOffTemplateUpdateParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffTemplateUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTimeOffTemplate = createAsyncThunk<
  services.TimeOffTemplateResponse,
  services.TimeOffTemplateIdParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffTemplateDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const clearTimeOffTemplate = createAction(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_CLEAR
)

// TimeOff Request
export const fetchTimeOffRequestList = createAsyncThunk<
  services.TimeOffRequestListResponse,
  TimeOffListFilter
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffRequestListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createTimeOffRequest = createAsyncThunk<
  services.TimeOffRequestResponse,
  services.TimeOffRequestCreateParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffRequestCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getTimeOffRequestDetail = createAsyncThunk<
  services.TimeOffRequestResponse,
  services.TimeOffRequestIdParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffRequestDetailsService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

// export const updateTimeOffRequest = createAsyncThunk<
//   services.TimeOffRequestResponse,
//   services.TimeOffRequestUpdateParams
// >(
//   TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_UPDATE,
//   async (params, { rejectWithValue }) => {
//     try {
//       const res = await services.timeOffRequestUpdateService(params)
//       return res
//     } catch (err) {
//       const error: any = err
//       if (!error.response) {
//         throw error
//       }
//       return rejectWithValue(error.response.data)
//     }
//   }
// )

export const deleteTimeOffRequest = createAsyncThunk<
  services.TimeOffRequestResponse,
  services.TimeOffRequestIdParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffRequestDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTimeOffRequestByApprover = createAsyncThunk<
  services.TimeOffRequestResponse,
  services.TimeOffRequestIdParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_DELETE_BY_APPROVER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffRequestDeleteByApproverService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const clearTimeOffRequest = createAction(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_CLEAR
)

export const fetchTimeOffApproveList = createAsyncThunk<
  services.TimeOffApproveListShortResponse,
  TimeOffListFilter
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_APPROVE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffApproveListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const approveTimeOffRequest = createAsyncThunk<
  void,
  services.TimeOffApproveParams
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_APPROVE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffApproveService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const denyTimeOffRequest = createAsyncThunk<
  void,
  services.TimeOffDenyParams
>(TIME_OFF_ACTION_TYPE.TIME_OFF_DENY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeOffDenyService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

//APPROVAL TEMPLATE ACTIONS
export const fetchApprovalTemplateList = createAsyncThunk<
  services.ApprovalTemplateListResponse,
  FilterParams
>(
  TIME_OFF_ACTION_TYPE.GET_APPROVAL_TEMPLATE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchApprovalTemplateAll = createAsyncThunk<
  services.ApprovalTemplateAllResponse,
  void
>(
  TIME_OFF_ACTION_TYPE.GET_APPROVAL_TEMPLATE_ALL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateAllService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createApprovalTemplate = createAsyncThunk<
  services.ApprovalTemplateResponse,
  services.ApprovalTemplateCreateParams
>(
  TIME_OFF_ACTION_TYPE.CREATE_APPROVAL_TEMPLATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateApprovalTemplate = createAsyncThunk<
  services.ApprovalTemplateResponse,
  services.ApprovalTemplateUpdateParams
>(
  TIME_OFF_ACTION_TYPE.UPDATE_APPROVAL_TEMPLATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchApprovalTemplate = createAsyncThunk<
  services.ApprovalTemplateResponse,
  services.ApprovalTemplateIdParam
>(
  TIME_OFF_ACTION_TYPE.GET_APPROVAL_TEMPLATE_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateShowService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteApprovalTemplate = createAsyncThunk<
  services.ApprovalTemplateResponse,
  services.ApprovalTemplateIdParam
>(
  TIME_OFF_ACTION_TYPE.DELETE_APPROVAL_TEMPLATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

// APPROVAL TEMPLATE DETAIL
export const fetchApprovalTemplateDetailList = createAsyncThunk<
  services.ApprovalTemplateDetailListResponse,
  services.ApprovalTemplateIdParam
>(
  TIME_OFF_ACTION_TYPE.GET_APPROVAL_TEMPLATE_DETAIL_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createApprovalTemplateDetail = createAsyncThunk<
  services.ApprovalTemplateDetailResponse,
  services.ApprovalTemplateDetailCreateParams
>(
  TIME_OFF_ACTION_TYPE.CREATE_APPROVAL_TEMPLATE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateDetailCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateApprovalTemplateDetail = createAsyncThunk<
  services.ApprovalTemplateDetailResponse,
  services.ApprovalTemplateDetailUpdateParams
>(
  TIME_OFF_ACTION_TYPE.UPDATE_APPROVAL_TEMPLATE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateDetailUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateApprovalTemplateStep = createAsyncThunk<
  services.ApprovalTemplateDetailListResponse,
  services.ApprovalTemplateStepParams
>(
  TIME_OFF_ACTION_TYPE.UPDATE_APPROVAL_TEMPLATE_STEP,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateUpdateStepService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const deleteApprovalTemplateDetail = createAsyncThunk<
  services.ApprovalTemplateDetailResponse,
  services.ApprovalTemplateDetailDeleteParams
>(
  TIME_OFF_ACTION_TYPE.DELETE_APPROVAL_TEMPLATE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.approvalTemplateDetailDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

// COMPENSATORY DAYS
export const fetchCompensatoryDayList = createAsyncThunk<
  services.CompensatoryDaysResponse,
  void
>(
  TIME_OFF_ACTION_TYPE.COMPENSATORY_DAYS,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.compensatoryDaysService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchUserCompensatoryList = createAsyncThunk<
  services.UserCompensatoryDaysListResponse,
  FilterParams
>(
  TIME_OFF_ACTION_TYPE.USER_COMPENSATORY_DAYS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.userCompensatoryDaysListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateUserCompensatoryDays = createAsyncThunk<
  services.UserCompensatoryDaysUpdateResponse,
  services.UserCompensatoryDaysUpdateParams
>(
  TIME_OFF_ACTION_TYPE.USER_COMPENSATORY_DAYS_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.userCompensatoryDaysUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTimeOffReportTemplateList = createAsyncThunk<
  services.TimeOffReportTemplateListResponse,
  void
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_REPORT_TEMPLATE_LIST,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.timeOffReportTemplateAllService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTimeOffReportList = createAsyncThunk<
  services.TimeOffReportResponse,
  services.TimeOffReportParams
>(TIME_OFF_ACTION_TYPE.TIME_OFF_REPORT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeOffReportService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchTimeOffReportTotalList = createAsyncThunk<
  services.TimeOffReportTotalReponse,
  services.TimeOffReportTotalParam
>(
  TIME_OFF_ACTION_TYPE.TIME_OFF_TOTAL_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeOffReportTotalService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
