import { api } from './api'
import { URI } from '@constants/uri.constants'
import { ProjectItem } from './project.services'
import { TimeCardItem } from './time.services'
import { PROJECT_STATUS, PROJECT_TYPE } from '@constants/project.constants'
import { PositionItem } from './resource.services'

export type DailyReportFilterParams = {
  start_date?: any
  project_ids?: string[]
  end_date?: any | null
}

export type DailyReportCreateParams = {
  report: string
  start_at: string
  end_at: string
  project_id?: string | null
  time_record_id: number
}

export type DailyReportInsertParams = {
  report?: string
  start_at?: Date
  end_at?: Date
  project_id?: string | null
  deadline?: number
  complexity?: number
  tag_id?: number
}

export type DailyReportUpdateParams = {
  id: string
  params: DailyReportInsertParams
}

export type DailyReport = {
  id: string
  type: 'daily_report'
  attributes: {
    daily_report_details: {
      data: DailyReportDetailItem[]
    }
  }
}

export type TagItem = {
  id: string
  type: 'tag'
  attributes: {
    name: string
    description: string
    has_position: boolean
  }
}

export type DailyReportDetailItem = {
  id: string
  type: 'daily_report_detail'
  attributes: {
    report: string
    start_at: string
    end_at: string
    project: { data: ProjectItem }
    complexity: number
    deadline: number
    tags: { data: TagItem }
  }
}

export type TeamDailyReportItem = {
  id: string
  type: 'team_daily_report'
  attributes: {
    name: string
    status: PROJECT_STATUS
    members: {
      data: TeamDailyReportDetailItem[]
      meta: { start_date: string; end_date: string }
    }
  }
}

export type DailyReportTagItem = {
  id: string
  type: 'daily_report_tag'
  attributes: {
    tags: { data: TagItem }
    duration: number
  }
}

export type DailyReportWorkedProjectItem = {
  id: string
  type: 'daily_report_project_with_duration'
  attributes: {
    project: {
      data: {
        id: string
        type: 'daily_report_project'
        attributes: {
          name: string
          project_type: PROJECT_TYPE
          status: PROJECT_STATUS
        }
      }
    }
    project_duration: number
  }
}

export type TeamDailyReportDetailItem = {
  id: string
  type: 'team_daily_report_pm'
  attributes: {
    user_id: string
    user_code: string
    firstname: string
    lastname: string
    firstname_en: string
    stress_level: number
    lastname_en: string
    avatar: string
    other_projects: number
    position: { data: PositionItem }
    reports: {
      data: TimeCardItem[]
    }
    tag_data: {
      data: DailyReportTagItem
    }
    worked_projects: {
      data: DailyReportWorkedProjectItem
    }[]
  }
}

export type MyDailyReportAllResponse = {
  data: TimeCardItem[]
}

export type TeamDailyReportAllResponse = {
  data: TeamDailyReportItem[]
}

export type DailyReportDetailResponse = {
  data: DailyReportDetailItem
}

export type TagsResponse = {
  data: TagItem[]
}

export const myDailyReportService = async (
  params: DailyReportFilterParams
): Promise<MyDailyReportAllResponse> => {
  const { data } = await api.get<MyDailyReportAllResponse>(
    `${URI.DAILY_REPORTS}/my_daily_reports`,
    {
      params,
    }
  )
  return data
}
export const teamDailyReportService = async (
  params: DailyReportFilterParams
): Promise<TeamDailyReportAllResponse> => {
  const { data } = await api.get<TeamDailyReportAllResponse>(
    `${URI.DAILY_REPORTS}/team_daily_reports`,
    {
      params,
    }
  )
  return data
}
export const hrDailyReportService = async (
  params: DailyReportFilterParams
): Promise<TeamDailyReportAllResponse> => {
  const { data } = await api.get<TeamDailyReportAllResponse>(
    `${URI.DAILY_REPORTS}/all_daily_reports`,
    {
      params,
    }
  )
  return data
}

export const dailyReportCreateService = async (
  params: DailyReportCreateParams
): Promise<DailyReportDetailResponse> => {
  const { data } = await api.post<DailyReportDetailResponse>(
    URI.DAILY_REPORTS,
    params
  )
  return data
}

export const dailyReportUpdateService = async (
  arg: DailyReportUpdateParams
): Promise<DailyReportDetailResponse> => {
  const { data } = await api.put<DailyReportDetailResponse>(
    `${URI.DAILY_REPORTS}/${arg.id}`,
    arg.params
  )
  return data
}

export const dailyReportDeleteService = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.DAILY_REPORTS}/${id}`)
  return data
}

export const fetchTagsService = async (): Promise<TagsResponse> => {
  const { data } = await api.get<TagsResponse>(`${URI.TAGS}`)
  return data
}
