import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts'

interface PlaceholderProps {
  width: string | number
  height: number
  emptyChartData: { name: string; count: number; bgColor: string }[]
  placeholderText: string
  innerRadius: number
  outerRadius: number
}

const PlaceholderPieChart: React.FC<PlaceholderProps> = ({
  width,
  height,
  innerRadius,
  outerRadius,
  emptyChartData,
  placeholderText,
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          data={emptyChartData ?? []}
          dataKey="count"
          nameKey="name"
          cx="50%"
          stroke="transparent"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
          labelLine={false}
        >
          {emptyChartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              style={{
                outline: 'none',
                filter: `drop-shadow(rgb(0, 0, 0, .1) 0px 0px 6px)`,
              }}
              fill={entry.bgColor}
            />
          ))}

          <Label
            value={placeholderText}
            position="center"
            style={{ fontSize: '12px', fill: '#111' }} // Style for the label
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PlaceholderPieChart
