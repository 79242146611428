/* eslint-disable no-console */
import { createReducer } from '@reduxjs/toolkit'
import {
  DashboardEmployeeItem,
  DashboardPositionItem,
  DashboardProjectItem,
  ProjectOverviewResponse,
} from '@services/dashboard.services'
import * as actions from '../actions'
import { ShortUserItem } from '@services/user.services'

type StateType = {
  employeeData?: DashboardEmployeeItem
  projectData?: DashboardProjectItem
  positionsData?: DashboardPositionItem[]
  projectOverivew?: ProjectOverviewResponse
  employeeList?: ShortUserItem[]
}

const initialState: StateType = {
  employeeData: undefined,
  projectData: undefined,
  positionsData: undefined,
  projectOverivew: undefined,
  employeeList: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchDashboardOverview.fulfilled, (state, action) => {
    state.employeeData = action.payload.employee
    state.projectData = action.payload.project
    state.positionsData = action.payload.position
  })
  builder.addCase(actions.fetchProjectOverview.fulfilled, (state, action) => {
    state.projectOverivew = action.payload
  })
  builder.addCase(actions.fetchDashboardEmployee.fulfilled, (state, action) => {
    state.employeeList = action.payload.data
  })
  builder.addCase(actions.clearEmployeeList, (state) => {
    state.employeeList = undefined
  })
})
