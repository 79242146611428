import dayjs from 'dayjs'
import { Direction } from './common.constants'

export enum VACATION_BALANCE_STATUS_TYPE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const VACATION_GRID = {
  rowWidth: 70,
  rowHeight: 30,
}

export const VacationBalanceMax = 50
export const NDSHMax = 800

export const VacationDefaultFilter = {
  order_by: 'user_code',
  direction: Direction.ASC,
}
export const VacationReportDefaultFilter = {
  start_date: dayjs(new Date()).subtract(1, 'year').toDate(),
  end_date: dayjs(new Date()).toDate(),
  user_id: '',
}
