import { PROJECT_STATUS } from '@constants/project.constants'
import { URI } from '@constants/uri.constants'
import { api } from './api'
import { ProjectItem } from './project.services'
import { EmployeeShortItem } from './user.services'

export type CostReportParams = string
export type ActualCostReportParams = {
  report_start_date: any
  report_end_date?: any
}

export type CostProjectListParams = {
  report_start_date: string
  report_end_date: string | null
}

export type PurchasePositionItem = {
  id: number
  name: string
  short_name: string
  display_order: number
}

export type CostMemberItem = {
  user: {
    id: number
    lastname: string
    firstname: string
    lastname_en: string
    firstname_en: string
  }
  position: {
    id: number
    name: string
    short_name: string
    display_order: number
  }
  cost: number
  planned_cost: number
  actual_cost: number
  purchased_position: PurchasePositionItem
}

export type CostPartnerDivisionItem = {
  id: number
  name: string
  partner_company_id: number
  division_lead: string
  description: string | null
  created_at: string
  updated_at: string
  display_order: number
}

export type CostPartnerPurchaseItem = {
  id: number
  cost: number
  position: {
    id: number
    name: string
    short_name: string
    display_order: number
  }
}

export type CostReportItem = {
  id: number
  name: string
  description: string | null
  status: PROJECT_STATUS
  partner_company_id: number | null
  division_id: number | null
  partner_division: CostPartnerDivisionItem | null
  members: CostMemberItem[]
  partner_purchase_orders: CostPartnerPurchaseItem[]
}

export type CostReportInfo = {
  id: number
  target_month: string
  created_at: string
  updated_at: string
}

export type CostReportResponse = {
  data: CostReportItem[]
  cost_report: CostReportInfo
}

export type CompanyTotalItem = {
  position_id: string
  position_name: string
  total_cost: number
  total_count: number
  total_purchased_cost: number
  total_purchased_count: number
  display_order: number
  total_ordered_cost: number
  total_ordered_count: number
}

export type AcutalCostFilterDates = {
  holidays: number
  weekends: number
  work_dates: number
}

export type ActualCostReportResponse = {
  data: ActualCostReportItem[]
  filter_dates: AcutalCostFilterDates
}

export type CostPartnerCompanyItem = {
  id: number
  name: string
  description: string
  created_at: Date
  updated_at: Date
}

export type CostDivisionItem = {
  id: number
  name: string
  division_lead_id: number
  created_at: Date
  updated_at: Date
}

export type ActualCostReportItem = {
  user: {
    id: number
    lastname: string
    firstname: string
    lastname_en: string
    firstname_en: string
    user_code: string
  }
  position: {
    id: number
    name: string
    short_name: string
    display_order: string
  }
  // other_cost: number
  // timeoff_days: number
  planned_cost: 1.0
  actual_cost: 0
  within_invoice_all: 0
  within_invoice_with_timeoff: 0
  projects_include_timeoff: 0
  all_include_timeoff: 0
}

type ProjectTotal = {
  project_id: string
  actual_cost: number
  planned_cost: number
}

export type ActualCostProjectTotal = {
  position_id: string
  projects: ProjectTotal[]
}

export type CostProjectItem = ProjectItem & {
  attributes: {
    partner_purchase_orders: CostPartnerPurchaseItem[]
    division: CostDivisionItem
  }
}

export type CostProjectListResponse = {
  data: CostProjectItem[]
}

export type COST_ACTION_TYPE = 'created' | 'updated'

export type CostReportActivityItem = {
  id: string
  type: 'cost_report_activities'
  attributes: {
    cost_report_id: number
    action_type: COST_ACTION_TYPE
    actioned_by: {
      data: EmployeeShortItem
    }
  }
}

export type CostReportActivityResponse = {
  data: CostReportActivityItem[]
}

export const costReportService = async (
  params: CostReportParams
): Promise<CostReportResponse> => {
  const { data } = await api.get<CostReportResponse>(
    `${URI.COST_REPORT.replace(/:id/gi, params)}.json`,
    {
      params: params,
    }
  )
  return data
}

export const costReportActivityService = async (
  params: CostReportParams
): Promise<CostReportActivityResponse> => {
  const { data } = await api.get<CostReportActivityResponse>(
    `${URI.COST_REPORT.replace(/:id/gi, params)}/activities`,
    {
      params: params,
    }
  )
  return data
}

export const costReportDownloadService = async (
  params: CostReportParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `${URI.COST_REPORT.replace(/:id/gi, params)}.xlsx`,
    {
      responseType: 'blob',
    }
  )
  return data
}

export const actualCostReportService = async (
  params: ActualCostReportParams
): Promise<ActualCostReportResponse> => {
  const { data } = await api.get<ActualCostReportResponse>(
    `${URI.ACTUAL_COST_REPORT}.json`,
    {
      params: params,
    }
  )
  return data
}

export const actualCostReportDownloadService = async (
  params: ActualCostReportParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${URI.ACTUAL_COST_REPORT}.xlsx`, {
    params: params,
    responseType: 'blob',
  })
  return data
}

export const costProjectListService = async (
  params: CostProjectListParams
): Promise<CostProjectListResponse> => {
  const { data } = await api.get<CostProjectListResponse>(
    `${URI.COST_REPORT_PROJECTS}`,
    {
      params: params,
    }
  )
  return data
}

export type ActualCostReportDetailProjectItem = {
  id: string
  name: string
  description: string | null
  status: PROJECT_STATUS | null
  planned_cost: string | null
  actual_cost: string
  actual_cost_with_timeoff: string
}

export type ActualCostReportDetailFilterDates = {
  holidays: number
  timeoff_days: number
  work_dates: number
}

export type ActualCostReportDetailItem = {
  user: {
    id: string
    user_code: string
    lastname: string
    firstname: string
    lastname_en: string
    firstname_en: string
  }
  position: {
    id: string
    name: string
    short_name: string
    display_order: string
  }
  planned_cost: number | null
  actual_cost: string
  within_invoice_all: string
  within_invoice_with_timeoff: string
  projects_include_timeoff: string
  all_include_timeoff: string
  projects: ActualCostReportDetailProjectItem[]
}

export type ActualCostReportDetailParams = {
  user_id?: string
  report_start_date: any
  report_end_date?: any
}

export type ActualCostReportDetailResponse = {
  data: ActualCostReportDetailItem
  filter_dates: ActualCostReportDetailFilterDates
}

export const actualCostDetailService = async (
  params: ActualCostReportDetailParams
): Promise<ActualCostReportDetailResponse> => {
  const { data } = await api.get<ActualCostReportDetailResponse>(
    `${URI.ACTUAL_COST_REPORT_DETAILS}.json`,
    {
      params: params,
    }
  )
  return data
}

export const actualCostReporDetailDownloadService = async (
  params: ActualCostReportDetailParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `${URI.ACTUAL_COST_REPORT_DETAILS}.xlsx`,
    {
      params: params,
      responseType: 'blob',
    }
  )
  return data
}
