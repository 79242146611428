import { createReducer } from '@reduxjs/toolkit'
import {
  DailyReportDetailItem,
  TagItem,
  TeamDailyReportItem,
} from '@services/dailyreport.services'
import * as actions from '../actions'
import { TimeCardItem } from '@services/time.services'

type StateType = {
  myDailyReportList?: TimeCardItem[]
  teamDailyReportList?: TeamDailyReportItem[]
  hrDailyReportList?: TeamDailyReportItem[]
  dailyReportDetail?: DailyReportDetailItem
  tagList?: TagItem[]
}

const initialState: StateType = {
  myDailyReportList: undefined,
  teamDailyReportList: undefined,
  hrDailyReportList: undefined,
  dailyReportDetail: undefined,
  tagList: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchMyDailyReport.fulfilled, (state, action) => {
    state.myDailyReportList = action.payload.data
  })
  builder.addCase(actions.fetchTeamDailyReport.fulfilled, (state, action) => {
    state.teamDailyReportList = action.payload.data
  })
  builder.addCase(actions.fetchHrDailyReport.fulfilled, (state, action) => {
    state.hrDailyReportList = action.payload.data
  })
  builder.addCase(actions.updateDailyReport.fulfilled, (state, action) => {
    state.dailyReportDetail = action.payload.data
  })
  builder.addCase(actions.clearReports, (state) => {
    state.hrDailyReportList = undefined
    state.teamDailyReportList = undefined
  })
  builder.addCase(actions.fetchTagList.fulfilled, (state, action) => {
    state.tagList = action.payload.data
  })
})
