import * as React from 'react'
import { Box, Button, Checkbox, Grid, ListItem, Stack } from '@mui/material'
import { monthType } from '.'
import { TimeOffReportTotalItem } from '@services/timeoff.services'

import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TimeOffTemplates } from '@components/@data-grid/TimeOffReportGrid'

import AutoCompleteSelect from '@components/@material-extend/SelectWithSearch/AutoCompleteSelect'
import CustomDatePicker from '@components/@material-extend/CustomDatePicker'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

interface TimeoffTotalReportFilterProps {
  months: monthType[]
  timeOffReportTotalList: TimeOffReportTotalItem[]
  onFilter?: (data: any) => void
}
const TimeoffTotalReportFilter: React.FC<TimeoffTotalReportFilterProps> = ({
  months,
  timeOffReportTotalList,
  onFilter,
}) => {
  const { t } = useTranslation()

  const initialValues = {
    month: months ? [months[0]] : [],
    vacations: [],
    year: dayjs.utc(),
  }
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        month: Yup.array().of(Yup.object()).required('Month is required'),
        vacations: Yup.array()
          .of(Yup.object())
          .required('Vacation type is required'),
        year: Yup.date().nullable(),
      }),
    []
  )

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })
  const selectedMonths = watch('month')

  const filterReport = (data: any) => {
    const monthList =
      data.month?.map(({ id }: monthType) => {
        const year = data?.year.getFullYear()
        const month = id.toString().padStart(2, '0')
        return `${year}-${month}-01`
      }) ?? []

    const timoffTemplateList = data?.vacations?.map(
      (template: TimeOffTemplates) => template?.timeoff_template_id
    )
    if (onFilter) {
      onFilter({
        months: monthList,
        timeoff_templates: timoffTemplateList,
      })
    }
  }

  const flattenedData = _.flatten(
    (timeOffReportTotalList ?? []).map((report) => report?.data ?? [])
  )
  const allTemplates: TimeOffTemplates[] = _.uniqBy(
    flattenedData,
    'timeoff_template_id'
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <Stack sx={{ width: '100%' }} direction="row" spacing={1}>
            {/* Year Picker */}
            <Controller
              control={control}
              name="year"
              render={({ field: { value, onChange } }) => (
                <Box sx={{ width: '40%' }}>
                  <CustomDatePicker
                    inputFormat="YYYY"
                    views={['year']}
                    isHelperShow={false}
                    value={value}
                    onChange={onChange}
                    error={false}
                  />
                </Box>
              )}
            />

            {/* Month Selection Autocomplete */}
            <Controller
              name="month"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box sx={{ width: '60%' }}>
                  <AutoCompleteSelect
                    value={value}
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    disablePortal={false}
                    data={months}
                    getOptionLabel={(option) => t(option.month)}
                    placeholder={t('TIMEOFF_TOTAL_REPORT.month')}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <ListItem component="span" {...props} key={option.id}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.month}
                        </ListItem>
                      )
                    }}
                    onInputChange={onChange}
                    isHelperShow={false}
                  />
                </Box>
              )}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            spacing={1}
            alignItems="flex-center "
          >
            {/* Vacation Type Selection Autocomplete */}
            <Controller
              name="vacations"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box sx={{ flexGrow: 1 }}>
                  <AutoCompleteSelect
                    value={value}
                    fullWidth
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    isOptionEqualToValue={(option: any, value: any) => {
                      console.warn('isOption: ', option, value)
                      return (
                        option.timeoff_template_id === value.timeoff_template_id
                      )
                    }}
                    disablePortal={false}
                    data={allTemplates}
                    getOptionLabel={(option) => t(option.timeoff_template_name)}
                    placeholder={t('TIMEOFF_TOTAL_REPORT.vacations')}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <ListItem
                          component="span"
                          {...props}
                          key={option.timeoff_template_id}
                        >
                          <Checkbox
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.timeoff_template_name}
                        </ListItem>
                      )
                    }}
                    onInputChange={onChange}
                    isHelperShow={false}
                  />
                </Box>
              )}
            />
            <Box sx={{ pt: 0.3 }}>
              <Button
                onClick={handleSubmit(filterReport)}
                disabled={!selectedMonths || selectedMonths.length === 0}
                data-test-id="filter-button"
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('TIMEOFF_TOTAL_REPORT.submit')}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TimeoffTotalReportFilter
