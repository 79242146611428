import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  SkillCategoryItem,
  SkillItem,
  SkillReportListItem,
  UserSkillItem,
} from '@services/skill.services'
import { clearEmployee } from '@store/user/actions'
import _ from 'lodash'
import * as actions from '../actions'

type StateType = {
  skillCategoryList?: SkillCategoryItem[]
  allSkillCategoryList?: SkillCategoryItem[]
  skillCategoryListMeta?: MetaPage

  skillList?: SkillItem[]
  skillListMeta?: MetaPage
  allSkills?: SkillItem[]
  addableSkillList?: SkillItem[]

  skillReportList?: SkillReportListItem[]

  userSkillsAll?: UserSkillItem[]
}

const initialState: StateType = {
  skillCategoryList: undefined,
  allSkillCategoryList: undefined,
  skillCategoryListMeta: undefined,

  skillList: undefined,
  skillListMeta: undefined,
  allSkills: undefined,

  skillReportList: undefined,

  userSkillsAll: undefined,
  addableSkillList: undefined,
}

export default createReducer(initialState, (builder) => {
  //SKILL CATEGORY
  builder.addCase(actions.fetchSkillCategoryList.fulfilled, (state, action) => {
    state.skillCategoryList = action.payload.data
    state.skillCategoryListMeta = action.payload.meta
  })
  builder.addCase(
    actions.fetchAllSkillCategoryList.fulfilled,
    (state, action) => {
      state.allSkillCategoryList = action.payload.data
    }
  )
  builder.addCase(actions.updateSkillCategory.fulfilled, (state, action) => {
    state.skillCategoryList = state.skillCategoryList
      ? _.map(state.skillCategoryList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  //SKILL
  builder.addCase(actions.fetchSkillList.fulfilled, (state, action) => {
    state.skillList = action.payload.data
    state.skillListMeta = action.payload.meta
  })
  builder.addCase(actions.updateSkill.fulfilled, (state, action) => {
    state.skillList = state.skillList
      ? _.map(state.skillList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.fetchAllSkillList.fulfilled, (state, action) => {
    state.allSkills = action.payload.data
  })
  builder.addCase(actions.fetchAddableSkillList.fulfilled, (state, action) => {
    state.addableSkillList = action.payload.data
  })
  //REPORT
  builder.addCase(actions.fetchSkillReportList.fulfilled, (state, action) => {
    state.skillReportList = action.payload
  })
  // USER SKILLS
  builder.addCase(actions.fetchUserSkillListAll.fulfilled, (state, action) => {
    state.userSkillsAll = action.payload.data
  })
  builder.addCase(actions.fetchUserSkillsById.fulfilled, (state, action) => {
    state.userSkillsAll = action.payload.data
  })
  builder.addCase(clearEmployee, (state) => {
    state.userSkillsAll = undefined
  })
  builder.addCase(actions.updateUserSkills.fulfilled, (state, action) => {
    const existingSkills = state.userSkillsAll
    if (existingSkills) {
      for (const updatedSkill of action.payload.data) {
        const existingSkillIndex = existingSkills.findIndex(
          (skill) => skill.id === updatedSkill.id
        )
        if (existingSkillIndex !== -1) {
          existingSkills[existingSkillIndex] = updatedSkill
        }
      }
    }

    state.userSkillsAll = existingSkills
  })
  builder.addCase(actions.updateSingleSkill.fulfilled, (state, action) => {
    state.userSkillsAll = state.userSkillsAll
      ? _.map(state.userSkillsAll, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : state.userSkillsAll
  })
})
