import { yupResolver } from '@hookform/resolvers/yup'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const useWorkspaceChangeForm = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .min(
            6,
            t('ERROR.E000050', {
              field: t('WORKSPACE.verification_code'),
              number: 6,
            })
          )
          .max(
            6,
            t('ERROR.E000050', {
              field: t('WORKSPACE.verification_code'),
              number: 6,
            })
          )
          .required()
          .typeError(
            t('ERROR.E000051', { field: t('WORKSPACE.verification_code') })
          ),
        workspace_id: Yup.string().required(
          t('ERROR.E000001', {
            field: t('WORKSPACE.title'),
          })
        ),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      code: null,
      workspace_id: {
        id: '',
        name: '',
      },
    },
    mode: 'onChange',
  })

  return { Controller, methods }
}

export default useWorkspaceChangeForm
