import React from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Badge, { BadgeProps } from '@mui/material/Badge'

interface IconButtonExtendProps extends IconButtonProps {
  children: any
  elevation: number
  badge?: BadgeProps
}

const IconButtonExtend: React.FC<IconButtonExtendProps> = ({
  children,
  elevation,
  badge,
  ...restProps
}) => {
  return (
    <IconButton
      {...restProps}
      sx={{
        width: 40,
        height: 40,
        boxShadow: elevation,
        padding: 1.25,
        ...restProps.sx,
      }}
    >
      {badge ? (
        <Badge
          variant={badge.variant}
          color="primary"
          badgeContent={badge.badgeContent}
        >
          {children}
        </Badge>
      ) : (
        children
      )}
    </IconButton>
  )
}

export default IconButtonExtend
