import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'

const getRoot = (state: RootState) => state.notification

export const getNotifyList = createSelector(getRoot, (state) => state.list)
export const getNotifyShortList = createSelector(
  getRoot,
  (state) => state.shortList
)
export const getNotifyPaging = createSelector(
  getRoot,
  (state) => state.listPaging
)
export const getBadgeCount = createSelector(getRoot, (state) => state.count)
