import { ConfirmProvider } from '@components/Confirm'
import { SnackBarProvider } from '@components/Toast'
import { defaultConfirmationOptions } from '@constants/common.constants'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { store } from '@store/store'
import theme from '@theme/index'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { LayoutProvider } from '@layouts/MainLayout/LayoutProvider'
import '@locales/i18n'
import { UploaderProvider } from '@components/FileUpload'
import RealTimeInjector from '@containers/@firebase/RealTimeInjector'
import TimeOffProvider from '@components/TimeOff/TimeOffProvider'
import ReportProvider from '@components/MissingDailyReport/ReportProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <GoogleOAuthProvider
    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
  >
    <Provider store={store}>
      <PersistGate loading={<>Loading</>} persistor={persistStore(store)}>
        <ThemeProvider theme={theme}>
          <SnackBarProvider />
          <UploaderProvider>
            <ReportProvider defaultOptions={{}}>
              <RealTimeInjector />
              <LayoutProvider>
                <ConfirmProvider defaultOptions={defaultConfirmationOptions}>
                  <TimeOffProvider>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <App />
                  </TimeOffProvider>
                </ConfirmProvider>
              </LayoutProvider>
            </ReportProvider>
          </UploaderProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
