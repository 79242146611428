import { MetaPage } from '@constants/common.constants'
import { RESOURCE_PLAN_STATUS } from '@constants/resource.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  CostReportListItem,
  DepartmentItem,
  PositionItem,
  ResourcePlanActivityItem,
  ResourcePlanDetails,
  ResourcePlanItem,
  ResourcePlanListItem,
  RoleItem,
  RoleModuleItem,
} from '@services/resource.services'
import { EmployeeItem, EmployeeShortItem } from '@services/user.services'
import _ from 'lodash'
import * as actions from '../actions'

type StateType = {
  positionList?: PositionItem[]
  positionListAll?: PositionItem[]
  positionMeta?: MetaPage
  positionCreate?: PositionItem
  positionDetail?: PositionItem

  departmentList?: DepartmentItem[]
  departmentMeta?: MetaPage
  departmentCreate?: DepartmentItem
  departmentDetail?: DepartmentItem

  roleList?: RoleItem[]
  roleMeta?: MetaPage
  roleCreate?: RoleItem
  roleModule?: RoleModuleItem[]
  roleListAll?: RoleItem[]

  resourcePlanList?: ResourcePlanListItem[]
  resourcePlanListMeta?: MetaPage
  resourcePlanListAll?: ResourcePlanListItem[]
  resourcePlanDetail?: ResourcePlanItem
  // resourcePlanInfo is only for storing detail.resource_plan for optimizing performance
  // when project is renamed / confirmed / refused table data doesn't need to be refreshed
  resourcePlanInfo?: ResourcePlanDetails
  resourcePlanUsers?: EmployeeItem[]
  resourcePlanActivities?: ResourcePlanActivityItem[]
  addableUsers?: EmployeeShortItem[]
  costItemsList?: CostReportListItem[]
  costListMeta?: MetaPage
}

const initialState: StateType = {
  positionList: undefined,
  positionListAll: undefined,
  positionCreate: undefined,
  positionDetail: undefined,
  positionMeta: undefined,

  departmentList: undefined,
  departmentCreate: undefined,
  departmentDetail: undefined,
  departmentMeta: undefined,

  roleList: undefined,
  roleMeta: undefined,
  roleCreate: undefined,
  roleModule: undefined,

  resourcePlanList: undefined,
  resourcePlanListMeta: undefined,
  resourcePlanListAll: undefined,
  resourcePlanDetail: undefined,
  resourcePlanInfo: undefined,
  resourcePlanUsers: undefined,
  resourcePlanActivities: undefined,
  addableUsers: undefined,
}

export default createReducer(initialState, (builder) => {
  // POSITION
  builder.addCase(actions.fetchPositionList.fulfilled, (state, action) => {
    state.positionList = action.payload.data
    state.positionMeta = action.payload.meta
  })
  builder.addCase(actions.fetchCostList.fulfilled, (state, action) => {
    state.costItemsList = action.payload.data
    state.costListMeta = action.payload.meta
  })
  builder.addCase(actions.fetchPositionListAll.fulfilled, (state, action) => {
    state.positionListAll = action.payload.data
  })
  builder.addCase(actions.createPosition.fulfilled, (state, action) => {
    state.positionCreate = action.payload.data
  })
  builder.addCase(actions.showPosition.fulfilled, (state, action) => {
    state.positionDetail = action.payload.data
  })
  builder.addCase(actions.updatePosition.fulfilled, (state, action) => {
    state.positionList = state.positionList
      ? _.map(state.positionList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  // DEPARTMENT
  builder.addCase(actions.fetchDepartmentList.fulfilled, (state, action) => {
    state.departmentList = action.payload.data
    state.departmentMeta = action.payload.meta
  })
  builder.addCase(actions.createDepartment.fulfilled, (state, action) => {
    state.departmentCreate = action.payload.data
  })
  builder.addCase(actions.showDepartment.fulfilled, (state, action) => {
    state.departmentDetail = action.payload.data
  })
  builder.addCase(actions.updateDepartment.fulfilled, (state, action) => {
    state.departmentList = state.departmentList
      ? _.map(state.departmentList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  // ROLE REDUCERS
  builder.addCase(actions.fetchRoleList.fulfilled, (state, action) => {
    state.roleList = action.payload.data
    state.roleMeta = action.payload.meta
  })
  builder.addCase(actions.createRole.fulfilled, (state, action) => {
    state.roleCreate = action.payload.data
  })
  builder.addCase(actions.updateRole.fulfilled, (state, action) => {
    state.roleList = state.roleList
      ? _.map(state.roleList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.fetchRoleModuleList.fulfilled, (state, action) => {
    state.roleModule = action.payload.data
  })

  builder.addCase(actions.fetchRoleListAll.fulfilled, (state, action) => {
    state.roleListAll = action.payload.data
  })
  // RESOURCE PLAN
  builder.addCase(actions.fetchResourcePlanList.fulfilled, (state, action) => {
    state.resourcePlanList = action.payload.data
    state.resourcePlanListMeta = action.payload.meta
  })
  builder.addCase(actions.batchResourcePlan.fulfilled, (state) => {
    state.resourcePlanInfo = state.resourcePlanInfo && {
      ...state.resourcePlanInfo,
      batch_count: state.resourcePlanInfo?.batch_count + 1,
      is_calculated: true,
    }
  })
  builder.addCase(
    actions.fetchResourcePlanListAll.fulfilled,
    (state, action) => {
      state.resourcePlanListAll = action.payload.data
    }
  )
  builder.addCase(actions.showResourcePlan.fulfilled, (state, action) => {
    state.resourcePlanDetail = action.payload.data
    state.resourcePlanInfo = action.payload.data?.resource_plan
  })
  builder.addCase(actions.clearResourcePlanDetails, (state) => {
    state.resourcePlanDetail = undefined
    state.resourcePlanInfo = undefined
    state.resourcePlanUsers = undefined
    state.resourcePlanActivities = undefined
  })
  builder.addCase(actions.updateResourcePlan.fulfilled, (state, action) => {
    // TODO: list ee shinechleh
    state.resourcePlanDetail = action.payload.data
  })
  builder.addCase(actions.renameResourcePlan.fulfilled, (state, action) => {
    state.resourcePlanInfo = action.payload.data.resource_plan
  })
  builder.addCase(actions.confirmResourcePlan.fulfilled, (state, _) => {
    if (state.resourcePlanInfo) {
      state.resourcePlanInfo = {
        ...state.resourcePlanInfo,
        status: RESOURCE_PLAN_STATUS.CONFIRMED,
      }
    }
  })
  builder.addCase(actions.refuseResourcePlan.fulfilled, (state, _) => {
    if (state.resourcePlanInfo) {
      state.resourcePlanInfo = {
        ...state.resourcePlanInfo,
        status: RESOURCE_PLAN_STATUS.PLANNING,
        is_calculated: false,
      }
    }
  })
  builder.addCase(actions.fetchResourcePlanUsers.fulfilled, (state, action) => {
    state.resourcePlanUsers = action.payload.data
  })
  builder.addCase(
    actions.fetchResourcePlanActivities.fulfilled,
    (state, action) => {
      state.resourcePlanActivities = action.payload.data
    }
  )
  builder.addCase(actions.addableResourceUsers.fulfilled, (state, action) => {
    state.addableUsers = action.payload.data
  })
})
