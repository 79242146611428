import { CostMemberItem, PurchasePositionItem } from '@services/report.services'
import { GroupedCostReportItem } from '@store/report/selectors/helper'
import dayjs from 'dayjs'
import _ from 'lodash'

/**
 * Retrieves the purchased position short name for a member.
 *
 * @param {CostMemberItem} member - The member object.
 * @returns {string} The purchased position short name, if available; otherwise an empty string.
 */

export const getPurchasedPosition = (member: CostMemberItem) => {
  if (
    !_.isEmpty(member.purchased_position) &&
    _.get(member, 'purchased_position.id') !== _.get(member, 'position.id')
  ) {
    return `${member.purchased_position.short_name}`
  } else {
    return ''
  }
}

/**
 * Counts the number of members with a purchased position.
 *
 * @param {CostMemberItem[]} members - An array of CostMemberItem objects.
 * @returns {number} The count of members with a purchased position.
 */

export const getPurchasedMemberCost = (
  members: CostMemberItem[],
  position: PurchasePositionItem
) => {
  let cost = 0
  _.map(members, (member) => {
    if (
      !_.isEmpty(member.purchased_position) &&
      member.purchased_position.id === position.id
    ) {
      cost += member.planned_cost
    }
  })
  return cost
}

/**
 * Counts the number of members with a purchased position.
 *
 * @param {CostMemberItem[]} members - An array of CostMemberItem objects.
 * @returns {number} The count of members with a purchased position.
 */
export const calculatePositionCosts = (dataItem: GroupedCostReportItem) => {
  const totalRowSpan = _.reduce(
    dataItem.data,
    (acc, project) => {
      const pos = _.chain(project.members).map('position.name').uniq().value()
      const merged = [
        ...pos,
        ..._.chain(project.partner_purchase_orders)
          .map('position.name')
          .uniq()
          .value(),
      ]
      const totalRow = _.chain(merged).uniq().value().length //Calculating division rowspan

      return acc + totalRow
    },
    0
  )

  // Mapping each data item to group members by position ID and calculate total cost for each position
  const result = _.map(dataItem.data, (project) => {
    const groupedMembers = _.groupBy(project.members, 'position.id')

    const positionsInFirstArray = _.flatMap(groupedMembers, (innerArray) =>
      _.map(innerArray, (item) => {
        return {
          position: {
            id: item.position.id,
          },
        }
      })
    ) // make new position array from data

    // Find positions in the second array that don't exist in the first array using lodash
    const positionsNotInFirstArray = _.differenceBy(
      project.partner_purchase_orders,
      positionsInFirstArray,
      'position.id'
    )

    // Log the result
    const positionData = _.map(groupedMembers, (members, positionId) => {
      const orderCost = _.find(
        project.partner_purchase_orders,
        (order) => order.position.id === Number(positionId)
      )
      return {
        position_id: positionId,
        position_name: members[0].position.short_name,
        display_order: members[0].position.display_order,
        totalCost: _.sumBy(members, 'cost'),
        orderedCost: orderCost ? orderCost.cost : 0,
      }
    })

    const additionalData = _.map(positionsNotInFirstArray, (g: any) => ({
      position_id: g.position.id,
      position_name: g.position.short_name,
      display_order: g.position.display_order,
      totalCost: null,
      orderedCost: g.cost,
    }))

    return [...positionData, ...additionalData]
  })

  const flattenedResult = _.flatten(result)

  // Calculation total cost of position in division section
  const finalResult = _.map(
    _.groupBy(flattenedResult, 'position_id'),
    (group) => {
      return {
        position_id: group[0].position_id,
        position_name: group[0].position_name,
        display_order: group[0].display_order,
        totalCost: _.sumBy(group, 'totalCost'),
        orderedTotalCost: _.sumBy(group, 'orderedCost'),
        totalProject: group.length,
      }
    }
  ).flat()

  const orderedTotalData = _.orderBy(finalResult, 'display_order')

  return { totalRowSpan, orderedTotalData }
}

export const shouldDisableMonth = (date: any) => {
  return dayjs(date).month() > dayjs().month()
}

export const shouldDisableYear = (date: any) => {
  return dayjs(date).year() > dayjs().year()
}

export const shouldDisableDate = (date: any) => {
  return dayjs(date.toDate()).isAfter(dayjs().endOf('month'))
}
