/* eslint-disable @typescript-eslint/no-unused-vars */
import { MODULES } from '@constants/modules.constants'
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useCurrentRole from 'utils/hooks/useCurrentRole'
import _ from 'lodash'
import { ROLE_PERMISSION } from '@constants/user.constants'

interface ProtectedOutletProps {
  redirectPath?: string
  module: MODULES
}

const ProtectedFromRead: React.FC<ProtectedOutletProps> = ({
  redirectPath,
  module,
}) => {
  const { permissions } = useCurrentRole()
  const [isLoading, setIsLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)

  useEffect(() => {
    // Check if permissions data is available
    if (permissions !== undefined && _.isArray(permissions)) {
      // Check if the required module permission is present
      const foundItem = _.find(
        permissions,
        (item) => item.attributes.name === module
      )

      setIsAllowed(
        !!(
          (foundItem && foundItem.attributes.type === ROLE_PERMISSION.ADMIN) ||
          (foundItem && foundItem.attributes.type === ROLE_PERMISSION.WRITE)
        )
      )
    }

    // eslint-disable-next-line no-console

    setIsLoading(false)
  }, [permissions, module])

  if (!isAllowed && !isLoading) {
    return <Navigate to={redirectPath ? redirectPath : '/403'} replace />
  }

  return <Outlet />
}

export default ProtectedFromRead
