import MuiAlert, { AlertColor } from '@mui/material/Alert'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { removeToast } from '@store/common/actions'
import { getToasts } from '@store/common/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export type toastProps = {
  message: string
  severity?: AlertColor | undefined
  uuid: string
  anchorOrigin?: SnackbarOrigin | undefined
  autoHideDuration?: number | null
}

const SnackBarProvider: React.FC = () => {
  const dispatch = useDispatch()
  const toasts = useSelector(getToasts)
  const remove = (uuid: string) => dispatch(removeToast(uuid))
  return (
    <>
      {toasts &&
        toasts.map((t: toastProps, idx: number) => (
          <Snackbar
            key={idx}
            open={true}
            anchorOrigin={
              t.anchorOrigin
                ? t.anchorOrigin
                : { vertical: 'top', horizontal: 'right' }
            }
            autoHideDuration={
              t.autoHideDuration !== undefined ? t.autoHideDuration : 2000
            }
            onClose={() => remove(t.uuid)}
          >
            <MuiAlert
              elevation={2}
              sx={{
                lineHeight: 1.8,
                '& .MuiButtonBase-root': {
                  border: '0 none',
                },
              }}
              onClose={() => remove(t.uuid)}
              severity={t.severity}
            >
              {t.message}
            </MuiAlert>
          </Snackbar>
        ))}
    </>
  )
}

export { SnackBarProvider }
