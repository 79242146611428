import { Box, FormControl, FormHelperText, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import React, { ReactElement } from 'react'
import { CustomLabel } from '../StyledTextField'

interface DateTimePickerProps {
  helperText?: string
  labelPrimary?: string
  isHelperShow?: boolean
  labelSecondary?: ReactElement
  required?: boolean
  value: string | Dayjs | null
  onChange: (v: string | null) => void
  error: boolean
  readOnly?: boolean
  format: string
  shouldDisableDate?: ((day: any) => boolean) | undefined
  hideDate?: boolean
}

const CustomDateTimePicker: React.FC<DateTimePickerProps> = ({
  helperText,
  labelPrimary,
  labelSecondary,
  isHelperShow = true,
  required = false,
  value,
  error,
  onChange,
  readOnly = false,
  format = 'YYYY-MM-DD HH:mm',
  shouldDisableDate,
  hideDate = false,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl
        sx={{
          paddingBottom: '5px',
        }}
        fullWidth
      >
        {(labelPrimary || labelSecondary) && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingBottom: 0,
            }}
          >
            <Box sx={{ padding: 0 }} display="flex" alignItems="center">
              <CustomLabel text={labelPrimary ? labelPrimary : ''} />
              {required && (
                <Typography
                  component="span"
                  sx={{
                    paddingLeft: (theme) => theme.spacing(1 / 2),
                    paddingRight: (theme) => theme.spacing(1 / 2),
                    height: 16,
                    fontSize: 10,
                    marginLeft: '5px',
                    color: red[400],
                  }}
                >
                  *
                </Typography>
              )}
            </Box>
            {labelSecondary}
          </Box>
        )}

        <DateTimePicker
          readOnly={readOnly}
          value={value}
          slotProps={{
            textField: { error: error },
            desktopPaper: hideDate
              ? {
                  sx: {
                    '& .MuiDateCalendar-root': {
                      display: 'none !important',
                    },
                    '& .MuiMultiSectionDigitalClock-root': {
                      maxHeight: 200,
                    },
                  },
                }
              : {},
          }}
          defaultValue={null}
          format={format}
          shouldDisableDate={shouldDisableDate}
          onChange={(newValue: any) => {
            onChange(newValue as any)
          }}
          {...rest}
        />

        {isHelperShow &&
          (helperText ? (
            <FormHelperText
              sx={{
                height: 12,
                m: 0,
                lineHeight: 1.2,
              }}
              error={error}
            >
              {helperText}
            </FormHelperText>
          ) : (
            <Box sx={{ height: 12 }} />
          ))}
      </FormControl>
    </LocalizationProvider>
  )
}

export default CustomDateTimePicker
