/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */
import { URI, excludeURI } from '@constants/uri.constants'
import { logOut, loginByEmail } from '@store/auth/actions'
import { StoreType } from '@store/store'
import { api, authApi } from './api'

let subscribers: Function[] = []

function onAccessTokenFetched(access_token: string) {
  subscribers = subscribers.filter((callback: Function) =>
    callback(access_token)
  )
}

function addSubscriber(callback: Function) {
  subscribers.push(callback)
}
export const authorizationProvider = (store: StoreType): void => {
  //  api interceptors header token set
  api.interceptors.request.use(
    (config: any) => {
      const { auth } = store.getState()
      const user = auth.user
      if (user) {
        const token = `Bearer ${user.access_token}`
        config.baseURL = auth.auth.base_url
        if (token) {
          // eslint-disable-next-line no-console
          config.headers.Authorization = `${token}`
        }
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  // auth api interceptors header token set
  authApi.interceptors.request.use(
    (config: any) => {
      const { auth } = store.getState()
      const user = auth.user
      if (user) {
        const token = `Bearer ${user.access_token}`
        if (token) {
          if (config.headers === undefined) {
            config.headers = {}
          }
          config.headers.Authorization = `${token}`
        }
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  //api interceptors

  let isRefreshing = false // shared retry variable

  api.interceptors.response.use(
    (response: any) => {
      return response
    },
    (error: any) => {
      const status = error.response ? error.response.status : null
      if (status === 401) {
        if (!isRefreshing) {
          isRefreshing = true
          const { auth } = store.getState()
          if (auth && auth.user) {
            const user = auth.user
            const refreshToken = user.refresh_token
            authApi
              .post(URI.REFRESH, { refresh_token: refreshToken })
              .then((res) => {
                store.dispatch({
                  type: loginByEmail.fulfilled.toString(),
                  payload: res.data,
                })
                onAccessTokenFetched(res.data.access_token)
              })
              .catch(() => {
                store.dispatch({
                  type: logOut.fulfilled.toString(),
                })
              })
              .finally(() => {
                isRefreshing = false
              })
          }
          const retryRequest = new Promise((resolve) => {
            const originalRequest = error.config
            addSubscriber((access_token: string) => {
              originalRequest.headers.Authorization = 'Bearer ' + access_token
              resolve(authApi.request(originalRequest))
            })
          })

          return retryRequest
        }
      }
      return Promise.reject(error)
    }
  )

  // auth interceptors check exclude urls

  authApi.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const status = error.response ? error.response.status : null
      const url = error.config.url

      if (status === 401) {
        if (excludeURI.includes(url)) {
          if (url === URI.REFRESH) {
            store.dispatch({
              type: logOut.fulfilled.toString(),
            })
          }
          return Promise.reject(error)
        }
        if (!isRefreshing) {
          isRefreshing = true
          const { auth } = store.getState()
          if (auth && auth.user) {
            const user = auth.user
            const refreshToken = user.refresh_token
            authApi
              .post(URI.REFRESH, { refresh_token: refreshToken })
              .then((res) => {
                store.dispatch({
                  type: loginByEmail.fulfilled.toString(),
                  payload: res.data,
                })
                onAccessTokenFetched(res.data.access_token)
              })
              .catch(() => {
                store.dispatch({
                  type: logOut.fulfilled.toString(),
                })
              })
              .finally(() => {
                isRefreshing = false
              })
          }
          const retryRequest = new Promise((resolve) => {
            const originalRequest = error.config
            addSubscriber((access_token: string) => {
              originalRequest.headers.Authorization = 'Bearer ' + access_token
              resolve(authApi.request(originalRequest))
            })
          })

          return retryRequest
        }
      }

      return Promise.reject(error)
    }
  )
}
