import useNotify from '@containers/@modules/Notification/useNotify'
import useAuth from '@utils/hooks/useAuth'
import useFirebaseListener from '@utils/hooks/useFirebaseListener'
import useInit from '@utils/hooks/useInit'
import _ from 'lodash'
import { useEffect } from 'react'

const RealTimeInjector: React.FC = () => {
  const { initApp: app, notifications } = useFirebaseListener()
  const { initApp, baseURL } = useInit()
  const { isLoggedIn } = useAuth()
  const { fetchCount } = useNotify()

  useEffect(() => {
    if (!_.isEmpty(baseURL) && isLoggedIn) {
      initApp()
      // eslint-disable-next-line no-console
      console.info(
        '%cSyncing common data',
        'color: white; font-size: 12px; background:green; padding:5px;',
        'id:',
        app
      )
    }
  }, [isLoggedIn, baseURL, app])

  useEffect(() => {
    if (notifications) {
      fetchCount()
      //fetch notification
      // eslint-disable-next-line no-console
      console.info(
        '%cSyncing notification',
        'color: white; font-size: 12px; background:blue; padding:5px;',
        'id:',
        notifications
      )
    }
  }, [notifications])

  // This component doesn't render anything visible
  return null
}

export default RealTimeInjector
