import * as React from 'react'
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  Span,
  CellStyle,
} from '@silevis/reactgrid'
import { Box } from '@mui/material'
import { SkillReportUserItem } from '@services/skill.services'
import SkillEmployeeInfo from './SkillEmployeeInfo'

export interface SkillEmployeeInfoCell extends Cell, Span {
  type: 'skillemployee'
  text: SkillReportUserItem
  tooltip: string
}

export class SkillEmployeeCellTemplate
  implements CellTemplate<SkillEmployeeInfoCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<SkillEmployeeInfoCell>
  ): Compatible<SkillEmployeeInfoCell> {
    const text = getCellProperty(uncertainCell, 'text', 'object')
    const tooltip = getCellProperty(uncertainCell, 'tooltip', 'string')

    const value = text

    return { ...uncertainCell, text, value, tooltip }
  }

  render(
    cell: Compatible<SkillEmployeeInfoCell>,
    _isInEditMode: boolean,
    _onCellChanged: (
      cell: Compatible<SkillEmployeeInfoCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return (
      <Box sx={{ width: '100%', background: '#fff' }}>
        <SkillEmployeeInfo member={cell.text} tooltip={cell.tooltip} />
      </Box>
    )
  }

  isFocusable = (_cell: Compatible<SkillEmployeeInfoCell>): boolean => false

  getClassName(
    cell: Compatible<SkillEmployeeInfoCell>,
    _isInEditMode: boolean
  ): string {
    return cell.className ? cell.className : ''
  }

  getStyle = (_cell: Compatible<SkillEmployeeInfoCell>): CellStyle => ({
    background: '#fff',
  })
}
