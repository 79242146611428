import { ROUTE } from './route.constants'

export enum RESOURCE_PLAN_STATUS {
  PLANNING = 'planning',
  CONFIRMED = 'confirmed',
}

export type ResourcePlanStatusLabels = {
  [key: string]: {
    color: any
    label: string
  }
}

export const RESOURCE_PLAN_STATUS_LABELS: ResourcePlanStatusLabels = {
  [`${RESOURCE_PLAN_STATUS.PLANNING}`]: {
    color: 'default',
    label: 'RESOURCE_PLAN.status_planning',
  },
  [`${RESOURCE_PLAN_STATUS.CONFIRMED}`]: {
    color: 'success',
    label: 'RESOURCE_PLAN.status_confirmed',
  },
}

export enum RESOURCE_PLAN_CONFIRM_STATUS {
  CONFIRMED = 'Confirmed',
  REFUSED = 'Refused',
}

export enum RESOURCE_PLAN_BATCH_STATUS {
  SUCCESS = 'success',
}

export type ResourcePlanUsersFilter = {
  position_ids: string[]
}

export const ResourcePlanUsersDefaultFilter = {
  position_ids: [],
}

export const EmployeeListGroups = [
  ROUTE.EMPLOYEE.EDIT,
  ROUTE.EMPLOYEE.CREATE,
  ROUTE.EMPLOYEE.DETAILS,
]

export enum ResourcePlanActions {
  CREATED = 'created',
  RENAME = 'rename',
  CONFIRM = 'confirm',
  UNCONFIRM = 'unconfirm',
  CALCULATED = 'calculated',
}

export enum ResourceDataGridColumnWidth {
  employee = 350,
  total = 50,
  customPosition = 130,
  project = 100,
}

export enum WorkspaceStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export const WORKSPACE_STATUS_LABEL: ResourcePlanStatusLabels = {
  [`${WorkspaceStatus.INACTIVE}`]: {
    color: 'default',
    label: 'WORKSPACE.in_active',
  },
  [`${WorkspaceStatus.ACTIVE}`]: {
    color: 'success',
    label: 'WORKSPACE.active',
  },
}
