import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const initialValues = {
  expire_at: dayjs(),
  comment: '',
}

const useUserCompensatoryEditForm = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        expire_at: Yup.date(),
        comment: Yup.string().max(
          255,
          t('ERROR.E000046', {
            field: t('COMPANY.description_placeholder'),
            number: 255,
          })
        ),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods }
}

export default useUserCompensatoryEditForm
