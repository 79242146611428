import { Download } from '@mui/icons-material'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ReportDownloaderProps {
  fetchCSV: () => void
}

const ReportDownloader: React.FC<ReportDownloaderProps> = ({ fetchCSV }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Button
      variant="contained"
      color="primary"
      onClick={() => fetchCSV()}
      sx={{
        color: 'white',
      }}
    >
      <Download />
    </Button>
  ) : (
    <Button
      data-test-id="download-button"
      variant="contained"
      color="primary"
      startIcon={<Download />}
      onClick={() => fetchCSV()}
    >
      {t('SYSCOMMON.download')}
    </Button>
  )
}

export default ReportDownloader
