export enum USER_ACTION_TYPE {
  GET_PROFILE = 'user/profile',
  GET_EMPLOYEE_LIST = 'employee/list',
  GET_EMPLOYEE_PUBLIC_LIST = 'employee/publicList',
  GET_EMPLOYEE_ALL = 'employee/all',
  GET_EMPLOYEE_ACTIVE_PLUS_QUIT = 'employee/activePlusQuit',
  GET_PRIVATE_EMPLOYEE_LIST = 'employee/private/list',
  CREATE_EMPLOYEE = 'employee/add',
  DELETE_EMPLOYEE = 'employee/delete',
  UPDATE_EMPLOYEE = 'employee/update',
  SHOW_EMPLOYEE = 'employee/detail',
  SHOW_PUBLIC_EMPLOYEE = 'employee/publicDetail',
  QUIT_EMPLOYEE = 'employee/quit',
  BACK_TO_WORK_EMPLOYEE = 'employee/backToWork',
  BACK_TO_WORK_FROM_LONG_TERM = 'employee/backToWorkFromLongTerm',
  GET_EMPLOYEE_DEPARTMENTS = 'employee/departments',
  GET_EMPLOYEE_POSIITONS = 'employee/positions',
  GET_EMPLOYEE_PROJECTS = 'employee/projects',
  UPDATE_PROFILE = 'user/profileUpdate',
  UPDATE_AVATAR = 'user/avatarUpdate',
  QUIT_REASON = 'users/quitReason',
  INFO_LOG = 'users/infoLog',
  UPDATE_QUIT_REASON = 'reason/update',
  RESET_PASSWORD = 'employee/resetpassword',
}
