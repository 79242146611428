import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import React from 'react'
import {
  VacationReportDetailItem,
  VacationReportItem,
} from '@services/vacation.services'
import { useTranslation } from 'react-i18next'
import { SkillReportUserItem } from '@services/skill.services'
import SkillEmployeeInfo from '@components/@data-grid/Cell/SkillEmployeeInfo'

interface Props {
  key: number
  row: VacationReportItem
  selectedEmployeeId: string
  handleRowClick: (id: string) => any
  vacationReportDetails: VacationReportDetailItem[]
}
const VacationDetailFunction: React.FC<Props> = ({
  row,
  handleRowClick,
  selectedEmployeeId,
  vacationReportDetails,
}) => {
  const isSelected = selectedEmployeeId === row.user_id.toString()
  const { t } = useTranslation()
  const userCellTemplate: SkillReportUserItem = {
    id: row.user_id,
    name: row.lastname,
    firstname_en: row.firstname_en,
    user_code: row.user_code,
    lastname_en: row.lastname_en,
    position: {
      id: row.position.data.id,
      name: row.position.data.attributes?.name,
      short_name: row.position.data.attributes?.short_name,
      display_order: row.position.data.attributes?.display_order,
    },
  }
  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset', padding: '6px 8px' },
          boxShadow: isSelected ? '0 4px 10px rgba(0,0,0,0.1)' : 'none',
          height: '100%',
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ p: 0 }}
            onClick={() => handleRowClick(row.user_id.toString())}
          >
            {isSelected ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          scope="row"
          sx={{
            width: '350px',
            height: '100%',
            borderBottomWidth: 0,
            '&.MuiTableCell-root': { p: 0 },
            '& .MuiTableCell-root.loader-holder-cell': {
              p: 0,
              height: 0,
            },
          }}
        >
          <SkillEmployeeInfo member={userCellTemplate} tooltip="" />
        </TableCell>
        <TableCell align="center" sx={{ borderBottom: 0 }}>
          {row.used_days}
        </TableCell>
        <TableCell align="center" sx={{ borderBottom: 0 }}>
          {row.remaining_days}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse timeout="auto" in={isSelected} unmountOnExit>
            <Box
              sx={{
                marginY: 2,
                marginLeft: 1,
                ml: '33px',
                mr: '10px',
              }}
            >
              <Table
                size="small"
                aria-label="purchases"
                sx={{
                  borderLeft: '1px solid #e0e0e0',
                  WebkitTextFillColor: '#595959',
                  '& .MuiTableCell-root': {
                    fontSize: 12,
                  },
                }}
              >
                <TableHead
                  sx={{
                    borderTop: '1px solid #e0e0e0',
                  }}
                >
                  <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                    <TableCell align="center">
                      {t('VACATION_REPORT.year')}
                    </TableCell>
                    <TableCell align="center">
                      {t('VACATION_REPORT.used_days')}
                    </TableCell>
                    <TableCell align="center">
                      {t('VACATION_REPORT.remaining_days')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vacationReportDetails &&
                    vacationReportDetails.map((detailrows) => (
                      <TableRow key={detailrows.year}>
                        <TableCell
                          align="center"
                          scope="row"
                          sx={{ width: '363px' }}
                        >
                          {detailrows.year}
                        </TableCell>
                        <TableCell align="center">
                          {detailrows.used_days}
                        </TableCell>
                        <TableCell align="center">
                          {detailrows.remaining_days}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
export default VacationDetailFunction
