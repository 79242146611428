import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'

const getRoot = (state: RootState) => state.auth

export const getAuth = createSelector(getRoot, (state) => state.user)

export const getStatus = createSelector(getRoot, (state) => state.status)

export const currentUserLang = createSelector(
  getRoot,
  (state) => state.user?.language
)

export const getIsAuthenticated = createSelector(
  getRoot,
  (state) => !!state.auth?.isLoggedIn
)

export const activeProject = createSelector(getRoot, (state) => {
  return state.activeProject
})

export const baseURL = createSelector(getRoot, (state) => state.auth.base_url)
