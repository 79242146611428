export enum VACATION_BALANCE_TYPE {
  VACATION_BALANCE_LIST = 'vacation-balance/list',
  VACATION_BALANCE_CREATE = 'vacation-balance/create',
  VACATION_BALANCE_UPDATE = 'vacation-balance/update',
  VACATION_BALANCE_DELETE = 'vacation-balance/delete',
  VACATION_BALANCE_CALCULATE = 'vacation-balance/calculate',
  VACATION_BALANCE_CALCULATE_FOR_USER = 'vacation-balance/calculateForUser',
  VACATION_BALANCE_MY_DATA = 'vacation-balance/myData',
  VACATION_BALANCE_USER_HISTORY = 'vacation-balance/userHistory',
  VACATION_REPORT_LIST = 'vacation-report/list',
  VACATION_REPORT_DETAIL = 'vacation-report/detail',
}
