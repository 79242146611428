export enum SETTINGS_ACTION_TYPE {
  GET_CONTENT = 'settings/content',

  EVENT_LIST_ALL = 'settings/eventListAll',
  EVENT_LIST_PUBLIC = 'settings/eventListPublic',
  EVENT_CREATE = 'settings/eventCreate',
  EVENT_UPDATE = 'settings/eventUpdate',
  EVENT_DELETE = 'settings/eventDelete',
  HELP_CLEAR = 'help/clear',

  MEETING_ROOM_LIST = 'settings/meetingRoomList',
  MEETING_ROOM_CREATE = 'settings/meetingRoomCreate',
  MEETING_ROOM_UPDATE = 'settings/meetingRoomUpdate',
  UPDATE_MEETING_ROOM_ORDER = 'settings/meetingRoomUpdateOrder',
  MEETING_ROOM_DELETE = 'settings/meetingRoomDelete',
  MEETING_ROOM_DETAIL = 'settings/meetingRoomDetail',

  MEETING_ROOM_ORDER_LIST = 'settings/meetingRoomOrderList',
  MEETING_ROOM_ORDER_CREATE = 'settings/meetingRoomOrderCreate',
  MEETING_ROOM_ORDER_UPDATE = 'settings/meetingRoomOrderUpdate',
  MEETING_ROOM_ORDER_DELETE = 'settings/meetingRoomOrderDelete',
  MEETING_ROOM_ORDER_DETAIL = 'settings/meetingRoomOrderDetail',

  TIME_TABLE_LIST = 'settings/timeTableList',
  TIME_TABLE_CREATE = 'settings/timeTableCreate',
  TIME_TABLE_UPDATE = 'settings/timeTableUpdate',
  TIME_TABLE_DELETE = 'settings/timeTableDelete',
  TIME_TABLE_DETAIL = 'settings/timeTableDetail',

  SYSTEM_SETTINGS_LIST = 'settings/systemSettingsList',
  SYSTEM_SETTINGS_CREATE = 'settings/systemSettingsCreate',
  SYSTEM_SETTINGS_UPDATE = 'settings/systemSettingsUpdate',

  COMPANY_RULES_LIST = 'settings/companyRulesList',
  COMPANY_RULES_UPDATE = 'settings/companyRulesUpdate',

  WORKSPACE_LIST = 'settings/workspaceList',
  WORKSPACE_ALL_LIST = 'settings/workspaceAllList',
  WORKSPACE_CREATE = 'settings/workspaceCreate',
  WORKSPACE_DETAIL = 'settings/workspaceDetail',
  WORKSPACE_UPDATE = 'settings/workspaceUpdate',
  WORKSPACE_DELETE = 'settings/workspaceDelete',
  WORKSPACE_STATUS_CHANGE = 'settings/workspaceStatusChange',
  WORKSPACE_VERIFY = 'settings/workspaceVerify',
  WORKSPACE_REGENERATE_UID = 'settings/regenerateToken',
}
