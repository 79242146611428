import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
} from '@mui/material/'
import { red } from '@mui/material/colors'
import { ReactElement } from 'react'

export interface InputProps extends OutlinedInputProps {
  helperText?: string
  labelPrimary?: string
  isHelperShow?: boolean
  labelSecondary?: ReactElement
  required?: boolean
}

export const CustomLabel = ({ text }: { text: string }) => {
  return (
    <label className="custom-label" style={{ fontSize: 14, paddingBottom: 3 }}>
      {text}
    </label>
  )
}

const StyledTextField: React.FC<InputProps> = ({
  helperText,
  labelPrimary,
  labelSecondary,
  isHelperShow = true,
  required = false,
  ...rest
}) => {
  return (
    <FormControl fullWidth={rest.fullWidth ? rest.fullWidth : false}>
      {(labelPrimary || labelSecondary) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: 0 }}
        >
          <Box sx={{ padding: 0 }} display="flex" alignItems="center">
            <CustomLabel text={labelPrimary ? labelPrimary : ''} />
            {required && (
              <Typography
                component="span"
                noWrap
                sx={{
                  paddingLeft: (theme) => theme.spacing(1 / 2),
                  paddingRight: (theme) => theme.spacing(1 / 2),
                  height: 16,
                  fontSize: 10,
                  marginLeft: '5px',
                  color: red[400],
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                *
              </Typography>
            )}
          </Box>
          {labelSecondary}
        </Box>
      )}
      <OutlinedInput margin="dense" {...rest} />
      {isHelperShow &&
        (helperText ? (
          <FormHelperText
            sx={{
              height: '16px',
              m: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            error={rest.error}
          >
            {helperText}
          </FormHelperText>
        ) : (
          <Box sx={{ height: '14px' }} />
        ))}
    </FormControl>
  )
}

export default StyledTextField
