/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import {
  TIME_OFF_IDENTIFIER,
  TIME_OFF_TEMPLATE_RANGE_TYPE,
} from '@constants/timeoff.constants'
import { PositionItem } from '@services/resource.services'
import { MergedEventType } from '@store/settings/selectors/helper'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'

const momentTz = require('moment-timezone')

export const getPositionsString = (data: PositionItem[]): string => {
  const shortNames: string[] = _.map(data, 'attributes.short_name')
  return shortNames.join(', ')
}

import {
  ApprovalTemplateDetailItem,
  TimeOffTemplateItem,
  TimeOffTemplateLogItem,
  TimeOffTemplateShortLogItem,
} from '@services/timeoff.services'
import { EmployeeItem } from '@services/user.services'
import { t } from 'i18next'
import { EventTypes } from '@constants/common.constants'
import {
  RemoteWorkTemplateItem,
  TimeCorrectionTemplateItem,
} from '@services/time.services'
import { SystemSettingsItem } from '@services/settings.services'

export const getDuration = (
  start: string | null | undefined,
  end: string | null | undefined,
  identifier: TIME_OFF_IDENTIFIER,
  rangeType: TIME_OFF_TEMPLATE_RANGE_TYPE | string,
  publicEvents: MergedEventType[] | undefined,
  lunchStart: number,
  lunchEnd: number
): string => {
  if (!start) return ''
  if (!end) return t('TIMEOFF_REQUEST.not_specified')

  let difference = 0
  let strValue = ''

  switch (rangeType) {
    case TIME_OFF_TEMPLATE_RANGE_TYPE.TIME:
      difference = dayjs(end).diff(dayjs(start), 'minutes')

      const lunchStartTime = dayjs(start)
        .set('hour', lunchStart)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
      const lunchEndTime = dayjs(start)
        .set('hour', lunchEnd)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)

      const startAtTime = dayjs(start)
      const endAtTime = dayjs(end)

      if (
        startAtTime.isBefore(lunchEndTime) &&
        endAtTime.isAfter(lunchStartTime)
      ) {
        const overlapStart = startAtTime.isAfter(lunchStartTime)
          ? startAtTime
          : lunchStartTime
        const overlapEnd = endAtTime.isBefore(lunchEndTime)
          ? endAtTime
          : lunchEndTime
        const lunchOverlapDuration = Math.round(
          overlapEnd.diff(overlapStart, 'minutes', true)
        )

        difference -= lunchOverlapDuration
      }

      // Ensure difference is not negative
      difference = Math.max(0, difference)

      // Format the duration string
      if (difference === 0) {
        strValue = '0' + t('TIMEOFF_REQUEST.duration_min')
      } else {
        const adjustedHours = Math.floor(difference / 60)
        const adjustedMins = difference % 60

        strValue = ''
        if (adjustedHours >= 1) {
          strValue =
            adjustedHours.toString() + t('TIMEOFF_REQUEST.duration_hour')
        }
        if (adjustedMins !== 0) {
          strValue += _.isEmpty(strValue) ? '' : ' '
          strValue +=
            adjustedMins.toString() + t('TIMEOFF_REQUEST.duration_min')
        }
      }
      break

    case TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_AM:
      return t('TIMEOFF_REQUEST.half_day_am_duration')
    case TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_PM:
      return t('TIMEOFF_REQUEST.half_day_pm_duration')
    case TIME_OFF_TEMPLATE_RANGE_TYPE.DAY:
      const startOfDay = dayjs(start).startOf('day')
      const endOfDay = dayjs(end).startOf('day')
      difference = dayjs(endOfDay).diff(dayjs(startOfDay), 'day') + 1

      let currentDate = dayjs(startOfDay)

      while (currentDate.isBefore(endOfDay)) {
        // Check if the current day is to disabled
        if (shouldDisableDay(currentDate, identifier, publicEvents)) {
          difference--
        }

        // Move to the next day
        currentDate = currentDate.add(1, 'day')
      }

      strValue =
        difference.toString() +
        (difference === 1
          ? t('SYSCOMMON.duration_day')
          : t('SYSCOMMON.duration_days'))
      break
    default:
      break
  }

  return strValue
}

export const getDayDuration = (
  start: string | null | undefined,
  end: string | null | undefined,
  identifier: TIME_OFF_IDENTIFIER,
  rangeType: TIME_OFF_TEMPLATE_RANGE_TYPE | string,
  publicEvents: MergedEventType[] | undefined
): number => {
  if (
    rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_AM ||
    rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_PM
  )
    return 0.5

  const startOfDay = dayjs(start).startOf('day')
  const endOfDay = dayjs(end).startOf('day')
  let difference = (dayjs(endOfDay).diff(dayjs(startOfDay), 'day') +
    1) as number

  let currentDate = dayjs(startOfDay)

  while (currentDate.isBefore(endOfDay)) {
    // Check if the current day is to disabled
    if (shouldDisableDay(currentDate, identifier, publicEvents)) {
      difference--
    }
    currentDate = currentDate.add(1, 'day')
  }

  return difference
}

export type TimeSlotItem = {
  date: Date
  isHoliday: boolean
  event_name?: string
  isWeekend: boolean
}

export const getPreviousMonthDaysArray = (
  currentDate: Date,
  events: MergedEventType[],
  salaryMonthStartDay: number
): TimeSlotItem[] => {
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1

  let previousMonth = salaryMonthStartDay == 1 ? month : month - 1
  let previousYear = year

  if (previousMonth === 0) {
    previousMonth = 12
    previousYear--
  }

  const lastDayOfPreviousMonth =
    salaryMonthStartDay == 1
      ? new Date(previousYear, month, 0).getDate()
      : new Date(previousYear, month - 1, 0).getDate()

  const daysArray: TimeSlotItem[] = []

  for (let day = salaryMonthStartDay; day <= lastDayOfPreviousMonth; day++) {
    const formattedDay = new Date(previousYear, previousMonth - 1, day)
    const event = events.find(
      (event) =>
        moment(new Date(event.start).setHours(0, 0, 0, 0)).isSameOrBefore(
          formattedDay,
          'date'
        ) && moment(event.end).isSameOrAfter(formattedDay, 'date')
    )
    const isHoliday = event?.type === EventTypes.Holiday
    daysArray.push({
      date: formattedDay,
      isHoliday,
      event_name: event ? event.name : undefined,
      isWeekend: isWeekend(formattedDay),
    })
  }

  for (let day = 1; day <= salaryMonthStartDay - 1; day++) {
    const formattedDay = new Date(year, month - 1, day)
    const event = events.find(
      (event) =>
        moment(new Date(event.start).setHours(0, 0, 0, 0)).isSameOrBefore(
          formattedDay,
          'date'
        ) && moment(event.end).isSameOrAfter(formattedDay, 'date')
    )
    const isHoliday = event?.type === EventTypes.Holiday
    daysArray.push({
      date: formattedDay,
      isHoliday,
      event_name: event ? event.name : undefined,
      isWeekend: isWeekend(formattedDay),
    })
  }
  return daysArray
}

export const getFormattedDateRange = (
  currentDate: Date,
  salaryMonthStartDay: SystemSettingsItem
): string => {
  const salaryMonthStartDays = _.get(
    salaryMonthStartDay,
    'attributes.value',
    '16'
  )

  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()

  let startMonth = salaryMonthStartDays == '1' ? month : month - 1
  const lastDayofCurrentMonths = new Date(year, month, 0).getDate()
  const endDate =
    salaryMonthStartDays == '1'
      ? lastDayofCurrentMonths
      : Number(salaryMonthStartDays) - 1
  if (startMonth === 0) {
    startMonth = 12
  }

  const formattedStartDate = `${startMonth
    .toString()
    .padStart(2, '0')}/${salaryMonthStartDays}`
  const formattedEndDate = `${month.toString().padStart(2, '0')}/${endDate}`

  return `${formattedStartDate} ~ ${formattedEndDate}`
}

// Helper function to check if a date falls on a Saturday or Sunday
export const isWeekend = (date: Date): boolean => {
  const dayOfWeek = date.getDay()
  return dayOfWeek === 0 || dayOfWeek === 6
}

export const getTime = (dateTime: string | null | undefined) => {
  if (_.isNull(dateTime) || _.isUndefined(dateTime)) {
    return ''
  } else {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const dateInUtc = momentTz.utc(dateTime)
    const dateInUserTimeZone = dateInUtc.tz(userTimeZone)
    return dateInUserTimeZone.format('HH:mm')
  }
}

export const isStepManual = (
  step: number,
  template:
    | TimeOffTemplateLogItem
    | TimeOffTemplateItem
    | undefined
    | null
    | TimeCorrectionTemplateItem
    | RemoteWorkTemplateItem
    | TimeOffTemplateShortLogItem
): boolean => {
  if (!template) return false

  const stepDetails = getStepDetail(step, template)

  return (stepDetails && stepDetails.attributes.is_manual) ?? false
}

export const getStepDetail = (
  step: number,
  template:
    | TimeOffTemplateLogItem
    | TimeOffTemplateItem
    | undefined
    | null
    | TimeCorrectionTemplateItem
    | RemoteWorkTemplateItem
    | TimeOffTemplateShortLogItem
): ApprovalTemplateDetailItem | undefined => {
  if (!template) return undefined

  const steps = _.get(
    template,
    'attributes.approval_template.data.attributes.approval_template_details.data',
    undefined
  )

  const stepDetails = _.isEmpty(steps)
    ? undefined
    : _.find(
        steps,
        (item: ApprovalTemplateDetailItem) => item?.attributes?.step === step
      )

  return stepDetails
}

export const getStepPositionNames = (
  step: number,
  template:
    | TimeOffTemplateLogItem
    | TimeOffTemplateItem
    | undefined
    | null
    | TimeCorrectionTemplateItem
    | RemoteWorkTemplateItem
    | TimeOffTemplateShortLogItem
): string => {
  if (!template) return ''

  const stepDetails = getStepDetail(step, template)
  const positions = _.get(stepDetails, 'attributes.positions.data', [])

  if (_.isEmpty(positions)) return t('TIMEOFF_REQUEST.any_position')

  let names = ''

  _.map(positions, (position, index) => {
    if (index === positions.length - 1) {
      names += _.get(position, 'attributes.short_name', '')
    } else {
      names += _.get(position, 'attributes.short_name', '') + ', '
    }
  })

  return names
}

export const checkDuration = (
  duration: number,
  template: TimeOffTemplateItem,
  compensatoryBalance: number | undefined,
  vacationBalance: number | undefined
): boolean => {
  // if the request is compensatory request
  // check if selected duration is not over compensatory balance
  if (
    _.get(template, 'attributes.identifier', '') ===
      TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST &&
    compensatoryBalance !== undefined
  ) {
    return duration > compensatoryBalance
  }

  // if the request deducts from vacation
  // check if selected duration is not over vacation balance
  if (
    _.get(template, 'attributes.is_deduct_from_vacation', true) &&
    vacationBalance !== undefined
  )
    return duration > vacationBalance

  return false
}

export const filterApprovers = (
  step: number,
  template:
    | TimeOffTemplateItem
    | TimeOffTemplateLogItem
    | undefined
    | TimeCorrectionTemplateItem
    | RemoteWorkTemplateItem
    | TimeOffTemplateShortLogItem,
  employees: EmployeeItem[] | undefined,
  userId: string
): EmployeeItem[] => {
  if (!employees || _.isEmpty(employees)) return []

  const stepDetail = getStepDetail(step, template)

  const positions = _.get(
    stepDetail,
    'attributes.positions.data',
    []
  ) as PositionItem[]

  if (_.isEmpty(positions))
    return employees.filter((user) => user.id !== userId)

  const filteredUsers = employees.filter((user: EmployeeItem) => {
    return (
      user.id !== userId &&
      user.attributes.position?.data &&
      user.attributes.position?.data?.id &&
      positions.some(
        (position) => position.id === _.get(user, 'attributes.position.data.id')
      )
    )
  })

  return filteredUsers
}

export const shouldDisableDay = (
  date: any,
  identifier: TIME_OFF_IDENTIFIER,
  publicEvents: MergedEventType[] | undefined
): boolean => {
  const isHoliday = _.isEmpty(publicEvents)
    ? false
    : !!_.find(publicEvents, (holiday) => {
        return (
          moment(date.toDate()).isSameOrAfter(moment(holiday.start)) &&
          moment(date.toDate()).isSameOrBefore(moment(holiday.end))
        )
      })

  // Disable working days
  if (identifier === TIME_OFF_IDENTIFIER.HOLIDAY_WORK) {
    return (
      new Date(date).getDay() !== 0 &&
      new Date(date).getDay() !== 6 &&
      !isHoliday
    )
  }

  // Disable weekends and holidays
  return (
    new Date(date).getDay() === 0 || new Date(date).getDay() === 6 || isHoliday
  )
}

export const millisecondToDuration = (
  millisec: number,
  rangeType: TIME_OFF_TEMPLATE_RANGE_TYPE | string
): string => {
  if (millisec <= 0) return ''
  if (rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_AM)
    return t('TIMEOFF_REQUEST.half_day_am_duration')
  if (rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_PM)
    return t('TIMEOFF_REQUEST.half_day_pm_duration')
  if (rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME) {
    const portions: string[] = []

    const msInHour = 1000 * 60 * 60
    const hours = Math.trunc(millisec / msInHour)
    if (hours > 0) {
      portions.push(hours + t('TIMEOFF_REQUEST.duration_hour'))
      millisec = millisec - hours * msInHour
    }

    const msInMinute = 1000 * 60
    const minutes = Math.trunc(millisec / msInMinute)
    if (minutes > 0) {
      portions.push(minutes + t('TIMEOFF_REQUEST.duration_min'))
      millisec = millisec - minutes * msInMinute
    }

    return portions.join(' ')
  }
  if (rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.DAY) {
    const days = Math.floor(millisec / (24 * 3600 * 1000))
    return `${days} ${
      days <= 1 ? t('SYSCOMMON.duration_day') : t('SYSCOMMON.duration_days')
    }`
  }

  return ''
}

export const millisecondToDay = (missisec: number) => {
  return Math.round(missisec / (24 * 60 * 60 * 100)) / 10
}
