import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  ActivityItem,
  AddableMemberItem,
  PartnerPurchaseOrderItem,
  ProjectItem,
  ProjectMemberItem,
  PartnerPurchaseOrderWithProjectItem,
} from '@services/project.services'
import * as actions from '../actions'
import { clearResourcePlanDetails } from '@store/resource/actions'
import _ from 'lodash'

type StateType = {
  projectListAll?: ProjectItem[]
  projectList?: ProjectItem[]
  projectMeta?: MetaPage
  create?: ProjectItem
  detail?: ProjectItem
  members?: ProjectMemberItem[]
  membersMeta?: MetaPage
  addableUsers?: AddableMemberItem[]
  projectCostList?: ProjectItem[]
  projectActivity?: ActivityItem[]
  activityMeta?: MetaPage
  partnerPurchaseOrderList?: PartnerPurchaseOrderItem[]
  partnerPurchaseOrderMeta?: MetaPage
  partnerPurchaseOrder?: PartnerPurchaseOrderItem
  myProjects?: ProjectItem[]
  all?: ProjectItem[]
  partnerPurchaseOrderProjects?: PartnerPurchaseOrderWithProjectItem[]
  partnerPurchaseOrderProjectsMeta?: MetaPage
  projectListWithInvoice?: ProjectItem[]
  projectListWithInvoiceMeta?: MetaPage
}

const initialState: StateType = {
  projectListAll: undefined,
  projectList: undefined,
  create: undefined,
  detail: undefined,
  projectMeta: undefined,
  members: undefined,
  membersMeta: undefined,
  addableUsers: undefined,
  projectCostList: undefined,
  projectActivity: undefined,
  partnerPurchaseOrderList: undefined,
  partnerPurchaseOrderMeta: undefined,
  partnerPurchaseOrder: undefined,
  myProjects: undefined,
  all: undefined,
  partnerPurchaseOrderProjects: undefined,
  partnerPurchaseOrderProjectsMeta: undefined,
  projectListWithInvoice: undefined,
  projectListWithInvoiceMeta: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchProjectListAll.fulfilled, (state, action) => {
    state.projectListAll = action.payload.data
  })
  builder.addCase(actions.fetchProjectList.fulfilled, (state, action) => {
    state.projectList = action.payload.data
    state.projectMeta = action.payload.meta
  })
  builder.addCase(actions.createProject.fulfilled, (state, action) => {
    state.create = action.payload.data
  })
  builder.addCase(actions.showProject.fulfilled, (state, action) => {
    state.detail = action.payload.data
  })
  builder.addCase(actions.updateProject.fulfilled, (state, action) => {
    state.detail = action.payload.data
  })
  builder.addCase(actions.memberList.fulfilled, (state, action) => {
    state.members = action.payload.data
  })
  builder.addCase(actions.addableUserList.fulfilled, (state, action) => {
    state.addableUsers = action.payload.data
  })
  builder.addCase(actions.clearProject, (state) => {
    state.detail = undefined
    state.members = undefined
    state.addableUsers = undefined
    state.activityMeta = undefined
    state.projectActivity = undefined
    state.partnerPurchaseOrderList = undefined
    state.myProjects = undefined
    state.all = undefined
  })
  builder.addCase(actions.projectActivity.fulfilled, (state, action) => {
    state.projectActivity = action.payload.data
    state.activityMeta = action.payload.meta
  })
  builder.addCase(actions.setProjectImage.fulfilled, (state, action) => {
    state.detail =
      action.payload.data.file_url && state.detail
        ? {
            ...state.detail,
            attributes: {
              ...state.detail?.attributes,
              image_url: action.payload.data.file_url,
            },
          }
        : state.detail
  })
  builder.addCase(clearResourcePlanDetails, (state) => {
    state.projectListAll = undefined
  })
  builder.addCase(actions.fetchProjectAll.fulfilled, (state, action) => {
    state.all = action.payload.data
  })

  builder.addCase(
    actions.fetchPartnerPurchaseOrderList.fulfilled,
    (state, action) => {
      state.partnerPurchaseOrderList = action.payload.data
      state.partnerPurchaseOrderMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.showPartnerPurchaseOrder.fulfilled,
    (state, action) => {
      state.partnerPurchaseOrder = action.payload.data
    }
  )
  builder.addCase(actions.myProjects.fulfilled, (state, action) => {
    state.myProjects = action.payload.data
  })
  builder.addCase(
    actions.updatePartnerPurchaseOrder.fulfilled,
    (state, action) => {
      state.partnerPurchaseOrderList = state.partnerPurchaseOrderList
        ? _.map(state.partnerPurchaseOrderList, (item) => {
            if (item.id === action.payload.data.id) {
              return action.payload.data
            }
            return item
          })
        : undefined
    }
  )
  builder.addCase(
    actions.deletePartnerPurchaseOrder.fulfilled,
    (state, action) => {
      state.partnerPurchaseOrderList = state.partnerPurchaseOrderList
        ? _.filter(
            state.partnerPurchaseOrderList,
            (item) => item.id !== action.meta.arg
          )
        : undefined
    }
  )
  builder.addCase(
    actions.fetchPartnerPurchaseOrderProject.fulfilled,
    (state, action) => {
      state.partnerPurchaseOrderProjects = action.payload.data
      state.partnerPurchaseOrderProjectsMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchProjectListInvoice.fulfilled,
    (state, action) => {
      state.projectListWithInvoice = action.payload.data
      state.projectListWithInvoiceMeta = action.payload.meta
    }
  )
  builder.addCase(actions.clearPartnerPurchaseOrderList, (state) => {
    state.partnerPurchaseOrderList = undefined
  })
})
