import {
  SocialLoginParams,
  UserLoginParams,
  UserLoginResponse,
} from '@services/auth.services'
import {
  changeProject,
  logOut,
  loginByEmail,
  loginWithGoogle,
  switchLang,
} from '@store/auth/actions'
import { AUTH_ACTION_TYPE, ProjectItem } from '@store/auth/actions/types'
import {
  baseURL as baseUrlSelector,
  currentUserLang,
  getAuth,
  getIsAuthenticated,
} from '@store/auth/selectors'
import { useAppDispatch } from '@store/hooks'
import { clearMetaData } from '@store/metadata/actions'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { useSelector } from 'react-redux'

interface Auth {
  login: (params: UserLoginParams) => Promise<any>
  googleLogin: (params: SocialLoginParams) => void
  signOut: () => any
  isLoggedIn: boolean
  user: UserLoginResponse
  lang: string
  changeLang: (id: string) => void
  onProjectChange: (data: ProjectItem) => void
  baseURL: string
  meta: Meta
  metaGoogle: Meta
  clearMeta: () => void
}

const useAuth = (): Auth => {
  const user = useSelector(getAuth)
  const isLoggedIn = useSelector(getIsAuthenticated)
  const lang = useSelector(currentUserLang)
  const dispatch = useAppDispatch()
  const login = (params: UserLoginParams) => dispatch(loginByEmail(params))
  const googleLogin = (params: SocialLoginParams) =>
    dispatch(loginWithGoogle(params))
  const signOut = () => dispatch(logOut())
  const changeLang = (id: string) => dispatch(switchLang({ language: id }))
  const baseURL = useSelector(baseUrlSelector)
  const onProjectChange = (data: ProjectItem) => dispatch(changeProject(data))
  const _meta = createMetaSelector(loginByEmail)
  const meta = useSelector(_meta)
  const _metaGoogle = createMetaSelector(loginWithGoogle)
  const metaGoogle = useSelector(_metaGoogle)
  const clearMeta = () =>
    dispatch(clearMetaData(AUTH_ACTION_TYPE.LOGIN_BY_EMAIL))

  return {
    meta,
    login,
    googleLogin,
    signOut,
    isLoggedIn,
    user,
    lang,
    baseURL,
    changeLang,
    onProjectChange,
    clearMeta,
    metaGoogle,
  }
}

export default useAuth
