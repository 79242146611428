import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  RemoteOfficeAttributes,
  RemoteOfficeSomeAttributes,
  RemoteWorkRequestItem,
  RemoteWorkSettingsItem,
  RemoteWorkTemplateItem,
  TimeCardItem,
  TimeCorrectionItem,
  TimeCorrectionTemplateItem,
  TimeRecordItem,
  TimeReportItem,
  TimeReportTotalItem,
  TimesheetItem,
  TimesheetRemoteWorkRequestItem,
  TimesheetRequestItem,
  TimesheetSumItem,
} from '@services/time.services'
import { EmployeeShortItem } from '@services/user.services'
import { updateDailyReport } from '@store/dailyreport/actions'
import _ from 'lodash'
import * as actions from '../actions'

type StateType = {
  recordList?: TimeRecordItem[]
  timesheetList?: TimesheetItem[]
  timesheetTotal?: TimesheetSumItem
  timesheetRequestList?: TimesheetRequestItem[]
  timesheetUser?: EmployeeShortItem
  timesheetRemoteWorkRequests?: TimesheetRemoteWorkRequestItem[]
  timeReport?: TimeReportItem[]
  timeReportTotal?: TimeReportTotalItem
  timeCorrectionList?: TimeCorrectionItem[]
  timeCorrectionPaging?: MetaPage
  timeCard?: TimeCardItem
  timeCorrectionApproveList?: TimeCorrectionItem[]
  timeCorrectionApprovePaging?: MetaPage
  timeCorrectionTemplate?: TimeCorrectionTemplateItem
  remoteWorkTemplate?: RemoteWorkTemplateItem
  remoteWorkSettingsList?: RemoteWorkSettingsItem[]
  remoteWorkSettingsListPaging?: MetaPage
  remoteWorkRequests?: RemoteWorkRequestItem[]
  remoteWorkRequestsPaging?: MetaPage
  remoteWorkRequestApproveList?: RemoteWorkRequestItem[]
  remoteWorkRequestApproveListPaging?: MetaPage
  remoteWorkSettingsMyList?: RemoteWorkSettingsItem[]
  remoteWorkSettingsMyListPaging?: MetaPage
  remoteWorkSettingsMyListAll?: RemoteWorkSettingsItem[]
  restoIntegrated?: boolean
  remoteOfficeReportList?: RemoteOfficeAttributes[]
  remoteOfficeSomeData?: RemoteOfficeSomeAttributes
}

const initialState: StateType = {
  recordList: undefined,
  timesheetList: undefined,
  timesheetTotal: undefined,
  timeReport: undefined,
  timeReportTotal: undefined,
  timesheetRequestList: undefined,
  timeCorrectionList: undefined,
  timeCard: undefined,
  timeCorrectionPaging: undefined,
  timeCorrectionApproveList: undefined,
  timeCorrectionApprovePaging: undefined,
  timesheetUser: undefined,
  timeCorrectionTemplate: undefined,
  remoteWorkTemplate: undefined,
  remoteWorkSettingsList: undefined,
  remoteWorkSettingsListPaging: undefined,
  remoteWorkRequests: undefined,
  remoteWorkRequestsPaging: undefined,
  remoteWorkRequestApproveList: undefined,
  remoteWorkRequestApproveListPaging: undefined,
  remoteWorkSettingsMyList: undefined,
  remoteWorkSettingsMyListPaging: undefined,
  remoteWorkSettingsMyListAll: undefined,
  timesheetRemoteWorkRequests: undefined,
  restoIntegrated: undefined,
  remoteOfficeReportList: undefined,
  remoteOfficeSomeData: undefined,
}

export default createReducer(initialState, (builder) => {
  // builder.addCase(actions.recordList.fulfilled, (state, action) => {
  //   state.recordList = action.payload.data
  // })

  builder.addCase(actions.timesheet.fulfilled, (state, action) => {
    state.timesheetList = action.payload.rec.data
    state.timesheetTotal = action.payload.sums
    state.timesheetRequestList = action.payload.request.data
    state.timesheetUser = action.payload.user.data
    state.timesheetRemoteWorkRequests = action.payload.remote_request.data
  })
  builder.addCase(actions.fetchTimeReportList.fulfilled, (state, action) => {
    state.timeReport = action.payload.data
    state.timeReportTotal = action.payload.total_sum
    state.restoIntegrated = action.payload.resto_integrated
  })
  builder.addCase(
    actions.fetchTimeCorrectionList.fulfilled,
    (state, action) => {
      state.timeCorrectionList = action.payload.data
      state.timeCorrectionPaging = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchTimeCorrectionApproveList.fulfilled,
    (state, action) => {
      state.timeCorrectionApproveList = action.payload.data
      state.timeCorrectionApprovePaging = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchTimeCorrectionTimeCard.fulfilled,
    (state, action) => {
      state.timeCard = action.payload.data
    }
  )
  builder.addCase(actions.updateTimeCorrection.fulfilled, (state, action) => {
    state.timeCorrectionList = state.timeCorrectionList
      ? state.timeCorrectionList.map((item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.createTimeCorrection.fulfilled, (state, action) => {
    state.timeCorrectionList
      ? state.timeCorrectionList.push(action.payload.data)
      : (state.timeCorrectionList = [action.payload.data])
  })
  builder.addCase(updateDailyReport.fulfilled, (state, action) => {
    state.timeCard = state.timeCard
      ? {
          ...state.timeCard,
          attributes: {
            ...state.timeCard.attributes,
            daily_report: {
              data: {
                ...state.timeCard.attributes.daily_report.data,
                attributes: {
                  ...state.timeCard.attributes.daily_report.data.attributes,
                  daily_report_details: {
                    data: _.map(
                      state.timeCard.attributes.daily_report.data.attributes
                        .daily_report_details.data,
                      (d) => {
                        if (d.id === action.payload.data.id) {
                          return action.payload.data
                        } else {
                          return d
                        }
                      }
                    ),
                  },
                },
              },
            },
          },
        }
      : state.timeCard
  })
  builder.addCase(
    actions.showTimeCorrectionTemplate.fulfilled,
    (state, action) => {
      state.timeCorrectionTemplate = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchRemoteWorkTemplateDetail.fulfilled,
    (state, action) => {
      state.remoteWorkTemplate = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchRemoteWorkSettingsList.fulfilled,
    (state, action) => {
      state.remoteWorkSettingsList = action.payload.data
      state.remoteWorkSettingsListPaging = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchRemoteWorkRequestList.fulfilled,
    (state, action) => {
      state.remoteWorkRequests = action.payload.data
      state.remoteWorkRequestsPaging = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchRemoteWorkRequestApproversList.fulfilled,
    (state, action) => {
      state.remoteWorkRequestApproveList = action.payload.data
      state.remoteWorkRequestApproveListPaging = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchMyRemoteWorkSettingsList.fulfilled,
    (state, action) => {
      state.remoteWorkSettingsMyList = action.payload.data
      state.remoteWorkSettingsMyListPaging = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchMyRemoteWorkSettingsListAll.fulfilled,
    (state, action) => {
      state.remoteWorkSettingsMyListAll = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchRemoteOfficeReport.fulfilled,
    (state, action) => {
      state.remoteOfficeReportList = action.payload.data
      state.remoteOfficeSomeData = {
        end_date: action.payload.end_date,
        start_date: action.payload.start_date,
        total_work_days: action.payload.total_work_days,
      }
    }
  )
})
