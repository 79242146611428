/* eslint-disable @typescript-eslint/no-unused-vars */
import { StyledTextField } from '@components/@material-extend'
import { FilterParams } from '@constants/common.constants'
import { Search } from '@mui/icons-material'
import { Box, Chip, IconButton, Stack } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFilterForm from './useFilterForm'

export type SearchParams = {
  keyword: string
}

interface SearchFilterProps {
  onFilter: (data: SearchParams) => void
  filter: FilterParams
}

const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const {
    onFilter,
    filter: { keyword },
  } = props
  const { t } = useTranslation()

  const onSubmit = (values: any) => {
    onFilter({
      keyword: values.keyword,
    })
  }

  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { Controller, methods } = useFilterForm()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    // reset,
  } = methods

  const handleDelete = () => {
    setValue('keyword', '')
    onFilter({ keyword: '' })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Stack
        sx={{ mt: '5px' }}
        spacing={1}
        alignItems={'center'}
        direction={'row'}
      >
        <Controller
          name="keyword"
          control={control}
          render={({ field: { ref, ...rest } }: FieldValues) => (
            <StyledTextField
              {...rest}
              inputRef={ref}
              isHelperShow={false}
              fullWidth
              error={errors.keyword}
              data-test-id="keyword"
              endAdornment={
                <IconButton
                  disableRipple
                  type="submit"
                  sx={{
                    mr: -1,
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                >
                  <Search sx={{ cursor: 'pointer' }} />
                </IconButton>
              }
              placeholder={t('SYSCOMMON.search_keyword')}
            />
          )}
        />
        {!_.isEmpty(keyword) && (
          <Box>
            <Chip
              label={keyword}
              size="medium"
              sx={{ maxWidth: { xs: 100, sm: 120, md: 160, lg: 180 } }}
              variant="outlined"
              onDelete={handleDelete}
            />
          </Box>
        )}
      </Stack>
    </form>
  )
}

export default SearchFilter
