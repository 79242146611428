import {
  Direction,
  FilterParams,
  validPerPages,
} from '@constants/common.constants'
import { Close } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { EmployeeListParams } from '@services/user.services'
import _ from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useSettingsForm from './useSettingsForm'

type OrderParamsType = {
  label: string
  value: string
}
interface ListSettingsProps {
  open: boolean
  handleClose: () => void
  orderParams: OrderParamsType[]
  onChangeSettings?: (
    params: Omit<FilterParams, 'keyword'> | Omit<EmployeeListParams, 'keyword'>
  ) => void
  filter: Omit<FilterParams, 'keyword'>
  perPages?: number[]
  defaultLabel?: string
}

const ListSettings: React.FC<ListSettingsProps> = (props) => {
  const { t } = useTranslation()
  const {
    handleClose,
    open,
    onChangeSettings,
    orderParams,
    filter,
    perPages,
    defaultLabel = `${t('SYSCOMMON.name')}`,
  } = props
  const pages = perPages ? perPages : validPerPages

  const onSubmit = (values: any) => {
    onChangeSettings && onChangeSettings(values)
    handleClose()
  }

  const { Controller, methods } = useSettingsForm()

  const { control, handleSubmit, reset } = methods

  React.useEffect(() => {
    reset(filter)
  }, [filter])

  return (
    <>
      <Dialog maxWidth={'lg'} open={open} onClose={handleClose}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          id="settings"
          data-test-id="order-settings-form"
        >
          <DialogTitle>
            {t('SYSCOMMON.settings')}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="h6">
                    {t('SYSCOMMON.page_size')}
                  </Typography>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="per_page"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        {pages.map((value) => {
                          return (
                            <FormControlLabel
                              key={value}
                              value={value}
                              control={<Radio />}
                              label={value}
                            />
                          )
                        })}
                      </RadioGroup>
                    )}
                  />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {t('SYSCOMMON.order_direction')}
                  </Typography>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="direction"
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value={Direction.ASC}
                          control={<Radio />}
                          label={t('SYSCOMMON.order_asc')}
                        />
                        <FormControlLabel
                          value={Direction.DESC}
                          control={<Radio />}
                          label={t('SYSCOMMON.order_desc')}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="h6">
                    {t('SYSCOMMON.order_by')}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 11 }}>
                    {t('SYSCOMMON.order_by_desc')}
                  </Typography>
                  <Box>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name="order_by"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value={''}
                            control={<Radio />}
                            label={`${t(
                              'SYSCOMMON.default'
                            )} (${defaultLabel})`}
                          />

                          {!_.isEmpty(orderParams)
                            ? orderParams.map((param) => {
                                return (
                                  <FormControlLabel
                                    key={param.label}
                                    value={param.value}
                                    control={<Radio />}
                                    label={param.label}
                                  />
                                )
                              })
                            : null}
                        </RadioGroup>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: '1px solid #cecece',
              justifyContent: 'space-between',
            }}
          >
            <Button
              size="medium"
              data-test-id="order-settings-cancel"
              variant="outlined"
              onClick={() => handleClose()}
            >
              {t('SYSCOMMON.cancel')}
            </Button>
            <Button
              size="medium"
              color="primary"
              data-test-id="order-settings-confirm"
              variant="contained"
              type="submit"
            >
              {t('SYSCOMMON.filter_confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default ListSettings
