import { AppInit, MissingReportItem } from '@services/common.services'
import { UserProfileItem } from '@services/user.services'
import { logOut, switchLang } from '@store/auth/actions'
import {
  baseURL as baseUrlSelector,
  currentUserLang,
} from '@store/auth/selectors'
import { appInit } from '@store/common/actions'
import { getInitApp, getReportRelated } from '@store/common/selectors'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { fetchProfile } from '@store/user/actions'
import { getUserProfile } from '@store/user/selectors'
import { useSelector } from 'react-redux'

interface Init {
  initApp: () => any
  profile: () => any
  data: UserProfileItem
  meta: Meta
  baseURL: string
  lang: string
  signOut: () => void
  changeLang: (id: string) => void
  initData: AppInit
  limit: number | undefined
  period: number | undefined
  missingReports: MissingReportItem[]
  isDenyWorkStart: boolean
}

const useInit = (): Init => {
  const dispatch = useAppDispatch()
  const data = useSelector(getUserProfile)
  const initApp = () => dispatch(appInit())
  const profile = () => dispatch(fetchProfile())
  const initData = useSelector(getInitApp)
  const { limit, period, missingReports } = useSelector(getReportRelated)

  const isDenyWorkStart = (() => {
    if (limit && limit === 0) {
      return false
    }
    if (
      !missingReports ||
      !Array.isArray(missingReports) ||
      !initData ||
      !limit
    ) {
      return true // Default to true for missing or corrupted data
    }
    return missingReports.length > limit
  })()

  // eslint-disable-next-line no-console

  // const checkPeriodReached = (date: Date) => {
  //   if (typeof period !== 'number' || isNaN(period) || period <= 0) {
  //     return false // Handle invalid or undefined period
  //   }

  //   const today = new Date() // Get today's date
  //   const periodLimit = new Date(today.setDate(today.getDate() - period)) // Calculate period limit date

  //   // Compare date with period limit
  //   return date.getTime() <= periodLimit.getTime()
  // }

  const _meta = createMetaSelector(appInit)
  const meta = useSelector(_meta)

  const baseURL = useSelector(baseUrlSelector)
  const lang = useSelector(currentUserLang)
  const signOut = () => {
    dispatch(logOut())
  }
  const changeLang = (id: string) => dispatch(switchLang({ language: id }))

  return {
    profile,
    data,
    signOut,
    changeLang,
    lang,
    baseURL,
    meta,
    initData,
    initApp,
    limit,
    period,
    isDenyWorkStart,
    missingReports,
  }
}

export default useInit
