import React from 'react'
import { Box, Typography } from '@mui/material'

const NotificationBar: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Box
      sx={{
        height: '30px',
        width: '100%',
        backgroundColor: 'warning.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" color="warning.contrastText">
        {text}
      </Typography>
    </Box>
  )
}

export default NotificationBar
