import { FilterParams, MetaPage } from '@constants/common.constants'
import {
  WorkspaceCreateParams,
  WorkspaceItem,
  WorkspaceSelectType,
  WorkspaceUpdateParams,
  WorkspaceVerifyParams,
} from '@services/settings.services'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import {
  createWorkspace,
  deleteWorkspace,
  fetchAllWorkspace,
  fetchWorkspace,
  fetchWorkspaceDetail,
  regenerateWorkspaceUid,
  statusChangeWorkspace,
  updateWorkspace,
  verifyWorkspace,
} from '@store/settings/actions'
import {
  getSelectableWorkspaceList,
  getWorkspaceDetail,
  getWorkspaceList,
  getWorkspaceListPaging,
} from '@store/settings/selectors'
import { useSelector } from 'react-redux'

interface Workspace {
  fetch: (params: FilterParams) => any
  fetchAll: () => any
  allList: WorkspaceSelectType[]
  list: WorkspaceItem[]
  allListMeta: Meta
  selectableWorkspaceList: WorkspaceSelectType[]
  meta: Meta
  paging: MetaPage
  create: (params: WorkspaceCreateParams) => any
  createMeta: Meta
  update: (params: WorkspaceUpdateParams) => any
  updateMeta: Meta
  deleteItem: (params: string) => any
  deleteMeta: Meta
  changeStatus: (params: string) => any
  changeStatusMeta: Meta
  fetchDetail: (params: string) => any
  detail: WorkspaceItem
  detailMeta: Meta
  verify: (params: WorkspaceVerifyParams) => any
  verifyMeta: Meta
  regenerateToken: (params: string) => any
  regenerateTokenMeta: Meta
}

const useWorkspace = (): Workspace => {
  const dispatch = useAppDispatch()
  const fetch = (params: FilterParams) => dispatch(fetchWorkspace(params))
  const list = useSelector(getWorkspaceList)
  const selectableWorkspaceList = useSelector(getSelectableWorkspaceList)
  const paging = useSelector(getWorkspaceListPaging)
  const _meta = createMetaSelector(fetchWorkspace)
  const meta = useSelector(_meta)

  const fetchAll = () => dispatch(fetchAllWorkspace())
  const allList = useSelector(getSelectableWorkspaceList)
  const _allListMeta = createMetaSelector(fetchAllWorkspace)
  const allListMeta = useSelector(_allListMeta)

  const create = (params: WorkspaceCreateParams) =>
    dispatch(createWorkspace(params))
  const _createMeta = createMetaSelector(createWorkspace)
  const createMeta = useSelector(_createMeta)

  const update = (params: WorkspaceUpdateParams) =>
    dispatch(updateWorkspace(params))
  const _updateMeta = createMetaSelector(updateWorkspace)
  const updateMeta = useSelector(_updateMeta)

  const deleteItem = (params: string) => dispatch(deleteWorkspace(params))
  const _deleteMeta = createMetaSelector(deleteWorkspace)
  const deleteMeta = useSelector(_deleteMeta)

  const changeStatus = (params: string) =>
    dispatch(statusChangeWorkspace(params))
  const _changeStatusMeta = createMetaSelector(statusChangeWorkspace)
  const changeStatusMeta = useSelector(_changeStatusMeta)

  const fetchDetail = (params: string) => dispatch(fetchWorkspaceDetail(params))
  const _detailMeta = createMetaSelector(fetchWorkspaceDetail)
  const detailMeta = useSelector(_detailMeta)
  const detail = useSelector(getWorkspaceDetail)

  const verify = (params: WorkspaceVerifyParams) =>
    dispatch(verifyWorkspace(params))
  const _verifyMeta = createMetaSelector(verifyWorkspace)
  const verifyMeta = useSelector(_verifyMeta)
  const regenerateToken = (params: string) =>
    dispatch(regenerateWorkspaceUid(params))
  const _regenerateTokenMeta = createMetaSelector(regenerateWorkspaceUid)
  const regenerateTokenMeta = useSelector(_regenerateTokenMeta)

  return {
    fetch,
    list,
    paging,
    meta,
    create,
    createMeta,
    update,
    updateMeta,
    deleteItem,
    deleteMeta,
    changeStatus,
    changeStatusMeta,
    fetchDetail,
    detail,
    detailMeta,
    selectableWorkspaceList,
    verify,
    verifyMeta,
    fetchAll,
    allList,
    allListMeta,
    regenerateToken,
    regenerateTokenMeta,
  }
}

export default useWorkspace
