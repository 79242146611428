import TimeStatusTable from '@components/@modules/Time/TimeStatusTable'
import { useConfirm } from '@components/Confirm'
import useTimeOff from '@components/TimeOff/useTimeOff'
import { SIDEBAR } from '@constants/layouts.constants'
import { WORK_TIME_ACTION } from '@constants/time.constants'
import useTimeRecord from '@containers/@modules/Time/useTimeRecord'
import {
  AccessTime,
  AlarmOff,
  AlarmOn,
  Close,
  PlayArrow,
  PlayDisabled,
  SlowMotionVideo,
  Stop,
  WorkOff,
} from '@mui/icons-material'
import { Box, Fab, IconButton } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'
import { TimeRecordItem, TimesheetItem } from '@services/time.services'
import { TimeOffRequestResponse } from '@services/timeoff.services'
import { formatDate } from '@utils/helper/common.helper'
import useCurrentTime from '@utils/hooks/useCurrentTime'
import _ from 'lodash'
import React, { useState, Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LocationStatus from './LocationStatus'
import WorkspaceConfirmationDialog from './WorkspaceConfirmationDialog'
import { useGeolocated } from './useGeolocated'
import useInit from '@utils/hooks/useInit'
import useReport from '@components/MissingDailyReport/useReport'
import { MissingReportItem } from '@services/common.services'
import { ROUTE } from '@constants/route.constants'
import { ACTIONS, CallBackProps } from 'react-joyride'

// Lazy load the component
const LazyJoyride = lazy(() => import('react-joyride'))

interface MobileActionsProps {
  isSideOpen: boolean
  isMobile: boolean
  isShow?: boolean
  setBottomOpen?: (v: boolean) => void
}

export interface ConfirmationDialogState {
  open: boolean
  timeRecord: TimeRecordItem | TimesheetItem | null
  action: WORK_TIME_ACTION
}

const MobileActions: React.FC<MobileActionsProps> = ({
  isSideOpen,
  isMobile,
  isShow = true,
  setBottomOpen,
}) => {
  const { startTime, endTime, startBreak, endBreak } = useTimeRecord()
  const { t } = useTranslation()
  const confirm = useConfirm()
  const report = useReport()
  const [run, setRun] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isDenyWorkStart) {
        setRun(true)
      }
    }, 2000)

    // Clean up the timer if the component unmounts or useEffect runs again
    return () => clearTimeout(timer)
  }, []) // Empty dependency array ensures this effect runs only once on mount

  const steps = [
    {
      target: '.daily-report-badge',
      content: t('DAILY_REPORT.report_warning'),
      disableBeacon: true,
    },
  ]
  const {
    isWorkInProgress,
    isWorkNotStarted,
    isBreakInProgress,
    isBreakNotStarted,
    isWorkDone,
    breaks,
    record,
    isLimitReached,
    isLocationRequired,
  } = useCurrentTime()

  const { initData, isDenyWorkStart } = useInit()
  const [openLocationDialog, SetOpenLocationDialog] =
    useState<ConfirmationDialogState>({
      open: false,
      timeRecord: null,
      action: WORK_TIME_ACTION.start,
    })

  const { coords, isGeolocationEnabled, permissionState } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
    isOptimisticGeolocationEnabled: false,
    watchLocationPermissionChange: true,
    suppressLocationOnMount: false,
    isLocationRequired: isLocationRequired,
  })

  const handleStartTime = () => {
    startTime({
      location: coords
        ? {
            latitude: coords?.latitude,
            longitude: coords?.longitude,
            accuracy: coords?.accuracy,
          }
        : null,
    })
      .unwrap()
      .then((data: any) => {
        SetOpenLocationDialog({
          open: true,
          timeRecord: data.data,
          action: WORK_TIME_ACTION.start,
        })
      })
      .catch(() => null)
  }

  const navigate = useNavigate()

  const timeoff = useTimeOff()

  const handleEndTime = () => {
    confirm({
      title: t('TIME_RECORDS.end_confirm_title'),
      description: t('TIME_RECORDS.end_confirm_desc'),
      dialogProps: { disableScrollLock: true },
    })
      .then(() => {
        endTime({
          location: coords
            ? {
                latitude: coords?.latitude,
                longitude: coords?.longitude,
                accuracy: coords?.accuracy,
              }
            : null,
        })
          .unwrap()
          .then((data: any) => {
            SetOpenLocationDialog({
              timeRecord: data.data,
              open: true,
              action: WORK_TIME_ACTION.end,
            })
          })
          .catch(() => null)
      })
      .catch(() => null)
  }

  const handleStartBreak = () => {
    confirm({
      title: t('TIME_RECORDS.break_start_confirm_title'),
      description: t('TIME_RECORDS.break_start_confirm_desc'),
      dialogProps: { disableScrollLock: true },
    })
      .then(() => {
        startBreak()
          .unwrap()
          .then(() => null)
          .catch(() => null)
      })
      .catch(() => null)
  }

  const handleEndBreak = () => {
    confirm({
      title: t('TIME_RECORDS.break_end_confirm_title'),
      description: t('TIME_RECORDS.break_end_confirm_desc'),
      dialogProps: { disableScrollLock: true },
    })
      .then(() => {
        endBreak()
          .unwrap()
          .then(() => null)
          .catch(() => null)
      })
      .catch(() => null)
  }

  const handleTimeOff = () => {
    timeoff()
      // eslint-disable-next-line no-console
      .then((res: TimeOffRequestResponse) =>
        confirm({
          title: t('TIMEOFF_REQUEST.create_success_title'),
          description: t('TIMEOFF_REQUEST.create_success_desc', {
            start_at: formatDate(_.get(res, 'data.attributes.start_at')),
            end_at: formatDate(_.get(res, 'data.attributes.end_at')),
          }),
          sweet_alert: 'success',
          confirmationButtonProps: {
            color: 'secondary',
          },
          dialogProps: { disableScrollLock: true },
        })
          .then(() => null)
          .catch(() => null)
      )
      .catch(() => null)
  }

  const handleMissingReport = () => {
    report({})
      .then((item: MissingReportItem) => {
        // eslint-disable-next-line no-console
        item &&
          navigate(
            `${ROUTE.DAILY_REPORT.ROOT}?date=${item.attributes.work_date}`
          )
      })
      .catch(() => null)
  }

  const renderAttendanceButton = () => {
    if (isWorkNotStarted) {
      return (
        <BottomNavigationAction
          onClick={isDenyWorkStart ? handleMissingReport : handleStartTime}
          label={t('TIME_RECORDS.work_start')}
          icon={
            <Box sx={{ position: 'relative', height: 22, width: '100%' }}>
              <Box
                sx={{
                  borderRadius: 10,
                  position: 'absolute',
                  top: -35,
                  width: 50,
                  height: 50,
                  left: 0,
                  right: 0,
                  margin: '0 auto',
                  border: '2px solid #fff',
                  boxShadow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: isDenyWorkStart
                    ? (theme) => theme.palette.grey[300]
                    : (theme) => theme.palette.success.main,
                  zIndex: 1,
                }}
              >
                {isDenyWorkStart ? (
                  <PlayDisabled sx={{ color: '#fff' }} />
                ) : (
                  <PlayArrow sx={{ color: '#fff' }} />
                )}
                <Box sx={{ position: 'absolute', right: -2, top: -2 }}>
                  <LocationStatus
                    isGeolocationEnabled={isGeolocationEnabled}
                    isLocationRequired={isLocationRequired}
                    permissionState={permissionState}
                  />
                </Box>
              </Box>
            </Box>
          }
        />
      )
    } else if (isWorkInProgress) {
      return (
        <BottomNavigationAction
          onClick={handleEndTime}
          label={t('TIME_RECORDS.work_end')}
          disabled={!!isBreakInProgress}
          icon={
            <Box sx={{ position: 'relative', height: 22, width: '100%' }}>
              <Box
                sx={{
                  borderRadius: 10,
                  position: 'absolute',
                  top: -35,
                  width: 50,
                  height: 50,
                  left: 0,
                  right: 0,
                  margin: '0 auto',
                  border: '2px solid #fff',
                  boxShadow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pointerEvents: 'auto !important',
                  background: isBreakInProgress
                    ? '#ccc'
                    : (theme) => theme.palette.primary.main,
                  zIndex: 1,
                }}
              >
                <Stop sx={{ color: '#fff' }} />
                <Box sx={{ position: 'absolute', right: -2, top: -2 }}>
                  <LocationStatus
                    isGeolocationEnabled={isGeolocationEnabled}
                    isLocationRequired={isLocationRequired}
                    permissionState={permissionState}
                  />
                </Box>
              </Box>
            </Box>
          }
        />
      )
    }
    return (
      <BottomNavigationAction
        label={t('TIME_RECORDS.work_done')}
        disableRipple
        icon={
          <Box sx={{ position: 'relative', height: 22, width: '100%' }}>
            <Box
              sx={{
                borderRadius: 10,
                position: 'absolute',
                top: -35,
                width: 50,
                height: 50,
                left: 0,
                right: 0,
                margin: '0 auto',
                border: '2px solid #fff',
                boxShadow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#eee',
                zIndex: 1,
              }}
            >
              <AlarmOn />
            </Box>
          </Box>
        }
      />
    )
  }

  const renderBreakButton = () => {
    if (isBreakNotStarted && !isWorkDone) {
      return (
        <BottomNavigationAction
          onClick={handleStartBreak}
          label={
            isLimitReached
              ? t('TIME_RECORDS.shift_limit')
              : t('TIME_RECORDS.shift_start')
          }
          icon={<AlarmOn />}
          sx={{
            '&.MuiButtonBase-root.Mui-disabled': {
              opacity: 0.5,
            },
          }}
          disabled={isWorkNotStarted || isLimitReached}
        />
      )
    } else if (isBreakInProgress && !isWorkDone) {
      return (
        <BottomNavigationAction
          onClick={handleEndBreak}
          label={t('TIME_RECORDS.shift_end')}
          icon={
            <AlarmOff sx={{ color: (theme) => theme.palette.primary.main }} />
          }
        />
      )
    }
    return (
      <BottomNavigationAction
        sx={{ opacity: '0.5' }}
        disabled
        label={t('TIME_RECORDS.shift_start')}
        icon={<SlowMotionVideo />}
      />
    )
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action } = data

    if (action === ACTIONS.CLOSE) {
      report({})
        .then((item: MissingReportItem) => {
          // eslint-disable-next-line no-console
          item &&
            navigate(
              `${ROUTE.DAILY_REPORT.ROOT}?date=${item.attributes.work_date}`
            )
        })
        .catch(() => null)
    }
  }

  return isShow ? (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        pl: isSideOpen && !isMobile ? `${SIDEBAR.width}px` : 0,
        zIndex: 1000,
      }}
      elevation={4}
    >
      <Suspense fallback={<div></div>}>
        <LazyJoyride
          steps={steps}
          run={run}
          callback={handleJoyrideCallback}
          floaterProps={{
            styles: {
              floater: {},
              wrapper: {
                zIndex: 9999,
              },
            },
          }}
          styles={{
            options: {
              zIndex: 9999,
            },
            buttonNext: {
              backgroundColor: '#000',
            },
          }}
          continuous={false}
          showSkipButton={false}
          showProgress={false}
          locale={{
            close: t('SYSCOMMON.confirm'),
          }}
        />
      </Suspense>
      <Box
        sx={{
          position: 'absolute',
          pl:
            isSideOpen && !isMobile
              ? `${SIDEBAR.width + 10}px`
              : isMobile
              ? '2px'
              : '10px',
          willChange: 'auto',
          left: 0,
          bottom: { sm: 56, xs: 56, md: isSideOpen ? 56 : 8, lg: 8, xl: 8 },
          zIndex: 1,
        }}
      >
        <TimeStatusTable
          isMobile={isMobile}
          timeRecord={record}
          breakRecords={breaks}
          onMore={() => null}
        />
      </Box>
      <BottomNavigation
        sx={{
          position: 'relative',
          '& .MuiBottomNavigationAction-label': {
            color: '#555',
          },
        }}
        showLabels
      >
        <BottomNavigationAction
          onClick={handleTimeOff}
          label={t('TIME_RECORDS.time_off')}
          icon={<WorkOff />}
        />
        {renderAttendanceButton()}
        {renderBreakButton()}
      </BottomNavigation>
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: isMobile ? '0px' : 20,
        }}
        onClick={() => setBottomOpen && setBottomOpen(false)}
      >
        <Close />
      </IconButton>
      {openLocationDialog.open && (
        <WorkspaceConfirmationDialog
          open={openLocationDialog.open}
          timeRecord={openLocationDialog.timeRecord}
          action={openLocationDialog.action}
          onCancel={() => {
            SetOpenLocationDialog({
              ...openLocationDialog,
              open: false,
            })
            if (
              _.isEmpty(
                _.get(
                  initData,
                  'attributes.time_records.data.attributes.daily_report.data.attributes.daily_report_details.data'
                )
              ) &&
              openLocationDialog.action === WORK_TIME_ACTION.end
            ) {
              navigate('/dailyreport', { state: { date: new Date() } })
            }
          }}
        />
      )}
    </Paper>
  ) : (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: isMobile ? '0px' : 20,
        zIndex: 100,
      }}
    >
      <Fab
        color="primary"
        size="small"
        onClick={() => setBottomOpen && setBottomOpen(true)}
      >
        <AccessTime />
      </Fab>
    </Box>
  )
}

export default MobileActions
