import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  CompensatoryDayItem,
  TimeOffRequestItem,
  TimeOffApproveShortItem,
  TimeOffTemplateItem,
  UserCompensatoryItem,
  TimeOffTemplateShortItem,
  TimeOffReportItem,
  TimeOffReportTotalItem,
} from '@services/timeoff.services'
import _ from 'lodash'
import * as actions from '../actions'

import {
  ApprovalTemplateDetailItem,
  ApprovalTemplateItem,
} from '@services/timeoff.services'

type StateType = {
  timeOffTemplateListMeta?: MetaPage
  timeOffTemplateList?: TimeOffTemplateItem[]
  timeOffTemplateAll?: TimeOffTemplateItem[]
  timeOffTemplate?: TimeOffTemplateItem

  timeOffRequestListMeta?: MetaPage
  timeOffRequestList?: TimeOffRequestItem[]
  timeOffRequest?: TimeOffRequestItem

  timeOffApproveListMeta?: MetaPage
  timeOffApproveList?: TimeOffApproveShortItem[]

  approvalTemplateList?: ApprovalTemplateItem[]
  approvalTemplateAll?: ApprovalTemplateItem[]
  approvalTemplateListMeta?: MetaPage
  approvalTemplateDetailList?: ApprovalTemplateDetailItem[]
  approvalTemplateItem?: ApprovalTemplateItem

  compensatoryDays?: CompensatoryDayItem[]
  userCompensatoryDays?: UserCompensatoryItem[]
  userCompensatoryDaysMeta?: MetaPage

  timeOffReportTemplateList?: TimeOffTemplateShortItem[]
  timeOffReport?: TimeOffReportItem[]
  timeOffReportMeta?: MetaPage

  timeOffReportTotalList: TimeOffReportTotalItem[]
}

const initialState: StateType = {
  timeOffTemplateListMeta: undefined,
  timeOffTemplateList: undefined,
  timeOffTemplateAll: undefined,
  timeOffTemplate: undefined,

  timeOffRequestList: undefined,
  timeOffRequestListMeta: undefined,
  timeOffRequest: undefined,

  approvalTemplateList: undefined,
  approvalTemplateAll: undefined,
  approvalTemplateListMeta: undefined,
  approvalTemplateDetailList: undefined,
  approvalTemplateItem: undefined,

  compensatoryDays: undefined,
  userCompensatoryDays: undefined,
  userCompensatoryDaysMeta: undefined,
  timeOffReportTemplateList: undefined,
  timeOffReport: undefined,
  timeOffReportMeta: undefined,
  timeOffReportTotalList: [],
}

export default createReducer(initialState, (builder) => {
  // Timeoff Template
  builder.addCase(
    actions.fetchTimeOffTemplateList.fulfilled,
    (state, action) => {
      state.timeOffTemplateList = action.payload.data
      state.timeOffTemplateListMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchTimeOffTemplateAll.fulfilled,
    (state, action) => {
      state.timeOffTemplateAll = action.payload.data
    }
  )
  builder.addCase(actions.updateTimeOffTemplate.fulfilled, (state, action) => {
    state.timeOffTemplateList = state.timeOffTemplateList
      ? _.map(state.timeOffTemplateList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.clearTimeOffTemplate, (state) => {
    state.timeOffTemplate = undefined
  })
  // Timeoff Request
  builder.addCase(
    actions.fetchTimeOffRequestList.fulfilled,
    (state, action) => {
      state.timeOffRequestList = action.payload.data
      state.timeOffRequestListMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchTimeOffApproveList.fulfilled,
    (state, action) => {
      state.timeOffApproveList = action.payload.data
      state.timeOffApproveListMeta = action.payload.meta
    }
  )
  builder.addCase(actions.clearTimeOffRequest, (state) => {
    state.timeOffRequest = undefined
  })
  // Approval Template
  builder.addCase(
    actions.fetchApprovalTemplateList.fulfilled,
    (state, action) => {
      state.approvalTemplateList = action.payload.data
      state.approvalTemplateListMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchApprovalTemplateAll.fulfilled,
    (state, action) => {
      state.approvalTemplateAll = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchApprovalTemplateDetailList.fulfilled,
    (state, action) => {
      state.approvalTemplateDetailList = action.payload.data
    }
  )
  builder.addCase(
    actions.updateApprovalTemplateDetail.fulfilled,
    (state, action) => {
      state.approvalTemplateDetailList = state.approvalTemplateDetailList
        ? _.map(state.approvalTemplateDetailList, (item) => {
            if (item.id === action.payload.data.id) {
              return action.payload.data
            }
            return item
          })
        : undefined
    }
  )
  builder.addCase(actions.updateApprovalTemplate.fulfilled, (state, action) => {
    state.approvalTemplateList = state.approvalTemplateList
      ? _.map(state.approvalTemplateList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.fetchApprovalTemplate.fulfilled, (state, action) => {
    state.approvalTemplateItem = action.payload.data
  })
  builder.addCase(
    actions.fetchCompensatoryDayList.fulfilled,
    (state, action) => {
      state.compensatoryDays = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchUserCompensatoryList.fulfilled,
    (state, action) => {
      state.userCompensatoryDays = action.payload.data
      state.userCompensatoryDaysMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchTimeOffReportTemplateList.fulfilled,
    (state, action) => {
      state.timeOffReportTemplateList = action.payload.data
    }
  )
  builder.addCase(actions.fetchTimeOffReportList.fulfilled, (state, action) => {
    state.timeOffReport = action.payload.data
    state.timeOffReportMeta = action.payload.meta.meta
  })
  builder.addCase(
    actions.fetchTimeOffReportTotalList.fulfilled,
    (state, action) => {
      state.timeOffReportTotalList = action.payload.data
    }
  )
})
