import React from 'react'
import { Typography } from '@mui/material'
import { NotifyContent } from '@services/notification.services'
import { NOTIFY_TYPE } from '@constants/notify.constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@utils/helper/common.helper'

interface MessageContentProps {
  content: NotifyContent
}

const MessageContent: React.FC<MessageContentProps> = ({ content }) => {
  const { t } = useTranslation()
  const actionType = _.get(content, 'attributes.target_type')
  const messageCode = _.get(content, 'attributes.title', '')

  const renderMessage = () => {
    if (content) {
      switch (actionType) {
        case NOTIFY_TYPE.PROJECT:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`, {
                  name: `<span>${_.get(
                    content,
                    'attributes.target_content.data.attributes.name'
                  )}</span>`,
                }),
              }}
            />
          )
        case NOTIFY_TYPE.TIMEOFF_APPROVER:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`, {
                  name: `<span>${_.get(
                    content,
                    'attributes.target_content.data.attributes.timeoff_template_log.data.attributes.name'
                  )}</span>`,
                }),
              }}
            />
          )
        case NOTIFY_TYPE.TIMEOFF_CREATOR:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`, {
                  name: `<span>${_.get(
                    content,
                    'attributes.target_content.data.attributes.timeoff_template_log.data.attributes.name'
                  )}</span>`,
                }),
              }}
            />
          )
        case NOTIFY_TYPE.TIME_CORRECTION_APPROVER:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`, {
                  date: `<span>${formatDate(
                    _.get(
                      content,
                      'attributes.target_content.data.attributes.work_date'
                    ),
                    'YYYY-MM-DD'
                  )}</span>`,
                }),
              }}
            />
          )
        case NOTIFY_TYPE.TIME_CORRECTION_CREATOR:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`, {
                  date: `<a class="MuiLink-root MuiLink-underlineNone pure-link" href='/timecorrection/requests'>${formatDate(
                    _.get(
                      content,
                      'attributes.target_content.data.attributes.work_date'
                    ),
                    'YYYY-MM-DD'
                  )}</a>`,
                }),
              }}
            />
          )
        case NOTIFY_TYPE.REMOTE_WORK_CREATOR:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`),
              }}
            />
          )
        case NOTIFY_TYPE.REMOTE_WORK_APPROVER:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`),
              }}
            />
          )
        case NOTIFY_TYPE.REMOTE_WORK_DISABLED:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t(`MESSAGES.${messageCode}`, {
                  date: `${formatDate(
                    _.get(
                      content,
                      'attributes.target_content.data.attributes.disable_start_date'
                    ),
                    'YYYY-MM-DD'
                  )}   ~   ${formatDate(
                    _.get(
                      content,
                      'attributes.target_content.data.attributes.disable_end_date'
                    ),
                    'YYYY-MM-DD'
                  )}`,
                }),
              }}
            />
          )
        default:
          return null
      }
    }

    return null
  }

  return (
    <>
      <Typography
        component={'span'}
        sx={{
          fontWeight: 400,
          color: '#555',
          lineHeight: 1.4,
          '& span': {
            color: '#333',
            fontWeight: 500,
          },
        }}
      >
        {renderMessage()}
      </Typography>
      {/* Add more components to display other attributes */}
    </>
  )
}

export default MessageContent
