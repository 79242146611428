import { MetaPage } from '@constants/common.constants'
import {
  ICount,
  NotificationParams,
  NotifyItem,
  ReadParams,
} from '@services/notification.services'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import {
  badgeCount,
  markAllRead,
  notifyList,
  notifyListShort,
  readOne,
} from '@store/notification/actions'
import {
  getBadgeCount,
  getNotifyList,
  getNotifyPaging,
  getNotifyShortList,
} from '@store/notification/selectors'
import { useSelector } from 'react-redux'

interface Notify {
  markAllAsRead: () => any
  list: (params: NotificationParams) => any
  shortList: (params: NotificationParams) => void
  fetchCount: () => any
  meta: Meta
  countMeta: Meta
  count: ICount
  data: NotifyItem[]
  read: (params: ReadParams) => any
  paging: MetaPage
  metaShort: Meta
  shortData: NotifyItem[]
}

const useNotify = (): Notify => {
  const dispatch = useAppDispatch()

  // ACTIONS
  const markAllAsRead = () => dispatch(markAllRead())
  const list = (params: NotificationParams) => dispatch(notifyList(params))
  const shortList = (params: NotificationParams) =>
    dispatch(notifyListShort(params))
  const fetchCount = () => dispatch(badgeCount())
  const read = (params: ReadParams) => dispatch(readOne(params))

  const data = useSelector(getNotifyList)
  const shortData = useSelector(getNotifyShortList)
  const paging = useSelector(getNotifyPaging)
  const count = useSelector(getBadgeCount)

  // LIST META
  const _meta = createMetaSelector(notifyList)
  const meta = useSelector(_meta)

  // LIST META
  const _metaShort = createMetaSelector(notifyListShort)
  const metaShort = useSelector(_metaShort)

  // ADD META
  const _countMeta = createMetaSelector(badgeCount)
  const countMeta = useSelector(_countMeta)

  // DETAILS META
  return {
    list,
    markAllAsRead,
    fetchCount,
    meta,
    countMeta,
    count,
    read,
    paging,
    data,
    metaShort,
    shortList,
    shortData,
  }
}

export default useNotify
