import IconButtonExtend from '@components/@material-extend/IconButtonExtend'
import StringAvatar from '@components/@material-extend/StringAvatar'
import { useConfirm } from '@components/Confirm'
import { ROUTE } from '@constants/route.constants'
import { Bolt, Notifications } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { UserProfileItem } from '@services/user.services'
import { getShortName } from '@utils/helper/common.helper'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface UserMenuProps {
  onLogout: () => void
  user?: UserProfileItem
}

const UserMenu: React.FC<UserMenuProps> = ({ onLogout, user }) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const navigate = useNavigate()

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const confirm = useConfirm()

  const handleLogout = () => {
    confirm({
      title: t('CONFIRM.logout_title'),
      description: t('CONFIRM.logout_desc'),
    })
      .then(() => {
        onLogout()
      })
      .catch(() => null)
  }

  return (
    <>
      <IconButtonExtend
        onClick={handleClick}
        elevation={3}
        data-test-id="avatar-btn"
      >
        {user && (
          <StringAvatar
            src={
              _.get(
                user,
                'attributes.avatar_thumbnail',
                '/images/default-150x150.png'
              ) as any
            }
            imgProps={{ loading: 'lazy', width: 150, height: 150 }}
            alt={user?.attributes.firstname_en}
            sx={{ cursor: 'pointer' }}
          />
        )}
      </IconButtonExtend>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: 0,
            background: (theme) => theme.palette.secondary.main,
          },
        }}
        sx={{
          mt: '5px',
          mr: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2.5),
            minWidth: 240,
          }}
        >
          {user && (
            <>
              <StringAvatar
                src={_.get(
                  user,
                  'attributes.avatar_thumbnail',
                  '/images/default-150x150.png'
                )}
                imgProps={{ loading: 'lazy', width: 150, height: 150 }}
                alt={user?.attributes.firstname_en}
                sx={{ width: 60, height: 60, mb: 2 }}
              />
              <Typography
                sx={{ color: (theme) => theme.palette.secondary.contrastText }}
                variant={'h5'}
              >
                {getShortName(user as any)}
              </Typography>
              <Typography noWrap sx={{ color: '#AAB7B8' }} variant={'body1'}>
                {_.get(user, 'attributes.email', '')}
              </Typography>
            </>
          )}
        </Box>
        <Divider
          sx={{ borderColor: (theme) => theme.palette.secondary.light }}
        />
        <nav>
          <List disablePadding>
            <ListItemButton
              data-test-id="profile-menu"
              onClick={() => {
                navigate(ROUTE.PROFILE.ROOT)
                setAnchorEl(null)
              }}
              sx={{
                color: '#eaeded',
                '&:hover': {
                  background: (theme) => theme.palette.secondary.light,
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon
                  sx={{
                    color: '#eaeded',
                    '&:hover': {
                      background: (theme) => theme.palette.secondary.light,
                    },
                  }}
                />
              </ListItemIcon>

              <ListItemText primary={t('USER_MENU.profile')} sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton
              data-test-id="skills-menu"
              onClick={() => {
                navigate(ROUTE.PROFILE.SKILLS)
                setAnchorEl(null)
              }}
              sx={{
                color: '#eaeded',
                '&:hover': {
                  background: (theme) => theme.palette.secondary.light,
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <Bolt
                  sx={{
                    color: '#eaeded',
                    '&:hover': {
                      background: (theme) => theme.palette.secondary.light,
                    },
                  }}
                />
              </ListItemIcon>

              <ListItemText primary={t('USER_MENU.skill')} sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate(ROUTE.NOTIFICATION.LIST)
                setAnchorEl(null)
              }}
              sx={{
                color: '#eaeded',
                '&:hover': {
                  background: (theme) => theme.palette.secondary.light,
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <Notifications
                  sx={{
                    color: '#eaeded',
                    '&:hover': {
                      background: (theme) => theme.palette.secondary.light,
                    },
                  }}
                />
              </ListItemIcon>

              <ListItemText
                primary={t('SYSCOMMON.notifications')}
                sx={{ my: 0 }}
              />
            </ListItemButton>
            <ListItemButton
              data-test-id="logout-btn"
              sx={{
                color: '#eaeded',
                '&:hover': {
                  background: (theme) => theme.palette.secondary.light,
                },
              }}
              onClick={handleLogout}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon sx={{ color: '#eaeded' }} />
              </ListItemIcon>
              <ListItemText primary={t('USER_MENU.logout')} sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </Popover>
    </>
  )
}
export default UserMenu
