import React from 'react'
import { MODULES } from '@constants/modules.constants'
import useCurrentRole from 'utils/hooks/useCurrentRole'
import { ROLE_PERMISSION } from '@constants/user.constants'

export type RenderParams = {
  isDisabled: boolean
  isAdmin?: boolean
}

interface PermissionWrapperProps {
  module: MODULES
  readBehavior?: 'hidden' | 'disabled'
  render: (params: RenderParams) => React.ReactElement
}

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  module,
  readBehavior = 'hidden',
  render,
}) => {
  const { permissions } = useCurrentRole()

  const allowedPermission = permissions?.find(
    (item) => item.attributes.name === module
  )

  const isAllowedRead = !!(
    allowedPermission &&
    allowedPermission.attributes.type === ROLE_PERMISSION.READ
  )

  const isAllowedWrite = !!(
    (allowedPermission &&
      allowedPermission.attributes.type === ROLE_PERMISSION.WRITE) ||
    (allowedPermission &&
      allowedPermission.attributes.type === ROLE_PERMISSION.ADMIN)
  )

  const isAdmin =
    allowedPermission &&
    allowedPermission.attributes.type === ROLE_PERMISSION.ADMIN

  const renderContent = () => {
    if (isAllowedWrite) {
      return render({ isDisabled: !isAllowedWrite, isAdmin: isAdmin })
    }

    if (isAllowedRead) {
      if (readBehavior === 'disabled') {
        return render({ isDisabled: isAllowedRead, isAdmin: isAdmin })
      }
      return null
    }

    return null
  }

  return renderContent()
}

export default PermissionWrapper
