import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/dashboard.services'
import { DASHBOARD_ACTION_TYPE } from './types'

export const fetchDashboardOverview = createAsyncThunk<
  services.DashboardResponse,
  void
>(DASHBOARD_ACTION_TYPE.OVERVIEW, async (_, { rejectWithValue }) => {
  try {
    const res = await services.dashboardService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchProjectOverview = createAsyncThunk<
  services.ProjectOverviewResponse,
  services.DashboardProjectParams
>(
  DASHBOARD_ACTION_TYPE.PROJECT_OVERVIEW,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.projectService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchDashboardEmployee = createAsyncThunk<
  services.DashboardEmployeeResponse,
  services.DashboardEmployeeParams
>(DASHBOARD_ACTION_TYPE.EMPLOYEE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.dashboardEmployee(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const clearEmployeeList = createAction(DASHBOARD_ACTION_TYPE.CLEAR_LIST)
