import { HEADER, SIDEBAR } from '@constants/layouts.constants'
import generateMenus, { MenuItemChildren } from '@constants/menu'
import Header from '@layouts/Shared/Header'
import MobileActions from '@layouts/Shared/Header/MobileActions'
import SideBar from '@layouts/Shared/SideBar'
import NavCollapseItem from '@layouts/Shared/SideBar/NavCollapseItem'
import NavItem from '@layouts/Shared/SideBar/NavItem'
import {
  Box,
  CssBaseline,
  List,
  ListSubheader,
  useMediaQuery,
} from '@mui/material'
import theme from '@theme/index'
import _ from 'lodash'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { LayoutContext } from './LayoutProvider'
import PermissionWrapper from '@containers/PermissionWrapper'
import { MODULES } from '@constants/modules.constants'
import { ROLE_PERMISSION } from '@constants/user.constants'
import { useDemo } from '@utils/hooks/useDemo'

interface LayoutProps extends PropsWithChildren {
  noSpacing?: boolean
  footerDisabled?: boolean
}

const MainLayout: React.FC<LayoutProps> = ({
  noSpacing = true,
  footerDisabled = false,
}) => {
  const {
    isSidebarOpen,
    toggleSidebar,
    user,
    changeLang,
    lang,
    permissions,
    isBottomOpen,
    setBottomOpen,
  } = useContext(LayoutContext)
  const location = useLocation()
  const navigate = useNavigate()

  const menus = generateMenus()

  const onLogout = () => {
    navigate('/logout')
  }
  const { t } = useTranslation()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    // execute on location change
    if (isMobile) {
      toggleSidebar(false)
    }
  }, [location])

  const { isDemo } = useDemo()

  const renderNavs = (menus: MenuItemChildren[]) => {
    return (
      <>
        {menus.map((menu: MenuItemChildren) => {
          const foundItem = _.find(
            permissions,
            (m) => m.attributes.name === menu.module
          )

          const isVisible = menu.module
            ? (foundItem &&
                foundItem.attributes.type !== null &&
                !menu.isAdminOnly) ||
              (menu.isAdminOnly &&
                foundItem &&
                foundItem.attributes.type === ROLE_PERMISSION.ADMIN)
            : true

          return isVisible && menu.type !== 'collapsible' ? (
            <NavItem item={menu} key={menu.label} />
          ) : isVisible && menu.type === 'collapsible' ? (
            <NavCollapseItem item={menu} key={menu.label} />
          ) : null
        })}
      </>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        minWidth: 0,
        minHeight: '100%',
        flexDirection: 'column',
      }}
    >
      <CssBaseline />

      <Header
        onSwitch={changeLang}
        isDemo={isDemo ? isDemo : false}
        user={user}
        language={lang}
        onLogout={onLogout}
        setSideOpen={toggleSidebar}
        open={isSidebarOpen}
      />
      <SideBar open={isSidebarOpen} setSideOpen={toggleSidebar}>
        <List
          disablePadding
          sx={{
            mr: 2,
            pb: 2,
          }}
        >
          {menus.map((item) => {
            const foundItem = _.find(
              permissions,
              (m) => m.attributes.name === item.module
            )

            const isVisible = item.module
              ? foundItem && foundItem.attributes.type !== null
              : true
            return (
              isVisible && (
                <Box key={item.label}>
                  <ListSubheader
                    component="li"
                    disableSticky
                    sx={{
                      fontSize: '80%',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      textTransform: 'uppercase',
                      bgcolor: 'transparent',
                      p: (theme) => theme.spacing(2.75, 3.75, 1.875),
                    }}
                  >
                    {item.label}
                  </ListSubheader>
                  {item.children ? renderNavs(item.children) : null}
                </Box>
              )
            )
          })}
        </List>
      </SideBar>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minWidth: 0,
          position: 'relative',
          pt: isDemo ? '30px' : 0,
        }}
        className="wrapper"
      >
        <Box
          sx={{
            display: 'flex',
            minWidth: 0,
            flex: 1,
            flexDirection: 'column',
            minHeight: '100%',

            marginLeft: isSidebarOpen
              ? {
                  md: `${SIDEBAR.width}px`,
                  sm: 0,
                }
              : 0,
            transition: (theme) => theme.transitions.create(['margin-left']),
            willChange: 'width, margin',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              py: noSpacing ? 0 : { lg: 4, xs: 2 },
              px: noSpacing ? 0 : { lg: 4, xs: 2 },
              mt: noSpacing ? 0 : `${HEADER.height}px`,
              minHeight: `calc(100vh - ${HEADER.height}px)`,
            }}
            className="content"
          >
            <Outlet />
          </Box>
          {!footerDisabled && (
            <Box
              sx={{
                py: 1.5,
                px: 2,
                textAlign: 'center',
                background: '#fff',
                borderTop: '2px solid #eee',
              }}
            >
              {t('SYSCOMMON.copyright')}
            </Box>
          )}
        </Box>
      </Box>
      <PermissionWrapper
        module={MODULES.TIME}
        readBehavior="hidden"
        render={() => (
          <MobileActions
            isShow={isBottomOpen}
            setBottomOpen={setBottomOpen}
            isSideOpen={isSidebarOpen}
            isMobile={isMobile}
          />
        )}
      />
    </Box>
  )
}

export default MainLayout
