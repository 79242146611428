//SKILL CATEGORY SERVICES

import { SearchParams } from '@components/SearchFilter'
import {
  FilterParams,
  MetaPage,
  SearchFilter,
} from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import { api } from './api'
import { SkillCategoryTargetType } from '@constants/settings.constants'
import { PositionItem } from './resource.services'

export type SkillCategoryCreateParams = {
  name: string
  description: string
  display_order?: number
  target: SkillCategoryTargetType
  position_ids?: number[]
}

export type SkillCategoryUpdateOrderParams = {
  skill_categories: { id: string; display_order: number }[]
}

export type SkillCategoryUpdateParams = {
  id: string
  params: SkillCategoryCreateParams
}
export type SkillCategoryDeleteParams = string

export type SkillCategoryListResponse = {
  data: SkillCategoryItem[]
  meta: MetaPage
}

export type SkillCategoryAllResponse = {
  data: SkillCategoryItem[]
}
export type SkillCategoryResponse = {
  data: SkillCategoryItem
}

export type SkillCategoryItem = {
  id: string
  type: 'skill_category'
  attributes: {
    name: string
    description: string
    display_order: number
    target: SkillCategoryTargetType
    positions: { data: PositionItem[] }
  }
}

export const skillCategoryListService = async (
  params: FilterParams
): Promise<SkillCategoryListResponse> => {
  const { data } = await api.get<SkillCategoryListResponse>(
    URI.SKILL_CATEGORY,
    { params }
  )
  return data
}

export const skillCategoryAllService = async (
  params: SearchFilter
): Promise<SkillCategoryAllResponse> => {
  const { data } = await api.get<SkillCategoryAllResponse>(
    URI.SKILL_CATEGORY_LIST,
    { params }
  )
  return data
}

export const skillCategoryCreateService = async (
  params: SkillCategoryCreateParams
): Promise<SkillCategoryResponse> => {
  const { data } = await api.post<SkillCategoryResponse>(
    URI.SKILL_CATEGORY,
    params
  )
  return data
}

export const skillCategoryUpdateService = async (
  params: SkillCategoryUpdateParams
): Promise<SkillCategoryResponse> => {
  const { data } = await api.put<SkillCategoryResponse>(
    `${URI.SKILL_CATEGORY}/${params.id}`,
    params.params
  )
  return data
}

export const skillCategoryUpdateOrderService = async (
  params: SkillCategoryUpdateOrderParams
): Promise<SkillCategoryAllResponse> => {
  const { data } = await api.put<SkillCategoryAllResponse>(
    URI.SKILL_CATEGORY_UPDATE_ORDER,
    params
  )
  return data
}

export const skillCategoryDeleteService = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.SKILL_CATEGORY}/${id}`)
  return data
}

//SKILL SERVICES

export type SkillCreateParams = {
  name: string
  description: string
  skill_category_id?: string
}

export type SkillUpdateParams = {
  id: string
  params: SkillCreateParams
}
export type SkillDeleteParams = string

export type SkillListResponse = {
  data: SkillItem[]
  meta: MetaPage
}

export type SkillAllResponse = {
  data: SkillItem[]
}
export type SkillResponse = {
  data: SkillItem
}
export type SingleSkillResponse = {
  data: UserSkillItem
}

export type SkillItem = {
  id: string
  type: 'skill'
  attributes: {
    name: string
    description: string
    skill_category: {
      data: SkillCategoryItem
    }
  }
}

export const skillListService = async (
  params: FilterParams
): Promise<SkillListResponse> => {
  const { data } = await api.get<SkillListResponse>(URI.SKILL, { params })
  return data
}

export const skillAllService = async (
  params: SearchFilter
): Promise<SkillAllResponse> => {
  const { data } = await api.get<SkillAllResponse>(URI.SKILL_LIST, { params })
  return data
}

export const skillCreateService = async (
  params: SkillCreateParams
): Promise<SkillResponse> => {
  const { data } = await api.post<SkillResponse>(URI.SKILL, params)
  return data
}

export const skillUpdateService = async (
  params: SkillUpdateParams
): Promise<SkillResponse> => {
  const { data } = await api.put<SkillResponse>(
    `${URI.SKILL}/${params.id}`,
    params.params
  )
  return data
}

export const skillDeleteService = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.SKILL}/${id}`)
  return data
}

//REPORT SERVICES

export type SkillReportUserItem = {
  id: number
  name: string
  firstname_en: string
  lastname_en: string
  user_code: string
  position: {
    id: number
    name: string
    short_name: string
    display_order: number
  }
}

export type SkillReportCategoryItem = {
  id: number
  name: string
  display_order: number
}

export type ReportSkillItem = {
  id: number
  skill_name: string
  point: number
  category: SkillReportCategoryItem
}

export type SkillReportListItem = {
  user: SkillReportUserItem
  skills: ReportSkillItem[]
}

export type SkillReportListResponse = SkillReportListItem[]

export type SkillReportParams = {
  positions?: string[]
}

export const skillReportListService = async (
  params: SkillReportParams
): Promise<SkillReportListResponse> => {
  const response = await api.get<SkillReportListResponse>(
    `${URI.SKILL_REPORT}.json`,
    {
      params,
    }
  )
  return response.data
}

export const skillReportCSV = async (
  params: SkillReportParams
): Promise<Blob> => {
  const response = await api.get<Blob>(`${URI.SKILL_REPORT}.xlsx`, {
    params,
    responseType: 'blob',
  })
  return response.data
}

// User Skill
export type UserSkillFormItem = {
  skill_id: {
    id: string
    name: string
  }
  point: string
}
export type UserSkillListResponse = {
  data: UserSkillItem[]
  meta: MetaPage
}

export type UserSkillsAllResponse = {
  data: UserSkillItem[]
}
export type UserSkillParams = {
  user_skills: UserSkillParamsItem[]
}
export type UserSkillsByIdParams = string

export type UserSkillParamsItem = {
  skill_id: string
  point: string
}

export type UserSkillSingleParams = {
  id: string
  params: UserSkillParamsItem
}

export type UserSkillItem = {
  id: string
  type: 'user_skills'
  attributes: {
    skill_id: string
    skill_name: string
    point: number
    category_id: string
    category_name: string
  }
}

export type GroupedSkills = GroupedSkillItem[]

export type GroupedSkillItem = {
  category_id: number
  category_name: string
  data: UserSkillItem[]
}
export type UserSkillDeleteParams = string

export type UserSkillDeleteResponse = UserSkillItem

export const userSkillListService = async (
  params: FilterParams
): Promise<UserSkillListResponse> => {
  const { data } = await api.get<UserSkillListResponse>(URI.USER_SKILL, {
    params,
  })
  return data
}

export const userSkillListAllService = async (
  params: SearchParams
): Promise<UserSkillsAllResponse> => {
  const { data } = await api.get<UserSkillsAllResponse>(URI.USER_SKILL_ALL, {
    params,
  })
  return data
}

export const userSkillsByIdService = async (
  id: UserSkillsByIdParams
): Promise<UserSkillsAllResponse> => {
  const { data } = await api.get<UserSkillsAllResponse>(
    `${URI.USER_SKILL}/${id}`
  )
  return data
}

export const userSkillCreateService = async (
  params: UserSkillParams
): Promise<UserSkillsAllResponse> => {
  const { data } = await api.post<UserSkillsAllResponse>(URI.USER_SKILL, params)
  return data
}

export const userSkillUpdateService = async (
  params: UserSkillParams
): Promise<UserSkillsAllResponse> => {
  const { data } = await api.put<UserSkillsAllResponse>(
    URI.USER_SKILL_UPDATE,
    params
  )
  return data
}

export const userSkillUpdateSingle = async (
  params: UserSkillSingleParams
): Promise<SingleSkillResponse> => {
  const { data } = await api.put<SingleSkillResponse>(
    `${URI.USER_SKILL}/${params.id}`,
    params.params
  )
  return data
}

export const userSkillDeleteService = async (
  id: UserSkillDeleteParams
): Promise<UserSkillDeleteResponse> => {
  const { data } = await api.delete<UserSkillDeleteResponse>(
    `${URI.USER_SKILL}/${id}`
  )
  return data
}

export const addableUserSkillListService = async (
  params: SearchFilter
): Promise<SkillAllResponse> => {
  const { data } = await api.get<SkillAllResponse>(URI.ADDABLE_SKILL_LIST, {
    params,
  })
  return data
}
