import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  EmployeeDepartmentItem,
  EmployeeItem,
  EmployeePositionItem,
  EmployeeProjectItem,
  EmployeeShortItem,
  EmployeeUserItem,
  InfoLogEmployeeItem,
  QuitReasonItem,
  UserProfileItem,
} from '@services/user.services'
import * as actions from '../actions'
import { logOut } from '@store/auth/actions'

type StateType = {
  userProfile?: UserProfileItem
  employeeList?: EmployeeItem[]
  publicEmployeeList?: EmployeeUserItem[]
  employeeAll?: EmployeeShortItem[]
  employeeActivePlusQuitList?: EmployeeShortItem[]
  privateEmployeeList?: EmployeeItem[]
  meta?: MetaPage
  publicPaging?: MetaPage
  privateListMeta?: MetaPage
  employeeDepartmentList?: EmployeeDepartmentItem[]
  employeePositionList?: EmployeePositionItem[]
  employeeProjectList?: EmployeeProjectItem[]
  detail?: EmployeeItem
  publicDetail?: EmployeeUserItem
  quitUsers?: QuitReasonItem[]
  quitUsersMeta?: MetaPage
  infoLog?: InfoLogEmployeeItem[]
  infoLogPaging?: MetaPage
}

const initialState: StateType = {
  userProfile: undefined,
  employeeList: undefined,
  employeeAll: undefined,
  privateEmployeeList: undefined,
  meta: undefined,
  employeeDepartmentList: undefined,
  employeePositionList: undefined,
  employeeProjectList: undefined,
  detail: undefined,
  employeeActivePlusQuitList: undefined,
  quitUsers: undefined,
  quitUsersMeta: undefined,
  infoLog: undefined,
  infoLogPaging: undefined,
  publicDetail: undefined,
  publicEmployeeList: undefined,
  publicPaging: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchProfile.fulfilled, (state, action) => {
    state.userProfile = action.payload.data
  })
  builder.addCase(actions.fetchEmployeeList.fulfilled, (state, action) => {
    state.employeeList = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(
    actions.fetchEmployeePublicList.fulfilled,
    (state, action) => {
      state.publicEmployeeList = action.payload.data
      state.publicPaging = action.payload.meta
    }
  )
  builder.addCase(actions.fetchEmployeeAll.fulfilled, (state, action) => {
    state.employeeAll = action.payload.data
  })
  builder.addCase(
    actions.fetchEmployeeActivePlusQuitList.fulfilled,
    (state, action) => {
      state.employeeActivePlusQuitList = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchPrivateEmployeeList.fulfilled,
    (state, action) => {
      state.privateEmployeeList = action.payload.data
      state.privateListMeta = action.payload.meta
    }
  )
  builder.addCase(
    actions.fetchEmployeeDepartmentList.fulfilled,
    (state, action) => {
      state.employeeDepartmentList = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchEmployeePositionList.fulfilled,
    (state, action) => {
      state.employeePositionList = action.payload.data
    }
  )

  builder.addCase(
    actions.fetchEmployeeProjectList.fulfilled,
    (state, action) => {
      state.employeeProjectList = action.payload.data
    }
  )
  builder.addCase(actions.updateProfile.fulfilled, (state, action) => {
    state.userProfile = action.payload.data
  })
  builder.addCase(actions.updateEmployee.fulfilled, (state, action) => {
    const updatedEmployeeList = state.employeeList?.map((item) => {
      if (item.id === action.payload.data.id) {
        return action.payload.data
      }
      return item
    })
    state.employeeList = updatedEmployeeList || undefined
  })
  builder.addCase(actions.showEmployee.fulfilled, (state, action) => {
    state.detail = action.payload.data
  })
  builder.addCase(actions.showPublicEmployee.fulfilled, (state, action) => {
    state.publicDetail = action.payload.data
  })
  builder.addCase(actions.setAvatar.fulfilled, (state, action) => {
    state.userProfile =
      action.payload.data.file_url && state.userProfile
        ? {
            ...state.userProfile,
            attributes: {
              ...state.userProfile?.attributes,
              avatar: action.payload.data.file_url,
            },
          }
        : state.userProfile
  })
  builder.addCase(actions.clearEmployee, (state) => {
    state.detail = undefined
    state.employeeProjectList = undefined
  })
  builder.addCase(actions.fetchQuitUsersReason.fulfilled, (state, action) => {
    state.quitUsers = action.payload.data
    state.quitUsersMeta = action.payload.meta
  })
  builder.addCase(actions.fetchUserInfoLog.fulfilled, (state, action) => {
    state.infoLog = action.payload.data
    state.infoLogPaging = action.payload.meta
  })
  builder.addCase(logOut.fulfilled, (state) => {
    state.userProfile = undefined
  })
})
