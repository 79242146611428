import { DefaultFilter, DefaultPaging } from '@constants/common.constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

export const initialValues = {
  per_page: DefaultPaging.per_page,
  direction: DefaultFilter.direction,
  order_by: '',
  page: DefaultPaging.page,
}

const useSettingsForm = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        per_page: Yup.string(),
        page: Yup.string(),
        order_by: Yup.string(),
        order: Yup.string(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useSettingsForm
