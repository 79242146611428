export enum NOTIFY_TYPE {
  PROJECT = 'project',
  EMPLOYEE = 'employee',
  SETTINGS = 'settings',
  TIMEOFF_CREATOR = 'timeoff_creator', //my time off
  TIMEOFF_APPROVER = 'timeoff_approver', //approve time off
  TIME_CORRECTION_APPROVER = 'time_correction_approver', //approve time correction
  TIME_CORRECTION_CREATOR = 'time_correction_creator', //my time correction
  REMOTE_WORK_APPROVER = 'remote_work_approver', //approve time correction
  REMOTE_WORK_CREATOR = 'remote_work_creator', //my time correction
  REMOTE_WORK_DISABLED = 'remote_work_disabled',
}
