export enum MODULES {
  PARTNER_COMPANY = 'Company',
  PROJECTS = 'Projects',
  REPORT = 'Report',
  RESOURCE = 'Resource',
  SETTINGS = 'Settings',
  EMAIL = 'Email',
  TIME_SETTINGS = 'Time management settings',
  TIME = 'Time management',
  REMOTE = 'Remote work',
  TIMESHEET = 'Timesheet',
  RESOURCE_PLANNING = 'Resource planning',
}

export const MODULES_LABELS: any = {
  [`${MODULES.PARTNER_COMPANY}`]: {
    label: 'MODULES.partner_company',
  },
  [`${MODULES.PROJECTS}`]: {
    label: 'MODULES.projects',
  },
  [`${MODULES.REPORT}`]: {
    label: 'MODULES.report',
  },
  [`${MODULES.RESOURCE}`]: {
    label: 'MODULES.resource',
  },
  [`${MODULES.SETTINGS}`]: {
    label: 'MODULES.settings',
  },
  [`${MODULES.EMAIL}`]: {
    label: 'MODULES.email',
  },
  [`${MODULES.TIME}`]: {
    label: 'MODULES.time',
  },
  [`${MODULES.TIME_SETTINGS}`]: {
    label: 'MODULES.time_settings',
  },
  [`${MODULES.REMOTE}`]: {
    label: 'MODULES.remote',
  },
  [`${MODULES.TIMESHEET}`]: {
    label: 'SYSCOMMON.timesheet',
  },
  [`${MODULES.RESOURCE_PLANNING}`]: {
    label: 'MODULES.resource_planning',
  },
}
