import { createReducer } from '@reduxjs/toolkit'
import { CurrentUser } from '@services/auth.services'
import * as actions from '../actions'
import { AuthState } from '../actions/types'
import _ from 'lodash'
import { appInit } from '@store/common/actions'

type StateType = {
  user?: CurrentUser
  auth: AuthState

  passwordEmail?: string
}

const initialState: StateType = {
  user: undefined,
  auth: {
    isLoggedIn: false,
    base_url: '',
  },

  passwordEmail: undefined,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actions.loginByEmail.fulfilled, (state, action) => {
      state.user = action.payload
      state.auth = {
        isLoggedIn: true,
        base_url: action.payload.base_url,
      }
    })
    .addCase(actions.loginWithGoogle.fulfilled, (state, action) => {
      state.user = action.payload
      state.auth = {
        isLoggedIn: true,
        base_url: action.payload.base_url,
      }
    })

  builder
    .addCase(appInit.fulfilled, (state, action) => {
      const apiUser = _.get(action, 'payload.data.attributes.current_user')
      state.user =
        state.user && apiUser
          ? {
              ...state.user,
              email: apiUser.email,
              uid: apiUser.uid,
              id: apiUser.id,
            }
          : state.user
    })
    // pending
    .addCase(actions.logOut.fulfilled, (state) => {
      state.user = undefined
      state.auth = {
        base_url: '',
        isLoggedIn: false,
      }
    })
    .addCase(actions.logOut.rejected, (state) => {
      state.user = undefined
      state.auth = {
        base_url: '',
        isLoggedIn: false,
      }
    })
    .addCase(actions.switchLang.fulfilled, (state, action) => {
      state.user = state.user
        ? {
            ...state.user,
            language: action.meta.arg.language,
          }
        : undefined
    })
    .addCase(actions.switchLang.rejected, (state, action) => {
      state.user = state.user
        ? {
            ...state.user,
            language: action.meta.arg.language,
          }
        : undefined
    }) // remove later
  //PASSWORD REDUCERS
  builder.addCase(actions.sendConfirmationEmail.fulfilled, (state, action) => {
    state.passwordEmail = action.meta.arg.email
  })
  builder.addCase(actions.changeCurrentPassword.fulfilled, (state) => {
    state.user = state.user
      ? {
          ...state.user,
          password_change_required: false,
        }
      : undefined
  })
})
