import dayjs from 'dayjs'
import { Direction } from './common.constants'

export type ApprovalTemplateLabels = {
  [key: string]: {
    color: any
    label: string
  }
}

export enum APPROVAL_TEMPLATE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const APPROVAL_TEMPLATE_STATUS_LABELS: ApprovalTemplateLabels = {
  [`${APPROVAL_TEMPLATE_STATUS.INACTIVE}`]: {
    color: 'default',
    label: 'APPROVAL_TEMPLATES.inactive',
  },
  [`${APPROVAL_TEMPLATE_STATUS.ACTIVE}`]: {
    color: 'success',
    label: 'APPROVAL_TEMPLATES.active',
  },
}

export const TIMESHEET_GRID = {
  rowWidth: 20,
  rowHeight: 25,
  headerHeight: 40,
  columnWidth: 100,
  timeoffDefaultWidth: 150,
  totalHeight: 40,
  columns: 12,
}

export enum TIME_CORRECTION_STATUS {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DELETED = 'deleted',
}

export enum TIMESHEET_WORKSPACE {
  remote = 'remote',
  office = 'office',
  no_location = 'no_location',
}

export const TIME_CORRECTION_STATUS_LABELS: any = {
  [`${TIME_CORRECTION_STATUS.PENDING}`]: {
    color: 'warning',
    dotColor: 'warning',
    label: 'TIME_CORRECTION.pending',
  },
  [`${TIME_CORRECTION_STATUS.REJECTED}`]: {
    color: 'error',
    dotColor: 'error',
    label: 'TIME_CORRECTION.rejected',
  },
  [`${TIME_CORRECTION_STATUS.ACCEPTED}`]: {
    color: 'success',
    dotColor: 'success',
    label: 'TIME_CORRECTION.accepted',
  },
  [`${TIME_CORRECTION_STATUS.DELETED}`]: {
    color: 'default',
    dotColor: 'grey',
    label: 'TIME_CORRECTION.deleted',
  },
}

export const TimeCorrectionRequestDefaultFilter = {
  direction: Direction.ASC,
  order_by: 'created_at',
  salary_month: new Date(),
  status: [TIME_CORRECTION_STATUS.PENDING],
}

export const DailyBreakLimitName = 'daily_break_limit'
export const SalaryMonthStartDay = 'salary_month_start_day'
export const DailyReportMissingLimit = 'daily_report_missing_limit'
export const DailyReportPossiblePeriod = 'daily_report_possible_payback_period'

export const StressLevelWeights = {
  complexity: 'stress_level_complexity',
  deadline: 'stress_level_deadline',
  extendWorkedhours: 'stress_level_extend_worked_hours',
  numProjects: 'stress_level_num_projects',
}

export enum TimeReportFilterType {
  OVERTIME_HOLIDAY = 'overtime_holiday',
  OVERTIME_WEEKEND = 'overtime_weekend',
  ACTUAL_OVERTIME = 'actual_overtime',
}

export const TimeReportFilterOptions = [
  {
    id: TimeReportFilterType.OVERTIME_HOLIDAY,
    name: 'TIME_REPORT.overtime_holiday',
  },
  {
    id: TimeReportFilterType.OVERTIME_WEEKEND,
    name: 'TIME_REPORT.overtime_weekend',
  },
  {
    id: TimeReportFilterType.ACTUAL_OVERTIME,
    name: 'TIME_REPORT.actual_overtime',
  },
]

export type TimeCorrectionListFilter = {
  keyword: string
  order_by: string
  direction: string
  per_page: string
  page: string
  status: string[]
}

export type RemoteWorkListFilter = {
  keyword: string
  order_by: string
  direction: string
  per_page: string
  page: string
  status: REMOTE_WORK_REQUEST_STATUS[]
  start_date: string
  end_date: string
  action_by_me: boolean
}

export const TimeCorrectionListDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
  status: [TIME_CORRECTION_STATUS.PENDING, TIME_CORRECTION_STATUS.ACCEPTED],
}

export const timeCorrectionStatuses: {
  id: TIME_CORRECTION_STATUS
  name: string
}[] = [
  {
    id: TIME_CORRECTION_STATUS.PENDING,
    name: 'TIME_CORRECTION.pending',
  },
  {
    id: TIME_CORRECTION_STATUS.REJECTED,
    name: 'TIME_CORRECTION.rejected',
  },
  {
    id: TIME_CORRECTION_STATUS.ACCEPTED,
    name: 'TIME_CORRECTION.accepted',
  },
  {
    id: TIME_CORRECTION_STATUS.DELETED,
    name: 'TIME_CORRECTION.deleted',
  },
]

export enum WORK_TIME_ACTION {
  start = 'work_start',
  end = 'work_end',
}

export enum REMOTE_WORK_REQUEST_STATUS {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DELETED = 'deleted',
}

export const RemoteWorkRequetsDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
  status: [
    REMOTE_WORK_REQUEST_STATUS.PENDING,
    REMOTE_WORK_REQUEST_STATUS.ACCEPTED,
  ],
  start_date: dayjs(new Date()).startOf('year').toDate(),
  end_date: dayjs(new Date()).endOf('year').toDate(),
}

export const RemoteWorkSettingsDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
  start_date: dayjs(new Date()).startOf('year').toDate(),
  end_date: dayjs(new Date()).endOf('year').toDate(),
}
export const RemoteReportDefaultFilter = {
  start_date: dayjs(new Date()).subtract(1, 'month').toDate(),
  end_date: dayjs(new Date()).toDate(),
  user_id: '',
}
