import { Suspense, lazy, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { initReactI18next } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import moment from 'moment'
import 'moment/locale/ja'
import 'moment/locale/mn'
import ReactGA4 from 'react-ga4'

import { authorizationProvider } from '@services/interceptor'
import { previousRoute } from '@store/common/actions'
import { store } from '@store/store'
import customHistory from 'customHistory'
import { CustomRouter } from 'customRouter'
import useAuth from 'utils/hooks/useAuth'
import useCurrentRole from 'utils/hooks/useCurrentRole'
import useInit from '@utils/hooks/useInit'
import useProfile from '@containers/@modules/User/useProfile'
import { MODULES } from '@constants/modules.constants'

import ProtectedOutlet from '@containers/ProtectOutlet'
import ProtectedFromRead from '@containers/ProtectOutlet/ProtectFromRead'

import InitAppLoader from '@components/InitApp/InitAppLoader'
import ContactAdmin from '@components/InitApp/ContactAdmin'
import AuthLayout from 'layouts/AuthLayout'
import BlankLayout from 'layouts/BlankLayout'
import MainLayout from 'layouts/MainLayout'
import FullScreenLayout from '@layouts/FullScreenLayout'

import dayjs from 'dayjs'

const PermissionDenied = lazy(() => import('@pages/403'))
const NotFound = lazy(() => import('@pages/404'))
const NotAvailable = lazy(() => import('@pages/503'))
const CalendarPage = lazy(() => import('@pages/calendar'))
// const ComingSoon = lazy(() => import('@pages/coming_soon'));
const PartnerDivisionList = lazy(() => import('@pages/company/division'))
const DivisionOrderPage = lazy(() => import('@pages/company/division/order'))
const CompanyListPage = lazy(() => import('@pages/company/index'))
const CompanyOverviewPage = lazy(() => import('@pages/company/overview'))
const CostReportPage = lazy(() => import('@pages/cost_report'))
const DashboardPage = lazy(() => import('@pages/dashboard'))
const DepartmentListPage = lazy(() => import('@pages/department'))
const EmployeePage = lazy(() => import('@pages/employee'))
const EmployeeCreatePage = lazy(() => import('@pages/employee/create'))
const EmployeeDetail = lazy(() => import('@pages/employee/detail'))
const EmployeeEditPage = lazy(() => import('@pages/employee/edit'))
const ResourceEmployeeListPage = lazy(() => import('@pages/employee/list'))
const ForgotPasswordPage = lazy(() => import('@pages/forgot_password'))
const Home = lazy(() => import('@pages/home'))
const MeetingRoomListPage = lazy(() => import('@pages/meeting_room'))
const ApprovalTemplateListPage = lazy(() => import('@pages/approval_template'))
const ApprovalTemplateDetailPage = lazy(
  () => import('@pages/approval_template/details')
)
const ApprovalTemplateStepOrderPage = lazy(
  () => import('@pages/approval_template/order')
)
const CampaignListPage = lazy(() => import('@pages/email/campaign'))
const SalaryImportPage = lazy(
  () => import('@pages/email/campaign/edit/data_import')
)
const EmailCampaignDesignPage = lazy(
  () => import('@pages/email/campaign/edit/design')
)
const EmailCampaignSettings = lazy(
  () => import('@pages/email/campaign/edit/settings')
)
const SendPage = lazy(() => import('@pages/email/send'))
const TemplateListPage = lazy(() => import('@pages/email/templates'))
const EmailDesignPage = lazy(() => import('@pages/email/templates/edit/design'))
const EditSettings = lazy(() => import('@pages/email/templates/edit/settings'))
const LoginPage = lazy(() => import('@pages/login'))
const LogOut = lazy(() => import('@pages/logout'))
const MeetingRoomOrderPage = lazy(() => import('@pages/meeting_room/order'))
const MeetingRoomBookingPage = lazy(() => import('@pages/meeting_room_booking'))
const NotificationPage = lazy(() => import('@pages/notifications'))
const PositionListPage = lazy(() => import('@pages/position'))
const PositionOrderPage = lazy(() => import('@pages/position/order'))
const ProfilePage = lazy(() => import('@pages/profile'))
const UpsertUserSkillPage = lazy(
  () => import('@pages/profile/UpsertUserSkillPage')
)
const ProjectListPage = lazy(() => import('@pages/project'))
const PartnerPurchaseOrderWithProjects = lazy(
  () => import('@pages/partner_purchase_order_with_projects')
)

const ProjectActivityPage = lazy(() => import('@pages/project/activity'))
const ProjectEditPage = lazy(() => import('@pages/project/edit'))
const ProjectMemberPage = lazy(
  () => import('@pages/project/edit/ProjectMemberPage')
)
const ProjectOverviewPage = lazy(() => import('@pages/project/overview'))
const PartnerPurchaseOrderPage = lazy(
  () => import('@pages/project/partner_purchase_order')
)
const RequiredChangePasswordPage = lazy(
  () => import('@pages/required_change_password')
)
const ResetPasswordPage = lazy(() => import('@pages/reset_password'))
const ResourcePlanListPage = lazy(() => import('@pages/resource_plan'))
const NewResourcePlanPage = lazy(() => import('@pages/resource_plan/details'))
const RoleListPage = lazy(() => import('@pages/role'))
const SkillListPage = lazy(() => import('@pages/skill'))
const SkillCategoryListPage = lazy(() => import('@pages/skill_category'))
const SkillCategoryListOrderPage = lazy(
  () => import('@pages/skill_category/order')
)
const SkillTable = lazy(() => import('@pages/skill_report'))
const SystemSettingsPage = lazy(() => import('@pages/system_settings'))
const TimeOffApproveListPage = lazy(() => import('@pages/timeoff_approve'))
const TimeOffRequestListPage = lazy(() => import('@pages/timeoff_request'))
const TimeoffReportPage = lazy(() => import('@pages/timeoff_report'))

const TimeOffTemplateListPage = lazy(() => import('@pages/timeoff_template'))
const VacationBalancePage = lazy(() => import('@pages/vacation_balance'))
const VerifyPage = lazy(() => import('@pages/verify_code'))
const TimesheetPage = lazy(() => import('@pages/timesheet'))
const TimeReportPage = lazy(() => import('@pages/time_report'))
const DailyReportPage = lazy(() => import('@pages/dailyreport'))
const QuitUsersPage = lazy(() => import('@pages/quit_users'))
const TeamReportPage = lazy(() => import('@pages/dailyreport/team'))
const InfoLogPage = lazy(() => import('@pages/info_log'))
const ApproveTimeCorrectionListPage = lazy(
  () => import('@pages/approve_time_correction')
)
const HRReportPage = lazy(() => import('@pages/hrdaily'))
const MyTimeCorrectionListPage = lazy(() => import('@pages/my_time_correction'))
const TimeCorrectionTemplatePage = lazy(
  () => import('@pages/time_correction_template')
)
const ActualCostReportPage = lazy(() => import('@pages/actual_cost_report'))
const PrivacyPolicyPage = lazy(() => import('@pages/privacy_policy'))
const TermsOfConditionPage = lazy(() => import('@pages/terms_of_condition'))
const SupportPage = lazy(() => import('@pages/support'))
const CompanyRulePage = lazy(() => import('@pages/company_rule'))
const WorkspacePage = lazy(() => import('@pages/workspace'))
const MapPage = lazy(() => import('@pages/map'))
const RemoteWorkTemplatePage = lazy(() => import('@pages/remote_work_template'))
const RemoteWorkRequestPage = lazy(() => import('@pages/remote_work_request'))
const RemoteWorkRequestApprovePage = lazy(
  () => import('@pages/remote_work_request_approve')
)
const RemoteWorkSettingsPage = lazy(() => import('@pages/remote_work_settings'))
const RemoteWorkMySettingsPage = lazy(
  () => import('@pages/remote_work_my_settings')
)
const CostReportListPage = lazy(() => import('@pages/cost_list'))
const FaqPage = lazy(() => import('@pages/faq'))
const AboutPage = lazy(() => import('@pages/about'))
const ContactPage = lazy(() => import('@pages/contact'))
const DemoPage = lazy(() => import('@pages/demo'))
const PricingPage = lazy(() => import('@pages/pricing'))
import '@silevis/reactgrid/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css' // fetch css for all calendar
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import '@theme/global.css'
import '@theme/home.css'
import '@components/@data-grid/grid.css'
import PageLoader from '@components/InitApp/PageLoader'
import ProtectFromWrite from '@containers/ProtectOutlet/ProtectFromWrite'
import ActualCostReportDetailPage from '@pages/actual_cost_report_detail'
import CompensatoryDaysPage from '@pages/compensatory_days'
import RemoteOfficeReportPage from '@pages/time_report/remote_office_report'
import EmployeeVacationReport from '@pages/vacation_report'
import TimeOffTotalReportPage from '@pages/timeoff_total_report'

// Initialize React Ga with your tracking ID

// eslint-disable-next-line no-console

moment.locale('en')

const trackingID = process.env.REACT_APP_GOOGLE_ANALYTICS
  ? process.env.REACT_APP_GOOGLE_ANALYTICS
  : 'G-HNT6CEDKJV'

function App() {
  const { lang, isLoggedIn, signOut } = useAuth()
  const { permissions } = useCurrentRole()
  const { i18n } = useTranslation()
  const { profile, meta, initApp, initData } = useInit()
  const { data, publicLang } = useProfile()

  useEffect(() => {
    // eslint-disable-next-line no-console
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        lng: lang ? lang : publicLang,
      })

    if (isLoggedIn) {
      initApp()
    }

    trackingID &&
      ReactGA4.initialize(trackingID, {
        gaOptions: {
          cookieDomain: '.unimedia.mn', // Ensure cookies are accessible across subdomains
          cookieFlags: 'SameSite=None; Secure', // Set SameSite and Secure attributes
        },
      })
  }, [])

  authorizationProvider(store)

  const handleResume = () => {
    // eslint-disable-next-line no-console
    console.info(
      '%cApp resumed',
      'color: white; font-size: 12px; background:green; padding:5px;',
      dayjs()
    )
    if (isLoggedIn) {
      initApp()
    }
  }

  useIdleTimer({ onActive: handleResume, timeout: 1000 * 60 * 20 })

  useEffect(() => {
    if (!isLoggedIn) {
      const locale = publicLang === 'jp' ? 'ja' : publicLang
      i18n.changeLanguage(publicLang)
      moment.locale(locale)
    }
    const locale = lang === 'jp' ? 'ja' : lang
    i18n.changeLanguage(lang)
    moment.locale(locale)
  }, [lang, publicLang])

  useEffect(() => {
    if (initData && !data) {
      profile()
    }
  }, [initData])

  if (!permissions && isLoggedIn && initData === undefined) {
    return <InitAppLoader />
  }

  if (!permissions && isLoggedIn && initData === null && meta.loaded) {
    return <ContactAdmin onLogout={signOut} />
  }

  return (
    <CustomRouter history={customHistory}>
      <Routes>
        {/* MAIN PUBLIC STACK START */}
        <Route path={'/'} element={<PublicOutlet />}>
          <Route
            path={'home'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <Home />
                </Suspense>
              </BlankLayout>
            }
          />

          <Route
            path={'login'}
            element={
              <AuthLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <LoginPage />
                </Suspense>
              </AuthLayout>
            }
          />

          <Route
            path={'forgot-password'}
            element={
              <AuthLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <ForgotPasswordPage />
                </Suspense>
              </AuthLayout>
            }
          />
          <Route
            path={'verify-code'}
            element={
              <AuthLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <VerifyPage />
                </Suspense>
              </AuthLayout>
            }
          />
          <Route
            path={'reset-password'}
            element={
              <AuthLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <ResetPasswordPage />
                </Suspense>
              </AuthLayout>
            }
          />
          <Route
            path={'privacy'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <PrivacyPolicyPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'about'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <AboutPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'demo'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <DemoPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'pricing'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <PricingPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'contact'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <ContactPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'faq'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <FaqPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'terms'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <TermsOfConditionPage />
                </Suspense>
              </BlankLayout>
            }
          />
          <Route
            path={'support'}
            element={
              <BlankLayout>
                <Suspense fallback={<InitAppLoader />}>
                  <SupportPage />
                </Suspense>
              </BlankLayout>
            }
          />
        </Route>
        {/* MAIN PUBLIC STACK END */}
        {/* MAIN PRIVATE STACK START*/}
        <Route element={<PrivateOutlet />} path={'/'}>
          <Route element={<MainLayout />} path={'/'}>
            <Route
              index
              element={
                <Suspense fallback={<PageLoader />}>
                  <DashboardPage />
                </Suspense>
              }
            />
            <Route
              path={'logout'}
              element={
                <BlankLayout>
                  <Suspense fallback={<PageLoader />}>
                    <LogOut />
                  </Suspense>
                </BlankLayout>
              }
            />
            {/* Project Route Pack Start */}
            <Route
              element={<ProtectedOutlet module={MODULES.PROJECTS} />}
              path={'project'}
            >
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <ProjectListPage />
                  </Suspense>
                }
              />
              <Route path={':id'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ProjectOverviewPage />
                    </Suspense>
                  }
                />
                <Route
                  path="edit"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ProjectEditPage />
                    </Suspense>
                  }
                />
                <Route
                  path="access"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ProjectMemberPage />
                    </Suspense>
                  }
                />
                <Route
                  path="activity"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ProjectActivityPage />
                    </Suspense>
                  }
                />
                <Route
                  path="partner_purchase_order"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <PartnerPurchaseOrderPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path={'map'}>
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <MapPage />
                  </Suspense>
                }
              />
            </Route>
            {/* Project Route Pack End */}
            {/* Profile Start */}
            <Route path={'profile'}>
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <ProfilePage />
                  </Suspense>
                }
              />
              <Route
                path={'skills'}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <UpsertUserSkillPage />
                  </Suspense>
                }
              />
            </Route>
            {/* Profile End */}
            {/* Company Route Pack Start */}
            <Route
              element={<ProtectedOutlet module={MODULES.PARTNER_COMPANY} />}
              path={'company'}
            >
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <CompanyListPage />
                  </Suspense>
                }
              />
              <Route
                path={':id/overview'}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <CompanyOverviewPage />
                  </Suspense>
                }
              />
              <Route path="divisions">
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <PartnerDivisionList />
                    </Suspense>
                  }
                />
                <Route
                  path="order"
                  element={
                    <ProtectedFromRead module={MODULES.PARTNER_COMPANY} />
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <DivisionOrderPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>
            {/* Company Route Pack End */}

            <Route
              path="resource_planning"
              element={<ProtectedOutlet module={MODULES.RESOURCE_PLANNING} />}
            >
              <Route
                path="partner_purchase_order"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <PartnerPurchaseOrderWithProjects />
                  </Suspense>
                }
              />
              <Route path={'cost'}>
                <Route
                  path={'report/:id'}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <CostReportPage />
                    </Suspense>
                  }
                />
                <Route path={'list'}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <CostReportListPage />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path={'actual_report'}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <ActualCostReportPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={':id/detail'}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <ActualCostReportDetailPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route path={'resource_plan'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ResourcePlanListPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <NewResourcePlanPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Settings Start */}

            <Route
              path="events"
              element={
                <Suspense fallback={<PageLoader />}>
                  <CalendarPage />
                </Suspense>
              }
            />
            <Route path="rules">
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <CompanyRulePage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={'settings'}
              element={<ProtectedOutlet module={MODULES.SETTINGS} />}
            >
              <Route path="meeting_rooms">
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <MeetingRoomListPage />
                    </Suspense>
                  }
                />
                <Route
                  path="order"
                  element={<ProtectedFromRead module={MODULES.SETTINGS} />}
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <MeetingRoomOrderPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route path="workspace">
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <WorkspacePage />
                    </Suspense>
                  }
                />
                <Route path={':id'}>
                  <Route
                    path="location"
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <MapPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="system_settings"
                element={<ProtectedFromRead module={MODULES.SETTINGS} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <SystemSettingsPage />
                    </Suspense>
                  }
                />
              </Route>

              {/* move later */}
            </Route>

            {/* Settings End */}

            <Route
              path={'email'}
              element={<ProtectedOutlet module={MODULES.EMAIL} />}
            >
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <SendPage />
                  </Suspense>
                }
              />
              <Route path="campaigns">
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <CampaignListPage />
                    </Suspense>
                  }
                />
                <Route
                  path={':id/settings'}
                  element={<ProtectedFromRead module={MODULES.EMAIL} />}
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <EmailCampaignSettings />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path={':id/import_data'}>
                  <Route
                    index
                    element={
                      <FullScreenLayout noSpacing>
                        <Suspense fallback={<PageLoader />}>
                          <SalaryImportPage />
                        </Suspense>
                      </FullScreenLayout>
                    }
                  />
                </Route>
                <Route
                  path={':id/content'}
                  element={<ProtectedFromRead module={MODULES.EMAIL} />}
                >
                  <Route
                    index
                    element={
                      <FullScreenLayout noSpacing>
                        <Suspense fallback={<PageLoader />}>
                          <EmailCampaignDesignPage />
                        </Suspense>
                      </FullScreenLayout>
                    }
                  />
                </Route>
              </Route>

              <Route path="templates">
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TemplateListPage />
                    </Suspense>
                  }
                />
                <Route
                  path={':id/settings'}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <EditSettings />
                    </Suspense>
                  }
                />
                <Route
                  path={':id/content'}
                  element={<ProtectedFromRead module={MODULES.EMAIL} />}
                >
                  <Route
                    index
                    element={
                      <FullScreenLayout noSpacing>
                        <Suspense fallback={<PageLoader />}>
                          <EmailDesignPage />
                        </Suspense>
                      </FullScreenLayout>
                    }
                  />
                </Route>
              </Route>
            </Route>

            {/* Employee Start */}
            <Route path={'employee'}>
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <EmployeePage />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <EmployeeDetail />
                  </Suspense>
                }
              />
            </Route>
            {/* Employee End */}
            {/* Meeting room order start*/}
            <Route
              path="book_meeting_room"
              element={
                <Suspense fallback={<PageLoader />}>
                  <MeetingRoomBookingPage />
                </Suspense>
              }
            />
            {/* Meeting room order end*/}
            {/* Resource start */}
            <Route
              path={'resource'}
              element={<ProtectedOutlet module={MODULES.RESOURCE} />}
            >
              <Route
                path={'remoteworkreport'}
                element={<ProtectedFromRead module={MODULES.RESOURCE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <RemoteOfficeReportPage />
                    </Suspense>
                  }
                />
              </Route>

              <Route
                path={'vacationreport'}
                element={<ProtectedFromRead module={MODULES.RESOURCE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <EmployeeVacationReport />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'timeofftotalreport'}
                element={<ProtectedFromRead module={MODULES.RESOURCE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeOffTotalReportPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="department"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <DepartmentListPage />
                  </Suspense>
                }
              />
              <Route
                path="role"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <RoleListPage />
                  </Suspense>
                }
              />
              <Route path="position">
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <PositionListPage />
                    </Suspense>
                  }
                />
                <Route
                  path="order"
                  element={<ProtectedFromRead module={MODULES.RESOURCE} />}
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <PositionOrderPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path={'list'}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <ResourceEmployeeListPage />
                  </Suspense>
                }
              />
              <Route
                path={'create'}
                element={<ProtectedFromRead module={MODULES.RESOURCE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <EmployeeCreatePage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={':id/edit'}
                element={<ProtectedFromRead module={MODULES.RESOURCE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <EmployeeEditPage />{' '}
                    </Suspense>
                  }
                />
              </Route>

              <Route path={'skill'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <SkillListPage />
                    </Suspense>
                  }
                />
                <Route path={'category'}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <SkillCategoryListPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={'order'}
                    element={<ProtectedFromRead module={MODULES.RESOURCE} />}
                  >
                    <Route
                      index
                      element={
                        <Suspense fallback={<PageLoader />}>
                          <SkillCategoryListOrderPage />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path={'report'}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <SkillTable />
                    </Suspense>
                  }
                />
              </Route>
              <Route path={'quit_users'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <QuitUsersPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route path={'info_log'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <InfoLogPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route
              path={'dailyreport'}
              element={<ProtectedOutlet module={MODULES.TIME} />}
            >
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <DailyReportPage />
                  </Suspense>
                }
              />
              <Route
                path="team"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <TeamReportPage />
                  </Suspense>
                }
              />
              <Route
                path="hr"
                element={<ProtectedOutlet module={MODULES.SETTINGS} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <HRReportPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Resource End */}
            {/* Time Off Start*/}
            <Route path={'timeoff'}>
              <Route
                path={'requests'}
                element={<ProtectedOutlet module={MODULES.TIME} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeOffRequestListPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'approve_list'}
                element={<ProtectedFromRead module={MODULES.TIME} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeOffApproveListPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'templates'}
                element={<ProtectedOutlet module={MODULES.TIME_SETTINGS} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeOffTemplateListPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'report'}
                element={<ProtectedOutlet module={MODULES.TIME_SETTINGS} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeoffReportPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'approval_templates'}
                element={<ProtectedOutlet module={MODULES.TIME_SETTINGS} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ApprovalTemplateListPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ApprovalTemplateDetailPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":id/step"
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ApprovalTemplateStepOrderPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Time Off End */}
            {/* Time Start*/}
            <Route path={'time'}>
              <Route
                path={'report'}
                element={<ProtectedFromRead module={MODULES.TIME_SETTINGS} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeReportPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Time  End */}
            {/* Timesheet  Start */}
            <Route
              path={'timesheet'}
              element={<ProtectedOutlet module={MODULES.TIMESHEET} />}
            >
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <TimesheetPage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={'remotework'}
              element={<ProtectedOutlet module={MODULES.REMOTE} />}
            >
              <Route
                path={'requests'}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <RemoteWorkRequestPage />
                  </Suspense>
                }
              />
              <Route
                path={'disabled_log'}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <RemoteWorkMySettingsPage />
                  </Suspense>
                }
              />
              <Route
                path={'approve'}
                element={<ProtectedFromRead module={MODULES.REMOTE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <RemoteWorkRequestApprovePage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'template'}
                element={<ProtectFromWrite module={MODULES.REMOTE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <RemoteWorkTemplatePage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={'settings'}
                element={<ProtectFromWrite module={MODULES.REMOTE} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <RemoteWorkSettingsPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Timesheet  End */}
            {/* Time settings start */}
            <Route
              path={'timesettings'}
              element={<ProtectedOutlet module={MODULES.TIME_SETTINGS} />}
            >
              <Route path={'vacation_balances'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <VacationBalancePage />
                    </Suspense>
                  }
                />
              </Route>
              <Route path={'compensatory_days'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <CompensatoryDaysPage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Time settings end */}
            {/* Time correction start */}
            <Route path={'timecorrection'}>
              <Route path={'approve'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <ApproveTimeCorrectionListPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route path={'requests'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <MyTimeCorrectionListPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route path={'template'}>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TimeCorrectionTemplatePage />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Time correction end */}
            {/* Notification Start */}
            <Route path="notifications">
              <Route
                index
                element={
                  <Suspense fallback={<PageLoader />}>
                    <NotificationPage />
                  </Suspense>
                }
              />
            </Route>
            {/* Notification End*/}

            <Route
              path="/403"
              element={
                <Suspense fallback={<PageLoader />}>
                  <PermissionDenied />
                </Suspense>
              }
            />
            {/* Resource end */}
          </Route>
          <Route element={<FullScreenLayout />} path={'/f'}>
            <Route
              path={'email'}
              element={<ProtectedOutlet module={MODULES.EMAIL} />}
            >
              <Route path="campaigns">
                <Route path={':id/import_data'}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <SalaryImportPage />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path={':id/content'}
                  element={<ProtectedFromRead module={MODULES.EMAIL} />}
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <EmailCampaignDesignPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              <Route path="templates">
                <Route
                  path={':id/content'}
                  element={<ProtectedFromRead module={MODULES.EMAIL} />}
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <EmailDesignPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        {/* MAIN PRIVATE STACK END*/}

        {/* No Route match redirects to last route */}
        <Route
          path="/password_change_required"
          element={
            <AuthLayout>
              <Suspense fallback={<PageLoader />}>
                <RequiredChangePasswordPage />
              </Suspense>
            </AuthLayout>
          }
        />
        <Route
          path="/503"
          element={
            <BlankLayout>
              <Suspense fallback={<PageLoader />}>
                <NotAvailable />
              </Suspense>
            </BlankLayout>
          }
        />
        <Route
          path="*"
          element={
            <BlankLayout>
              <Suspense fallback={<PageLoader />}>
                <NotFound />
              </Suspense>
            </BlankLayout>
          }
        />
      </Routes>
    </CustomRouter>
  )
}

const PrivateOutlet = () => {
  const { isLoggedIn, user } = useAuth()
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    dispatch(previousRoute({ path: location.pathname }))
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    ReactGA4.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }, [location])

  return isLoggedIn ? (
    user?.password_change_required ? (
      <Navigate to="password_change_required" />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={'/home'} />
  )
}

const PublicOutlet = () => {
  const { isLoggedIn } = useAuth()
  const location = useLocation()

  useEffect(() => {
    ReactGA4.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }, [location])

  const authPagePaths = [
    '/login',
    '/reset-password',
    '/verify-code',
    '/forgot-password',
  ]

  const isAuthPage = authPagePaths.includes(location.pathname)

  if (isLoggedIn && isAuthPage) {
    return <Navigate to={'/'} />
  }
  return <Outlet />
}

export default App
