import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'

const getRoot = (state: RootState) => state.dashboard

export const getEmployeeData = createSelector(
  getRoot,
  (state) => state.employeeData
)

export const getProjectData = createSelector(
  getRoot,
  (state) => state.projectData
)

export const getPositionData = createSelector(
  getRoot,
  (state) => state.positionsData
)

export const getProjectOverviewData = createSelector(
  getRoot,
  (state) => state.projectOverivew
)

export const getEmployeeList = createSelector(
  getRoot,
  (state) => state.employeeList
)
