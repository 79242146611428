/* eslint-disable @typescript-eslint/no-unused-vars */
import { StyledTextField } from '@components/@material-extend'
import CustomDatePicker from '@components/@material-extend/CustomDatePicker'
import { TIME_OFF_TEMPLATE_RANGE_TYPE } from '@constants/timeoff.constants'
import useUserCompensatoryEditForm from '@containers/@modules/TimeOffRequest/useUserCompensatoryEditForm'
import { LoadingButton } from '@mui/lab'
import { Divider, Stack, Typography } from '@mui/material'
import { UserCompensatoryItem } from '@services/timeoff.services'
import { Meta } from '@store/metadata/actions/types'
import { formatDate, getFullNameEn } from '@utils/helper/common.helper'
import { millisecondToDuration } from '@utils/helper/timeoff.helper'
import useServerFieldErrors from '@utils/hooks/useServerFieldError'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type UserCompensatoryEditFormParams = {
  onSubmitUpdate: (values: any) => void
  item: UserCompensatoryItem | null
  isDisabled: boolean
  meta: Meta
}

const UserCompensatoryEditForm = ({
  item,
  meta,
  onSubmitUpdate,
  isDisabled,
}: UserCompensatoryEditFormParams) => {
  const { t } = useTranslation()
  const { Controller, methods: methods } = useUserCompensatoryEditForm()
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid, isDirty },
  } = methods

  useServerFieldErrors(meta, setError)

  useEffect(() => {
    if (item) {
      reset({
        comment: item.attributes.comment ? item.attributes.comment : '',
        expire_at: dayjs(item.attributes.expire_at),
      })
    }
  }, [item])

  return (
    <>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('TIME_CORRECTION.employee')}: &nbsp;
        </Typography>
        <Typography>
          {getFullNameEn(_.get(item, 'attributes.user.data'))}
        </Typography>
      </Stack>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('TIME_CORRECTION.user_code')}: &nbsp;
        </Typography>
        <Typography>
          {_.get(item, 'attributes.user.data.attributes.user_code')}
        </Typography>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h4">
        {_.get(item, 'attributes.timeoff_name', '')}
      </Typography>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('TIMEOFF_REQUEST.start_at')}: &nbsp;
        </Typography>
        <Typography>
          {formatDate(_.get(item, 'attributes.timeoff_start_at'))}
        </Typography>
      </Stack>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('TIMEOFF_REQUEST.end_at')}: &nbsp;
        </Typography>
        <Typography>
          {formatDate(_.get(item, 'attributes.timeoff_end_at'))}
        </Typography>
      </Stack>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('TIMEOFF_REQUEST.duration')}: &nbsp;
        </Typography>
        <Typography>
          {_.get(item, 'attributes.timeoff_start_at') &&
          _.get(item, 'attributes.timeoff_end_at')
            ? millisecondToDuration(
                _.get(item, 'attributes.timeoff_duration', 0),
                TIME_OFF_TEMPLATE_RANGE_TYPE.DAY
              )
            : '-'}
        </Typography>
      </Stack>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('COMPENSATORY_DAYS.earned_days')}: &nbsp;
        </Typography>
        <Typography>{_.get(item, 'attributes.earned_day')}</Typography>
      </Stack>
      <Stack direction={'row'}>
        <Typography fontWeight={'500'}>
          {t('COMPENSATORY_DAYS.remaining_days')}: &nbsp;
        </Typography>
        <Typography>{_.get(item, 'attributes.remaining_day')}</Typography>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <form id="user-compensatory-days" onSubmit={handleSubmit(onSubmitUpdate)}>
        <Controller
          name="expire_at"
          control={control}
          render={({ field: { value, ref, ...rest } }: FieldValues) => {
            return (
              <CustomDatePicker
                required
                inputRef={ref}
                fullWidth
                readOnly={isDisabled}
                value={value}
                shouldDisableDate={(day: any) =>
                  dayjs(item?.attributes.timeoff_end_at).isAfter(day)
                }
                labelPrimary={t('COMPENSATORY_DAYS.expire_date')}
                error={errors.expire_at ? true : false}
                helperText={errors.expire_at ? errors.expire_at.message : ''}
                {...rest}
              />
            )
          }}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field: { ref, ...rest } }: FieldValues) => (
            <StyledTextField
              inputRef={ref}
              fullWidth
              required
              readOnly={isDisabled}
              labelPrimary={t('COMPENSATORY_DAYS.comment')}
              placeholder={t('COMPENSATORY_DAYS.comment')}
              error={errors.comment ? true : false}
              helperText={errors.comment ? errors.comment.message : ''}
              {...rest}
            />
          )}
        />
        {!isDisabled && (
          <LoadingButton
            loading={meta.pending}
            data-test-id="update-button"
            variant="contained"
            type="submit"
            color="secondary"
            fullWidth
            disabled={!isValid || !isDirty}
          >
            {t('SYSCOMMON.update')}
          </LoadingButton>
        )}
      </form>
    </>
  )
}

export default UserCompensatoryEditForm
