import { BreakRecordItem, TimeRecordItem } from '@services/time.services'
import {
  getCompanySettings,
  getTimeBreaks,
  getTimeRecords,
} from '@store/common/selectors'
import { useSelector } from 'react-redux'
import _ from 'lodash'

interface Time {
  breaks: BreakRecordItem[] | undefined
  record: TimeRecordItem
  isWorkNotStarted: boolean
  isWorkInProgress: boolean
  isBreakNotStarted: boolean
  isBreakInProgress: boolean
  isWorkDone: boolean
  isLimitReached: boolean
  isLocationRequired: boolean
}

const useCurrentTime = (): Time => {
  const breaks = useSelector(getTimeBreaks)
  const record = useSelector(getTimeRecords)
  const settings = useSelector(getCompanySettings)

  const breakRecordLimit = _.get(
    _.find(settings, (s) => {
      if (s.attributes.name === 'daily_break_limit') {
        return s
      }
    }),
    'attributes.value',
    null
  )

  const isLocationRequired =
    _.get(
      _.find(settings, (s) => {
        if (s.attributes.name === 'is_location_required') {
          return s
        }
      }),
      'attributes.value',
      null
    ) === '1'

  const isWorkNotStarted = !record || !record.attributes.start_at
  const isWorkInProgress =
    record && record.attributes.start_at && !record.attributes.end_at
  const isWorkDone =
    record && record.attributes.start_at && record.attributes.end_at

  const lastBreakRecordWithoutEnd =
    breaks && breaks.find((br: BreakRecordItem) => !br.attributes.end_at)

  const isBreakNotStarted = !record || !lastBreakRecordWithoutEnd
  const isBreakInProgress =
    record &&
    record.attributes.start_at &&
    !record.attributes.end_at &&
    lastBreakRecordWithoutEnd
  const isLimitReached =
    breakRecordLimit && breaks?.length >= breakRecordLimit ? true : false

  return {
    breaks,
    record,
    isWorkNotStarted,
    isWorkInProgress,
    isBreakNotStarted,
    isWorkDone,
    isBreakInProgress,
    isLimitReached,
    isLocationRequired,
  }
}

export default useCurrentTime
