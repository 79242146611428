import { FullScreenOptions } from './types'
import {
  AppBar,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Toolbar,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { HEADER } from '@constants/layouts.constants'

interface FormDialogProps {
  open: boolean
  options: FullScreenOptions
  onClose: (event: Event, reason: string) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const FormDialog = ({ open, options, onClose }: FormDialogProps) => {
  const { title, content, dialogProps } = options

  return (
    <Dialog
      fullScreen
      fullWidth
      {...dialogProps}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <AppBar color="secondary" sx={{ position: 'relative' }}>
        <Toolbar sx={{ minHeight: { xs: HEADER.height, xl: HEADER.height } }}>
          <IconButton
            edge="start"
            sx={{ color: '#fff' }}
            onClick={(e) => onClose(e as any, 'mouse')}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth={options.dialogProps?.maxWidth}>
        <Paper sx={{ mt: 4 }}>
          {title && (
            <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
          )}
          {content ? <DialogContent>{content}</DialogContent> : ''}
        </Paper>
      </Container>
    </Dialog>
  )
}

export default FormDialog
