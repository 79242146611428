const API = process.env.REACT_APP_AUTH_API

export const URI = {
  API,
  LOGIN: '/v1/login',
  LOGOUT: 'v1/logout',
  GOOGLE_LOGIN: '/v1/google_login',
  REFRESH: '/v1/refresh_token',
  FORGOT_PASSWORD: '/v1/passwords/forgot',
  CONFIRM_PASSWORD: '/v1/passwords/confirm',
  RESET_PASSWORD: '/v1/passwords/reset',
  CHANGE_PASSWORD: '/v1/passwords/change',
  PROFILE: '/v1/employee/users/profile',
  PROFILE_UPDATE: '/v1/employee/users/profile_update',
  CHANGE_LANGUAGE: '/v1/change_language',
  // Position
  POSITION: '/v1/employee/positions',
  POSITION_LIST: '/v1/employee/positions/list',
  POSITION_UPDATE_ORDER: '/v1/employee/positions/update_positions',
  // Project
  PROJECT: '/v1/project/projects',
  PROJECT_LIST_COST: '/v1/project/cost_reports/project_list',
  PROJECT_RESOURCE_LIST:
    '/v1/resource_planning/resource_plans/:id/project_list',
  COST_REPORT_LIST: '/v1/resource_planning/cost_reports',
  COST_REPORT_DETAIL: 'v1/resource_planning/cost_reports/:id',
  PROJECT_ACTIVITIES: '/v1/project/projects/:id/activities',
  PROJECT_MEMBER: '/v1/project/projects/:id/project_members',
  PROJECT_ADDABLE_USERS: '/v1/project/projects/:id/addable_users',
  PARTNER_PURCHASE_ORDER: '/v1/project/partner_purchase_orders',
  // Partner Companies
  PARTNER_COMPANY: '/v1/company/partner_companies',
  PARTNER_COMPANY_LIST: '/v1/company/partner_companies/list',
  PARTNER_COMPANY_DIVISIONS: 'v1/company/partner_companies/:id/divisions',
  // Partner Division
  PARTNER_DIVISION: '/v1/company/partner_divisions',
  PARTNER_DIVISION_LIST: '/v1/company/partner_divisions/list',
  PARTNER_DIVISION_UPDATE_ORDER:
    '/v1/company/partner_divisions/update_partner_divisions',
  // Employee
  EMPLOYEE: '/v1/employee/users',
  EMPLOYEE_ALL: '/v1/employee/users/list',
  EMPLOYEE_USER_LIST: 'v1/employee/users/employee_user_list',
  EMPLOYEE_USER_SHOW: 'v1/employee/users/:id/employee_user_show',
  EMPLOYEE_ACTIVE_PLUS_QUIT: '/v1/employee/users/active_plus_quit_users',
  EMPLOYEE_PROJECTS: '/v1/employee/users/:id/project_list',
  EMPLOYEE_PASSWORD_RESET: '/v1/employee/users/:id/reset_user_password',
  BACK_TO_WORK_FROM_LONG_TERM_LEAVE:
    '/v1/employee/users/:id/back_to_work_from_long_term_leave',
  BACK_TO_WORK: '/v1/employee/users/:id/back_to_work',
  // Department
  DEPARTMENT: '/v1/employee/divisions',
  // Holiday
  HOLIDAY: '/v1/base/holidays',
  //Role
  ROLE: '/v1/employee/roles',
  ROLE_MODULE: '/v1/employee/roles/modules',
  EVENT_LIST: '/v1/settings/events/list',
  EVENTS: '/v1/settings/events',
  DASHBOARD: '/v1/base/dashboard',
  // SKILL
  SKILL_CATEGORY: '/v1/settings/skill_categories',
  SKILL_CATEGORY_LIST: '/v1/settings/skill_categories/list',
  SKILL_CATEGORY_UPDATE_ORDER:
    '/v1/settings/skill_categories/update_skill_categories',
  SKILL: '/v1/settings/skills',
  SKILL_LIST: '/v1/settings/skills/list',
  // USER SKILL
  USER_SKILL: '/v1/employee/user_skills',
  USER_SKILL_ALL: '/v1/employee/user_skills/list',
  USER_SKILL_UPDATE: '/v1/employee/user_skills/update_user_skills',
  ADDABLE_SKILL_LIST: '/v1/settings/skills/my_skill',
  //SKILL REPORT
  SKILL_REPORT: '/v1/employee/skill_reports',
  COST_REPORT: '/v1/resource_planning/cost_reports/:id',
  ACTUAL_COST_REPORT: '/v1/resource_planning/cost_reports/actual_cost',
  ACTUAL_COST_REPORT_DETAILS:
    '/v1/resource_planning/cost_reports/actual_cost_details',
  COST_REPORT_PROJECTS: '/v1/resource_planning/cost_reports/project_list',
  TIME_RECORD: '/v1/time_management/time_records',
  TIME_CORRECTION: '/v1/time_management/time_correction_requests',
  TIME_CORRECTION_TEMPLATE:
    '/v1/time_management_settings/time_correction_templates',
  BREAK_RECORD: '/v1/time_management/break_records',
  TAGS: '/v1/time_management/tags',
  // RESOURCE PLAN
  RESOURCE_PLAN: '/v1/resource_planning/resource_plans',
  RESOURCE_PLAN_ALL: '/v1/resource_planning/resource_plans/list',
  RESOURCE_PLAN_USERS: '/v1/resource_planning/resource_plans/:id/users',
  UPLOAD_IMAGE: '/v1/s3/presigned_url',
  RESOURCE_PLAN_ACTIVITY: '/v1/resource_planning/resource_plans/:id/activities',
  RESOURCE_ADD_EMPLOYEE:
    '/v1/resource_planning/resource_plans/:id/add_employee',
  RESOURCE_REMOVE_EMPLOYEE:
    '/v1/resource_planning/resource_plans/:id/remove_employee',
  RESOURCE_ADDABLE_USERS:
    '/v1/resource_planning/resource_plans/:id/addable_users',
  // MEETING ROOM
  MEETING_ROOM: '/v1/settings/meeting_rooms',
  MEETING_ROOM_UPDATE_ORDER: '/v1/settings/meeting_rooms/update_meeting_rooms',
  // MEETING ROOM ORDER
  MEETING_ROOM_ORDER: '/v1/settings/meeting_room_orders',
  // TIME TABLE
  TIME_TABLE: '/v1/time_management_settings/time_tables',
  // EMAIL
  EMAIL_CAMPAIGN: '/v1/email/email_campaigns',
  EMAIL_FAILURE: '/v1/email/email_failures',
  EMAIL_TEMPLATE: '/v1/email/email_templates',
  SYSTEM_SETTINGS: '/v1/base/company_settings',
  COMPANY_RULES: '/v1/base/company_rules',
  // TIME OFF TEMPLATE
  TIME_OFF_TEMPLATE: '/v1/time_management_settings/timeoff_templates',
  TIME_OFF_TEMPLATE_ALL: '/v1/time_management_settings/timeoff_templates/list',
  TIME_OFF_REPORT_TEMPLATE_LIST:
    'v1/time_management_settings/timeoff_templates/timeoff_list',
  TIME_OFF_REPORT_LIST: 'v1/time_management/timeoff_requests/timeoff_report',
  TIME_OFF_REPORT_TOTAL_LIST:
    'v1/time_management/timeoff_requests/timeoff_report_total',
  // TIME OFF REQUEST
  TIME_OFF_REQUEST: '/v1/time_management/timeoff_requests',
  TIME_OFF_REQUEST_DELETE_BY_APPROVER:
    '/v1/time_management/timeoff_requests/:id/timeoff_request_delete',
  TIME_OFF_APPROVE_LIST:
    '/v1/time_management/timeoff_requests/timeoff_approve_list',
  TIME_OFF_APPROVE: '/v1/time_management/timeoff_requests/:id/approve',
  TIME_OFF_DENY: '/v1/time_management/timeoff_requests/:id/deny',
  TIME_OFF_DETAIL: '/v1/time_management/timeoff_requests/:id/approve_detail',
  // APPROVAL TEMPLATES
  APPROVAL_TEMPLATES: '/v1/time_management_settings/approval_templates',
  APPROVAL_TEMPLATES_ALL:
    '/v1/time_management_settings/approval_templates/list',
  STEP_UPDATE: '/v1/time_management_settings/update_steps',
  INIT: '/v1/app_init',
  NOTIFICATIONS: '/v1/employee/notifications',
  // VACATION BALANCE
  VACATION_BALANCE: '/v1/time_management_settings/vacation_balances',
  // DAILY REPORT
  DAILY_REPORTS: '/v1/time_management/daily_report_details',
  // TIME REPORT
  TIME_REPORT: '/v1/time_management/time_reports',
  // COMPENSATORY DAYS
  COMPENSATORY_DAYS: '/v1/time_management/timeoff_requests/compensatory_days',
  USER_COMPENSATORY_LIST: '/v1/time_management/compensatory_days',
  // QUIT REASON
  QUIT_REASON: '/v1/employee/quit_reasons',
  QUIT_REASON_UPDATE: '/v1/employee/quit_reasons/update_reason',
  WORKSPACE: '/v1/settings/workspaces',
  REMOTE_WORK_TEMPLATE: '/v1/remote_work/remote_work_templates',
  REMOTE_WORK_REQUEST: '/v1/remote_work/remote_work_requests',
  REMOTE_WORK_SETTINGS: '/v1/remote_work/remote_work_settings',
  DASHBOARD_EMPLOYEE: '/v1/base/dashboard/workers_info',
  //COST REPORT LIST
}

export const excludeURI = [
  URI.LOGIN,
  URI.LOGOUT,
  URI.GOOGLE_LOGIN,
  URI.RESET_PASSWORD,
  URI.CONFIRM_PASSWORD,
  URI.FORGOT_PASSWORD,
  URI.REFRESH,
]
