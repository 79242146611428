export enum AUTH_ACTION_TYPE {
  LOGIN_BY_EMAIL = 'auth/authLoginByEmail',
  LOGIN_WITH_GOOGLE = 'auth/authLoginWithGoogle',
  LOGOUT = 'auth/logout',
  CHANGE_LANGUAGE = 'auth/authChangeLanguage',

  FORGOT_PASSWORD = 'auth/authForgot',
  CONFIRM_PASSWORD = 'auth/authConfirm',
  RESET_PASSWORD = 'auth/authReset',
  CHANGE_PASSWORD = 'auth/authChange',
}

export type ClientLoginParams = {
  access_token: string
}

export type AuthState = {
  isLoggedIn: boolean
  base_url: string
}

export type ProjectItem = {
  id: number
  name: string
}
