import { BgColorOptionsLight } from '@constants/common.constants'
import { generateColor } from '@marko19907/string-to-color'
import {
  Box,
  Chip,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { SkillReportUserItem } from '@services/skill.services'
import { getShortName } from '@utils/helper/common.helper'
import _ from 'lodash'
import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Link } from 'react-router-dom'

interface SkillEmployeeInfoProps {
  member: SkillReportUserItem
  tooltip: string
}

const SkillEmployeeInfo: React.FC<SkillEmployeeInfoProps> = ({
  member,
  tooltip,
}) => {
  const position = _.get(member, 'position.name', '')

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        height: 28,
        justifyContent: 'flex-between',
      }}
    >
      <Box
        sx={{
          p: 1,
          borderRight: '1px solid #eee',
          width: 120,
          maxWidth: 120,
          textAlign: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: 500,
          }}
          noWrap
        >
          {member.user_code}
        </Typography>
      </Box>
      <Box
        data-test-id="employee-name"
        tabIndex={-1}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <MuiLink
          data-test-id="employee-name"
          tabIndex={-1}
          sx={{
            fontSize: 14,
            textDecoration: 'none',
            color: (theme) => theme.palette.secondary.main,
            '&:hover': {
              color: (theme) => theme.palette.primary.main,
            },
          }}
          to={`/employee/${member.id}`}
          component={Link}
        >
          <Typography noWrap sx={{ pl: 1, pr: 1, textAlign: 'left' }}>
            {getShortName(
              undefined,
              _.get(member, 'firstname_en'),
              _.get(member, 'lastname_en')
            )}
          </Typography>
        </MuiLink>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            placement="right"
            sx={{
              ml: 1,
              mr: 1,
              width: '20px',
              height: '20x',
            }}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>

      <Box sx={{ maxWidth: 80, width: 80, minWidth: 80, height: '100%' }}>
        <Tooltip title={position} placement="right">
          <Chip
            sx={{
              backgroundColor: generateColor(
                `${position}-${_.get(member, 'position.id', '')}`,
                BgColorOptionsLight
              ),
              width: '100%',
              borderRadius: 0,
              height: '100%',
            }}
            size={'small'}
            label={_.get(member, 'position.short_name', '')}
          />
        </Tooltip>
      </Box>
    </Stack>
  )
}

export default SkillEmployeeInfo
