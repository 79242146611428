import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import HelpContent from '@components/HelpContent'
import VacationReportFilter from './VacationReportFilter'
import VacationReportWidget from '@components/@modules/Report/VacationReportWidget'
import PermissionWrapper from '@containers/PermissionWrapper'
import DetailPageLayout from '@layouts/ContentLayout/DetailPageLayout'
import { LayoutContext } from '@layouts/MainLayout/LayoutProvider'
import useEmployeeReport from '@containers/@modules/Time/useEmployeeReport'
import { HelpPage, lang } from '@constants/help.constants'
import { MODULES } from '@constants/modules.constants'
import { ROUTE } from '@constants/route.constants'
import { VacationReportDefaultFilter } from '@constants/vacation.constants'
import { remoteReportParams } from '@services/time.services'
import VacationDetailFunction from '@components/@modules/Report/VacationReportTable'
import ReportDownloader from '@components/ReportDownloader'
import { vacationReportDownloadService } from '@services/vacation.services'
import { generateFileName } from '@utils/helper/common.helper'
import saveAs from 'file-saver'
import dayjs from 'dayjs'

const EmployeeVacationReport: React.FC = () => {
  const { t } = useTranslation()
  const { isSidebarOpen } = useContext(LayoutContext)

  const [filter, setFilter] = useState<remoteReportParams>({
    ...VacationReportDefaultFilter,
    start_date: VacationReportDefaultFilter.start_date,
    end_date: VacationReportDefaultFilter.end_date,
    user_id: VacationReportDefaultFilter.user_id,
  })
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('')

  const {
    fetchVacationReport,
    vacationReportList,
    vacationReportDetails,
    fetchVacationReportDetail,
    metaVacation,
  } = useEmployeeReport()

  useEffect(() => {
    fetchVacationReport({
      start_at: dayjs(filter.start_date).startOf('day').toDate(),
      end_at: dayjs(filter.end_date).endOf('day').toDate(),
    })
      .unwrap()
      .catch(() => console.error('Error fetching report:'))

    if (selectedEmployeeId) {
      fetchVacationReportDetail({
        start_at: dayjs(filter.start_date).startOf('day').toDate(),
        end_at: dayjs(filter.end_date).endOf('day').toDate(),
        user_id: selectedEmployeeId,
      })
        .unwrap()
        .catch(() => console.error('Error fetching report:'))
    }
  }, [filter])
  useEffect(() => {
    if (selectedEmployeeId) {
      fetchVacationReportDetail({
        start_at: dayjs(filter.start_date).startOf('day').toDate(),
        end_at: dayjs(filter.end_date).endOf('day').toDate(),
        user_id: selectedEmployeeId,
      })
        .unwrap()
        .catch(() => console.error('Error fetching report:'))
    }
  }, [selectedEmployeeId])
  const handleRowClick = (userId: string) => {
    setSelectedEmployeeId((prevId) => (prevId === userId ? '' : userId))
  }
  const fetchCSV = async () => {
    try {
      const blob = await vacationReportDownloadService({
        start_at: filter.start_date,
        end_at: filter.end_date,
      })
      const fileName = generateFileName('vacation-report', 'xlsx')
      saveAs(blob, fileName)
    } catch (error) {
      console.error('ダウンロードに失敗しました。:', error)
    }
  }
  const breadcrumbs = [
    { to: ROUTE.ROOT, title: t('SYSCOMMON.dashboard') },
    { title: `${t('VACATION_REPORT.report_title')} ` },
  ]

  return (
    <PermissionWrapper
      module={MODULES.RESOURCE}
      readBehavior="disabled"
      render={() => (
        <DetailPageLayout
          title={t('VACATION_REPORT.report_title')}
          helpTitle={t('VACATION_REPORT.report_title')}
          breadcrumbs={breadcrumbs}
          actions={
            <>
              <ReportDownloader fetchCSV={fetchCSV} />
            </>
          }
          helpContent={
            <HelpContent
              id={HelpPage.time_report[`${i18next.language as lang}`]}
            />
          }
        >
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} md={isSidebarOpen ? 12 : 8} lg={7} xl={9}>
              <Paper sx={{ px: 2, mb: 2, pt: 1, pb: 0.1 }}>
                <Box
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: 1,
                    width: '100%',
                    paddingBottom: { xs: '10px', md: '0px' },
                  }}
                >
                  <VacationReportFilter onFilter={setFilter} />
                </Box>
              </Paper>

              <Paper
                sx={{ width: '100%', position: 'relative', minHeight: '120px' }}
              >
                {metaVacation.pending && (
                  <Box
                    sx={{
                      position: 'absolute',
                      background: 'rgba(255,255,255,0.6)',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'top',
                      paddingTop: '50px',
                      zIndex: 100,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                <Box
                  className="scrollbar-container"
                  sx={{
                    width: '100%',
                  }}
                >
                  <React.Suspense
                    fallback={
                      <Box
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          left: 0,
                          right: 0,
                          top: 0,
                          bottom: 0,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    }
                  >
                    <TableContainer component={Paper}>
                      {vacationReportList && (
                        <Table
                          aria-label="collapsible table"
                          sx={{
                            '& .MuiTableCell-root': {
                              py: 0,
                              px: 1,
                              fontSize: 12,
                              fontWeight: 500,
                              borderRight: '1px solid #e0e0e0',
                              overflowY: 'hidden',
                            },
                            '& .MuiTableCell-root.loader-holder-cell': {
                              p: 0,
                              height: 0,
                            },
                          }}
                        >
                          <TableHead
                            sx={{
                              height: '50px',
                              backgroundColor: '#FFFFFF',
                              boxShadow: '0 2px 2px rgba(0,0,0,0.1)',
                            }}
                          >
                            <TableRow>
                              <TableCell sx={{ width: '25px' }} />
                              <TableCell align="center">
                                {t('VACATION_REPORT.employee')}
                              </TableCell>
                              <TableCell align="center">
                                {t('VACATION_REPORT.used_days')}
                              </TableCell>
                              <TableCell align="center">
                                {t('VACATION_REPORT.remaining_days')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {vacationReportList &&
                              vacationReportList.map((row) => (
                                <VacationDetailFunction
                                  key={row.user_id}
                                  row={row}
                                  handleRowClick={handleRowClick}
                                  selectedEmployeeId={selectedEmployeeId}
                                  vacationReportDetails={vacationReportDetails}
                                />
                              ))}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </React.Suspense>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={isSidebarOpen ? 12 : 4} lg={4} xl={3}>
              <Paper sx={{ minHeight: 100, position: 'relative' }}>
                {metaVacation.pending && (
                  <Box
                    sx={{
                      position: 'absolute',
                      background: 'rgba(255,255,255,0.6)',
                      left: 0,
                      right: 0,
                      top: 16,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 100,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {vacationReportList && (
                  <VacationReportWidget
                    vacationReportList={vacationReportList}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </DetailPageLayout>
      )}
    />
  )
}

export default EmployeeVacationReport
