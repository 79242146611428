import { yupResolver } from '@hookform/resolvers/yup'
import dayjs, { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const initialValues = {
  partner_division_ids: [],
  report_start_date: dayjs(new Date()),
  report_end_date: null as Dayjs | null,
}

const useCostReportFilterForm = () => {
  const validationSchema = Yup.object().shape(
    {
      partner_division_ids: Yup.array(),
      report_start_date: Yup.date()
        .nullable()
        .required(t('ERROR.E000002'))
        .typeError(t('ERROR.E000006')),
      report_end_date: Yup.date()
        .when('report_start_date', (reportStartDate: any, schema: any) => {
          if (
            reportStartDate !== null &&
            reportStartDate[0] !== null &&
            dayjs(reportStartDate[0]).isValid()
          ) {
            return schema.test({
              test: function (reportEndDate: any) {
                if (!reportEndDate) {
                  // If report_end_date is not set, it's valid.
                  return true
                }

                const startDate = dayjs(reportStartDate)
                const endDate = dayjs(reportEndDate)

                return (
                  (startDate.isSame(endDate, 'year') &&
                    startDate.isSame(endDate, 'month') &&
                    endDate.isSame(startDate, 'day')) ||
                  (startDate.isSame(endDate, 'year') &&
                    startDate.isSame(endDate, 'month') &&
                    endDate.isAfter(startDate, 'day'))
                )
              },
              message:
                'Start end dates must be same month and after start date ',
            })
          }
          return schema
        })
        .nullable()
        .typeError(t('ERROR.E000006')),
      customDateRange: Yup.boolean(),
    },
    [['report_start_date', 'report_end_date']]
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods }
}

export default useCostReportFilterForm
