import { api } from './api'
import axios from 'axios'
import { ExtendedFile } from '@components/FileUpload/UploaderDialog'
import { BreakRecordItem, TimeRecordItem } from './time.services'
import { URI } from '@constants/uri.constants'
import { SystemSettingsItem } from './settings.services'

export type getPresignedUrlResponse = {
  url: string
  file_url: string
  file_name: string
  key: string
}

export type AppInitResponse = {
  data: AppInit
}

export type AvatarUrls = {
  avatar_cover: string | null
  avatar_medium: string | null
  avatar_thumbnail: string | null
}

export type MissingReportItem = {
  id: string
  type: 'daily_report_missing'
  attributes: {
    work_date: string
  }
}

export type AppInit = {
  id: string
  type: string
  attributes: {
    time_records: {
      data: TimeRecordItem
    }
    break_records: {
      data: BreakRecordItem[]
    }
    company_settings: {
      data: SystemSettingsItem[]
    }
    system_timezone: string
    current_user: { uid: string; email: string; id: number }
    daily_report_missing_dates: {
      data: MissingReportItem[]
    }
  }
}

export const uploadImage = async (
  uri: string,
  file: ExtendedFile,
  type: string,
  cb?: (progress: number) => void,
  project_id?: string
) => {
  const { data: presignedResponse } = await api.post<getPresignedUrlResponse>(
    uri,
    {
      file_name: file.name,
      content_type: type,
      mime_type: file.type,
      project_id: project_id,
    }
  )
  await axios.put(presignedResponse.url, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress(e: ProgressEvent) {
      const percentCompleted = Math.round((e.loaded * 100) / e.total)
      cb && cb(percentCompleted)
    },
  })
  return presignedResponse
}

export const initService = async (): Promise<AppInitResponse> => {
  const { data } = await api.get<AppInitResponse>(URI.INIT)
  return data
}
