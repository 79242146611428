import { HEADER } from '@constants/layouts.constants'
import { LayoutContext } from '@layouts/MainLayout/LayoutProvider'
import Header from '@layouts/Shared/Header'
import { Box, CssBaseline } from '@mui/material'
import React, { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

interface LayoutProps extends PropsWithChildren {
  noSpacing?: boolean
  footerDisabled?: boolean
}

const FullScreenLayout: React.FC<LayoutProps> = ({
  noSpacing = true,
  footerDisabled = false,
}) => {
  const { user, isSidebarOpen, toggleSidebar, changeLang, lang } =
    useContext(LayoutContext)
  const navigate = useNavigate()

  const onLogout = () => {
    navigate('/logout')
  }
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        minWidth: 0,
        minHeight: '100%',
        flexDirection: 'column',
      }}
    >
      <CssBaseline />
      <Header
        onSwitch={changeLang}
        user={user}
        language={lang}
        onLogout={onLogout}
        setSideOpen={toggleSidebar}
        open={isSidebarOpen}
        isFull={true}
      />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minWidth: 0,
          position: 'relative',
        }}
        className="wrapper"
      >
        <Box
          sx={{
            display: 'flex',
            minWidth: 0,
            flex: 1,
            flexDirection: 'column',
            minHeight: '100%',
            marginLeft: 0,
            transition: (theme) => theme.transitions.create(['margin-left']),
            willChange: 'width, margin',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              py: noSpacing ? 0 : { lg: 4, xs: 2 },
              px: noSpacing ? 0 : { lg: 4, xs: 2 },
              mt: noSpacing ? 0 : `${HEADER.height}px`,
              minHeight: `calc(100vh - ${HEADER.height}px)`,
            }}
            className="content"
          >
            <Outlet />
          </Box>
          {!footerDisabled && (
            <Box
              sx={{
                py: 1.5,
                px: 2,
                textAlign: 'center',
                background: '#fff',
                borderTop: '2px solid #eee',
              }}
            >
              {t('SYSCOMMON.copyright')}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default FullScreenLayout
