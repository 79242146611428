import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/auth.services'
import { AUTH_ACTION_TYPE, ProjectItem } from './types'

export const loginByEmail = createAsyncThunk<
  services.UserLoginResponse,
  services.UserLoginParams
>(AUTH_ACTION_TYPE.LOGIN_BY_EMAIL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.loginService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const logOut = createAsyncThunk<void, void>(
  AUTH_ACTION_TYPE.LOGOUT,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.logOutService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const loginWithGoogle = createAsyncThunk<
  services.UserLoginResponse,
  services.SocialLoginParams
>(AUTH_ACTION_TYPE.LOGIN_WITH_GOOGLE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.socialLoginService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const switchLang = createAsyncThunk<
  services.UserLangResponse,
  services.LangParams
>(AUTH_ACTION_TYPE.CHANGE_LANGUAGE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.changeLangService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const changeProject = createAction<ProjectItem>('auth/changeProject')

// PASSWORD ACTIONS

export const sendConfirmationEmail = createAsyncThunk<
  services.PasswordActionCommonResponse,
  services.ForgotPasswordParams
>(
  AUTH_ACTION_TYPE.FORGOT_PASSWORD,
  async (params: services.ForgotPasswordParams, { rejectWithValue }) => {
    try {
      const res = await services.forgotPasswordService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const verifyConfirmationCode = createAsyncThunk<
  services.PasswordActionCommonResponse,
  services.ConfirmPasswordParams
>(
  AUTH_ACTION_TYPE.CONFIRM_PASSWORD,
  async (params: services.ConfirmPasswordParams, { rejectWithValue }) => {
    try {
      const res = await services.confirmPasswordService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetForgotPassword = createAsyncThunk<
  services.PasswordActionCommonResponse,
  services.ResetPasswordParams
>(
  AUTH_ACTION_TYPE.RESET_PASSWORD,
  async (params: services.ResetPasswordParams, { rejectWithValue }) => {
    try {
      const res = await services.resetPasswordService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const changeCurrentPassword = createAsyncThunk<
  services.PasswordActionCommonResponse,
  services.ChangePasswordParams
>(
  AUTH_ACTION_TYPE.CHANGE_PASSWORD,
  async (params: services.ChangePasswordParams, { rejectWithValue }) => {
    try {
      const res = await services.changePasswordService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
