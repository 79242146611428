import {
  DailyReport,
  TeamDailyReportItem,
} from '@services/dailyreport.services'

export interface TeamEventData {
  memberId: string
  name: string
  start: Date
  end: Date | null
  reports: DailyReport
  user: {
    firstname: string
    lastname: string
    user_code: string
    avatar: string | null
    lastname_en: string
    firstname_en: string
  }
}

export const transformTeamDailyReport = (
  data: TeamDailyReportItem[]
): TeamEventData[] => {
  if (!data) return []

  return data[0]?.attributes.members.data.flatMap((member) =>
    member.attributes.reports.data.map((timeCard) => ({
      memberId: member.attributes.user_id,
      name: timeCard.id,
      start: new Date(timeCard.attributes.work_date),
      end: new Date(timeCard.attributes.work_date),
      reports: timeCard.attributes.daily_report.data,
      user: {
        firstname: member.attributes.firstname,
        lastname: member.attributes.lastname,
        user_code: member.attributes.user_code,
        avatar: member.attributes.avatar,
        lastname_en: member.attributes.lastname_en,
        firstname_en: member.attributes.firstname_en,
      },
    }))
  )
}
