import { MetaPage } from '@constants/common.constants'
import { RoleItem } from '@services/resource.services'
import {
  BackToWorkFromLongTermLeaveParams,
  EmployeeAddParams,
  EmployeeBackToWorkParams,
  EmployeeDeleteParams,
  EmployeeDepartmentItem,
  EmployeeDetailParams,
  EmployeeItem,
  EmployeeListParams,
  EmployeePositionItem,
  EmployeeProjectItem,
  EmployeeProjectListParams,
  EmployeeQuitParams,
  EmployeeResetPasswordParams,
  EmployeeUpdateParams,
  EmployeeUserItem,
  InfoLogEmployeeItem,
  QuitReasonItem,
  QuitReasonParam,
  ReasonUpdateParams,
  UserInfoLogParams,
} from '@services/user.services'
import { useAppDispatch } from '@store/hooks'
import { clearMetaData } from '@store/metadata/actions'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { fetchRoleListAll } from '@store/resource/actions'
import { getRoleListAll } from '@store/resource/selectors'
import {
  backToWorkEmployee,
  backToWorkFromLongTermEmployee,
  clearEmployee,
  createEmployee,
  deleteEmployee,
  fetchEmployeeActivePlusQuitList,
  fetchEmployeeAll,
  fetchEmployeeDepartmentList,
  fetchEmployeeList,
  fetchEmployeePositionList,
  fetchEmployeeProjectList,
  fetchEmployeePublicList,
  fetchPrivateEmployeeList,
  fetchQuitUsersReason,
  fetchUserInfoLog,
  quitEmployee,
  resetPassword,
  showEmployee,
  showPublicEmployee,
  updateEmployee,
  updateQuitReason,
} from '@store/user/actions'
import { USER_ACTION_TYPE } from '@store/user/actions/types'
import {
  getActivePlusQuitEmployee,
  getEmployeeAll,
  getEmployeeDepartmentList,
  getEmployeeDetail,
  getEmployeeList,
  getEmployeeMeta,
  getEmployeePositionList,
  getEmployeeProjectList,
  getPrivateEmployeeList,
  getPrivateEmployeesMeta,
  getPublicEmployeeDetail,
  getPublicEmployeeList,
  getPublicEmployeesMeta,
  getQuitUsers,
  getQuitUsersMeta,
  getUserInfoLog,
  getUserInfoLogMeta,
} from '@store/user/selectors'
import { useSelector } from 'react-redux'

interface Employee {
  fetch: (params: EmployeeListParams) => any
  fetchAll: () => any
  fetchPrivateList: (params: EmployeeListParams) => any
  fetchPublicList: (params: EmployeeListParams) => any
  addEmployee: (params: EmployeeAddParams) => any
  fetchDepartmentList: () => any
  fetchPositionList: () => any
  fetchProjectList: (params: EmployeeProjectListParams) => any
  deleteItem: (params: EmployeeDeleteParams) => any
  updateItem: (params: EmployeeUpdateParams) => any
  fetchDetail: (params: EmployeeDetailParams) => any
  fetchPublicDetail: (params: EmployeeDetailParams) => any
  backToWork: (params: EmployeeBackToWorkParams) => any
  backToWorkFromLongTerm: (params: BackToWorkFromLongTermLeaveParams) => any
  employeeList: EmployeeItem[]
  employeePublicList: EmployeeUserItem[]
  employeeAll: EmployeeItem[]
  privateEmployeeList: EmployeeItem[]
  departmentList: EmployeeDepartmentItem[]
  positionList: EmployeePositionItem[]
  projectList: EmployeeProjectItem[]
  detail: EmployeeItem
  paging: MetaPage
  publicDetail: EmployeeUserItem
  publicDetailMeta: Meta
  publicPaging: MetaPage
  publicListMeta: Meta
  privateListPaging: MetaPage
  addEmployeeMeta: Meta
  clearAddEmployeeMeta: () => void
  deleteMeta: Meta
  clearDeleteEmployeeMeta: () => void
  updateMeta: Meta
  clearUpdateEmployeeMeta: () => void
  showEmployeeMeta: Meta
  clearShowEmployeeMeta: () => void
  meta: Meta
  clearMeta: () => void
  privateListMeta: Meta
  clearPrivateListMeta: () => void
  fetchRoleList: (params: string) => void
  roleList: RoleItem[]
  roleListMeta: Meta
  departmentListMeta: Meta
  positionListMeta: Meta
  clearDetailData: () => void
  quit: (params: EmployeeQuitParams) => any
  quitMeta: Meta
  clearQuitMeta: () => void
  backToWorkMeta: Meta
  clearBackToWorkMeta: () => void
  projectListMeta: Meta
  allEmployeeMeta: Meta
  clearAllEmployeeMeta: () => void
  fetchActivePlusQuitList: () => void
  employeeActivePlusQuit: EmployeeItem[]
  activePlusQuitMeta: Meta
  quitUsers: QuitReasonItem[]
  fetchQuitUsers: (params: QuitReasonParam) => any
  quitUsersPaging: MetaPage
  quitUsersMeta: Meta
  fetchInfoLog: (params: UserInfoLogParams) => any
  infoLog: InfoLogEmployeeItem[]
  infoLogPaging: MetaPage
  infoLogMeta: Meta
  backToWorkFromLongTermMeta: Meta
  updateReason: (params: ReasonUpdateParams) => any
  resetEmployeePassword: (params: EmployeeResetPasswordParams) => any
}

const useEmployee = (): Employee => {
  const dispatch = useAppDispatch()

  // ACTIONS
  const fetch = (params: EmployeeListParams) =>
    dispatch(fetchEmployeeList(params))

  const fetchAll = () => dispatch(fetchEmployeeAll())
  const fetchActivePlusQuitList = () =>
    dispatch(fetchEmployeeActivePlusQuitList())

  const fetchPrivateList = (params: EmployeeListParams) =>
    dispatch(fetchPrivateEmployeeList(params))

  const addEmployee = (params: EmployeeAddParams) =>
    dispatch(createEmployee(params))
  const deleteItem = (params: EmployeeDeleteParams) =>
    dispatch(deleteEmployee(params))
  const updateItem = (params: EmployeeUpdateParams) =>
    dispatch(updateEmployee(params))
  const fetchDetail = (params: EmployeeDetailParams) =>
    dispatch(showEmployee(params))
  const quit = (params: EmployeeQuitParams) => dispatch(quitEmployee(params))
  const backToWork = (params: EmployeeBackToWorkParams) =>
    dispatch(backToWorkEmployee(params))
  const backToWorkFromLongTerm = (params: BackToWorkFromLongTermLeaveParams) =>
    dispatch(backToWorkFromLongTermEmployee(params))
  const fetchDepartmentList = () => dispatch(fetchEmployeeDepartmentList())
  const fetchPositionList = () => dispatch(fetchEmployeePositionList())
  const fetchRoleList = (keyword: string) =>
    dispatch(fetchRoleListAll({ keyword: keyword }))
  const fetchProjectList = (params: EmployeeProjectListParams) =>
    dispatch(fetchEmployeeProjectList(params))
  const fetchQuitUsers = (params: QuitReasonParam) =>
    dispatch(fetchQuitUsersReason(params))
  const fetchInfoLog = (params: UserInfoLogParams) =>
    dispatch(fetchUserInfoLog(params))
  const fetchPublicList = (params: EmployeeListParams) =>
    dispatch(fetchEmployeePublicList(params))
  const fetchPublicDetail = (params: EmployeeDetailParams) =>
    dispatch(showPublicEmployee(params))
  const updateReason = (params: ReasonUpdateParams) =>
    dispatch(updateQuitReason(params))
  const resetEmployeePassword = (params: EmployeeResetPasswordParams) =>
    dispatch(resetPassword(params))
  // STATES
  const employeeList = useSelector(getEmployeeList)
  const employeeAll = useSelector(getEmployeeAll)
  const privateEmployeeList = useSelector(getPrivateEmployeeList)
  const departmentList = useSelector(getEmployeeDepartmentList)
  const positionList = useSelector(getEmployeePositionList)
  const detail = useSelector(getEmployeeDetail)
  const roleList = useSelector(getRoleListAll)
  const projectList = useSelector(getEmployeeProjectList)
  const employeeActivePlusQuit = useSelector(getActivePlusQuitEmployee)
  const quitUsers = useSelector(getQuitUsers)
  const quitUsersPaging = useSelector(getQuitUsersMeta)
  const infoLog = useSelector(getUserInfoLog)
  const infoLogPaging = useSelector(getUserInfoLogMeta)
  const employeePublicList = useSelector(getPublicEmployeeList)
  const publicDetail = useSelector(getPublicEmployeeDetail)

  // LIST META
  const paging = useSelector(getEmployeeMeta)
  const _meta = createMetaSelector(fetchEmployeeList)
  const meta = useSelector(_meta)
  const clearMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.GET_EMPLOYEE_LIST))
  const _roleListMeta = createMetaSelector(fetchRoleListAll)
  const roleListMeta = useSelector(_roleListMeta)
  const _departmentListMeta = createMetaSelector(fetchEmployeeDepartmentList)
  const departmentListMeta = useSelector(_departmentListMeta)
  const _positionListMeta = createMetaSelector(fetchEmployeePositionList)
  const positionListMeta = useSelector(_positionListMeta)
  const _projectListMeta = createMetaSelector(fetchEmployeeProjectList)
  const projectListMeta = useSelector(_projectListMeta)
  const _activePlusQuitMeta = createMetaSelector(
    fetchEmployeeActivePlusQuitList
  )
  const activePlusQuitMeta = useSelector(_activePlusQuitMeta)
  const publicPaging = useSelector(getPublicEmployeesMeta)
  const _publicMeta = createMetaSelector(fetchEmployeePublicList)
  const publicListMeta = useSelector(_publicMeta)

  // PRIVATE LIST META
  const privateListPaging = useSelector(getPrivateEmployeesMeta)
  const _privateListMeta = createMetaSelector(fetchPrivateEmployeeList)
  const privateListMeta = useSelector(_privateListMeta)
  const clearPrivateListMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.GET_PRIVATE_EMPLOYEE_LIST))

  // ADD META
  const _addEmployeeMeta = createMetaSelector(createEmployee)
  const addEmployeeMeta = useSelector(_addEmployeeMeta)
  const clearAddEmployeeMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.CREATE_EMPLOYEE))

  // DELETE META
  const _deleteMeta = createMetaSelector(deleteEmployee)
  const deleteMeta = useSelector(_deleteMeta)
  const clearDeleteEmployeeMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.DELETE_EMPLOYEE))

  // UPDATE META
  const _updateMeta = createMetaSelector(updateEmployee)
  const updateMeta = useSelector(_updateMeta)
  const clearUpdateEmployeeMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.UPDATE_EMPLOYEE))

  // SHOW META
  const _showEmployeeMeta = createMetaSelector(showEmployee)
  const showEmployeeMeta = useSelector(_showEmployeeMeta)
  const clearShowEmployeeMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.SHOW_EMPLOYEE))

  // QUIT META
  const _quitMeta = createMetaSelector(quitEmployee)
  const quitMeta = useSelector(_quitMeta)
  const clearQuitMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.QUIT_EMPLOYEE))

  // BACK TO WORK META
  const _backToWorkMeta = createMetaSelector(backToWorkEmployee)
  const backToWorkMeta = useSelector(_backToWorkMeta)
  const clearBackToWorkMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.BACK_TO_WORK_EMPLOYEE))

  // BACK TO WORK FROM LONG TERM META
  const _backToWorkFromLongTermMeta = createMetaSelector(
    backToWorkFromLongTermEmployee
  )
  const backToWorkFromLongTermMeta = useSelector(_backToWorkFromLongTermMeta)

  // ALL EMPLOYEE META
  const _allEmployeeMeta = createMetaSelector(fetchEmployeeAll)
  const allEmployeeMeta = useSelector(_allEmployeeMeta)
  const clearAllEmployeeMeta = () =>
    dispatch(clearMetaData(USER_ACTION_TYPE.GET_EMPLOYEE_ALL))

  const clearDetailData = () => dispatch(clearEmployee())

  // QUIT USERS META
  const _quitUsersMeta = createMetaSelector(fetchQuitUsersReason)
  const quitUsersMeta = useSelector(_quitUsersMeta)

  // INFO LOG META
  const _infoLogMeta = createMetaSelector(fetchUserInfoLog)
  const infoLogMeta = useSelector(_infoLogMeta)

  // PUBLIC DETAIL META
  const _publicDetailMeta = createMetaSelector(showPublicEmployee)
  const publicDetailMeta = useSelector(_publicDetailMeta)

  return {
    fetch,
    fetchAll,
    fetchPrivateList,
    addEmployee,
    deleteItem,
    updateItem,
    fetchRoleList,
    fetchDetail,
    fetchDepartmentList,
    fetchPositionList,
    fetchProjectList,
    backToWorkFromLongTerm,
    employeeList,
    employeeAll,
    privateEmployeeList,
    departmentList,
    roleListMeta,
    positionList,
    projectList,
    detail,
    paging,
    meta,
    privateListMeta,
    privateListPaging,
    clearDetailData,
    departmentListMeta,
    positionListMeta,
    projectListMeta,
    clearMeta,
    clearPrivateListMeta,
    addEmployeeMeta,
    clearAddEmployeeMeta,
    deleteMeta,
    clearDeleteEmployeeMeta,
    updateMeta,
    roleList,
    clearUpdateEmployeeMeta,
    showEmployeeMeta,
    clearShowEmployeeMeta,
    quit,
    quitMeta,
    clearQuitMeta,
    backToWork,
    backToWorkMeta,
    clearBackToWorkMeta,
    allEmployeeMeta,
    clearAllEmployeeMeta,
    fetchActivePlusQuitList,
    activePlusQuitMeta,
    employeeActivePlusQuit,
    quitUsers,
    fetchQuitUsers,
    quitUsersMeta,
    quitUsersPaging,
    fetchInfoLog,
    infoLog,
    infoLogPaging,
    infoLogMeta,
    backToWorkFromLongTermMeta,
    fetchPublicDetail,
    fetchPublicList,
    publicPaging,
    publicDetail,
    publicDetailMeta,
    employeePublicList,
    publicListMeta,
    updateReason,
    resetEmployeePassword,
  }
}

export default useEmployee
