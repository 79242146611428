export enum DAILY_REPORT_ACTION_TYPE {
  MY_DAILY_REPORT_LIST = 'daily-report/myReport',
  TEAM_DAILY_REPORT_LIST = 'daily-report/teamReport',
  HR_DAILY_REPORT_LIST = 'daily-report/hrReport',
  DAILY_REPORT_CREATE = 'daily-report/create',
  DAILY_REPORT_UPDATE = 'daily-report/update',
  DAILY_REPORT_CLEAR = 'daily-report/clear',
  DAILY_REPORT_DELETE = 'daily-report/delete',
  TAGS = 'daily-report/tags',
}
