import StringAvatar from '@components/@material-extend/StringAvatar'
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { getFullNameEn } from '@utils/helper/common.helper'
import _ from 'lodash'
import { FC } from 'react'

interface Option {
  id: string
  attributes: {
    avatar: string
    email: string
    avatar_thumbnail: string
    lastname: string
    firstname: string
    firstname_en: string
    user_code: string
  }
}

interface Props {
  option: Option
}

const CustomListItem: FC<Props> = ({ option, ...props }) => {
  return (
    <ListItem component="li" {...props} sx={{ zIndex: 100 }}>
      <ListItemAvatar>
        <StringAvatar
          src={option?.attributes?.avatar_thumbnail}
          alt={option?.attributes?.firstname_en}
        />
      </ListItemAvatar>
      <ListItemText
        sx={{ display: 'block' }}
        secondary={_.get(option, 'attributes.user_code', '')}
        secondaryTypographyProps={{ sx: { fontSize: 11 } }}
      >
        {`${getFullNameEn(
          undefined,
          _.get(option, 'attributes.lastname_en', ''),
          _.get(option, 'attributes.firstname_en', '')
        )}`}
      </ListItemText>
    </ListItem>
  )
}

export default CustomListItem
