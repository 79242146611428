import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import _ from 'lodash'

const getRoot = (state: RootState) => state.user

export const getUserProfile = createSelector(
  getRoot,
  (state) => state.userProfile
)

export const getEmployeeList = createSelector(
  getRoot,
  (state) => state.employeeList
)

export const getEmployeeAll = createSelector(
  getRoot,
  (state) => state.employeeAll
)

export const getEmployeeMeta = createSelector(getRoot, (state) => state.meta)

export const getPrivateEmployeeList = createSelector(
  getRoot,
  (state) => state.privateEmployeeList
)

export const getPrivateEmployeesMeta = createSelector(
  getRoot,
  (state) => state.privateListMeta
)

export const getPublicEmployeeList = createSelector(
  getRoot,
  (state) => state.publicEmployeeList
)

export const getPublicEmployeesMeta = createSelector(
  getRoot,
  (state) => state.publicPaging
)
export const getPublicEmployeeDetail = createSelector(
  getRoot,
  (state) => state.publicDetail
)

export const getEmployeeDepartmentList = createSelector(
  getRoot,
  (state) => state.employeeDepartmentList
)

export const getEmployeePositionList = createSelector(
  getRoot,
  (state) => state.employeePositionList
)

export const getEmployeeProjectList = createSelector(
  getRoot,
  (state) => state.employeeProjectList
)

export const getActivePlusQuitEmployee = createSelector(getRoot, (state) =>
  _.orderBy(state.employeeActivePlusQuitList, 'attributes.user_code')
)

export const getEmployeeDetail = createSelector(
  getRoot,
  (state) => state.detail
)

export const getQuitUsers = createSelector(getRoot, (state) => state.quitUsers)
export const getQuitUsersMeta = createSelector(
  getRoot,
  (state) => state.quitUsersMeta
)

export const getUserInfoLog = createSelector(getRoot, (state) => state.infoLog)

export const getUserInfoLogMeta = createSelector(
  getRoot,
  (state) => state.infoLogPaging
)
