import { addToast } from '@store/common/actions'
import { Middleware } from 'redux'
import { actions, generateMessages } from './eventActions'

export const globalMiddleware: Middleware =
  (store: any) => (next) => (action) => {
    const globalAction = actions[action.type]

    if (globalAction) globalAction(action, store)

    const messages = generateMessages()

    const message = messages[action.type]
    if (message) {
      store.dispatch(addToast(message))
    }

    return next(action)
  }
