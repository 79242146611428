import { LocationOff, LocationOn, NotListedLocation } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LanguageSwitcherProps {
  isGeolocationEnabled: boolean
  isLocationRequired: boolean
  permissionState?: PermissionState
}

const LocationStatus: React.FC<LanguageSwitcherProps> = ({
  isGeolocationEnabled,
  isLocationRequired,
  permissionState,
}) => {
  const { t } = useTranslation()
  const renderIcon = () => {
    if (isGeolocationEnabled && permissionState === 'granted') {
      return (
        <Tooltip placement="right" title={t('TIME_RECORDS.permission_allowed')}>
          <Box
            sx={{
              borderRadius: 20,
              width: 20,
              height: 20,
              background: '#fff',
              boxShadow: 1,
              border: `1px solid transparent`,
              borderColor: (theme) => theme.palette.success.main,
            }}
          >
            <LocationOn
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.success.main,
                position: 'relative',
                top: 1,
              }}
            />
          </Box>
        </Tooltip>
      )
    } else if (!isGeolocationEnabled && permissionState === 'denied') {
      return (
        <Tooltip placement="right" title={t('TIME_RECORDS.permission_denied')}>
          <Box
            sx={{
              borderRadius: 30,
              width: 20,
              height: 20,
              background: '#fff',
              boxShadow: 1,
              border: `1px solid transparent`,
              borderColor: (theme) => theme.palette.error.main,
            }}
          >
            <LocationOff
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.error.main,
                position: 'relative',
                top: 1,
              }}
            />
          </Box>
        </Tooltip>
      )
    }
    return (
      <Tooltip placement="right" title={t('TIME_RECORDS.permission_undefined')}>
        <Box
          sx={{
            borderRadius: 30,
            width: 20,
            height: 20,
            background: '#fff',
            boxShadow: 1,
            border: `1px solid transparent`,
            borderColor: (theme) => theme.palette.warning.main,
          }}
        >
          <NotListedLocation
            sx={{
              fontSize: 16,
              color: (theme) => theme.palette.warning.main,
              position: 'relative',
              top: 1,
            }}
          />
        </Box>
      </Tooltip>
    )
  }

  return isLocationRequired ? renderIcon() : null
}
export default LocationStatus
