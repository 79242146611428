import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import _ from 'lodash'
import { eventMerger, meetingRoomOrdersAttParser } from './helper'

const getRoot = (state: RootState) => state.settings

// HELP SELECTOR
export const getHelp = createSelector(getRoot, (state) => state.help)

export const getEvents = createSelector(getRoot, (state) => state.eventList)

export const getMergedEvents = createSelector(getRoot, (state) => {
  return eventMerger(state?.eventList, state?.init)
})

export const getMergedEventsPublic = createSelector(getRoot, (state) => {
  return eventMerger(state?.eventListPublic)
})

//Meeting room

export const getMeetingRooms = createSelector(
  getRoot,
  (state) => state.meetingRoomList
)

export const getMeetingRoomDetail = createSelector(
  getRoot,
  (state) => state.meetingRoomDetail
)

//Meeting room order

export const getMeetingRoomOrders = createSelector(getRoot, (state) =>
  meetingRoomOrdersAttParser(state?.meetingRoomOrderList)
)

export const getMeetingRoomOrderDetail = createSelector(
  getRoot,
  (state) => state?.meetingRoomOrderDetail
)
//Time table

export const getTimeTable = createSelector(
  getRoot,
  (state) => state.timeTableList
)

export const getTimeTableDetail = createSelector(
  getRoot,
  (state) => state.timeTableDetail
)

export const getSelectableRoomList = createSelector(getRoot, (state) => {
  return _.map(state.meetingRoomList, (item) => {
    return {
      id: parseInt(item.id),
      name: item.attributes.name,
    }
  })
})

export const getSystemSettingsList = createSelector(
  getRoot,
  (state) => state.systemSettingsList
)
export const getSystemSettingsListPaging = createSelector(
  getRoot,
  (state) => state.systemSettingsListPaging
)

export const getCompanyRulesList = createSelector(
  getRoot,
  (state) => state.companyRules
)

export const getWorkspaceList = createSelector(
  getRoot,
  (state) => state.workspaceList
)

export const getSelectableWorkspaceList = createSelector(getRoot, (state) => {
  return _.map(state.workspaceAllList, (item) => {
    return {
      id: parseInt(item.id),
      name: item.attributes.name,
    }
  })
})

export const getWorkspaceListPaging = createSelector(
  getRoot,
  (state) => state.workspaceListPaging
)

export const getWorkspaceDetail = createSelector(
  getRoot,
  (state) => state.workspaceDetail
)
