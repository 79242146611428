import {
  EventCreateParams,
  EventParams,
  EventUpdateParams,
} from '@services/settings.services'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import {
  eventCreate,
  eventDelete,
  eventUpdate,
  fetchEventListAll,
  fetchEventListPublic,
} from '@store/settings/actions'
import {
  getMergedEvents,
  getMergedEventsPublic,
} from '@store/settings/selectors'
import { MergedEventType } from '@store/settings/selectors/helper'
import { useSelector } from 'react-redux'

interface Event {
  fetch: (params: EventParams) => any
  fetchPublicEvents: () => void
  data: MergedEventType[]
  dataPublic: MergedEventType[]
  create: (params: EventCreateParams) => any
  update: (params: EventUpdateParams) => any
  deleteEvent: (id: string) => any
  meta: Meta
  updateMeta: Meta
  createMeta: Meta
}

const useEvents = (): Event => {
  const dispatch = useAppDispatch()

  // ACTIONS
  const fetch = (params: EventParams) => dispatch(fetchEventListAll(params))

  const fetchPublicEvents = () => dispatch(fetchEventListPublic())

  const data = useSelector(getMergedEvents)
  const dataPublic = useSelector(getMergedEventsPublic)

  const create = (params: EventCreateParams) => dispatch(eventCreate(params))

  const update = (params: EventUpdateParams) => dispatch(eventUpdate(params))

  const deleteEvent = (id: string) => dispatch(eventDelete(id))

  const _meta = createMetaSelector(fetchEventListAll)
  const meta = useSelector(_meta)

  const _createMeta = createMetaSelector(eventCreate)
  const createMeta = useSelector(_createMeta)

  const _updateMeta = createMetaSelector(eventUpdate)
  const updateMeta = useSelector(_updateMeta)

  return {
    fetch,
    fetchPublicEvents,
    meta,
    createMeta,
    updateMeta,
    deleteEvent,
    create,
    dataPublic,
    update,
    data,
  }
}

export default useEvents
