import { RolePermissionItem } from '@services/resource.services'
import { getUserRole } from '@store/common/selectors'
import { useSelector } from 'react-redux'

interface useCurrentRoleReturnType {
  permissions: RolePermissionItem[] | undefined
}

const useCurrentRole = (): useCurrentRoleReturnType => {
  const permissions = useSelector(getUserRole)
  return { permissions }
}

export default useCurrentRole
