import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/report.services'
import { REPORT_ACTION_TYPE } from './type'

export const fetchCostReport = createAsyncThunk<
  services.CostReportResponse,
  services.CostReportParams
>(REPORT_ACTION_TYPE.COST_REPORT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.costReportService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchActualCostReport = createAsyncThunk<
  services.ActualCostReportResponse,
  services.ActualCostReportParams
>(
  REPORT_ACTION_TYPE.ACTUAL_COST_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.actualCostReportService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchCostProjectList = createAsyncThunk<
  services.CostProjectListResponse,
  services.CostProjectListParams
>(REPORT_ACTION_TYPE.COST_PROJECT_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.costProjectListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchActualCostReportDetail = createAsyncThunk<
  services.ActualCostReportDetailResponse,
  services.ActualCostReportDetailParams
>(
  REPORT_ACTION_TYPE.ACTUAL_COST_REPORT_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.actualCostDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchCostReportActivity = createAsyncThunk<
  services.CostReportActivityResponse,
  services.CostReportParams
>(REPORT_ACTION_TYPE.ACTIVITY_REPORT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.costReportActivityService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
