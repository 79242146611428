import CustomDatePicker from '@components/@material-extend/CustomDatePicker'
import { Box, Button, Grid, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'

interface VacationReportFilterProps {
  onFilter?: (data: any) => void
}

const VacationReportFilter: React.FC<VacationReportFilterProps> = (props) => {
  const { t } = useTranslation()
  const { onFilter } = props

  const validationSchema = Yup.object().shape(
    {
      start_date: Yup.date()
        .required(t('ERROR.E000002'))
        .typeError(t('ERROR.E000006')),
      end_date: Yup.date()
        .when('start_date', (start, schema) => {
          if (
            start !== null &&
            start[0] !== null &&
            dayjs(start[0]).isValid()
          ) {
            return schema.min(start, t('ERROR.E000067'))
          }
          return schema
        })
        .required(t('ERROR.E000002'))
        .typeError(t('ERROR.E000006')),
    },
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      start_date: dayjs(new Date()).subtract(1, 'year'),
      end_date: dayjs(new Date()),
    },
    mode: 'onChange',
  })
  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = methods
  const startDate = useWatch({ control: control, name: 'start_date' })
  const endDate = useWatch({ control: control, name: 'end_date' })

  useEffect(() => {
    trigger('end_date')
  }, [startDate])
  const filterReport = () => {
    onFilter &&
      onFilter({
        start_date: startDate,
        end_date: endDate,
      })
  }

  const isButtonDisabled = !endDate || dayjs(endDate).isBefore(dayjs(startDate))
  return (
    <Box>
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12} md={true}>
          <Stack sx={{ width: '100%' }} direction="row" spacing={1}>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: '50%' }}>
                  <CustomDatePicker
                    labelPrimary={t('QUIT_USERS.start_date')}
                    error={errors.start_date ? true : false}
                    helperText={
                      errors.start_date ? errors.start_date.message : ''
                    }
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name="end_date"
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: '50%' }}>
                  <CustomDatePicker
                    labelPrimary={t('QUIT_USERS.end_date')}
                    error={errors.end_date ? true : false}
                    helperText={errors.end_date ? errors.end_date.message : ''}
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md="auto">
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handleSubmit(filterReport)}
              disabled={isButtonDisabled}
              data-test-id="filter-button"
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: { xs: 0, md: 3.3 } }} // Adjust top margin for medium and up screens
            >
              {t('TIMEOFF_TOTAL_REPORT.submit')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default VacationReportFilter
