import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/email.services'
import { EMAIL_ACTION_TYPE } from './types'

//Email campaign
export const fetchEmailCampaignList = createAsyncThunk<
  services.EmailCampaignListResponse,
  services.EmailCampaignFilter
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchEmailFailureList = createAsyncThunk<
  services.EmailFailureResponse,
  services.EmailFailureParams
>(EMAIL_ACTION_TYPE.EMAIL_FAILURE_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.emailCampaignFailureService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const emailCampaignDetail = createAsyncThunk<
  services.EmailCampaignResponse,
  services.EmailCampaignIdParams
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response)
    }
  }
)

export const emailCampaignCreate = createAsyncThunk<
  services.EmailCampaignResponse,
  services.EmailCampaignCreateParams
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailCampaignSalarySend = createAsyncThunk<
  void,
  services.EmailCampaignSalarySendParams
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_SALARY_SEND,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignSalarySendService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailCampaignUpdate = createAsyncThunk<
  services.EmailCampaignResponse,
  services.EmailCampaignUpdateParams
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailCampaignDelete = createAsyncThunk<
  services.EmailCampaignResponse,
  services.EmailCampaignIdParams
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

//Email template
export const fetchEmailTemplateList = createAsyncThunk<
  services.EmailTemplateListResponse,
  services.EmailTemplateFilter
>(
  EMAIL_ACTION_TYPE.EMAIL_TEMPLATE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailTemplateListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailTemplateDetail = createAsyncThunk<
  services.EmailTemplateResponse,
  services.EmailTemplateIdParams
>(
  EMAIL_ACTION_TYPE.EMAIL_TEMPLATE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailTemplateDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response)
    }
  }
)

export const emailTemplateCreate = createAsyncThunk<
  services.EmailTemplateResponse,
  services.EmailTemplateCreateParams
>(
  EMAIL_ACTION_TYPE.EMAIL_TEMPLATE_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailTemplateCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailTemplateUpdate = createAsyncThunk<
  services.EmailTemplateResponse,
  services.EmailTemplateUpdateParams
>(
  EMAIL_ACTION_TYPE.EMAIL_TEMPLATE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailTemplateUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailTemplateDelete = createAsyncThunk<
  services.EmailTemplateResponse,
  services.EmailTemplateIdParams
>(
  EMAIL_ACTION_TYPE.EMAIL_TEMPLATE_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailTemplateDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const emailCampaignClear = createAction(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_CLEAR
)
export const emailTemplateClear = createAction(
  EMAIL_ACTION_TYPE.EMAIL_TEMPLATE_CLEAR
)

export const emailCampaignBulkSend = createAsyncThunk<
  any,
  services.BulkEmailSendParams
>(
  EMAIL_ACTION_TYPE.EMAIL_CAMPAIGN_BULK_SEND,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.emailCampaignBulkSendService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
