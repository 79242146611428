import { Direction, MetaPage } from '@constants/common.constants'
import {
  EMAIL_CAMPAIGN_STATUS,
  EMAIL_TEMPLATE_TYPE,
} from '@constants/email.constants'
import { URI } from '@constants/uri.constants'
import { api } from './api'
import { EmployeeItem } from './user.services'

// Email Campaign

export type EmailCampaignFilter = {
  keyword?: string
  order_by?: string
  direction?: Direction
  per_page: string
  page: string
  template_type?: EMAIL_TEMPLATE_TYPE
  status?: EMAIL_CAMPAIGN_STATUS
}

export type EmailCampaignCreateParams = {
  name: string
  recipient?: string[] | null
  content?: string
  template_type: EMAIL_TEMPLATE_TYPE
  status?: EMAIL_CAMPAIGN_STATUS
  subject?: string
  email_template_id: string
  is_send_all?: boolean
}

export type EmailCampaignSalarySend = {
  user_code: string
  html_content: string
}

export type EmailCampaignSalarySendParams = {
  id: string
  params: EmailCampaignSalarySend
}

export type UpdateParamsCampaign = {
  name?: string
  subject?: string
  recipient?: string[] | null
  content?: string
  status?: EMAIL_CAMPAIGN_STATUS
}

type KeysOfTypeCampaign<T> = keyof T

// Use the KeysOfType type to get the keys of UpdateParams
export type UpdateParamsKeysCampaign = KeysOfTypeCampaign<UpdateParamsCampaign>

export type EmailCampaignUpdateParams = {
  id: string
  params: UpdateParamsCampaign
}

export type EmailCampaignIdParams = string

export type EmailCampaignItem = {
  id: string
  type: string
  attributes: {
    name: string
    email_template_id: string
    content: string
    recipient: string[]
    subject: string
    status: EMAIL_CAMPAIGN_STATUS
    template_type: EMAIL_TEMPLATE_TYPE
    created_at: string
    updated_at: string
    email_template_name: string
    created_by: {
      data: EmployeeItem
    }
  }
}

export type EmailCampaignListResponse = {
  data: EmailCampaignItem[]
  meta: MetaPage
}

export interface FailureItem {
  id: string
  type: 'email_failure'
  attributes: {
    email_campaign_id: string
    user_id: EmployeeItem
  }
}

export type EmailFailureResponse = { data: FailureItem[] }

export type EmailCampaignResponse = { data: EmailCampaignItem }

export type EmailFailureParams = {
  email_campaign_id: string
}

export const emailCampaignListService = async (
  params: EmailCampaignFilter
): Promise<EmailCampaignListResponse> => {
  const { data } = await api.get<EmailCampaignListResponse>(
    URI.EMAIL_CAMPAIGN,
    {
      params: params,
    }
  )
  return data
}

export const emailCampaignDetailService = async (
  params: EmailCampaignIdParams
): Promise<EmailCampaignResponse> => {
  const { data } = await api.get<EmailCampaignResponse>(
    `${URI.EMAIL_CAMPAIGN}/${params}`
  )
  return data
}

export const emailCampaignCreateService = async (
  params: EmailCampaignCreateParams
): Promise<EmailCampaignResponse> => {
  const { data } = await api.post<EmailCampaignResponse>(
    URI.EMAIL_CAMPAIGN,
    params
  )
  return data
}

export const emailCampaignSalarySendService = async (
  params: EmailCampaignSalarySendParams
): Promise<void> => {
  const { data } = await api.post<void>(
    `${URI.EMAIL_CAMPAIGN}/${params.id}/salary_email`,
    params.params
  )
  return data
}

export const emailCampaignUpdateService = async (
  params: EmailCampaignUpdateParams
): Promise<EmailCampaignResponse> => {
  const { data } = await api.put<EmailCampaignResponse>(
    `${URI.EMAIL_CAMPAIGN}/${params.id}`,
    params.params
  )
  return data
}

export const emailCampaignDeleteService = async (
  params: EmailCampaignIdParams
): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.EMAIL_CAMPAIGN}/${params}`)
  return data
}

export const emailCampaignFailureService = async (
  params: EmailFailureParams
): Promise<EmailFailureResponse> => {
  const { data } = await api.get<EmailFailureResponse>(`${URI.EMAIL_FAILURE}`, {
    params: params,
  })
  return data
}

//Email template

export type EmailTemplateFilter = {
  keyword?: string
  order_by?: string
  direction?: Direction
  per_page: string
  page: string
  template_type?: EMAIL_TEMPLATE_TYPE
}

export type EmailTemplateCreateParams = {
  name: string
  template_type: EMAIL_TEMPLATE_TYPE
  subject: string
  recipient?: string[] | null
  content?: string
  thumbnail?: string
  is_send_all?: boolean
}

export type UpdateParams = {
  name?: string
  template_type?: EMAIL_TEMPLATE_TYPE
  subject?: string
  recipient?: string[] | null
  content?: string
}

type KeysOfType<T> = keyof T

// Use the KeysOfType type to get the keys of UpdateParams
export type UpdateParamsKeys = KeysOfType<UpdateParams>

export type EmailTemplateUpdateParams = {
  id: string
  params: UpdateParams
}

export type EmailTemplateIdParams = string

export type EmailTemplateItem = {
  id: string
  type: string
  attributes: {
    name: string
    template_type: EMAIL_TEMPLATE_TYPE
    recipient: string[]
    subject: string
    content: string
    is_send_all: boolean
    thumbnail: string
    created_at: string
    updated_at: string
    created_by: {
      data: EmployeeItem
    }
  }
}

export type EmailTemplateListResponse = {
  data: EmailTemplateItem[]
  meta: MetaPage
}

export type EmailTemplateResponse = { data: EmailTemplateItem }

export type BulkEmailParam = {
  html_content: string
}

export type BulkEmailSendParams = {
  id: EmailTemplateIdParams
  params: BulkEmailParam
}

export const emailTemplateListService = async (
  params: EmailTemplateFilter
): Promise<EmailTemplateListResponse> => {
  const { data } = await api.get<EmailTemplateListResponse>(
    URI.EMAIL_TEMPLATE,
    {
      params: params,
    }
  )
  return data
}

export const emailTemplateDetailService = async (
  params: EmailTemplateIdParams
): Promise<EmailTemplateResponse> => {
  const { data } = await api.get<EmailTemplateResponse>(
    `${URI.EMAIL_TEMPLATE}/${params}`
  )
  return data
}

export const emailTemplateCreateService = async (
  params: EmailTemplateCreateParams
): Promise<EmailTemplateResponse> => {
  const { data } = await api.post<EmailTemplateResponse>(
    URI.EMAIL_TEMPLATE,
    params
  )
  return data
}

export const emailTemplateUpdateService = async (
  params: EmailTemplateUpdateParams
): Promise<EmailTemplateResponse> => {
  const { data } = await api.put<EmailTemplateResponse>(
    `${URI.EMAIL_TEMPLATE}/${params.id}`,
    params.params
  )
  return data
}

export const emailTemplateDeleteService = async (
  params: EmailTemplateIdParams
): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.EMAIL_TEMPLATE}/${params}`)
  return data
}

export const emailCampaignBulkSendService = async (
  params: BulkEmailSendParams
): Promise<{ success: string }> => {
  const { data } = await api.post<any>(
    `${URI.EMAIL_CAMPAIGN}/${params.id}/bulk_email`,
    params.params
  )
  return data
}
