import { FilterParams, MetaPage } from '@constants/common.constants'
import {
  TimeOffTemplateCreateParams,
  TimeOffTemplateIdParams,
  TimeOffTemplateItem,
  TimeOffTemplateUpdateParams,
} from '@services/timeoff.services'

import { useAppDispatch } from '@store/hooks'
import { clearMetaData } from '@store/metadata/actions'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'

import {
  createTimeOffTemplate,
  deleteTimeOffTemplate,
  fetchTimeOffTemplateAll,
  fetchTimeOffTemplateList,
  getTimeOffTemplateDetail,
  updateTimeOffTemplate,
} from '@store/timeoff/actions'
import { TIME_OFF_ACTION_TYPE } from '@store/timeoff/actions/types'
import {
  getTimeOffTemplate,
  getTimeOffTemplateAll,
  getTimeOffTemplateList,
  getTimeOffTemplateListMeta,
} from '@store/timeoff/selectors'

import { useSelector } from 'react-redux'

interface TimeOffTemplate {
  paging: MetaPage
  fetch: (params: FilterParams) => any
  fetchAll: () => any
  create: (params: TimeOffTemplateCreateParams) => any
  show: (params: TimeOffTemplateIdParams) => any
  update: (params: TimeOffTemplateUpdateParams) => any
  deleteItem: (params: TimeOffTemplateIdParams) => any
  timeOffTemplateList: TimeOffTemplateItem[]
  allTimeOffTemplates: TimeOffTemplateItem[]
  detail: TimeOffTemplateItem
  meta: Meta
  allMeta: Meta
  createMeta: Meta
  detailMeta: Meta
  updateMeta: Meta
  deleteMeta: Meta
  clearMeta: () => void
  clearAllMeta: () => void
  clearCreateMeta: () => void
  clearDetailMeta: () => void
  clearUpdateMeta: () => void
  clearDeleteMeta: () => void
}

const useTimeOffTemplate = (): TimeOffTemplate => {
  const dispatch = useAppDispatch()
  const fetch = (params: FilterParams) =>
    dispatch(fetchTimeOffTemplateList(params))
  const fetchAll = () => dispatch(fetchTimeOffTemplateAll())
  const create = (params: TimeOffTemplateCreateParams) =>
    dispatch(createTimeOffTemplate(params))
  const update = (params: TimeOffTemplateUpdateParams) =>
    dispatch(updateTimeOffTemplate(params))
  const show = (params: TimeOffTemplateIdParams) =>
    dispatch(getTimeOffTemplateDetail(params))
  const deleteItem = (params: TimeOffTemplateIdParams) =>
    dispatch(deleteTimeOffTemplate(params))

  const timeOffTemplateList = useSelector(getTimeOffTemplateList)
  const allTimeOffTemplates = useSelector(getTimeOffTemplateAll)
  const detail = useSelector(getTimeOffTemplate)
  const paging = useSelector(getTimeOffTemplateListMeta)

  const _meta = createMetaSelector(fetchTimeOffTemplateList)
  const meta = useSelector(_meta)
  const clearMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_LIST))

  const _allMeta = createMetaSelector(fetchTimeOffTemplateAll)
  const allMeta = useSelector(_allMeta)
  const clearAllMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_ALL))

  const _createMeta = createMetaSelector(createTimeOffTemplate)
  const createMeta = useSelector(_createMeta)
  const clearCreateMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_CREATE))

  const _updateMeta = createMetaSelector(updateTimeOffTemplate)
  const updateMeta = useSelector(_updateMeta)
  const clearUpdateMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_UPDATE))

  const _detailMeta = createMetaSelector(getTimeOffTemplateDetail)
  const detailMeta = useSelector(_detailMeta)
  const clearDetailMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_DETAIL))

  const _deleteMeta = createMetaSelector(deleteTimeOffTemplate)
  const deleteMeta = useSelector(_deleteMeta)
  const clearDeleteMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_TEMPLATE_DELETE))

  return {
    timeOffTemplateList,
    allTimeOffTemplates,
    paging,
    show,
    detail,
    fetch,
    fetchAll,
    deleteItem,
    update,
    create,
    allMeta,
    meta,
    createMeta,
    detailMeta,
    updateMeta,
    deleteMeta,
    clearMeta,
    clearAllMeta,
    clearCreateMeta,
    clearDetailMeta,
    clearUpdateMeta,
    clearDeleteMeta,
  }
}

export default useTimeOffTemplate
