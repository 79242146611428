export enum TIME_ACTION_TYPE {
  TIME_START = 'time/start',
  TIME_END = 'time/end',
  TIME_QR_START = 'time/qr_start',
  TIME_QR_END = 'time/qr_end',
  BREAK_START = 'time/breakStart',
  BREAK_END = 'time/breakEnd',
  RECORD_LIST = 'time/list',
  TIMESHEET = 'time/timesheet',
  TIME_REPORT = 'time/report',
  TIME_CORRECTION_LIST = 'timeCorrection/list',
  TIME_CORRECTION_APPROVE_LIST = 'timeCorrection/approveList',
  TIME_CORRECTION_CREATE = 'timeCorrection/create',
  TIME_CORRECTION_UPDATE = 'timeCorrection/update',
  TIME_CORRECTION_DELETE = 'timeCorrection/delete',
  TIME_CORRECTION_APPROVE = 'timeCorrection/approve',
  TIME_CORRECTION_DENY = 'timeCorrection/deny',
  TIME_CORRECTION_TIME_CARD = 'timeCorrection/timeCard',
  TIME_CORRECTION_TEMPLATE_SHOW = 'timeCorrectionTemplate/show',
  TIME_CORRECTION_TEMPLATE_UPDATE = 'timeCorrectionTemplate/update',
  REMOTE_WORK_TEMPLATE_DETAIL = 'remoteWorkTemplate/show',
  REMOTE_WORK_TEMPLATE_UPDATE = 'remoteWorkTemplate/update',
  REMOTE_WORK_SETTINGS_LIST = 'remoteWorkSettings/list',
  REMOTE_WORK_SETTINGS_MY_LIST = 'remoteWorkSettings/myList',
  REMOTE_WORK_SETTINGS_MY_LIST_ALL = 'remoteWorkSettings/myListAll',
  REMOTE_WORK_SETTINGS_CREATE = 'remoteWorkSettings/create',
  REMOTE_WORK_SETTINGS_FORCE_CREATE = 'remoteWorkSettings/forceCreate',
  REMOTE_WORK_SETTINGS_FORCE_UPDATE = 'remoteWorkSettings/forceUpdate',
  REMOTE_WORK_SETTINGS_UPDATE = 'remoteWorkSettings/update',
  REMOTE_WORK_SETTINGS_DETAIL = 'remoteWorkSettings/detail',
  REMOTE_WORK_SETTINGS_DELETE = 'remoteWorkSettings/delete',
  REMOTE_WORK_REQUEST_CREATE = 'remoteWorkRequest/create',
  REMOTE_WORK_REQUEST_UPDATE = 'remoteWorkRequest/update',
  REMOTE_WORK_REQUEST_DETAIL = 'remoteWorkRequest/create',
  REMOTE_WORK_REQUEST_LIST = 'remoteWorkRequest/list',
  REMOTE_WORK_REQUEST_APPROVER_LIST = 'remoteWorkRequest/approverList',
  REMOTE_WORK_REQUEST_DELETE = 'remoteWorkRequest/delete',
  REMOTE_WORK_REQUEST_APPROVE = 'remoteWorkRequest/approve',
  REMOTE_WORK_REQUEST_DENY = 'remoteWorkRequest/deny',
  REMOTE_WORK_REQUEST_APPROVER_DELETE = 'remoteWorkRequest/approveDelete',
  REMOTE_OFFICE_REPORT = 'time/remoteReport',
}
