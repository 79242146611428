import { CostReportItem } from '@services/report.services'
import _ from 'lodash'

export type GroupedCostReportItem = {
  title: string
  id: number | null
  data: CostReportItem[]
}

export const groupProjects = (
  projects: CostReportItem[]
): GroupedCostReportItem[] => {
  const filteredProjects = projects.filter(
    (project) => project.members.length > 0
  )

  const groupedProjects = _.groupBy(filteredProjects, (project) =>
    project.partner_division ? project.partner_division.id : 'null'
  )

  const result = Object.keys(groupedProjects).map((key) => {
    const division = groupedProjects[key][0].partner_division
    const section = {
      title:
        key === 'null'
          ? 'Uncategorized'
          : division
          ? division.name
          : 'Unknown division',
      id: key === 'null' ? 999999 : parseInt(key),
      display_order: division?.display_order,
      data: groupedProjects[key],
    }
    return section
  })
  const orderedResult = _.orderBy(result, ['display_order'], ['asc'])

  return orderedResult
}
