import { createAction } from '@reduxjs/toolkit'
import { CLEAR_ALL_FILTERS, SET_LIST_FILTER } from './types'

export interface SetListFilterPayload {
  listName: string
  filter: any // Replace with the actual type of your filter
}

// Create the action using createAction
export const setListFilter = createAction<SetListFilterPayload>(SET_LIST_FILTER)
export const clearListFilter = createAction<void>(CLEAR_ALL_FILTERS)
export const changePublicLanguage = createAction<string>('language/change')
