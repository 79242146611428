import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'

const getRoot = (state: RootState) => state.email

//Email campaign

export const getEmailCampaignList = createSelector(
  getRoot,
  (state) => state.emailCampaignList
)

export const getEmailCampaignMeta = createSelector(
  getRoot,
  (state) => state.emailCampaignMeta
)

export const getEmailCampaignDetail = createSelector(
  getRoot,
  (state) => state.emailCampaignDetail
)

//Email template

export const getEmailTemplateList = createSelector(
  getRoot,
  (state) => state.emailTemplateList
)

export const getEmailTemplateMeta = createSelector(
  getRoot,
  (state) => state.emailTemplateMeta
)

export const getEmailTemplateDetail = createSelector(
  getRoot,
  (state) => state.emailTemplateDetail
)

export const getEmailCampaignFailures = createSelector(
  getRoot,
  (state) => state.failures
)
