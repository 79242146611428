import {
  Checkbox,
  CheckboxProps,
  FormGroup,
  FormControlLabel,
} from '@mui/material/'

export type InputProps = {
  labelPrimary?: string
}

export const CustomLabel = ({ text }: { text: string }) => {
  return <label style={{ fontSize: 14, paddingBottom: 3 }}>{text}</label>
}

const StyledCheckbox: React.FC<CheckboxProps & InputProps> = ({
  labelPrimary,
  ...rest
}) => {
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox {...rest} />} label={labelPrimary} />
    </FormGroup>
  )
}

export default StyledCheckbox
