/* eslint-disable no-console */
import {
  TIMEOFF_MINIMUM_MINUTE,
  TIME_OFF_IDENTIFIER,
  TIME_OFF_TEMPLATE_RANGE_TYPE,
} from '@constants/timeoff.constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { MergedEventType } from '@store/settings/selectors/helper'
import { shouldDisableDay } from '@utils/helper/timeoff.helper'
import dayjs from 'dayjs'
import { t } from 'i18next'
import _ from 'lodash'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

export const initialValuesTimeOff = {
  timeoff_template_id: null,
  approver_id: null,
  is_break_morning: false,
  start_at: dayjs(new Date()),
  end_at: dayjs(new Date()),
  attachment_url: '',
  reason: '',
  is_manual: false,
}

const useTimeOffRequestCreateForm = (
  publicEvents?: MergedEventType[] | undefined
) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        timeoff_template_id: Yup.object().required(t('ERROR.E000002')),
        start_at: Yup.date()
          .required(t('ERROR.E000002'))
          .when('end_at', (endAt: any, schema: any) => {
            if (
              endAt !== null &&
              endAt[0] !== null &&
              dayjs(endAt[0]).isValid()
            ) {
              return schema.max(endAt, t('ERROR.E000007'))
            }
            return schema
          })
          .test(
            'Time range must be on the same day',
            t('ERROR.E000010'),
            (value, context) => {
              const rangeType = _.get(
                context.parent.timeoff_template_id,
                'attributes.range_type',
                ''
              )

              const end = context.parent.end_at

              if (
                rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME &&
                dayjs(value).isValid() &&
                dayjs(end).isValid()
              ) {
                const startOfDay = dayjs(value).startOf('day')
                const endOfDay = dayjs(end).startOf('day')
                return (
                  (dayjs(endOfDay).diff(dayjs(startOfDay), 'day') as number) ===
                  0
                )
              }

              return true
            }
          )
          .test('time-validation', t('ERROR.E000008'), (value, context) => {
            const rangeType = _.get(
              context.parent.timeoff_template_id,
              'attributes.range_type',
              ''
            )

            const end = context.parent.end_at
            const isBreakMorning = context.parent.is_break_morning

            if (
              rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME &&
              !isBreakMorning &&
              dayjs(end).isValid() &&
              dayjs(value).isValid()
            ) {
              return (
                dayjs(end).diff(dayjs(value), 'minutes') >=
                TIMEOFF_MINIMUM_MINUTE
              )
            }

            return true
          })
          .test(
            'should-disable-day-validation',
            t('ERROR.E000006'),
            (value, context) => {
              if (dayjs(value).isValid()) {
                return !shouldDisableDay(
                  dayjs(value),
                  _.get(
                    context.parent.timeoff_template_id,
                    'attributes.identifier',
                    ''
                  ),
                  publicEvents
                )
              }

              return true
            }
          )
          .test(
            'allow-send-request-later',
            t('ERROR.E000004', { field: t('TIMEOFF_REQUEST.start_at') }),
            (value, context) => {
              const isSendLater = _.get(
                context.parent.timeoff_template_id,
                'attributes.is_request_send_later',
                ''
              )
              const identifier = _.get(
                context.parent.timeoff_template_id,
                'attributes.identifier',
                ''
              )
              if (
                isSendLater === false &&
                identifier === TIME_OFF_IDENTIFIER.MY_TIME
              ) {
                return dayjs().isBefore(dayjs(value))
              }
              return true
            }
          )
          .typeError(t('ERROR.E000006')),
        end_at: Yup.date()
          .when('timeoff_template_id.attributes.is_long_term_leave', {
            is: false,
            then: (schema) => schema.required(t('ERROR.E000002')),
            otherwise: (schema) => schema.nullable(),
          })
          .test(
            'Time range must be on the same day',
            t('ERROR.E000010'),
            (value, context) => {
              const rangeType = _.get(
                context.parent.timeoff_template_id,
                'attributes.range_type',
                ''
              )

              const start = context.parent.start_at

              if (
                rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME &&
                dayjs(start).isValid() &&
                dayjs(value).isValid()
              ) {
                const startOfDay = dayjs(start).startOf('day')
                const endOfDay = dayjs(value).startOf('day')
                return (
                  (dayjs(endOfDay).diff(dayjs(startOfDay), 'day') as number) ===
                  0
                )
              }

              return true
            }
          )
          .test(
            'time-range-validation',
            t('ERROR.E000008'),
            (value, context) => {
              const rangeType = _.get(
                context.parent.timeoff_template_id,
                'attributes.range_type',
                ''
              )

              const start = context.parent.start_at
              const isBreakMorning = context.parent.is_break_morning

              if (
                rangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME &&
                !isBreakMorning &&
                dayjs(start).isValid() &&
                dayjs(value).isValid()
              ) {
                return (
                  dayjs(value).diff(dayjs(start), 'minutes') >=
                  TIMEOFF_MINIMUM_MINUTE
                )
              }

              return true
            }
          )
          .test(
            'should-disable-day-validation',
            t('ERROR.E000006'),
            (value, context) => {
              if (dayjs(value).isValid()) {
                return !shouldDisableDay(
                  dayjs(value),
                  _.get(
                    context.parent.timeoff_template_id,
                    'attributes.identifier',
                    ''
                  ),
                  publicEvents
                )
              }

              return true
            }
          )
          .test(
            'allow-send-request-later',
            t('ERROR.E000004', { field: t('TIMEOFF_REQUEST.end_at') }),
            (value, context) => {
              const isSendLater = _.get(
                context.parent.timeoff_template_id,
                'attributes.is_request_send_later',
                ''
              )
              const identifier = _.get(
                context.parent.timeoff_template_id,
                'attributes.identifier',
                ''
              )
              if (
                isSendLater === false &&
                identifier === TIME_OFF_IDENTIFIER.MY_TIME
              ) {
                return dayjs().isBefore(dayjs(value))
              }
              return true
            }
          )
          .typeError(t('ERROR.E000006')),
        is_break_morning: Yup.boolean().nullable(),
        approver_id: Yup.object().when('is_manual', {
          is: true,
          then: (schema) => schema.required(t('ERROR.E000002')),
          otherwise: (schema) => {
            return schema.nullable()
          },
        }),
        attachment_url: Yup.string().when(
          'timeoff_template_id.attributes.is_attachment',
          {
            is: true,
            then: (schema) => schema.required(t('ERROR.E000002')),
            otherwise: (schema) => {
              return schema.nullable()
            },
          }
        ),
        reason: Yup.string().when(
          [
            'timeoff_template_id.attributes.is_reason_allow',
            'timeoff_template_id.attributes.is_deduct_from_vacation',
          ],
          ([isReasonAllowed, isDeductFromVacation], schema) => {
            if (isReasonAllowed) {
              if (isDeductFromVacation) {
                return schema.nullable()
              } else {
                return schema.required(t('ERROR.E000002'))
              }
            } else {
              return schema.nullable()
            }
          }
        ),
      }),
    [publicEvents]
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValuesTimeOff,
    mode: 'onChange',
    shouldUnregister: false,
  })

  return { Controller, methods }
}

export default useTimeOffRequestCreateForm
