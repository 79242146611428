import { createSelector } from '@reduxjs/toolkit'
import { MyVacationBalanceItem } from '@services/vacation.services'
import { RootState } from '@store/store'

const getRoot = (state: RootState) => state.vacation

export const getVacationBalanceList = createSelector(
  getRoot,
  (state) => state.vacationBalanceList
)

export const getMyVacationBalances = createSelector(
  getRoot,
  (state) => state.myVacationBalance
)
export const getUserVacationHistory = createSelector(
  getRoot,
  (state) => state.userVacationHistory
)

export const getMyTotalBalance = createSelector(getRoot, (state) =>
  state.myVacationBalance
    ? state.myVacationBalance.reduce(
        (sum: number, item: MyVacationBalanceItem) => {
          return sum + item.attributes.remaining_days
        },
        0
      )
    : undefined
)
export const getVacationReportList = createSelector(
  getRoot,
  (state) => state.vacationReportList
)
export const getVacationReportDetail = createSelector(
  getRoot,
  (state) => state.vacationReportDetail
)
