import { CoordinateType, SystemSettingsItem } from '@services/settings.services'
import {
  getDailyBreakLimit,
  getLunchtime,
  getMapInitPoint,
  getSalaryMonth,
  getSalaryMonthStartDay,
  getStressLevelWeights,
} from '@store/common/selectors'
import _ from 'lodash'

import { Dayjs } from 'dayjs'
import { useSelector } from 'react-redux'
import moment from 'moment'

interface SystemValues {
  salaryMonth: Dayjs
  salaryMonthStartDay: SystemSettingsItem
  dailyBreakLimit: SystemSettingsItem
  calculateStressLevel: (
    complexity: number,
    deadline: number,
    hoursWorked: number,
    numProjects: number
  ) => any
  mapInitPoint: CoordinateType
  lunchStart: number
  lunchEnd: number
}

const useSystemValues = (): SystemValues => {
  const salaryMonth = useSelector(getSalaryMonth)
  const salaryMonthStartDay = useSelector(getSalaryMonthStartDay)
  const dailyBreakLimit = useSelector(getDailyBreakLimit)
  const {
    complexityWeight,
    deadlineWeight,
    extendWorkedhoursWeight,
    numProjectsWeight,
  } = useSelector(getStressLevelWeights)

  const calculateStressLevel = (
    complexity: number,
    deadline: number,
    hoursWorked: number,
    numProjects: number
  ) => {
    if (
      complexityWeight &&
      deadlineWeight &&
      extendWorkedhoursWeight &&
      numProjectsWeight
    ) {
      const stressScore =
        complexity * Number(complexityWeight.attributes.value) +
        deadline * Number(deadlineWeight.attributes.value) +
        hoursWorked * Number(extendWorkedhoursWeight.attributes.value) +
        numProjects * Number(numProjectsWeight.attributes.value)
      return stressScore
    }
    return null
  }
  const mapInitPoint = useSelector(getMapInitPoint)
  const lunchTime = useSelector(getLunchtime)

  const lunchStart = moment(
    _.get(lunchTime, 'lunchStartTime.attributes.value'),
    'HH:mm:ss'
  ).hour()
  const lunchEnd =
    moment(
      _.get(lunchTime, 'lunchStartTime.attributes.value'),
      'HH:mm:ss'
    ).hour() +
    moment(
      _.get(lunchTime, 'lunchDuration.attributes.value'),
      'HH:mm:ss'
    ).hour()

  return {
    salaryMonth,
    salaryMonthStartDay,
    dailyBreakLimit,
    calculateStressLevel,
    mapInitPoint,
    lunchStart,
    lunchEnd,
  }
}
export default useSystemValues
