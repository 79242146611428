export enum TIME_OFF_ACTION_TYPE {
  TIME_OFF_TEMPLATE_LIST = 'timeoff/templateList',
  TIME_OFF_TEMPLATE_ALL = 'timeoff/templateAll',
  TIME_OFF_TEMPLATE_CREATE = 'timeoff/templateCreate',
  TIME_OFF_TEMPLATE_UPDATE = 'timeoff/templateUpdate',
  TIME_OFF_TEMPLATE_DELETE = 'timeoff/templateDelete',
  TIME_OFF_TEMPLATE_DETAIL = 'timeoff/templateDetail',
  TIME_OFF_TEMPLATE_CLEAR = 'timeoff/templateClear',

  TIME_OFF_REQUEST_LIST = 'timeoff/requestList',
  TIME_OFF_REQUEST_CREATE = 'timeoff/requestCreate',
  // TIME_OFF_REQUEST_UPDATE = 'timeoff/requestUpdate',
  TIME_OFF_REQUEST_DELETE = 'timeoff/requestDelete',
  TIME_OFF_REQUEST_DELETE_BY_APPROVER = 'timeoff/requestDeleteByApprover',
  TIME_OFF_REQUEST_DETAIL = 'timeoff/requestDetail',
  TIME_OFF_REQUEST_CLEAR = 'timeoff/requestClear',

  TIME_OFF_APPROVE_LIST = 'timeoff/approveList',
  TIME_OFF_APPROVE = 'timeoff/approve',
  TIME_OFF_DENY = 'timeoff/deny',

  GET_APPROVAL_TEMPLATE_LIST = 'approval-template/list',
  GET_APPROVAL_TEMPLATE_ALL = 'approval-template/all',
  CREATE_APPROVAL_TEMPLATE = 'approval-template/create',
  UPDATE_APPROVAL_TEMPLATE = 'approval-template/update',
  DELETE_APPROVAL_TEMPLATE = 'approval-template/delete',
  GET_APPROVAL_TEMPLATE_BY_ID = 'approval-template/show',

  GET_APPROVAL_TEMPLATE_DETAIL_LIST = 'approval-template-detail/list',
  CREATE_APPROVAL_TEMPLATE_DETAIL = 'approval-template-detail/create',
  UPDATE_APPROVAL_TEMPLATE_DETAIL = 'approval-template-detail/update',
  DELETE_APPROVAL_TEMPLATE_DETAIL = 'approval-template-detail/delete',
  UPDATE_APPROVAL_TEMPLATE_STEP = 'approval-template-detail/step-update',

  COMPENSATORY_DAYS = 'compensatoryDays/list',
  USER_COMPENSATORY_DAYS_LIST = 'userCompensatoryDays/list',
  USER_COMPENSATORY_DAYS_UPDATE = 'userCompensatoryDays/update',

  //TIMEOFF_REPORT'
  TIME_OFF_REPORT_TEMPLATE_LIST = 'timeoff/reportTemplateList',
  TIME_OFF_REPORT = 'timeoff/report',
  TIME_OFF_TOTAL_REPORT = 'timeoff/totalreport',
}
