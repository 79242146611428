import { addToast as addToastAction, toastParams } from '@store/common/actions'
import { useAppDispatch } from '@store/hooks'

interface Toast {
  addToast: (params: toastParams) => any
}

const useToast = (): Toast => {
  const dispatch = useAppDispatch()
  const addToast = (params: toastParams) => dispatch(addToastAction(params))

  return {
    addToast,
  }
}

export default useToast
