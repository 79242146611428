import { FilterParams, SearchFilter } from '@constants/common.constants'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/company.services'
import { COMPANY_ACTION_TYPE } from './types'

// Partner Company
export const fetchCompanyList = createAsyncThunk<
  services.CompanyListResponse,
  FilterParams
>(COMPANY_ACTION_TYPE.GET_COMPANY_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.companyListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchCompanyListAll = createAsyncThunk<
  services.CompanyAllResponse,
  SearchFilter
>(COMPANY_ACTION_TYPE.GET_COMPANY_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.companyAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCompany = createAsyncThunk<
  services.CompanyResponse,
  services.AddCompanyParams
>(COMPANY_ACTION_TYPE.CREATE_COMPANY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.companyAddService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchCompanyDetails = createAsyncThunk<
  services.CompanyResponse,
  services.CompanyDetailsParams
>(COMPANY_ACTION_TYPE.SHOW_COMPANY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.companyDetailsService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchCompanyDivisionList = createAsyncThunk<
  services.DivisionAllResponse,
  services.CompanyDetailsParams
>(
  COMPANY_ACTION_TYPE.GET_COMPANY_DIVISIONS,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.companyDivisionListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCompany = createAsyncThunk<
  services.CompanyResponse,
  services.CompanyUpdateParams
>(COMPANY_ACTION_TYPE.UPDATE_COMPANY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.companyUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteCompany = createAsyncThunk<
  services.CompanyResponse,
  services.CompanyDeleteParams
>(COMPANY_ACTION_TYPE.DELETE_COMPANY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.companyDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const clearCompany = createAction(COMPANY_ACTION_TYPE.CLEAR_COMPANY)

// Partner Division
export const fetchPartnerDivisionList = createAsyncThunk<
  services.PartnerDivisionListResponse,
  FilterParams
>(
  COMPANY_ACTION_TYPE.GET_DIVISION_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerDivisionListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updatePartnerDivision = createAsyncThunk<
  services.PartnerDivisionUpdateResponse,
  services.PartnerDivisionUpdateParams
>(COMPANY_ACTION_TYPE.UPDATE_DIVISION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.partnerDivisionUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updatePartnerDivisionOrder = createAsyncThunk<
  services.DivisionAllResponse,
  services.PartnerDivisionUpdateOrderParams
>(
  COMPANY_ACTION_TYPE.UPDATE_DIVISION_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerDivisionUpdateOrderService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createPartnerDivision = createAsyncThunk<
  services.PartnerDivisionAddResponse,
  services.PartnerDivisionAddParams
>(COMPANY_ACTION_TYPE.CREATE_DIVISION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.partnerDivisionAddService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchAllDivisionList = createAsyncThunk<
  services.DivisionAllResponse,
  SearchFilter
>(COMPANY_ACTION_TYPE.DIVISION_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.divisionAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deletePartnerDivision = createAsyncThunk<
  services.PartnerDivisionDeleteResponse,
  services.PartnerDivisionDeleteParams
>(COMPANY_ACTION_TYPE.DELETE_DIVISION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.partnerDivisionDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
