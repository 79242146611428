import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import { reportParser } from './helper'
const getRoot = (state: RootState) => state.time

export const getRecordList = createSelector(
  getRoot,
  (state) => state.recordList
)

export const getTimesheetList = createSelector(
  getRoot,
  (state) => state.timesheetList
)

export const getTimesheetTotal = createSelector(
  getRoot,
  (state) => state.timesheetTotal
)

export const getTimesheetUser = createSelector(
  getRoot,
  (state) => state.timesheetUser
)

export const getTimesheetRequestList = createSelector(
  getRoot,
  (state) => state.timesheetRequestList
)

export const getTimesheetRemoteRequestList = createSelector(
  getRoot,
  (state) => state.timesheetRemoteWorkRequests
)

export const getTimeReport = createSelector(
  getRoot,
  (state) => state.timeReport
)

export const getRestoIntegrated = createSelector(
  getRoot,
  (state) => state.restoIntegrated
)

export const getTimeReportTotal = createSelector(
  getRoot,
  (state) => state.timeReportTotal
)

export const getTimeCorrectionList = createSelector(
  getRoot,
  (state) => state.timeCorrectionList
)
export const getTimeCorrectionPagingMeta = createSelector(
  getRoot,
  (state) => state.timeCorrectionPaging
)

export const getTimeCard = createSelector(getRoot, (state) => state.timeCard)

export const getTodaysReports = createSelector(getRoot, (state) =>
  reportParser(state.timeCard)
)

export const getTimeCorrectionApproveList = createSelector(
  getRoot,
  (state) => state.timeCorrectionApproveList
)

export const getTimeCorrectionApprovePagingMeta = createSelector(
  getRoot,
  (state) => state.timeCorrectionApprovePaging
)

export const getTimeCorrectionTemplate = createSelector(
  getRoot,
  (state) => state.timeCorrectionTemplate
)

export const getRemoteWorkTemplate = createSelector(
  getRoot,
  (state) => state.remoteWorkTemplate
)

export const getRemoteWorkSettingsList = createSelector(
  getRoot,
  (state) => state.remoteWorkSettingsList
)

export const getRemoteWorkSettingsMyList = createSelector(
  getRoot,
  (state) => state.remoteWorkSettingsMyList
)

export const getRemoteWorkSettingsMyAllList = createSelector(
  getRoot,
  (state) => state.remoteWorkSettingsMyListAll
)

export const getRemoteWorkSettingsMyListPaging = createSelector(
  getRoot,
  (state) => state.remoteWorkSettingsMyListPaging
)

export const getRemoteWorkSettingsListPaging = createSelector(
  getRoot,
  (state) => state.remoteWorkSettingsListPaging
)
export const getRemoteWorkRequests = createSelector(
  getRoot,
  (state) => state.remoteWorkRequests
)
export const getRemoteWorkRequestPaging = createSelector(
  getRoot,
  (state) => state.remoteWorkRequestsPaging
)
export const getRemoteWorkRequestApproveList = createSelector(
  getRoot,
  (state) => state.remoteWorkRequestApproveList
)
export const getRemoteWorkRequestApproveListPaging = createSelector(
  getRoot,
  (state) => state.remoteWorkRequestApproveListPaging
)
export const remoteOfficeSomeDatas = createSelector(
  getRoot,
  (state) => state.remoteOfficeSomeData
)

export const getRemoteOfficeList = createSelector(
  getRoot,
  (state) => state.remoteOfficeReportList
)
