import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import _ from 'lodash'

export type SelectItem = {
  id: string
  name: string
}

const getRoot = (state: RootState) => state.company

// Company
export const getCompanyList = createSelector(
  getRoot,
  (state) => state.companyList
)

export const getCompanyListMeta = createSelector(
  getRoot,
  (state) => state.companyListMeta
)

export const getAllCompanies = createSelector(
  getRoot,
  (state) => state.companyListAll
)

export const getCompanyDetails = createSelector(
  getRoot,
  (state) => state.companyDetails
)

export const getCompanyDivisions = createSelector(
  getRoot,
  (state) => state.companyDivisions
)

// Partner Division
export const getPartnerDivisionList = createSelector(
  getRoot,
  (state) => state.partnerDivisionList
)

export const getPartnerDivisionMeta = createSelector(
  getRoot,
  (state) => state.partnerDivisionMeta
)

export const getAllDivisions = createSelector(getRoot, (state) => {
  if (state.partnerDivisionAll) {
    return _.orderBy(
      state.partnerDivisionAll,
      'attributes.display_order',
      'asc'
    )
  } else {
    return state.partnerDivisionAll
  }
})
