/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgress, Paper, TableFooter } from '@mui/material'
import Table, { TableProps } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { BoxProps } from '@mui/system'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import FilterPagination from './FilterPagination'
import DataLoading, { DataLoadingProps } from '@components/DataLoading'

export interface TableLayoutProps {
  renderRow: (data: any, index: number) => any
  data: any[]
  head: ReactNode
  tableProps?: TableProps
  isLoading?: boolean
  filter?: ReactNode
  pagination?: ReactNode
  headSection?: ReactNode
  settings?: ReactNode
  paginationBox?: BoxProps
  minWidth?: number
  dataLoading?: DataLoadingProps
  isFilterArea?: boolean
  foot?: ReactNode
}

const TableLayout: React.FC<TableLayoutProps> = (props) => {
  const {
    data,
    renderRow,
    head,
    tableProps,
    isLoading,
    headSection,
    minWidth = 600,
    dataLoading,
    isFilterArea = true,
    foot,
  } = props
  const { t } = useTranslation()
  const initLoad = isLoading && _.isEmpty(data)
  return (
    <Paper sx={{ position: 'relative' }}>
      {isFilterArea && (
        <Box
          sx={{
            px: 1,
            py: 1,
            pt: 0.5,
            borderBottom: '1px solid #ddd',
            '& .MuiOutlinedInput-input': {
              padding: '8px 12px',
            },
          }}
        >
          {headSection && headSection}
          <FilterPagination {...props} />
        </Box>
      )}

      <TableContainer
        sx={{ position: 'relative', minHeight: initLoad ? 200 : 'auto' }}
        className="scrollbar-container"
      >
        {isLoading ? (
          <Box
            component="span"
            sx={{
              position: 'absolute',
              left: 0,
              background: 'rgba(255,255,255,0.6)',
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100,
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : null}
        {data && !_.isEmpty(data) && (
          <Table
            data-test-id="table"
            sx={{
              minWidth: minWidth,
              '& .MuiTableCell-root': {
                py: 1,
                px: 2,
                fontSize: 14,
              },
              '& .MuiTableCell-root.loader-holder-cell': {
                p: 0,
                height: 0,
              },
            }}
            {...tableProps}
          >
            <TableHead>{head}</TableHead>
            <TableBody sx={{ position: 'relative' }}>
              {data.map((item, index) => renderRow(item, index))}
            </TableBody>
            {foot && <TableFooter>{foot}</TableFooter>}
          </Table>
        )}
      </TableContainer>
      {!isLoading && (
        <DataLoading
          sx={{ textAlign: 'center' }}
          isLoading={false}
          isEmptyData={data && _.isEmpty(data)}
          emptyDataSx={{ py: 2 }}
          {...dataLoading}
        />
      )}
    </Paper>
  )
}

export default TableLayout
