import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, Stack, Typography } from '@mui/material'
interface AttachmentInfoProps {
  fileName?: string
  onClear?: () => void
}

const AttachmentInfo: React.FC<AttachmentInfoProps> = ({
  fileName,
  onClear,
}) => {
  return (
    <Box sx={{ border: '2px dashed', borderRadius: '4px' }}>
      <Stack direction={'row'} alignItems={'center'}>
        <AttachFileIcon fontSize="small" />
        <Typography fontStyle={'italic'} fontWeight={500}>
          {fileName}
        </Typography>
        <IconButton
          sx={{ ml: 2, p: '5px' }}
          onClick={() => onClear && onClear()}
        >
          <ClearIcon />
        </IconButton>
      </Stack>
    </Box>
  )
}

export default AttachmentInfo
