import { LayoutContext } from '@layouts/MainLayout/LayoutProvider'
import { Box, Grid } from '@mui/material'
import { useContext } from 'react'
import { TableLayoutProps } from '.'

type FilterPaginationProps = Pick<
  TableLayoutProps,
  'filter' | 'pagination' | 'settings' | 'paginationBox'
>

const FilterPagination: React.FC<FilterPaginationProps> = ({
  filter,
  pagination,
  settings,
  paginationBox,
}) => {
  const { isSidebarOpen } = useContext(LayoutContext)

  return (
    <>
      {filter || pagination ? (
        <Grid container alignItems={'flex-end'} justifyContent="flex-end">
          <Grid
            xs={12}
            sm={12}
            md={isSidebarOpen ? 12 : 5}
            lg={8}
            xl={7}
            alignItems={'center'}
            item
          >
            {filter && filter}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={isSidebarOpen ? 12 : 7}
            lg={4}
            xl={5}
            alignItems={'center'}
            height={'100%'}
            item
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems={'center'}
              {...paginationBox}
            >
              {pagination && pagination}
              {settings && settings}
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}

export default FilterPagination
