import { createReducer } from '@reduxjs/toolkit'
import {
  MyVacationBalanceItem,
  UserVacationHistoryItem,
  VacationBalanceItem,
  VacationReportDetailItem,
  VacationReportItem,
} from '@services/vacation.services'
import _ from 'lodash'
import * as actions from '../actions'

type StateType = {
  vacationBalanceList?: VacationBalanceItem[]
  myVacationBalance?: MyVacationBalanceItem[]
  userVacationHistory?: UserVacationHistoryItem[]
  vacationReportList?: VacationReportItem[]
  vacationReportDetail?: VacationReportDetailItem[]
}

const initialState: StateType = {
  vacationBalanceList: undefined,
  myVacationBalance: undefined,
  userVacationHistory: undefined,
  vacationReportList: undefined,
  vacationReportDetail: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchVacationBalances.fulfilled, (state, action) => {
    state.vacationBalanceList = action.payload.data
  })
  builder.addCase(
    actions.fetchMyVacationBalances.fulfilled,
    (state, action) => {
      state.myVacationBalance = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchUserVacationHistory.fulfilled,
    (state, action) => {
      state.userVacationHistory = action.payload.data
    }
  )
  builder.addCase(actions.createVacationBalance.fulfilled, (state, action) => {
    state.vacationBalanceList = state.vacationBalanceList
      ? _.map(state.vacationBalanceList, (item) => {
          if (
            item.user_id === Number(action.payload.data.attributes.user.data.id)
          ) {
            const updatedYears = [
              ...item.years,
              {
                year: action.payload.data.attributes.year,
                days: action.payload.data.attributes.days,
                used_days: action.payload.data.attributes.used_days,
                remaining_days: action.payload.data.attributes.remaining_days,
              },
            ]
            return {
              ...item,
              years: updatedYears,
            }
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.updateVacationBalance.fulfilled, (state, action) => {
    state.vacationBalanceList = state.vacationBalanceList
      ? _.map(state.vacationBalanceList, (item) => {
          if (
            item.user_id === Number(action.payload.data.attributes.user.data.id)
          ) {
            const yearDataIndex = item.years.findIndex(
              (yearItem) =>
                yearItem.year === action.payload.data.attributes.year
            )

            if (yearDataIndex !== -1) {
              item.years[yearDataIndex].days =
                action.payload.data.attributes.days

              item.years[yearDataIndex].remaining_days =
                action.payload.data.attributes.remaining_days

              item.years[yearDataIndex].used_days =
                action.payload.data.attributes.used_days
            }

            return item
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.deleteVacationBalance.fulfilled, (state, action) => {
    state.vacationBalanceList = state.vacationBalanceList
      ? state.vacationBalanceList.map((item) => {
          if (item.user_id === Number(action.meta.arg.user_id)) {
            const updatedYears = item.years.filter(
              (yearItem) => yearItem.year !== action.meta.arg.year
            )
            return {
              ...item,
              years: updatedYears,
            }
          }
          return item
        })
      : undefined
  })
  builder.addCase(
    actions.fetchVacationReportList.fulfilled,
    (state, action) => {
      state.vacationReportList = action.payload.data
    }
  )
  builder.addCase(
    actions.fetchVacationReportDetailList.fulfilled,
    (state, action) => {
      state.vacationReportDetail = action.payload.data
    }
  )
})
