import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material/'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { getFileExtension } from '@utils/helper/common.helper'
import { useEffect, useState } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { useTranslation } from 'react-i18next'
import { getPresignedUrlResponse, uploadImage } from 'services/common.services'
import { UploadOptions, UploadType } from './types'

interface UploadDialogProps {
  open: boolean
  options: UploadOptions
  onCancel: () => void
  onConfirm: (obj: getPresignedUrlResponse | FileWithPath) => void
  onClose: (event: Event, reason: string) => void
  replaceUri?: string
}

export interface ExtendedFile extends File {
  preview?: string
  customName?: string
}

interface ProgressProps extends LinearProgressProps {
  value: number
}

const LinearProgressWithLabel = (props: ProgressProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  )
}

const UploadDialog = ({
  options,
  onCancel,
  onConfirm,
  onClose,
  ...rest
}: UploadDialogProps) => {
  const { t } = useTranslation()
  const dialogOpen = rest.open
  const { title, dialogProps, upload_uri, maxSize, accept, type, project_id } =
    options
  const [file, setFile] = useState<ExtendedFile | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const [plainFile, setPlainFile] = useState<FileWithPath | null>(null)
  const reset = () => {
    setFile(null)
    setLoading(false)
    setProgress(0)
    setError(null)
    setPlainFile(null)
  }
  useEffect(() => {
    if (!dialogOpen) reset()
  }, [dialogOpen])
  const handleProgress = (percent: number) => {
    setProgress(percent)
  }

  const handleFile = async (acceptedFile: File) => {
    setError(null)
    if (acceptedFile) {
      console.warn(acceptedFile)
      if (type === UploadType.FILE) {
        setPlainFile(acceptedFile)
      } else {
        setFile(
          Object.assign(acceptedFile, {
            preview: URL.createObjectURL(acceptedFile),
          })
        )
      }
    }
  }

  const handleConfirm = async () => {
    setError(null)
    setLoading(true)
    if (type === UploadType.FILE) {
      if (plainFile) onConfirm(plainFile)
    } else {
      try {
        if (file && upload_uri) {
          try {
            const imgResponse = await uploadImage(
              upload_uri,
              file,
              type,
              handleProgress,
              project_id
            )
            if (imgResponse) {
              onConfirm(imgResponse)
              // after response
            }
          } catch (error: any) {
            // eslint-disable-next-line no-console
            setLoading(false)
            setError(t('ERROR.E000066'))
          }
        }
      } catch (err) {
        setLoading(false)
        setError(t('ERROR.E000066'))
      }
    }
  }

  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    reset()
    onClose(event, reason)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: accept,
    maxSize: maxSize, // 100mb
    onDrop: (acceptedFiles) => {
      const singleFile = acceptedFiles[0]
      handleFile(singleFile)
    },
  })

  const thumbs = () => {
    return (
      <>
        <Box
          sx={{
            display: 'block',
            borderRadius: 1,
            border: '1px solid #eaeaea',
            marginBottom: 1,
            marginRight: 1,
            width: 100,
            height: 100,
            padding: 0.5,
            boxSizing: 'border-box',
            overflow: 'hidden',
          }}
        >
          {file && file.name ? (
            file.type.startsWith('image/') ? (
              <img
                src={file.preview}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  height: '100%',
                  p: 0.5,
                  '& svg': {
                    height: '100%',
                    width: 'auto',
                    maxWidth: 'auto',
                  },
                }}
              >
                <FileIcon
                  extension={getFileExtension(file.name)}
                  {...defaultStyles[getFileExtension(file.name)]}
                />
                {file.name}
              </Box>
            )
          ) : null}
          {plainFile && plainFile.name ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  height: '100%',
                  p: 0.5,
                  '& svg': {
                    height: '100%',
                    width: 'auto',
                    maxWidth: 'auto',
                  },
                }}
              >
                <FileIcon
                  extension={getFileExtension(plainFile.name)}
                  {...defaultStyles[getFileExtension(plainFile.name)]}
                />
              </Box>
            </>
          ) : null}
        </Box>
      </>
    )
  }

  return (
    <Dialog fullWidth {...dialogProps} open={dialogOpen} onClose={handleClose}>
      <Container maxWidth={false}>
        <DialogTitle sx={{ padding: 0, mt: 2, mb: 2, textAlign: 'center' }}>
          {title ? title : t('UPLOAD.title')}
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Box
            display="flex"
            mb={1}
            mt={1}
            sx={{ height: 100, width: '100%' }}
            alignItems="center"
            justifyContent="center"
          >
            {thumbs()}
          </Box>
          <Box sx={{ textAlign: 'center', pb: 2 }}>
            {file ? <Typography noWrap>{file.name} </Typography> : null}
            {plainFile ? (
              <Typography noWrap>{plainFile.name} </Typography>
            ) : null}
          </Box>
          <Box
            {...getRootProps({ className: 'dropzone' })}
            sx={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              borderWidth: '2px',
              borderRadius: '2px',
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            }}
          >
            <input {...getInputProps()} />
            {/* <p>{t('helpers:upload.drag_message')}</p> */}
            <Button
              variant="outlined"
              disabled={loading}
              color={'success'}
              onClick={open}
            >
              {t('SYSCOMMON.select_file')}
            </Button>
          </Box>
          {loading ? (
            <Box sx={{ height: 20 }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          ) : (
            <Box sx={{ height: 20 }} />
          )}
          {error && (
            <Box mt={1}>
              <Alert severity="warning">{error}</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ pt: 2, pb: 2, pl: 0, pr: 0, justifyContent: 'space-between' }}
        >
          <Button variant="outlined" onClick={onCancel}>
            {t('SYSCOMMON.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 100 }}
            loading={loading}
            onClick={handleConfirm}
            disabled={
              type === UploadType.FILE
                ? !plainFile || loading
                : loading || !file
            }
          >
            {t('UPLOAD.upload')}
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default UploadDialog
