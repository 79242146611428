import { SYSTEM_SETTINGS_ITEM_NAME } from '@constants/common.constants'
import {
  SalaryMonthStartDay,
  DailyBreakLimitName,
  StressLevelWeights,
  DailyReportMissingLimit,
  DailyReportPossiblePeriod,
} from '@constants/time.constants'
import { createSelector } from '@reduxjs/toolkit'
import { SystemSettingsItem } from '@services/settings.services'
import { RootState } from '@store/store'
import dayjs from 'dayjs'
import _ from 'lodash'

const getState = (state: RootState) => state.common

export const getToasts = createSelector(getState, (state) => state.toasts)
export const previousPath = createSelector(getState, (state) => state.prevPath)
export const getContentImgs = createSelector(getState, (state) => state.imgs)
export const helper = createSelector(getState, (state) => state.helper)
export const getInitApp = createSelector(getState, (state) => state.init)
export const getUserRole = createSelector(getState, (state) =>
  _.get(state, 'init.attributes.role.data.attributes.modules.data', undefined)
)

export const getReportRelated = createSelector(getState, (state) => {
  const limitData = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === DailyReportMissingLimit
  )

  const limit = limitData
    ? Number(_.get(limitData, 'attributes.value', undefined))
    : undefined

  const periodData = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === DailyReportPossiblePeriod
  )

  const period = periodData
    ? Number(_.get(periodData, 'attributes.value', undefined))
    : undefined

  const missingReports = _.get(
    state,
    'init.attributes.daily_report_missing_dates.data',
    undefined
  )

  return {
    limit,
    period,
    missingReports,
  }
})

export const getTimeRecords = createSelector(getState, (state) =>
  _.get(state, 'init.attributes.time_records.data', undefined)
)

export const getTimeBreaks = createSelector(getState, (state) =>
  _.get(state, 'init.attributes.break_records.data', undefined)
)

export const getCompanySettings = createSelector(getState, (state) =>
  _.get(state, 'init.attributes.company_settings.data', undefined)
)

export const getSalaryMonth = createSelector(getState, (state) => {
  const salaryMonthStartDay = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) => item.attributes.name === SalaryMonthStartDay
  )

  const today = new Date()
  const salaryMonthStartDays = _.get(
    salaryMonthStartDay,
    'attributes.value',
    '16'
  )
  const currentDate =
    salaryMonthStartDays == '1' ||
    today.getDate() < Number(salaryMonthStartDays)
      ? dayjs(today).startOf('month').startOf('day')
      : dayjs(today).add(1, 'month').startOf('month')
  return currentDate
})
export const getSalaryMonthStartDay = createSelector(getState, (state) => {
  return _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) => item.attributes.name === SalaryMonthStartDay
  )
})

export const getDailyBreakLimit = createSelector(getState, (state) => {
  return _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) => item.attributes.name === DailyBreakLimitName
  )
})

export const getStressLevelWeights = createSelector(getState, (state) => {
  const complexityWeight = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === StressLevelWeights.complexity
  )
  const deadlineWeight = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === StressLevelWeights.deadline
  )
  const extendWorkedhoursWeight = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === StressLevelWeights.extendWorkedhours
  )

  const numProjectsWeight = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === StressLevelWeights.numProjects
  )

  return {
    complexityWeight,
    deadlineWeight,
    extendWorkedhoursWeight,
    numProjectsWeight,
  }
})

export const getMapInitPoint = createSelector(getState, (state) => {
  const mapInitPoint = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === SYSTEM_SETTINGS_ITEM_NAME.MAP_INIT_POINT
  )
  return mapInitPoint ? JSON.parse(mapInitPoint.attributes.value) : ''
})

export const getLunchtime = createSelector(getState, (state) => {
  const lunchStartTime = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === SYSTEM_SETTINGS_ITEM_NAME.LUNCH_START
  )
  const lunchDuration = _.find(
    _.get(state, 'init.attributes.company_settings.data', undefined),
    (item: SystemSettingsItem) =>
      item.attributes.name === SYSTEM_SETTINGS_ITEM_NAME.LUNCH_DURATION
  )
  return {
    lunchStartTime,
    lunchDuration,
  }
})
