import { useContext } from 'react'
import TimeOffContext from './TimeOffContext'

const useTimeOff = () => {
  const confirm = useContext(TimeOffContext)
  if (!confirm) throw new Error('within ConfirmProvider')
  return confirm
}

export default useTimeOff
