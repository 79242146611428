import {
  MyVacationBalanceItem,
  UserVacationHistoryItem,
  UserVacationHistoryParams,
  VacationBalanceCalculateParams,
  VacationBalanceCreateParams,
  VacationBalanceDestroyParams,
  VacationBalanceItem,
  VacationFilter,
} from '@services/vacation.services'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import {
  calculateUserVacationBalance,
  calculateVacationBalance,
  createVacationBalance,
  deleteVacationBalance,
  fetchMyVacationBalances,
  fetchUserVacationHistory,
  fetchVacationBalances,
  updateVacationBalance,
} from '@store/vacation/actions'
import {
  getMyTotalBalance,
  getMyVacationBalances,
  getUserVacationHistory,
  getVacationBalanceList,
} from '@store/vacation/selectors'
import { useSelector } from 'react-redux'

interface VacationBalance {
  vacationBalances?: VacationBalanceItem[]
  fetch: (params: VacationFilter) => any
  meta: Meta
  create: (params: VacationBalanceCreateParams) => any
  createMeta: Meta
  calculate: (params: VacationBalanceCalculateParams) => any
  calculateMeta: Meta
  update: (params: VacationBalanceCreateParams) => any
  updateMeta: Meta
  deleteBalance: (params: VacationBalanceDestroyParams) => any
  fetchMyData: () => any
  myData: MyVacationBalanceItem[]
  myTotalBalance: number
  myDataMeta: Meta
  calculateForUser: (params: VacationBalanceDestroyParams) => any
  calculateForUserMeta: Meta
  fetchUserHistory: (params: UserVacationHistoryParams) => any
  userHistory: UserVacationHistoryItem[]
  userHistoryMeta: Meta
}

const useVacationBalance = (): VacationBalance => {
  const dispatch = useAppDispatch()
  const fetch = (params: VacationFilter) =>
    dispatch(fetchVacationBalances(params))
  const _meta = createMetaSelector(fetchVacationBalances)
  const meta = useSelector(_meta)
  const vacationBalances = useSelector(getVacationBalanceList)

  const create = (params: VacationBalanceCreateParams) =>
    dispatch(createVacationBalance(params))
  const _createMeta = createMetaSelector(createVacationBalance)
  const createMeta = useSelector(_createMeta)

  const calculate = (params: VacationBalanceCalculateParams) =>
    dispatch(calculateVacationBalance(params))
  const _calculateMeta = createMetaSelector(calculateVacationBalance)
  const calculateMeta = useSelector(_calculateMeta)

  const update = (params: VacationBalanceCreateParams) =>
    dispatch(updateVacationBalance(params))
  const _updateMeta = createMetaSelector(updateVacationBalance)
  const updateMeta = useSelector(_updateMeta)

  const fetchMyData = () => dispatch(fetchMyVacationBalances())
  const _myDataMeta = createMetaSelector(fetchMyVacationBalances)
  const myDataMeta = useSelector(_myDataMeta)

  const myData = useSelector(getMyVacationBalances)
  const myTotalBalance = useSelector(getMyTotalBalance)

  const deleteBalance = (params: VacationBalanceDestroyParams) =>
    dispatch(deleteVacationBalance(params))

  const calculateForUser = (params: VacationBalanceDestroyParams) =>
    dispatch(calculateUserVacationBalance(params))
  const _calculateForUserMeta = createMetaSelector(calculateUserVacationBalance)
  const calculateForUserMeta = useSelector(_calculateForUserMeta)

  const fetchUserHistory = (params: UserVacationHistoryParams) =>
    dispatch(fetchUserVacationHistory(params))
  const _userHistoryMeta = createMetaSelector(fetchUserVacationHistory)
  const userHistoryMeta = useSelector(_userHistoryMeta)

  const userHistory = useSelector(getUserVacationHistory)

  return {
    fetch,
    meta,
    vacationBalances,
    create,
    createMeta,
    calculate,
    calculateMeta,
    update,
    updateMeta,
    deleteBalance,
    fetchMyData,
    myDataMeta,
    myData,
    myTotalBalance,
    calculateForUser,
    calculateForUserMeta,
    fetchUserHistory,
    userHistoryMeta,
    userHistory,
  }
}
export default useVacationBalance
