import { Direction } from '@constants/common.constants'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/project.services'
import { PROJECT_ACTION_TYPE } from './type'

export type ProjectListFilter = {
  keyword: string
  order_by: string
  direction: Direction
  per_page: string
  page: string
  employee_ids?: string[]
  status?: string[]
  project_type?: string
  division_id?: string
}

export const fetchProjectListAll = createAsyncThunk<
  services.ProjectListAllResponse,
  string
>(PROJECT_ACTION_TYPE.GET_PROJECT_LIST_ALL, async (id, { rejectWithValue }) => {
  try {
    const res = await services.projectListAllService(id)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchProjectAll = createAsyncThunk<
  services.ProjectListAllResponse,
  services.AllProjectParams
>(PROJECT_ACTION_TYPE.PROJECT_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectAll(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchProjectList = createAsyncThunk<
  services.ProjectListResponse,
  ProjectListFilter
>(PROJECT_ACTION_TYPE.GET_PROJECT_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createProject = createAsyncThunk<
  services.ProjectResponse,
  services.ProjectCreateParams
>(PROJECT_ACTION_TYPE.CREATE_PROJECT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const showProject = createAsyncThunk<
  services.ProjectResponse,
  services.ProjectDetailParams
>(PROJECT_ACTION_TYPE.SHOW_PROJECT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectDetailService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response)
  }
})

export const updateProject = createAsyncThunk<
  services.ProjectResponse,
  services.ProjectUpdateParams
>(PROJECT_ACTION_TYPE.UPDATE_PROJECT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteProject = createAsyncThunk<
  services.ProjectResponse,
  services.ProjectDeleteParams
>(PROJECT_ACTION_TYPE.DELETE_PROJECT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const memberList = createAsyncThunk<
  services.MemberListResponse,
  services.MemberListParams
>(PROJECT_ACTION_TYPE.MEMBER_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.memberListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response)
  }
})

export const myProjects = createAsyncThunk<
  services.MyProjectResponse,
  services.MyProjectParams
>(PROJECT_ACTION_TYPE.MY_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.myProjectsService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response)
  }
})

export const addableUserList = createAsyncThunk<
  services.AddableUsersResponse,
  string
>(PROJECT_ACTION_TYPE.ADDABLE_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.addableUserService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addUser = createAsyncThunk<
  services.MemberListResponse,
  services.MemberCreate
>(PROJECT_ACTION_TYPE.ADD_USER, async (params, { rejectWithValue }) => {
  try {
    const res = await services.addMemberService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUser = createAsyncThunk<
  services.MemberListResponse,
  services.MemberUpdate
>(PROJECT_ACTION_TYPE.UPDATE_USER, async (params, { rejectWithValue }) => {
  try {
    const res = await services.updateMemberService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteProjectUser = createAsyncThunk<
  services.MemberListResponse,
  services.MemberDelete
>(PROJECT_ACTION_TYPE.REMOVE_USER, async (params, { rejectWithValue }) => {
  try {
    const res = await services.deleteMemberService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const projectActivity = createAsyncThunk<
  services.ActivityListResponse,
  services.ProjectActivityArgs
>(PROJECT_ACTION_TYPE.PROJECT_ACTIVITY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.projectActivityService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const setProjectImage = createAsyncThunk<
  services.ProjectImageResponse,
  services.ProjectImageArg
>(
  PROJECT_ACTION_TYPE.UPDATE_PROJECT_IMAGE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.projectImageService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const clearProject = createAction(PROJECT_ACTION_TYPE.CLEAR_PROJECT)

//PARTNER PURCHASE ORDERS

export const fetchPartnerPurchaseOrderList = createAsyncThunk<
  services.PartnerPurchaseOrderListResponse,
  services.PartnerPurchaseOrderFilterParams
>(
  PROJECT_ACTION_TYPE.GET_PARTNER_PURCHASE_ORDER_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerPurchaseOrderListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const clearPartnerPurchaseOrderList = createAction(
  PROJECT_ACTION_TYPE.CLEAR_PARTNER_PURCHASE_ORDER_LIST
)

export const createPartnerPurchaseOrder = createAsyncThunk<
  services.PartnerPurchaseOrderResponse,
  services.PartnerPurchaseOrderCreateParams
>(
  PROJECT_ACTION_TYPE.CREATE_PARTNER_PURCHASE_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerPurchaseOrderCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const showPartnerPurchaseOrder = createAsyncThunk<
  services.PartnerPurchaseOrderResponse,
  services.PartnerPurchaseOrderIdParams
>(
  PROJECT_ACTION_TYPE.SHOW_PARTNER_PURCHASE_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerPurchaseOrderDetailsService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updatePartnerPurchaseOrder = createAsyncThunk<
  services.PartnerPurchaseOrderResponse,
  services.PartnerPurchaseOrderUpdateParams
>(
  PROJECT_ACTION_TYPE.UPDATE_PARTNER_PURCHASE_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerPurchaseOrderUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deletePartnerPurchaseOrder = createAsyncThunk<
  services.PartnerPurchaseOrderResponse,
  services.PartnerPurchaseOrderIdParams
>(
  PROJECT_ACTION_TYPE.DELETE_PARTNER_PURCHASE_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerPurchaseOrderDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchPartnerPurchaseOrderProject = createAsyncThunk<
  services.PartnerPurchaseOrderWithProjectResponse,
  services.PartnerPurchaseOrderWithProjectParams
>(
  PROJECT_ACTION_TYPE.PARTNER_PURCHASE_ORDER_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partnerPurchaseOrderWithProjectService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchProjectListInvoice = createAsyncThunk<
  services.ProjectListResponse,
  services.ProjectWithInvoiceParams
>(
  PROJECT_ACTION_TYPE.PROJECT_WITH_INVOICE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.projectsWithInvoiceService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
