export enum RESOURCE_ACTION_TYPE {
  GET_POSITION_LIST = 'resource/positionlist',
  GET_POSITION_ALL = 'resource/positionlistAll',
  CREATE_POSITION = 'resource/positionCreate',
  UPDATE_POSITION = 'resource/positionUpdate',
  UPDATE_POSITION_ORDER = 'resource/positionUpdateOrder',
  SHOW_POSITION = 'resource/positionShow',
  DELETE_POSITION = 'resource/positionDelete',

  GET_DEPARTMENT_LIST = 'resource/departmentList',
  CREATE_DEPARTMENT = 'resource/departmentCreate',
  UPDATE_DEPARTMENT = 'resource/departmentUpdate',
  SHOW_DEPARTMENT = 'resource/departmentShow',
  DELETE_DEPARTMENT = 'resource/departmentDelete',

  GET_ROLE_LIST = 'resource/roleList',
  CREATE_ROLE = 'resource/roleCreate',
  UPDATE_ROLE = 'resource/roleUpdate',
  DELETE_ROLE = 'resource/roleDelete',
  GET_ROLE_MODULE_LIST = 'resource/roleModule',
  ROLE_LIST_ALL = 'resource/roleListAll',

  RESOURCE_PLAN_LIST = 'resource/resourcePlanList',
  RESOURCE_PLAN_ALL = 'resource/resourcePlanListAll',
  SHOW_RESOURCE_PLAN = 'resource/resourcePlanShow',
  CREATE_RESOURCE_PLAN = 'resource/resourcePlanCreate',
  UPDATE_RESOURCE_PLAN = 'resource/resourcePlanUpdate',
  CLEAR_RESOURCE_PLAN = 'resource/resourcePlanClear',
  RENAME_RESOURCE_PLAN = 'resource/resourcePlanRename',
  CONFIRM_RESOURCE_PLAN = 'resource/resourcePlanConfirm',
  REFUSE_RESOURCE_PLAN = 'resource/resourcePlanRefuse',
  COPY_RESOURCE_PLAN = 'resource/resourcePlanCopy',
  DELETE_RESOURCE_PLAN = 'resource/resourcePlanDelete',
  RESOURCE_PLAN_USERS = 'resource/resourcePlanUsers',
  RESOURCE_PLAN_BATCH = 'resource/resourcePlanBatch',
  RESOURCE_PLAN_ACTIVITY = 'resource/resourcePlanActivity',
  RESOURCE_PLAN_ITEMS = 'resource/resourcePlanItems',
  RESOURCE_PLAN_UPDATE_ITEM_POSITION = 'resource/resourcePlanUpdateItemPosition',
  RESOURCE_PLAN_ADD_USER = 'resource/resourceAddUser',
  RESOURCE_PLAN_REMOVE_USER = 'resource/resourceRemoveUser',
  RESOURCE_PLAN_ADDABLE_USERS = 'resource/resourceAddableUsers',
  RESOURCE__COST_LIST = 'resource/costList',
}
