import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/time.services'
import { TIME_ACTION_TYPE } from './types'
import { FilterParams } from '@constants/common.constants'

// Events
export const timeStart = createAsyncThunk<
  services.TimeStartResponse,
  services.StartEndTimeParams
>(TIME_ACTION_TYPE.TIME_START, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeStartService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const timeEnd = createAsyncThunk<
  services.TimeStartResponse,
  services.StartEndTimeParams
>(TIME_ACTION_TYPE.TIME_END, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeEndService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const timeQrStart = createAsyncThunk<
  services.TimeStartResponse,
  services.TimeQrParams
>(TIME_ACTION_TYPE.TIME_QR_START, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeStartQrService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const timeQrEnd = createAsyncThunk<
  services.TimeStartResponse,
  services.TimeQrParams
>(TIME_ACTION_TYPE.TIME_QR_END, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeEndQrService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const breakStart = createAsyncThunk<services.BreakResponse>(
  TIME_ACTION_TYPE.BREAK_START,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.breakStartService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const breakEnd = createAsyncThunk<services.BreakResponse>(
  TIME_ACTION_TYPE.BREAK_END,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.breakEndService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const recordList = createAsyncThunk<services.TimeRecordsResponse>(
  TIME_ACTION_TYPE.RECORD_LIST,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.timeRecordsService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const timesheet = createAsyncThunk<
  services.TimesheetResponse,
  services.TimesheetParams
>(TIME_ACTION_TYPE.TIMESHEET, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timesheetService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchTimeReportList = createAsyncThunk<
  services.TimeReportResponse,
  services.TimeReportParam
>(TIME_ACTION_TYPE.TIME_REPORT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.timeReportService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchTimeCorrectionList = createAsyncThunk<
  services.TimeCorrectionListResponse,
  services.TimeCorrectionListParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTimeCorrectionApproveList = createAsyncThunk<
  services.TimeCorrectionListResponse,
  services.TimeCorrectionApproveListParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_APPROVE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionApproveListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createTimeCorrection = createAsyncThunk<
  services.TimeCorrectionResponse,
  services.TimeCorrectionCreateParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateTimeCorrection = createAsyncThunk<
  services.TimeCorrectionResponse,
  services.TimeCorrectionUpdateParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTimeCorrection = createAsyncThunk<
  services.TimeCorrectionResponse,
  services.TimeCorrectionIdParam
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const approveTimeCorrection = createAsyncThunk<
  services.TimeCorrectionResponse,
  services.TimeCorrectionApproveParam
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_APPROVE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionApproveService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const denyTimeCorrection = createAsyncThunk<
  services.TimeCorrectionResponse,
  services.TimeCorrectionDenyParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_DENY,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeCorrectionDenyService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTimeCorrectionTimeCard = createAsyncThunk<
  services.TimeCardResponse,
  services.TimeCardParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_TIME_CARD,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.TimeCardService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const showTimeCorrectionTemplate = createAsyncThunk<
  services.TimeCorrectionTemplatesResponse,
  undefined
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_TEMPLATE_SHOW,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.fetchTimeCorrectionTemplateService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateTimeCorrectionTemplate = createAsyncThunk<
  services.TimeCorrectionTemplatesResponse,
  services.TimeCorrectionTemplatesUpdateParams
>(
  TIME_ACTION_TYPE.TIME_CORRECTION_TEMPLATE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.updateTimeCorrectionTemplateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteWorkTemplateDetail = createAsyncThunk<
  services.RemoteWorkTemplateResponse,
  undefined
>(
  TIME_ACTION_TYPE.REMOTE_WORK_TEMPLATE_DETAIL,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkTemplateDetailService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateRemoteWorkTemplateDetail = createAsyncThunk<
  services.RemoteWorkTemplateResponse,
  services.RemoteWorkTemplateUpdateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_TEMPLATE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkTemplateUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteWorkSettingsList = createAsyncThunk<
  services.RemoteWorkSettingsListResponse,
  FilterParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchMyRemoteWorkSettingsList = createAsyncThunk<
  services.RemoteWorkSettingsListResponse,
  FilterParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_MY_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsMyListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchMyRemoteWorkSettingsListAll =
  createAsyncThunk<services.RemoteWorkSettingsListResponse>(
    TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_MY_LIST_ALL,
    async (_, { rejectWithValue }) => {
      try {
        const res = await services.remoteWorkSettingsMyListAllService()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return rejectWithValue(error.response.data)
      }
    }
  )

export const createRemoteWorkSettings = createAsyncThunk<
  services.RemoteWorkSettingsResponse,
  services.RemoteWorkSettingsCreateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateRemoteWorkSettings = createAsyncThunk<
  services.RemoteWorkSettingsResponse,
  services.RemoteWorkSettingsUpdateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const forceCreateRemoteWorkSettings = createAsyncThunk<
  services.RemoteWorkSettingsResponse,
  services.RemoteWorkSettingsForceCreateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_FORCE_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsForceCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const forceUpdateRemoteWorkSettings = createAsyncThunk<
  services.RemoteWorkSettingsResponse,
  services.RemoteWorkSettingsForceUpdateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_FORCE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsForceUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteWorkSettingsDetail = createAsyncThunk<
  services.RemoteWorkSettingsResponse,
  services.RemoteWorkIdType
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const deleteRemoteWorkSettings = createAsyncThunk<
  services.RemoteWorkSettingsResponse,
  services.RemoteWorkIdType
>(
  TIME_ACTION_TYPE.REMOTE_WORK_SETTINGS_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkSettingsDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteWorkRequestList = createAsyncThunk<
  services.RemoteWorkRequestListResponse,
  FilterParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createRemoteWorkRequest = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkRequestCreateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateRemoteWorkRequest = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkRequestUpdateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteWorkRequestDetail = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkRequestCreateParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteWorkRequestApproversList = createAsyncThunk<
  services.RemoteWorkRequestListResponse,
  FilterParams
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_APPROVER_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestApproverListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const approveRemoteWorkRequest = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkRequestApproveParam
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_APPROVE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestApproveService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const denyRemoteWorkRequest = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkRequestDenyParam
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_DENY,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestDenyService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteRemoteWorkRequest = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkIdType
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteApproverRemoteWorkRequest = createAsyncThunk<
  services.RemoteWorkRequestResponse,
  services.RemoteWorkIdType
>(
  TIME_ACTION_TYPE.REMOTE_WORK_REQUEST_APPROVER_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteWorkRequestApproverDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchRemoteOfficeReport = createAsyncThunk<
  services.remoteReportResponse,
  services.remoteReportParams
>(
  TIME_ACTION_TYPE.REMOTE_OFFICE_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.remoteOfficeReportService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
