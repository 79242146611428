import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/vacation.services'
import { VACATION_BALANCE_TYPE } from './type'

export const fetchVacationBalances = createAsyncThunk<
  services.VacationBalanceListResponse,
  services.VacationFilter
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationBalancesListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createVacationBalance = createAsyncThunk<
  services.VacationBalanceResponse,
  services.VacationBalanceCreateParams
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationBalancesCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const calculateVacationBalance = createAsyncThunk<
  services.VacationBalanceResponse,
  services.VacationBalanceCalculateParams
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_CALCULATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationBalancesCalculateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateVacationBalance = createAsyncThunk<
  services.VacationBalanceResponse,
  services.VacationBalanceCreateParams
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationBalancesUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteVacationBalance = createAsyncThunk<
  services.VacationBalanceResponse,
  services.VacationBalanceDestroyParams
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationBalancesDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchMyVacationBalances =
  createAsyncThunk<services.MyVacationBalanceResponse>(
    VACATION_BALANCE_TYPE.VACATION_BALANCE_MY_DATA,
    async (_, { rejectWithValue }) => {
      try {
        const res = await services.vacationBalancesGetMyDataService()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return rejectWithValue(error.response.data)
      }
    }
  )

export const calculateUserVacationBalance = createAsyncThunk<
  services.VacationBalanceResponse,
  services.VacationBalanceDestroyParams
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_CALCULATE_FOR_USER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationCalculateForUser(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchUserVacationHistory = createAsyncThunk<
  services.UserVacationHistoryResponse,
  services.UserVacationHistoryParams
>(
  VACATION_BALANCE_TYPE.VACATION_BALANCE_USER_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationUserHistoryService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchVacationReportList = createAsyncThunk<
  services.VacationReportResponse,
  services.VacationReportParams
>(
  VACATION_BALANCE_TYPE.VACATION_REPORT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationReportService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchVacationReportDetailList = createAsyncThunk<
  services.VacationReportDetailResponse,
  services.VacationReportDetailParams
>(
  VACATION_BALANCE_TYPE.VACATION_REPORT_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.vacationReportDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
