import { Meta } from '@store/metadata/actions/types'
import _ from 'lodash'
import { useEffect } from 'react'
import { UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useServerFieldErrors = (meta: Meta, setError: UseFormSetError<any>) => {
  const { t } = useTranslation()

  useEffect(() => {
    const error = meta.error
    const fieldErrors = _.get(error, 'error.errors', undefined)
    if (error.error && error.error.type === 'fields') {
      if (fieldErrors) {
        fieldErrors.forEach(
          ({
            field,
            message,
          }: {
            field: string
            message: string
            type: string
          }) => {
            setError(field, { type: 'server', message: t(`ERROR.${message}`) })
          }
        )
      }
    }
  }, [meta])
}

export default useServerFieldErrors
