import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/user.services'
import { USER_ACTION_TYPE } from './types'

export const fetchProfile = createAsyncThunk<
  services.UserProfileResponse,
  void
>(USER_ACTION_TYPE.GET_PROFILE, async (_, { rejectWithValue }) => {
  try {
    const res = await services.userProfileServive()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchEmployeeList = createAsyncThunk<
  services.EmployeeListResponse,
  services.EmployeeListParams
>(USER_ACTION_TYPE.GET_EMPLOYEE_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchEmployeePublicList = createAsyncThunk<
  services.EmployeeShortItemListResponse,
  services.EmployeeListParams
>(
  USER_ACTION_TYPE.GET_EMPLOYEE_PUBLIC_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.employeeShortItemListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchEmployeeAll = createAsyncThunk<
  services.EmployeeAllResponse,
  void
>(USER_ACTION_TYPE.GET_EMPLOYEE_ALL, async (_, { rejectWithValue }) => {
  try {
    const res = await services.employeeAllService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchEmployeeActivePlusQuitList = createAsyncThunk<
  services.EmployeeAllResponse,
  void
>(
  USER_ACTION_TYPE.GET_EMPLOYEE_ACTIVE_PLUS_QUIT,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.employeeActivePlusQuitService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchPrivateEmployeeList = createAsyncThunk<
  services.EmployeeListResponse,
  services.EmployeeListParams
>(
  USER_ACTION_TYPE.GET_PRIVATE_EMPLOYEE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.employeeListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.EmployeeAddParams
>(USER_ACTION_TYPE.CREATE_EMPLOYEE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeAddService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.EmployeeDeleteParams
>(USER_ACTION_TYPE.DELETE_EMPLOYEE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.EmployeeUpdateParams
>(USER_ACTION_TYPE.UPDATE_EMPLOYEE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const showEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.EmployeeDetailParams
>(USER_ACTION_TYPE.SHOW_EMPLOYEE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeDetailService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response)
  }
})

export const showPublicEmployee = createAsyncThunk<
  services.EmployeeShortItemResponse,
  services.EmployeeDetailParams
>(
  USER_ACTION_TYPE.SHOW_PUBLIC_EMPLOYEE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.employeeShortItemDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response)
    }
  }
)

export const quitEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.EmployeeQuitParams
>(USER_ACTION_TYPE.QUIT_EMPLOYEE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeQuitServive(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const backToWorkEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.EmployeeBackToWorkParams
>(
  USER_ACTION_TYPE.BACK_TO_WORK_EMPLOYEE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.employeeBackToWorkServive(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const backToWorkFromLongTermEmployee = createAsyncThunk<
  services.EmployeeResponse,
  services.BackToWorkFromLongTermLeaveParams
>(
  USER_ACTION_TYPE.BACK_TO_WORK_FROM_LONG_TERM,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.backToWorkFromLongTermServive(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateProfile = createAsyncThunk<
  services.UserProfileResponse,
  services.UserProfileParams
>(USER_ACTION_TYPE.UPDATE_PROFILE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userUpdateProfileServive(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchEmployeeDepartmentList =
  createAsyncThunk<services.EmployeeDepartmentListResponse>(
    USER_ACTION_TYPE.GET_EMPLOYEE_DEPARTMENTS,
    async (_, { rejectWithValue }) => {
      try {
        const res = await services.employeeDepartmentListService()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return rejectWithValue(error.response.data)
      }
    }
  )

export const fetchEmployeePositionList =
  createAsyncThunk<services.EmployeePositionListResponse>(
    USER_ACTION_TYPE.GET_EMPLOYEE_POSIITONS,
    async (_, { rejectWithValue }) => {
      try {
        const res = await services.employeePositionListService()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return rejectWithValue(error.response.data)
      }
    }
  )

export const fetchEmployeeProjectList = createAsyncThunk<
  services.EmployeeProjectListResponse,
  services.EmployeeProjectListParams
>(
  USER_ACTION_TYPE.GET_EMPLOYEE_PROJECTS,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.employeeProjectListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const setAvatar = createAsyncThunk<
  services.UserAvatarResponse,
  services.UserAvatarParam
>(USER_ACTION_TYPE.UPDATE_AVATAR, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userAvatarService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const clearEmployee = createAction('employee/clear')

export const fetchQuitUsersReason = createAsyncThunk<
  services.QuitReasonResponse,
  services.QuitReasonParam
>(USER_ACTION_TYPE.QUIT_REASON, async (params, { rejectWithValue }) => {
  try {
    const res = await services.quitReasonService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchUserInfoLog = createAsyncThunk<
  services.UserInfoLogResponse,
  services.UserInfoLogParams
>(USER_ACTION_TYPE.INFO_LOG, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userInfoLogService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateQuitReason = createAsyncThunk<
  services.ReasonUpdateResponse,
  services.ReasonUpdateParams
>(USER_ACTION_TYPE.UPDATE_QUIT_REASON, async (params, { rejectWithValue }) => {
  try {
    const res = await services.reasonUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const resetPassword = createAsyncThunk<
  services.EmployeeResetPasswordResponse,
  services.EmployeeResetPasswordParams
>(USER_ACTION_TYPE.RESET_PASSWORD, async (params, { rejectWithValue }) => {
  try {
    const res = await services.employeeResetPasswordService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
