import { FilterParams, MetaPage } from '@constants/common.constants'
import { TimeOffListFilter } from '@constants/timeoff.constants'
import {
  CompensatoryDayItem,
  TimeOffApproveParams,
  TimeOffDenyParams,
  TimeOffRequestCreateParams,
  TimeOffRequestIdParams,
  TimeOffRequestItem,
  TimeOffApproveShortItem,
  UserCompensatoryItem,
  UserCompensatoryDaysUpdateParams,
} from '@services/timeoff.services'

import { useAppDispatch } from '@store/hooks'
import { clearMetaData } from '@store/metadata/actions'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'

import {
  approveTimeOffRequest,
  createTimeOffRequest,
  deleteTimeOffRequest,
  deleteTimeOffRequestByApprover,
  denyTimeOffRequest,
  fetchCompensatoryDayList,
  fetchTimeOffApproveList,
  fetchTimeOffRequestList,
  fetchUserCompensatoryList,
  getTimeOffRequestDetail,
  updateUserCompensatoryDays,
} from '@store/timeoff/actions'
import { TIME_OFF_ACTION_TYPE } from '@store/timeoff/actions/types'
import {
  getCompensatoryBalance,
  getCompensatoryDays,
  getCompensatoryEarnedDays,
  getTimeOffApproveList,
  getTimeOffApproveListMeta,
  getTimeOffRequest,
  getTimeOffRequestList,
  getTimeOffRequestListMeta,
  getUserCompensatoryDays,
  getUserCompensatoryDaysMeta,
} from '@store/timeoff/selectors'

import { useSelector } from 'react-redux'

interface TimeOffRequest {
  paging: MetaPage
  approveListPaging: MetaPage
  fetch: (params: TimeOffListFilter) => any
  fetchApproveList: (params: TimeOffListFilter) => any
  fetchCompensatoryDays: () => any
  create: (params: TimeOffRequestCreateParams) => any
  show: (params: TimeOffRequestIdParams) => any
  //   update: (params: TimeOffRequestUpdateParams) => any
  deleteItem: (params: TimeOffRequestIdParams) => any
  deleteByApprover: (params: TimeOffRequestIdParams) => any
  approve: (params: TimeOffApproveParams) => any
  deny: (params: TimeOffDenyParams) => any
  timeOffRequestList: TimeOffRequestItem[]
  timeOffApproveList: TimeOffApproveShortItem[]
  compensatoryDays: CompensatoryDayItem[]
  compensatoryBalance: number
  compensatoryEarnedDays: number
  detail: TimeOffRequestItem
  meta: Meta
  createMeta: Meta
  detailMeta: Meta
  //   updateMeta: Meta
  deleteMeta: Meta
  deleteByApproverMeta: Meta
  approveListMeta: Meta
  approveMeta: Meta
  denyMeta: Meta
  compensatoryDaysMeta: Meta
  clearMeta: () => void
  clearCreateMeta: () => void
  clearDetailMeta: () => void
  //   clearUpdateMeta: () => void
  clearDeleteMeta: () => void
  fetchUserCompensatoryDays: (params: FilterParams) => any
  userCompensatoryList: UserCompensatoryItem[]
  userCompensatoryListPaging: MetaPage
  userCompensatoryListMeta: Meta
  updateUserCompensatory: (params: UserCompensatoryDaysUpdateParams) => any
  updateCompensatoryDaysMeta: Meta
}

const useTimeOffRequest = (): TimeOffRequest => {
  const dispatch = useAppDispatch()
  const fetch = (params: TimeOffListFilter) =>
    dispatch(fetchTimeOffRequestList(params))
  const fetchApproveList = (params: TimeOffListFilter) =>
    dispatch(fetchTimeOffApproveList(params))
  const fetchCompensatoryDays = () => dispatch(fetchCompensatoryDayList())
  const create = (params: TimeOffRequestCreateParams) =>
    dispatch(createTimeOffRequest(params))
  //   const update = (params: TimeOffRequestUpdateParams) =>
  //     dispatch(updateTimeOffRequest(params))
  const show = (params: TimeOffRequestIdParams) =>
    dispatch(getTimeOffRequestDetail(params))
  const deleteItem = (params: TimeOffRequestIdParams) =>
    dispatch(deleteTimeOffRequest(params))
  const deleteByApprover = (params: TimeOffRequestIdParams) =>
    dispatch(deleteTimeOffRequestByApprover(params))
  const approve = (params: TimeOffApproveParams) =>
    dispatch(approveTimeOffRequest(params))
  const deny = (params: TimeOffDenyParams) =>
    dispatch(denyTimeOffRequest(params))
  const fetchUserCompensatoryDays = (params: FilterParams) =>
    dispatch(fetchUserCompensatoryList(params))
  const updateUserCompensatory = (params: UserCompensatoryDaysUpdateParams) =>
    dispatch(updateUserCompensatoryDays(params))

  const timeOffRequestList = useSelector(getTimeOffRequestList)
  const timeOffApproveList = useSelector(getTimeOffApproveList)
  const detail = useSelector(getTimeOffRequest)
  const paging = useSelector(getTimeOffRequestListMeta)
  const approveListPaging = useSelector(getTimeOffApproveListMeta)
  const compensatoryDays = useSelector(getCompensatoryDays)
  const compensatoryBalance = useSelector(getCompensatoryBalance)
  const compensatoryEarnedDays = useSelector(getCompensatoryEarnedDays)
  const userCompensatoryList = useSelector(getUserCompensatoryDays)
  const userCompensatoryListPaging = useSelector(getUserCompensatoryDaysMeta)

  const _meta = createMetaSelector(fetchTimeOffRequestList)
  const meta = useSelector(_meta)
  const clearMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_LIST))

  const _createMeta = createMetaSelector(createTimeOffRequest)
  const createMeta = useSelector(_createMeta)
  const clearCreateMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_CREATE))

  //   const _updateMeta = createMetaSelector(updateTimeOffRequest)
  //   const updateMeta = useSelector(_updateMeta)
  //   const clearUpdateMeta = () =>
  //     dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_UPDATE))

  const _detailMeta = createMetaSelector(getTimeOffRequestDetail)
  const detailMeta = useSelector(_detailMeta)
  const clearDetailMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_DETAIL))

  const _deleteMeta = createMetaSelector(deleteTimeOffRequest)
  const deleteMeta = useSelector(_deleteMeta)
  const clearDeleteMeta = () =>
    dispatch(clearMetaData(TIME_OFF_ACTION_TYPE.TIME_OFF_REQUEST_DELETE))

  const _deleteByApproverMeta = createMetaSelector(deleteTimeOffRequest)
  const deleteByApproverMeta = useSelector(_deleteByApproverMeta)

  const _approveListMeta = createMetaSelector(fetchTimeOffApproveList)
  const approveListMeta = useSelector(_approveListMeta)

  const _approveMeta = createMetaSelector(approveTimeOffRequest)
  const approveMeta = useSelector(_approveMeta)

  const _denyMeta = createMetaSelector(denyTimeOffRequest)
  const denyMeta = useSelector(_denyMeta)

  const _compensatoryDaysMeta = createMetaSelector(fetchCompensatoryDayList)
  const compensatoryDaysMeta = useSelector(_compensatoryDaysMeta)
  const _userCompensatoryListMeta = createMetaSelector(
    fetchUserCompensatoryList
  )
  const userCompensatoryListMeta = useSelector(_userCompensatoryListMeta)
  const _updateCompensatoryDaysMeta = createMetaSelector(
    updateUserCompensatoryDays
  )
  const updateCompensatoryDaysMeta = useSelector(_updateCompensatoryDaysMeta)

  return {
    timeOffRequestList,
    timeOffApproveList,
    compensatoryDays,
    compensatoryBalance,
    compensatoryEarnedDays,
    approveListPaging,
    paging,
    show,
    detail,
    fetch,
    fetchApproveList,
    fetchCompensatoryDays,
    deleteItem,
    deleteByApprover,
    // update,
    create,
    approve,
    deny,
    meta,
    createMeta,
    detailMeta,
    // updateMeta,
    deleteMeta,
    deleteByApproverMeta,
    approveListMeta,
    approveMeta,
    denyMeta,
    compensatoryDaysMeta,
    clearMeta,
    clearCreateMeta,
    clearDetailMeta,
    // clearUpdateMeta,
    clearDeleteMeta,
    fetchUserCompensatoryDays,
    userCompensatoryList,
    userCompensatoryListMeta,
    userCompensatoryListPaging,
    updateUserCompensatory,
    updateCompensatoryDaysMeta,
  }
}

export default useTimeOffRequest
