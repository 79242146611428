import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/dailyreport.services'
import { DAILY_REPORT_ACTION_TYPE } from './types'

export const fetchMyDailyReport = createAsyncThunk<
  services.MyDailyReportAllResponse,
  services.DailyReportFilterParams
>(
  DAILY_REPORT_ACTION_TYPE.MY_DAILY_REPORT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.myDailyReportService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTeamDailyReport = createAsyncThunk<
  services.TeamDailyReportAllResponse,
  services.DailyReportFilterParams
>(
  DAILY_REPORT_ACTION_TYPE.TEAM_DAILY_REPORT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.teamDailyReportService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchHrDailyReport = createAsyncThunk<
  services.TeamDailyReportAllResponse,
  services.DailyReportFilterParams
>(
  DAILY_REPORT_ACTION_TYPE.HR_DAILY_REPORT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.hrDailyReportService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createDailyReport = createAsyncThunk<
  services.DailyReportDetailResponse,
  services.DailyReportCreateParams
>(
  DAILY_REPORT_ACTION_TYPE.DAILY_REPORT_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.dailyReportCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateDailyReport = createAsyncThunk<
  services.DailyReportDetailResponse,
  services.DailyReportUpdateParams
>(
  DAILY_REPORT_ACTION_TYPE.DAILY_REPORT_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.dailyReportUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteDailyReport = createAsyncThunk<any, string>(
  DAILY_REPORT_ACTION_TYPE.DAILY_REPORT_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.dailyReportDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const clearReports = createAction(
  DAILY_REPORT_ACTION_TYPE.DAILY_REPORT_CLEAR
)

export const fetchTagList = createAsyncThunk<services.TagsResponse>(
  DAILY_REPORT_ACTION_TYPE.TAGS,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.fetchTagsService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
