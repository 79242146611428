import { useState, useCallback, Fragment, PropsWithChildren } from 'react'
import UploaderContext from './DialogContext'
import { DialogOptions } from './types'
import ListDialog from './ListDialog'
import { MissingReportItem } from '@services/common.services'
import i18n from '@locales/i18n'

const DEFAULT_OPTIONS = (): DialogOptions => {
  const options = {
    title: i18n.t('DAILY_REPORT.missing_title'),
    description: '',
    content: null,
    confirmationText: i18n.t('DAILY_REPORT.write'),
    dialogProps: {},
    backDropClose: false,
  }
  return options
}
const buildOptions = (defaultOptions: any, options: any): DialogOptions => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS().dialogProps),
    ...(options.dialogProps || {}),
  }
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS().confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  }
  return {
    ...DEFAULT_OPTIONS(),
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
  }
}

interface Props extends PropsWithChildren {
  defaultOptions: any
}

const ReportProvider = ({ children, defaultOptions = {} }: Props) => {
  const [options, setOptions] = useState<DialogOptions>({
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
  })

  const [resolveReject, setResolveReject] = useState<any>([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback((options: DialogOptions): Promise<void> => {
    return new Promise((resolve, reject) => {
      setOptions(buildOptions(DEFAULT_OPTIONS, options))
      setResolveReject([resolve, reject])
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
      handleClose()
    }
  }, [reject, handleClose])

  const handleConfirm = useCallback(
    (item: MissingReportItem) => {
      if (resolve) {
        resolve(item)
        handleClose()
      }
    },
    [resolve, handleClose]
  )

  return (
    <Fragment>
      <UploaderContext.Provider value={confirm}>
        {children}
      </UploaderContext.Provider>
      <ListDialog
        open={resolveReject.length === 2}
        options={options}
        onClose={() => {
          handleClose()
        }}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  )
}

export default ReportProvider
