import {
  Box,
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  OutlinedInput,
  SxProps,
  Typography,
} from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { red } from '@mui/material/colors'
import _ from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomLabel } from '../StyledTextField'

const names: SelectOption[] = [
  { id: 1, name: 'Oliver Hansen' },
  { id: 2, name: 'Van Henry' },
  { id: 3, name: 'April Tucker' },
  { id: 4, name: 'Ralph Hubbard' },
]

export type SelectOption = {
  id: number
  name: string
}

interface StyledSelectProps {
  options?: SelectOption[]
  error?: boolean
  formProps?: FormControlProps
  onChange: (data: SelectChangeEvent<string | number>) => void
  value: string | number
  labelPrimary?: string
  labelSecondary?: string
  disabled?: boolean
  readOnly?: boolean
  sx?: SxProps
  isHelperShow?: boolean
  helperText?: string
  required?: boolean
  multiple?: boolean
}

const StyledSelect: React.FC<StyledSelectProps> = ({
  options = names,
  error,
  value,
  labelPrimary,
  labelSecondary,
  formProps,
  disabled = false,
  onChange,
  readOnly = false,
  multiple = false,
  sx,
  helperText,
  required,
  isHelperShow = true,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <FormControl sx={{ width: '100%' }} {...formProps}>
      {(labelPrimary || labelSecondary) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: 0 }}
        >
          <Box sx={{ padding: 0 }} display="flex" alignItems="center">
            <CustomLabel text={labelPrimary ? labelPrimary : ''} />
            {required && (
              <Typography
                component="span"
                sx={{
                  paddingLeft: (theme) => theme.spacing(1 / 2),
                  paddingRight: (theme) => theme.spacing(1 / 2),
                  height: 16,
                  fontSize: 10,
                  marginLeft: '5px',
                  color: red[400],
                }}
              >
                *
              </Typography>
            )}
          </Box>
          {labelSecondary}
        </Box>
      )}
      <Select
        readOnly={readOnly}
        value={value}
        renderValue={(selected: any) => {
          if (multiple) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip
                    sx={{
                      height: '20px',
                    }}
                    key={value}
                    label={t(_.find(options, (o) => o.id === value)?.name)}
                  />
                ))}
              </Box>
            )
          } else {
            return t(_.find(options, (o) => o.id === selected)?.name)
          }
        }}
        sx={{ ...sx }}
        className={readOnly ? 'r-only' : ''}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
        input={<OutlinedInput margin="dense" {...rest} />}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <ListItemText primary={t(item.name)} />
          </MenuItem>
        ))}
      </Select>
      {isHelperShow &&
        (helperText ? (
          <FormHelperText
            sx={{
              height: 12,
              m: 0,
            }}
            error={error}
          >
            {helperText}
          </FormHelperText>
        ) : (
          <Box sx={{ height: 12 }} />
        ))}
    </FormControl>
  )
}

export default StyledSelect
