import { StyledTextField } from '@components/@material-extend'
import CustomDatePicker from '@components/@material-extend/CustomDatePicker'
import CustomDateTimePicker from '@components/@material-extend/CustomDateTimePicker'
import AutoCompleteSelect from '@components/@material-extend/SelectWithSearch/AutoCompleteSelect'
import CustomListItem from '@components/@modules/Project/CustomSelectItem'
import { AntSwitch } from '@components/AntSwitch'
import AttachmentInfo from '@components/AttachmentInfo'
import { useConfirm } from '@components/Confirm'
import DataLoading from '@components/DataLoading'
import { useUpload } from '@components/FileUpload'
import { UploadType } from '@components/FileUpload/types'
import FormDialog from '@components/FullScreenForm/FormDialog'
import HtmlParser from '@components/HtmlParser'
import {
  TIMEOFF_MINIMUM_MINUTE,
  TIME_OFF_IDENTIFIER,
  TIME_OFF_TEMPLATE_PAY_STATUS_LABELS,
  TIME_OFF_TEMPLATE_RANGE_TYPE,
  TimeOffTemplateRangeTypes,
} from '@constants/timeoff.constants'
import { URI } from '@constants/uri.constants'
import useEvents from '@containers/@modules/Event/useEvents'
import useTimeOffRequest from '@containers/@modules/TimeOffRequest/useTimeOffRequest'
import useTimeOffRequestCreateForm, {
  initialValuesTimeOff,
} from '@containers/@modules/TimeOffRequest/useTimeOffRequestCreateForm'
import useTimeOffTemplate from '@containers/@modules/TimeOffTemplate/useTimeOffTemplate'
import useEmployee from '@containers/@modules/User/useEmployee'
import useVacationBalance from '@containers/@modules/Vacation/useVacationBalance'
import { Close } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { getPresignedUrlResponse } from '@services/common.services'
import {
  CompensatoryDayItem,
  TimeOffRequestResponse,
} from '@services/timeoff.services'
import { EmployeeItem } from '@services/user.services'
import theme from '@theme/index'
import { getFullNameEn } from '@utils/helper/common.helper'
import {
  checkDuration,
  filterApprovers,
  getDayDuration,
  getDuration,
  getStepPositionNames,
  isStepManual,
  shouldDisableDay,
} from '@utils/helper/timeoff.helper'
import useAuth from '@utils/hooks/useAuth'
import useServerFieldErrors from '@utils/hooks/useServerFieldError'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { FieldValues, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CompDayItem from './CompDayItem'
import useSystemValues from '@containers/@modules/SystemValues'
import useInit from '@utils/hooks/useInit'
import {
  getPossibleMyTimeHelper,
  isTodayHelper,
} from '@utils/helper/time.helper'

type CreateTimeOffRequestFormProps = {
  open: boolean
  onClose: () => void
  onDone: (res: TimeOffRequestResponse) => void
  onFailed: (res: any) => void
}

const TimeOffDialog = ({
  open,
  onClose,
  onDone,
}: // onFailed,
CreateTimeOffRequestFormProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const confirm = useConfirm()
  const upload = useUpload()

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [attachmentUploaded, setAttachmentUploaded] =
    useState<getPresignedUrlResponse | null>(null)
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeItem[]>([])
  const [selectedCompDays, setSelectedCompDays] = useState<string[]>([])
  const [positionNames, setPositionNames] = useState<string>('')
  const [useCompDayAlert, setUseCompDayAlert] = useState<boolean>(false)
  const [hasDurationError, setHasDurationError] = useState<boolean>(false)
  const { initData } = useInit()
  const {
    fetchMyData,
    myData,
    myDataMeta,
    myTotalBalance: vacationBalance,
  } = useVacationBalance()
  const { data: publicEvents, fetch: fetchHolidays } = useEvents()
  const {
    createMeta,
    clearCreateMeta,
    create,
    fetchCompensatoryDays,
    compensatoryDays,
    compensatoryBalance,
    compensatoryEarnedDays,
    compensatoryDaysMeta,
  } = useTimeOffRequest()

  const { user } = useAuth()

  const {
    fetchAll: fetchTemplates,
    meta: templateListMeta,
    allTimeOffTemplates: templateList,
  } = useTimeOffTemplate()

  const {
    fetchAll: fetchEmployees,
    employeeAll: employees,
    allEmployeeMeta: employeesMeta,
  } = useEmployee()
  const { lunchStart, lunchEnd } = useSystemValues()

  const { Controller, methods } = useTimeOffRequestCreateForm(publicEvents)

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    trigger,
    register,
    formState: { errors, isValid, isDirty },
  } = methods

  useServerFieldErrors(createMeta, setError)

  // Request Form Values
  const selectedTemplate = useWatch({
    control,
    name: 'timeoff_template_id',
  }) as any

  const startAt = useWatch({
    control,
    name: 'start_at',
  }) as any

  const endAt = useWatch({
    control,
    name: 'end_at',
  }) as any

  const isBreakMorning = useWatch({
    control,
    name: 'is_break_morning',
  }) as any

  useEffect(() => {
    if (!_.isNull(selectedTemplate)) {
      trigger('start_at')
      trigger('end_at')
    }
  }, [selectedTemplate])

  // Template infos
  const templateRangeType = _.get(selectedTemplate, 'attributes.range_type', '')
  const identifier = _.get(selectedTemplate, 'attributes.identifier', '')
  const showApproverOption =
    selectedTemplate && isStepManual(1, selectedTemplate)
  // Duration beetween selected days with disabled days are excluded
  const duration = getDayDuration(
    startAt,
    endAt,
    identifier,
    templateRangeType,
    publicEvents
  )

  // UseEffects start
  useEffect(() => {
    if (open) {
      fetchEmployees()
      fetchTemplates()
      fetchMyData()
      fetchHolidays({ holiday_flag: true })
      fetchCompensatoryDays()
    }

    reset({
      ...initialValuesTimeOff,
      start_at: dayjs(new Date()),
      end_at: dayjs(new Date()),
    })

    setOpenDrawer(open && isMobile)
  }, [open])

  useEffect(() => {
    setOpenDrawer(open && isMobile)
  }, [isMobile])

  useEffect(() => {
    return () => {
      clearCreateMeta()
    }
  }, [])

  useEffect(() => {
    if (!selectedTemplate) return

    setAttachmentUploaded(null)

    setValue('is_manual', showApproverOption)

    // filter approvers and get approvable position names
    if (showApproverOption) {
      setFilteredEmployees(
        filterApprovers(1, selectedTemplate, employees, user.id.toString())
      )
      setPositionNames(getStepPositionNames(1, selectedTemplate))
    }

    // If the template is time range,
    // set END value to 30mins later than now
    if (templateRangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME) {
      if (identifier === TIME_OFF_IDENTIFIER.MY_TIME) {
        setValue(
          'start_at',
          dayjs(new Date()).add(5, 'minute').startOf('minute')
        )
        setValue(
          'end_at',
          dayjs(new Date())
            .add(TIMEOFF_MINIMUM_MINUTE + 5, 'minute')
            .startOf('minute')
        )
      } else {
        setValue('start_at', dayjs(new Date()))
        setValue(
          'end_at',
          dayjs(new Date()).add(TIMEOFF_MINIMUM_MINUTE, 'minute')
        )
      }
    }

    // Check if the user should use their compensatory balance first
    setUseCompDayAlert(
      identifier === TIME_OFF_IDENTIFIER.OTHER && compensatoryBalance !== 0
    )

    setHasDurationError(
      checkDuration(
        duration,
        selectedTemplate,
        selectedCompDayRemainder(),
        vacationBalance
      )
    )
  }, [selectedTemplate])

  //calculate possible mytime
  const calculateTimeDifference = (startMax: number, startTime: number) => {
    const timeDifference = startMax - startTime - ((startMax - startTime) % 5)
    const hours = Math.floor(timeDifference / 60)
    const minutes = timeDifference % 60

    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    return `${formattedHours}:${formattedMinutes}:00`
  }

  //myTime calculate function
  const getPossibleMyTime = () => {
    const companySettings = initData.attributes.company_settings.data

    const findSettingValue = (settingName: string) =>
      companySettings.find((setting) => setting.attributes.name === settingName)
        ?.attributes.value

    const workStartMin = findSettingValue(TIME_OFF_IDENTIFIER.WORK_START_MIN)
    const workStartMax = findSettingValue(TIME_OFF_IDENTIFIER.WORK_START_MAX)
    const maxMyTimeDuration = findSettingValue(
      TIME_OFF_IDENTIFIER.MAX_MY_TIME_DURATION
    )
    const workStartTime =
      initData.attributes.time_records?.data?.attributes?.work_date

    const startMin = getPossibleMyTimeHelper(workStartMin)
    const startMax = getPossibleMyTimeHelper(workStartMax)
    const startTime = getPossibleMyTimeHelper(new Date(workStartTime))

    const isToday = isTodayHelper(startAt)

    if ((startTime < startMin || !isToday) && !isBreakMorning) {
      return maxMyTimeDuration
    }

    if (startTime > startMax || isBreakMorning) {
      return isToday && isBreakMorning && startTime
        ? t('REMOTE_WORK_REQUEST.started_work')
        : TIME_OFF_IDENTIFIER.BREAK_MORNING_TIME
    }

    if (startTime < startMax) {
      return calculateTimeDifference(startMax + 30, startTime)
    }

    if (!startTime && isToday) {
      return t('REMOTE_WORK_REQUEST.start_work')
    }

    return null
  }

  useEffect(() => {
    if (
      templateRangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_AM ||
      templateRangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_PM ||
      (identifier === TIME_OFF_IDENTIFIER.MY_TIME && isBreakMorning)
    ) {
      setValue('end_at', startAt)
    }

    // Check if the selected duration is not over the compensatory balance or vacation balance
    setHasDurationError(
      checkDuration(
        duration,
        selectedTemplate,
        selectedCompDayRemainder(),
        vacationBalance
      )
    )
  }, [startAt])

  useEffect(() => {
    // trigger error message
    if (endAt) {
      trigger('start_at')
      trigger('end_at')
    }

    setHasDurationError(
      checkDuration(
        duration,
        selectedTemplate,
        selectedCompDayRemainder(),
        vacationBalance
      )
    )
  }, [endAt])

  // after the user chooses isBreakMorning
  // the start_at and end_at must be on the same day
  useEffect(() => {
    if (isBreakMorning) {
      setValue('end_at', startAt)
    } else {
      setValue('start_at', dayjs(new Date()))
      setValue(
        'end_at',
        dayjs(new Date()).add(TIMEOFF_MINIMUM_MINUTE, 'minute')
      )
    }
  }, [isBreakMorning])

  // Set uploaded attachment key to form
  useEffect(() => {
    register('attachment_url')
    if (attachmentUploaded) {
      setValue('attachment_url', attachmentUploaded.key)
      trigger()
    } else {
      setValue('attachment_url', '')
    }
  }, [attachmentUploaded])
  // UseEffects end

  const onSubmit = (values: any) => {
    confirm({
      title: t('TIMEOFF_REQUEST.save_confirm_title'),
      description: t('TIMEOFF_REQUEST.save_confirm_desc'),
    })
      .then(() => {
        const params = {
          timeoff_template_id: values.timeoff_template_id?.id,
          approver_id: values.approver_id?.id,
          is_break_morning:
            identifier === TIME_OFF_IDENTIFIER.MY_TIME
              ? values.is_break_morning
              : null,
          start_at: values.start_at,
          end_at: values.end_at,
          attachment_url: _.get(
            selectedTemplate,
            'attributes.is_attachment',
            undefined
          )
            ? values.attachment_url
            : null,
          reason: _.get(
            selectedTemplate,
            'attributes.is_reason_allow',
            undefined
          )
            ? values.reason
              ? values.reason
              : null
            : null,
          compensatory_day_ids:
            identifier === TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST
              ? selectedCompDays
              : null,
        }
        create(params)
          .unwrap()
          .then((res: TimeOffRequestResponse) => {
            setAttachmentUploaded(null)
            onDone(res)
          })
          .catch(() => null)
      })
      .catch(() => null)
  }

  const handleCancel = () => {
    if (isDirty) {
      confirm({
        title: t('SYSCOMMON.back_confirm_title'),
        description: t('SYSCOMMON.back_confirm_desc'),
      })
        .then(() => {
          setAttachmentUploaded(null)
          setOpenDrawer(false)
          onClose()
        })
        .catch(() => null)
    } else {
      setAttachmentUploaded(null)
      setOpenDrawer(false)
      onClose()
    }
  }

  const renderTemplateList = () => {
    return (
      <Box sx={{ p: 0, minHeight: 100, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            zIndex: 100,
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}
        >
          <DataLoading
            isLoading={
              templateListMeta.pending ||
              myDataMeta.pending ||
              compensatoryDaysMeta.pending
            }
            isEmptyData={
              !templateListMeta.pending &&
              _.isEmpty(templateList) &&
              _.isArray(templateList)
            }
          />
        </Box>

        {templateList && !_.isEmpty(templateList) && (
          <List
            aria-label="timeoff-template-list"
            sx={{
              '.MuiListItemButton-root:nth-last-of-type(1)': {
                borderBottom: 'none',
              },
            }}
          >
            {templateList.map((template) => (
              <ListItemButton
                key={template.id}
                onClick={() => {
                  setValue('timeoff_template_id', template as any)
                }}
                sx={{ borderBottom: '1px solid #dedede' }}
              >
                <Stack>
                  <Typography
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                  >
                    {_.get(template, 'attributes.name', '')}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      display: 'block',
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-line',
                    }}
                    variant="body2"
                  >
                    <HtmlParser
                      content={_.get(template, 'attributes.description', '')}
                    />
                  </Typography>
                </Stack>
              </ListItemButton>
            ))}
          </List>
        )}
      </Box>
    )
  }

  const renderTimePicker = () => {
    return (
      <Stack direction={'row'} spacing={2}>
        <Controller
          name="start_at"
          control={control}
          render={({ field: { value, ref, ...rest } }: FieldValues) => {
            return (
              <CustomDateTimePicker
                required
                value={value}
                shouldDisableDate={(day) =>
                  shouldDisableDay(day, identifier, publicEvents)
                }
                labelPrimary={t('TIMEOFF_REQUEST.start_at')}
                error={errors.start_at ? true : false}
                inputRef={ref}
                format={'YYYY-MM-DD hh:mm A'}
                helperText={errors.start_at ? errors.start_at.message : ''}
                {...rest}
              />
            )
          }}
        />
        <Controller
          name="end_at"
          control={control}
          render={({ field: { ref, value, ...rest } }: FieldValues) => {
            return (
              <CustomDateTimePicker
                value={value}
                shouldDisableDate={(day) =>
                  shouldDisableDay(day, identifier, publicEvents)
                }
                labelPrimary={t('TIMEOFF_REQUEST.end_at')}
                error={errors.end_at ? true : false}
                inputRef={ref}
                format="YYYY-MM-DD hh:mm A"
                helperText={errors.end_at ? errors.end_at.message : ''}
                {...rest}
              />
            )
          }}
        />
      </Stack>
    )
  }

  const renderDatePicker = (disableEndAt: boolean) => {
    return (
      <Stack direction={'row'} spacing={2}>
        <Controller
          name="start_at"
          control={control}
          render={({ field: { value, ref, ...rest } }: FieldValues) => {
            return (
              <CustomDatePicker
                required
                inputRef={ref}
                fullWidth
                value={value}
                shouldDisableDate={(day: any) =>
                  shouldDisableDay(day, identifier, publicEvents)
                }
                labelPrimary={t('TIMEOFF_REQUEST.start_at')}
                error={errors.start_at ? true : false}
                helperText={errors.start_at ? errors.start_at.message : ''}
                {...rest}
              />
            )
          }}
        />
        <Controller
          name="end_at"
          control={control}
          render={({ field: { ref, value, ...rest } }: FieldValues) => {
            return (
              <CustomDatePicker
                inputRef={ref}
                fullWidth
                readOnly={disableEndAt}
                value={value}
                shouldDisableDate={(day: any) =>
                  shouldDisableDay(day, identifier, publicEvents)
                }
                labelPrimary={t('TIMEOFF_REQUEST.end_at')}
                error={errors.end_at ? true : false}
                helperText={errors.end_at ? errors.end_at.message : ''}
                {...rest}
              />
            )
          }}
        />
      </Stack>
    )
  }

  const handleUpload = () => {
    upload({
      title: t('UPLOAD.file'),
      upload_uri: URI.UPLOAD_IMAGE,
      type: UploadType.TIMEOFF,
      accept: {
        'image/jpeg': ['.jpeg', '.png', '.webp'],
        'application/pdf': ['.pdf'],
      },
    })
      .then((res: getPresignedUrlResponse) => {
        setAttachmentUploaded(res)
      })
      .catch(() => null)
  }

  const handleCompDayClick = (compDayId: string) => {
    const updatedValues = _.includes(selectedCompDays, compDayId)
      ? _.without(selectedCompDays, compDayId)
      : [...selectedCompDays, compDayId]

    setSelectedCompDays(updatedValues)
  }

  useEffect(() => {
    setHasDurationError(
      checkDuration(
        duration,
        selectedTemplate,
        selectedCompDayRemainder(),
        vacationBalance
      )
    )
  }, [selectedCompDays])

  const selectedCompDayRemainder = (): number => {
    return (
      compensatoryDays &&
      compensatoryDays
        .filter((item: CompensatoryDayItem) =>
          selectedCompDays.includes(item.id)
        )
        .reduce((sum, item) => sum + item.attributes.remaining_day, 0)
    )
  }

  return (
    <>
      {/* MOBILE: TimeOff Template list */}
      {!selectedTemplate && openDrawer && (
        <React.Fragment>
          <SwipeableDrawer
            anchor="bottom"
            open={openDrawer}
            onClose={handleCancel}
            onOpen={() => {
              setOpenDrawer(true)
            }}
            sx={{
              '.MuiDrawer-paper': {
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem',
              },
            }}
          >
            <Box>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography fontSize={18} px={2} py={1}>
                  {t('TIMEOFF_REQUEST.choose_template')}
                </Typography>
                <IconButton onClick={handleCancel}>
                  <ClearIcon />
                </IconButton>
              </Stack>
              <Divider />
              {renderTemplateList()}
            </Box>
          </SwipeableDrawer>
        </React.Fragment>
      )}
      {/* DESKTOP: TimeOff Template list */}
      {!selectedTemplate && !isMobile && (
        <Dialog open={open && !selectedTemplate} onClose={handleCancel}>
          <DialogTitle sx={{ p: 1, pl: 2, m: 0 }}>
            <Typography fontSize={18} sx={{ m: 0, p: 0, position: 'relative' }}>
              {t('TIMEOFF_REQUEST.choose_template')}
            </Typography>
            <IconButton
              sx={{ position: 'absolute', right: 10, top: 2 }}
              aria-label="close"
              onClick={handleCancel}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent
            className="scrollbar-container"
            sx={{
              height: '100%',
              overflowX: 'auto',
              p: 0,
              maxWidth: '500px',
              minWidth: '500px',
            }}
          >
            {renderTemplateList()}
          </DialogContent>
        </Dialog>
      )}
      {/* TimeOff Request Form */}
      {selectedTemplate && (
        <FormDialog
          open={!!selectedTemplate}
          onClose={handleCancel}
          options={{
            title: _.get(selectedTemplate, 'attributes.name', ''),
            content: (
              <form
                id="create-timeoff-request"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
              >
                <Box>
                  <Typography
                    component="div"
                    sx={{
                      display: 'block',
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-line',
                      mb: 2,
                    }}
                    variant="body2"
                  >
                    <HtmlParser
                      content={_.get(
                        selectedTemplate,
                        'attributes.description',
                        ''
                      )}
                    />
                  </Typography>
                  {/* Template Infos */}
                  <Alert
                    severity="info"
                    sx={{ '.MuiAlert-message': { width: '100%' }, mb: 2 }}
                  >
                    <Grid container rowSpacing={1.5}>
                      <Grid item md={6}>
                        <Stack direction={'row'}>
                          <Typography fontWeight={'600'}>
                            {t('TIMEOFF_REQUEST.pay_status')}&nbsp;
                          </Typography>
                          <Typography>
                            {t(
                              TIME_OFF_TEMPLATE_PAY_STATUS_LABELS[
                                _.get(selectedTemplate, 'attributes.pay_status')
                              ]?.label
                            )}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                          <Typography fontWeight={'600'}>
                            {t('TIMEOFF_REQUEST.range_type')}&nbsp;
                          </Typography>
                          <Typography>
                            {t(
                              _.find(
                                TimeOffTemplateRangeTypes,
                                (type) => type.id === templateRangeType
                              )?.name
                            )}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                          <Typography fontWeight={'600'}>
                            {t('TIMEOFF_REQUEST.is_deduct_from_vacation')}&nbsp;
                          </Typography>
                          <Typography>
                            {_.get(
                              selectedTemplate,
                              'attributes.is_deduct_from_vacation'
                            )
                              ? t('SYSCOMMON.yes')
                              : t('SYSCOMMON.no')}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                          <Typography fontWeight={'600'}>
                            {t('TIMEOFF_TEMPLATE.duration_min')} : &nbsp;
                          </Typography>
                          <Typography>
                            {_.defaultTo(
                              _.get(
                                selectedTemplate,
                                'attributes.duration_min'
                              ),
                              '-'
                            )}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                          <Typography fontWeight={'600'}>
                            {t('TIMEOFF_TEMPLATE.duration_max')} : &nbsp;
                          </Typography>
                          <Typography>
                            {_.defaultTo(
                              _.get(
                                selectedTemplate,
                                'attributes.duration_max'
                              ),
                              '-'
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      {/* Vacation & compensatory balance infos */}
                      <Grid item md={6}>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={3}
                          sx={{ borderLeft: '1px solid' }}
                          px={2}
                        >
                          <Box>
                            <Typography fontWeight={'600'}>
                              {t('TIMEOFF_REQUEST.year')}
                            </Typography>
                            {myData &&
                              _.map(myData, (yearData, index) => {
                                return (
                                  <Typography key={`year-${index}`}>
                                    {_.get(yearData, 'attributes.year')}
                                  </Typography>
                                )
                              })}
                          </Box>
                          <Box>
                            <Typography fontWeight={'600'}>
                              {t('TIMEOFF_REQUEST.vacation_days')}
                            </Typography>
                            {myData &&
                              _.map(myData, (yearData: any, index: any) => {
                                return (
                                  <Typography key={`vacation-day-${index}`}>
                                    {_.get(yearData, 'attributes.days')}
                                  </Typography>
                                )
                              })}
                          </Box>
                          <Box>
                            <Typography fontWeight={'600'}>
                              {t('TIMEOFF_REQUEST.remainig_days')}
                            </Typography>
                            {myData &&
                              _.map(myData, (yearData, index) => {
                                return (
                                  <Typography
                                    color={'error'}
                                    key={`remaining-day-${index}`}
                                  >
                                    {_.get(
                                      yearData,
                                      'attributes.remaining_days',
                                      0
                                    ).toFixed(1)}
                                  </Typography>
                                )
                              })}
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Alert>
                  {identifier === TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST && (
                    <Alert
                      variant="outlined"
                      severity={compensatoryBalance === 0 ? 'warning' : 'info'}
                      sx={{
                        '.MuiAlert-message': { width: '100%' },
                        mb: 2,
                        border: 'dotted',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography fontWeight={500}>
                            {t('TIMEOFF_REQUEST.total_remaining_comp_balance')}
                            {compensatoryBalance}
                            {'/'}
                            {compensatoryEarnedDays}
                          </Typography>
                          <Typography variant="caption">
                            {t(
                              'TIMEOFF_REQUEST.total_remaining_comp_balance_desc'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography fontWeight={500}>
                            {t('TIMEOFF_REQUEST.selected_day_info')}
                            {duration}
                            {'/'}
                            {selectedCompDayRemainder()}
                          </Typography>
                          <Typography variant="caption">
                            {t('TIMEOFF_REQUEST.selected_day_info_desc')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Alert>
                  )}
                  {identifier === TIME_OFF_IDENTIFIER.MY_TIME && (
                    <Alert
                      variant="outlined"
                      severity={compensatoryBalance === 0 ? 'warning' : 'info'}
                      sx={{
                        '.MuiAlert-message': { width: '100%' },
                        mb: 2,
                        border: 'dotted',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography fontWeight={500}>
                            {t('REMOTE_WORK_REQUEST.possible_my_time')}
                          </Typography>
                          <Typography variant="caption">
                            {t('REMOTE_WORK_REQUEST.total_time')}:{' '}
                            {getPossibleMyTime()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Alert>
                  )}
                  <Box>
                    {useCompDayAlert && (
                      <Alert severity="error" sx={{ mb: 1 }}>
                        {t('TIMEOFF_REQUEST.use_compday_alert', {
                          day: compensatoryBalance ?? 0,
                        })}
                      </Alert>
                    )}
                    <Controller
                      name="timeoff_template_id"
                      control={control}
                      render={({ field: { value, onChange } }: FieldValues) => (
                        <AutoCompleteSelect
                          value={value}
                          disableClearable
                          disablePortal={false}
                          labelPrimary={t('TIMEOFF_REQUEST.template_type')}
                          fullWidth
                          loading={templateListMeta.pending}
                          data={templateList ?? []}
                          getOptionLabel={(option) =>
                            getFullNameEn(
                              undefined,
                              _.get(option, 'attributes.name', '')
                            ) || ''
                          }
                          renderOption={(props, option) => {
                            return (
                              <ListItem
                                component="li"
                                {...props}
                                key={option.id}
                              >
                                <ListItemText sx={{ display: 'block' }}>
                                  {_.get(option, 'attributes.name', '')}
                                </ListItemText>
                              </ListItem>
                            )
                          }}
                          onInputChange={onChange}
                          error={errors.timeoff_template_id ? true : false}
                          helperText={
                            errors.timeoff_template_id
                              ? errors.timeoff_template_id.message
                              : ''
                          }
                        />
                      )}
                    />
                  </Box>
                  {identifier === TIME_OFF_IDENTIFIER.MY_TIME && (
                    <Box my={1}>
                      <Controller
                        name="is_break_morning"
                        control={control}
                        render={({
                          field: { value, onChange },
                        }: FieldValues) => (
                          <FormControlLabel
                            label={t('TIMEOFF_REQUEST.is_break_morning')}
                            control={
                              <AntSwitch
                                sx={{ m: 1 }}
                                value={value}
                                onChange={onChange}
                              />
                            }
                          />
                        )}
                      />
                    </Box>
                  )}
                  {showApproverOption && (
                    <Box mt={1}>
                      <Typography fontWeight={'500'}>
                        {t('TIMEOFF_REQUEST.eligible_positions')}
                        {positionNames}
                      </Typography>
                      <Controller
                        name="approver_id"
                        control={control}
                        render={({
                          field: { value, onChange },
                        }: FieldValues) => (
                          <AutoCompleteSelect
                            value={value}
                            required
                            labelPrimary={t('TIMEOFF_REQUEST.approver')}
                            fullWidth
                            disablePortal={false}
                            loading={employeesMeta.pending}
                            data={filteredEmployees ?? []}
                            getOptionLabel={(option) =>
                              getFullNameEn(
                                undefined,
                                _.get(option, 'attributes.lastname_en', ''),
                                _.get(option, 'attributes.firstname_en', '')
                              ) || ''
                            }
                            renderOption={(props, option) => {
                              return (
                                <CustomListItem
                                  key={option?.id}
                                  option={option}
                                  {...props}
                                />
                              )
                            }}
                            onInputChange={onChange}
                            error={errors.approver_id ? true : false}
                            helperText={
                              errors.approver_id
                                ? errors.approver_id.message
                                : ''
                            }
                          />
                        )}
                      />
                    </Box>
                  )}
                  {/* Choose from compday */}
                  {identifier === TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST &&
                    compensatoryDays &&
                    !_.isEmpty(compensatoryDays) && (
                      <Stack spacing={1.5} mt={1} mb={2}>
                        <Typography fontWeight={500}>
                          {t('TIMEOFF_REQUEST.compday_list_label')}
                        </Typography>
                        <Grid container columnGap={2} rowGap={2}>
                          {_.map(
                            compensatoryDays,
                            (compDay: CompensatoryDayItem, index) => {
                              return (
                                <ButtonBase
                                  key={`compday-${index}`}
                                  disableRipple
                                  onClick={() => handleCompDayClick(compDay.id)}
                                >
                                  <CompDayItem
                                    compDay={compDay}
                                    selected={_.includes(
                                      selectedCompDays,
                                      compDay.id
                                    )}
                                  />
                                </ButtonBase>
                              )
                            }
                          )}
                        </Grid>
                      </Stack>
                    )}
                  {/* Start End */}
                  {identifier === TIME_OFF_IDENTIFIER.MY_TIME &&
                    (isBreakMorning
                      ? renderDatePicker(true)
                      : renderTimePicker())}
                  {identifier !== TIME_OFF_IDENTIFIER.MY_TIME &&
                    (templateRangeType === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME
                      ? renderTimePicker()
                      : renderDatePicker(
                          templateRangeType ===
                            TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_AM ||
                            templateRangeType ===
                              TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_PM
                        ))}
                  <Typography
                    fontWeight={'600'}
                    sx={{ mt: 1.5 }}
                    color={hasDurationError ? 'error' : 'inherit'}
                  >
                    {t('TIMEOFF_REQUEST.selected_duration')}{' '}
                    {getDuration(
                      startAt,
                      endAt,
                      identifier,
                      templateRangeType,
                      publicEvents,
                      lunchStart,
                      lunchEnd
                    )}{' '}
                    {hasDurationError &&
                      (identifier === TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST
                        ? t('TIMEOFF_REQUEST.not_enough_compday')
                        : t('TIMEOFF_REQUEST.not_enough_balance'))}
                  </Typography>
                  {/* Reason Input */}
                  {_.get(
                    selectedTemplate,
                    'attributes.is_reason_allow',
                    undefined
                  ) && (
                    <Box mt={1}>
                      <Controller
                        control={control}
                        name="reason"
                        render={({ field: { ref, ...rest } }: FieldValues) => (
                          <StyledTextField
                            inputRef={ref}
                            fullWidth
                            required={
                              !_.get(
                                selectedTemplate,
                                'attributes.is_deduct_from_vacation',
                                false
                              )
                            }
                            labelPrimary={t('TIMEOFF_REQUEST.reason')}
                            placeholder={t('TIMEOFF_REQUEST.reason')}
                            error={errors.reason ? true : false}
                            helperText={
                              errors.reason ? errors.reason.message : ''
                            }
                            {...rest}
                          />
                        )}
                      />
                    </Box>
                  )}
                  {/* Attachment input */}
                  {_.get(
                    selectedTemplate,
                    'attributes.is_attachment',
                    undefined
                  ) && (
                    <Box>
                      <Typography>{t('TIMEOFF_REQUEST.attachment')}</Typography>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                        mt={1}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleUpload()}
                        >
                          {t('UPLOAD.upload')}
                        </Button>
                        {attachmentUploaded && (
                          <AttachmentInfo
                            fileName={attachmentUploaded.file_name}
                            onClear={() => setAttachmentUploaded(null)}
                          />
                        )}
                      </Stack>
                    </Box>
                  )}
                  <Stack
                    spacing={2}
                    justifyContent={'space-between'}
                    direction={'row'}
                    sx={{ width: '100%' }}
                    mt={2}
                  >
                    <Button variant="outlined" onClick={handleCancel}>
                      {t('SYSCOMMON.cancel')}
                    </Button>
                    <LoadingButton
                      data-test-id="create-button"
                      loading={createMeta.pending}
                      variant="contained"
                      type="submit"
                      disabled={
                        !isValid ||
                        createMeta.pending ||
                        useCompDayAlert ||
                        hasDurationError ||
                        (_.isEmpty(selectedCompDays) &&
                          identifier ===
                            TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST)
                      }
                    >
                      {t('SYSCOMMON.send')}
                    </LoadingButton>
                  </Stack>
                </Box>
              </form>
            ),
          }}
        />
      )}
    </>
  )
}

export default TimeOffDialog
