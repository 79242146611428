export enum SKILL_ACTION_TYPE {
  GET_CATEGORY_LIST = 'skill-category/list',
  UPDATE_CATEGORY = 'skill-category/update',
  UPDATE_CATEGORY_ORDER = 'skill-category/order/update',
  CREATE_CATEGORY = 'skill-category/add',
  DELETE_CATEGORY = 'skill-category/delete',
  CATEGORY_ALL = 'skill-category/all',

  GET_SKILL_LIST = 'skill/list',
  UPDATE_SKILL = 'skill/update',
  CREATE_SKILL = 'skill/add',
  DELETE_SKILL = 'skill/delete',
  SKILL_ALL = 'skill/all',
  ADDABLE_SKILL_LIST = 'skill/myList',

  SKILL_REPORT_LIST = 'skills/report',

  GET_USER_SKILL_LIST = 'user/skills/list',
  GET_USER_SKILL_ALL = 'user/skills/all',
  GET_USER_SKILLS_BY_ID = 'user/skills/show',
  CREATE_USER_SKILL = 'user/skills/create',
  DELETE_USER_SKILL = 'user/skills/delete',
  UPDATE_USER_SKILLS = 'user/skills/update',
  CLEAR_USER_SKILLS = 'user/skills/clear',
  UPDATE_SINGLE_SKILL = 'user/skills/single_update',
}
