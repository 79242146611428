import {
  FilterParams,
  MetaPage,
  SearchFilter,
} from '@constants/common.constants'
import { MODULES } from '@constants/modules.constants'
import {
  RESOURCE_PLAN_BATCH_STATUS,
  RESOURCE_PLAN_CONFIRM_STATUS,
  ResourcePlanUsersFilter,
} from '@constants/resource.constants'
import { URI } from '@constants/uri.constants'
import { ROLE_PERMISSION } from '@constants/user.constants'
import { api } from './api'
import { EmployeeItem, EmployeeShortItem } from './user.services'

// Positions

export type PositionListResponse = {
  data: PositionItem[]
  meta: MetaPage
}

export type PositionListAllResponse = {
  data: PositionItem[]
}

export type PositionCreateParams = {
  name: string
  short_name: string
  is_default_filter: boolean
}

export type PositionDetailParams = string
export type PositionDeleteParams = string

export type PositionUpdateParams = {
  id: string
  params: PositionCreateParams
}

export type PositionItem = {
  id: string
  type: 'position'
  attributes: {
    name: string
    short_name: string
    display_order: number
    is_default_filter: boolean
  }
}

export type PositionResponse = {
  data: PositionItem
}

export type PositionUpdateOrderParams = {
  positions: { id: string; display_order: number }[]
}

export type CostReportListItem = {
  id: string
  type: 'cost_report'
  attributes: {
    id: number
    target_month: string
    created_at: string
    updated_at: string
  }
}

export type CostReportListResponse = {
  data: CostReportListItem[]
  meta: MetaPage
}

export const positionListService = async (
  params: FilterParams
): Promise<PositionListResponse> => {
  const { data } = await api.get<PositionListResponse>(URI.POSITION, {
    params,
  })
  return data
}

export const positionListAllService =
  async (): Promise<PositionListAllResponse> => {
    const { data } = await api.get<PositionListAllResponse>(URI.POSITION_LIST)
    return data
  }

export const positionCreateService = async (
  params: PositionCreateParams
): Promise<PositionResponse> => {
  const { data } = await api.post<PositionResponse>(URI.POSITION, params)
  return data
}

export const positionDetailService = async (
  id: PositionDetailParams
): Promise<PositionResponse> => {
  const { data } = await api.get<PositionResponse>(`${URI.POSITION}/${id}`)
  return data
}

export const positionUpdateService = async (
  arg: PositionUpdateParams
): Promise<PositionResponse> => {
  const { data } = await api.put<PositionResponse>(
    `${URI.POSITION}/${arg.id}`,
    arg.params
  )
  return data
}

export const positionUpdateOrderService = async (
  params: PositionUpdateOrderParams
): Promise<PositionListAllResponse> => {
  const { data } = await api.put<PositionListAllResponse>(
    URI.POSITION_UPDATE_ORDER,
    params
  )
  return data
}

export const positionDeleteService = async (
  id: PositionDeleteParams
): Promise<PositionResponse> => {
  const { data } = await api.delete<PositionResponse>(`${URI.POSITION}/${id}`)
  return data
}

// DEPARTMENT SERVICES

export type DepartmentItem = {
  id: string
  type: string
  attributes: {
    name: string
    division_lead_id: number
    division_lead: {
      data: EmployeeItem
    }
  }
}

export type DepartmentDeleteParams = string

export type DepartmentUpdateParams = {
  id: string
  params: {
    name: string
    division_lead_id: number
  }
}

export type DepartmentCreateParams = {
  name: string
  division_lead_id: number
}

export type DepartmentDetailsParams = {
  id: string
}

export type DepartmentListResponse = {
  data: DepartmentItem[]
  meta: MetaPage
}
export type DepartmentResponse = {
  data: DepartmentItem
}

export const departmentListService = async (
  params: FilterParams
): Promise<DepartmentListResponse> => {
  const { data } = await api.get<DepartmentListResponse>(URI.DEPARTMENT, {
    params,
  })
  return data
}

export const departmentCreateService = async (
  params: DepartmentCreateParams
): Promise<DepartmentResponse> => {
  const { data } = await api.post<DepartmentResponse>(URI.DEPARTMENT, params)
  return data
}

export const departmentDetailsService = async (
  params: DepartmentDetailsParams
): Promise<DepartmentResponse> => {
  const { data } = await api.get<DepartmentResponse>(
    `${URI.DEPARTMENT}/${params.id}`
  )
  return data
}

export const departmentUpdateService = async (
  arg: DepartmentUpdateParams
): Promise<DepartmentResponse> => {
  const { data } = await api.put<DepartmentResponse>(
    `${URI.DEPARTMENT}/${arg.id}`,
    arg.params
  )
  return data
}

export const departmentDeleteService = async (
  id: DepartmentDeleteParams
): Promise<DepartmentResponse> => {
  const { data } = await api.delete<DepartmentResponse>(
    `${URI.DEPARTMENT}/${id}`
  )
  return data
}

// ROLE SERVICES

export type RoleItem = {
  id: string
  type: 'role'
  attributes: {
    name: string
    modules: { data: RolePermissionItem[] }
  }
}

export type RoleResponse = {
  data: RoleItem
}

export type RoleListResponse = {
  data: RoleItem[]
  meta: MetaPage
}

export type RolePermission = {
  id: number
  type: ROLE_PERMISSION | null
}

export type RolePermissionItem = {
  id: string
  type: string
  attributes: {
    id: number
    type: ROLE_PERMISSION | null
    name: MODULES
  }
}

export type RoleCreateParams = {
  name: string
  modules: RolePermission[]
}

export type RoleUpdateParams = {
  id: string
  params: RoleCreateParams
}

export type RoleDeleteParams = string

export type RoleModuleItem = {
  id: string
  type: 'modules'
  attributes: {
    name: string
    description: string
  }
}

export type RoleModuleListResponse = {
  data: RoleModuleItem[]
}

export const roleModuleListService =
  async (): Promise<RoleModuleListResponse> => {
    const { data } = await api.get<RoleModuleListResponse>(URI.ROLE_MODULE)
    return data
  }

export const roleListService = async (
  params: FilterParams
): Promise<RoleListResponse> => {
  const { data } = await api.get<RoleListResponse>(URI.ROLE, { params })
  return data
}

export const roleListAllService = async (
  params: SearchFilter
): Promise<RoleListResponse> => {
  const { data } = await api.get<RoleListResponse>(URI.ROLE, { params })
  return data
}

export const roleCreateService = async (
  params: RoleCreateParams
): Promise<RoleResponse> => {
  const { data } = await api.post<RoleResponse>(URI.ROLE, params)
  return data
}

export const roleUpdateService = async (
  params: RoleUpdateParams
): Promise<RoleResponse> => {
  const { data } = await api.put<RoleResponse>(
    `${URI.ROLE}/${params.id}`,
    params.params
  )
  return data
}

export const roleDeleteService = async (
  id: RoleDeleteParams
): Promise<RoleResponse> => {
  const { data } = await api.delete<RoleResponse>(`${URI.ROLE}/${id}`)
  return data
}

// RESOURCE PLAN
// RESPONSE
export type ResourcePlanListResponse = {
  data: ResourcePlanListItem[]
  meta: MetaPage
}
export type ResourcePlanAllResponse = {
  data: ResourcePlanListItem[]
}

export type ResourcePlanListItem = {
  id: string
  type: 'resource_plan'
  attributes: {
    id: number
    name: string
    source_id?: string
    status?: string
    target_month: string
    confirmed_at?: string
    updated_at: string
    confirmed_by?: {
      data: EmployeeItem
    }
    created_at?: string
    creator?: {
      data: EmployeeItem
    }
    batch_count: number
    is_calculated: boolean
    items: {
      data: {
        id: string
        type: 'resource_plan_items'
        attributes: {
          id: number
          user: {
            data: EmployeeItem
          }
          position: {
            data: PositionItem
          }
          projects: {
            data: ResourcePlanListItemProject[]
          }
        }
      }[]
    }
  }
}

export type ResourcePlanListItemProject = {
  id: string
  type: 'resource_plan_details'
  attributes: {
    id: number
    cost: number
    project_id: number
  }
}

export type ResourcePlanResponse = {
  data: ResourcePlanItem
}

// Single resource plan item
export type ResourcePlanItem = {
  resource_plan: ResourcePlanDetails
  items?: ResourcePlanMemberItem[]
}

export type ResourcePlanDetails = {
  id: string
  source_id?: string
  name: string
  status: string
  created_by: number
  confirmed_by?: string
  confirmed_at?: string
  created_at: string
  updated_at: string
  target_month: string
  batch_count: number
  is_calculated: boolean
}

export type ResourcePlanMemberItem = {
  id: ResourcePlanIdParams
  user: {
    id: number
    position: PositionResponse
  }
  projects: ResourcePlanProjectItem[]
}

export type ResourcePlanProjectItem = {
  cost: number
  project_id: number
}

export type ResourcePlanItemsMap = {
  [key: string]: {
    cost: number
    project_id: string
    item_id: string
    position: PositionItem
  }[]
}

export type ResourcePlanCreateResponse = {
  data: {
    id: string
    type: 'resource_plan'
    attributes: {
      id: string
      name: string
      creator_id: string
      creator_name: string
      items: {
        data: ResourcePlanMemberItem[]
      }
    }
  }
}

export type ResourcePlanStatusResponse = {
  message: RESOURCE_PLAN_CONFIRM_STATUS
}

export type ResourcePlanUsersResponse = {
  data: EmployeeItem[]
}

export type ResourcePlanActivityItem = {
  id: string
  type: string
  attributes: {
    resource_plan_id: number
    action_type: string
    created_at: string
    updated_by: {
      data: EmployeeItem
    }
  }
}

export type ResourcePlanActivityResponse = {
  data: ResourcePlanActivityItem[]
}

// RESOURCE PLAN PARAMS
export type ResourcePlanIdParams = string

export type ResourcePlanCreateParams = {
  name: string
  target_month: string
}

export type ResourcePlanUpdateParams = {
  id: string
  params: {
    user_id: number
    project_id: number
    cost: number | string
  }
}
export type ResourcePlanRenameParams = {
  id: string
  params: {
    name: string
  }
}

export type ResourcePlanCopyParams = {
  id: string
  params: {
    target_month: string
  }
}

export type ResourcePlanUsersArgs = {
  id: string
  params: ResourcePlanUsersFilter
}

export type ResourcePlanDownloadParams = {
  id: string
  params: ResourcePlanUsersFilter
}

export type ResourcePlanBatchResponse = {
  message: RESOURCE_PLAN_BATCH_STATUS
}

export type ResourcePlanItemResponse = {
  data: ResourcePlanListItem
}

export type ResourcePlanUpdateItemPositionParams = {
  id: ResourcePlanIdParams
  params: {
    item_id: number
    position_id: ResourcePlanIdParams
  }
}

export type AddRemoveUser = {
  params: { user_ids: number[] }
  id: string
}

export type AddRemoveResponse = {
  data: { status: string }
}

export type ResourceAddableUsers = EmployeeShortItem[]

export type ResourceAddableUsersResponse = {
  data: ResourceAddableUsers
}

export const resourcePlanListService = async (
  params: FilterParams
): Promise<ResourcePlanListResponse> => {
  const { data } = await api.get<ResourcePlanListResponse>(URI.RESOURCE_PLAN, {
    params,
  })
  return data
}

export const resourcePlanListAllService = async (
  params: SearchFilter
): Promise<ResourcePlanAllResponse> => {
  const { data } = await api.get<ResourcePlanAllResponse>(
    URI.RESOURCE_PLAN_ALL,
    { params }
  )
  return data
}

export const resourcePlanDetailService = async (
  params: ResourcePlanIdParams
): Promise<ResourcePlanResponse> => {
  const { data } = await api.get<ResourcePlanResponse>(
    `${URI.RESOURCE_PLAN}/${params}.json`
  )
  return data
}

export const resourcePlanDownloadService = async (
  args: ResourcePlanDownloadParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${URI.RESOURCE_PLAN}/${args.id}.xlsx`, {
    params: args.params,
    responseType: 'blob',
  })
  return data
}

export const resourcePlanCreateService = async (
  params: ResourcePlanCreateParams
): Promise<ResourcePlanCreateResponse> => {
  const { data } = await api.post<ResourcePlanCreateResponse>(
    URI.RESOURCE_PLAN,
    params
  )
  return data
}

export const resourcePlanUpdateService = async (
  params: ResourcePlanUpdateParams
): Promise<ResourcePlanResponse> => {
  const { data } = await api.put<ResourcePlanResponse>(
    `${URI.RESOURCE_PLAN}/${params.id}`,
    params.params
  )
  return data
}

export const resourcePlanRenameService = async (
  params: ResourcePlanRenameParams
): Promise<ResourcePlanResponse> => {
  const { data } = await api.post<ResourcePlanResponse>(
    `${URI.RESOURCE_PLAN}/${params.id}/rename`,
    params.params
  )
  return data
}
export const resourcePlanConfirmService = async (
  params: ResourcePlanIdParams
): Promise<ResourcePlanStatusResponse> => {
  const { data } = await api.post<ResourcePlanStatusResponse>(
    `${URI.RESOURCE_PLAN}/${params}/confirm`
  )
  return data
}

export const resourcePlanRefuseService = async (
  params: ResourcePlanIdParams
): Promise<ResourcePlanStatusResponse> => {
  const { data } = await api.post<ResourcePlanStatusResponse>(
    `${URI.RESOURCE_PLAN}/${params}/refuse`
  )
  return data
}

export const resourcePlanCopyService = async (
  args: ResourcePlanCopyParams
): Promise<ResourcePlanResponse> => {
  const { data } = await api.post<ResourcePlanResponse>(
    `${URI.RESOURCE_PLAN}/${args.id}/copy`,
    args.params
  )
  return data
}

export const resourcePlanDeleteService = async (
  params: ResourcePlanIdParams
): Promise<ResourcePlanResponse> => {
  const { data } = await api.delete<ResourcePlanResponse>(
    `${URI.RESOURCE_PLAN}/${params}`
  )
  return data
}

export const resourcePlanUsersService = async (
  args: ResourcePlanUsersArgs
): Promise<ResourcePlanUsersResponse> => {
  const { data } = await api.get<ResourcePlanUsersResponse>(
    URI.RESOURCE_PLAN_USERS.replace(/:id/gi, args.id),
    { params: args.params }
  )
  return data
}

export const resourcePlanBatchService = async (
  params: ResourcePlanIdParams
): Promise<ResourcePlanBatchResponse> => {
  const { data } = await api.post<ResourcePlanBatchResponse>(
    `${URI.RESOURCE_PLAN}/${params}/go_batch`,
    {}
  )
  return data
}

export const resourcePlanActivityService = async (
  params: ResourcePlanIdParams
): Promise<ResourcePlanActivityResponse> => {
  const { data } = await api.get<ResourcePlanActivityResponse>(
    `${URI.RESOURCE_PLAN}/${params}/activities`
  )
  return data
}

export const resourcePlanUpdateItemPosition = async (
  params: ResourcePlanUpdateItemPositionParams
): Promise<ResourcePlanItemResponse> => {
  const { data } = await api.put<ResourcePlanItemResponse>(
    `${URI.RESOURCE_PLAN}/${params.id}/update_item_position`,
    params.params
  )
  return data
}

export const resourcePlanAddUserService = async (
  args: AddRemoveUser
): Promise<AddRemoveResponse> => {
  const { data } = await api.post<AddRemoveResponse>(
    URI.RESOURCE_ADD_EMPLOYEE.replace(/:id/gi, args.id),
    args.params
  )
  return data
}

export const resourcePlanRemoveUserService = async (
  args: AddRemoveUser
): Promise<AddRemoveResponse> => {
  const { data } = await api.post<AddRemoveResponse>(
    URI.RESOURCE_REMOVE_EMPLOYEE.replace(/:id/gi, args.id),
    args.params
  )
  return data
}

export const resourcePlanAddaleUsersService = async (
  args: string
): Promise<ResourceAddableUsersResponse> => {
  const { data } = await api.get<ResourceAddableUsersResponse>(
    URI.RESOURCE_ADDABLE_USERS.replace(/:id/gi, args)
  )
  return data
}

export const costRepotListService = async (
  args: FilterParams
): Promise<CostReportListResponse> => {
  const { data } = await api.get<CostReportListResponse>(URI.COST_REPORT_LIST, {
    params: args,
  })
  return data
}
