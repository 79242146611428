import { createReducer } from '@reduxjs/toolkit'
import {
  changePublicLanguage,
  clearListFilter,
  setListFilter,
} from '../actions'
import {
  loginByEmail,
  loginWithGoogle,
  logOut,
  switchLang,
} from '@store/auth/actions'

type StateType = {
  listFilters: { [listName: string]: any | undefined }
  language?: string
}

const initialState: StateType = {
  listFilters: {},
  language: 'mn',
}

const commonReducer = createReducer(initialState, (builder) => {
  builder.addCase(setListFilter, (state, action) => {
    const { listName, filter } = action.payload
    state.listFilters[listName] = filter
  })
  builder.addCase(clearListFilter, (state) => {
    state.listFilters = {}
  })
  builder
    .addCase(logOut.fulfilled, (state) => {
      state.listFilters = {}
    })
    .addCase(logOut.rejected, (state) => {
      state.listFilters = {}
    })
    .addCase(switchLang.fulfilled, (state, action) => {
      state.language = action.meta.arg.language
    })
    .addCase(switchLang.rejected, (state, action) => {
      state.language = action.meta.arg.language
    })
    .addCase(changePublicLanguage, (state, action) => {
      state.language = action.payload
    })
    .addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.language = action.payload.language
    })
    .addCase(loginByEmail.fulfilled, (state, action) => {
      state.language = action.payload.language
    })
})
export default commonReducer
