import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import { globalMiddleware } from './middleware/globalMiddleware'
import reducer from './reducers'
import storage from './storage'

/**
 * initStore
 * Initialise and export redux store
 */

const initStore = () => {
  const persistConfig = {
    key: 'root',
    whitelist: ['auth', 'localsettings'],
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, reducer)
  // const store = configureStore(
  //   persistedReducer,
  //   applyMiddleware(thunk, globalMiddleware)
  // )
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(thunk, globalMiddleware),
  })

  return store
}

export const store = initStore()
export type StoreType = ReturnType<typeof initStore>
export type RootState = ReturnType<StoreType['getState']>
export type AppDispatch = StoreType['dispatch']
