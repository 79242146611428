import useAuth from '@utils/hooks/useAuth'
import { initializeApp } from 'firebase/app'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, off, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

interface IFireBaseData {
  initApp: string | null
  notifications: string | null
}

const useFirebaseListener = (): IFireBaseData => {
  const { isLoggedIn, user } = useAuth()
  const [data, setData] = useState<IFireBaseData | null>(null)

  useEffect(() => {
    let dataRef: any // Declare dataRef outside the if block
    if (isLoggedIn) {
      try {
        const firebaseConfigEnv =
          process.env.REACT_APP_FIREBASE_CONFIG &&
          JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)

        initializeApp(firebaseConfigEnv)
        const firebaseAuth = getAuth()
        signInAnonymously(firebaseAuth)
          .then(() => {
            const database = getDatabase()
            const dataRef = ref(database, `/users/${user.uid}`)
            onValue(
              dataRef,
              (snapshot) => {
                const data = snapshot.val()
                if (data) {
                  setData(data)
                } else {
                  // eslint-disable-next-line no-console
                  console.log('Data not found')
                }
              },
              (err) => {
                // eslint-disable-next-line no-console
                console.log(err)
              }
            )
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('----->>>> ', error)
            // const errorCode = error.code
            // const errorMessage = error.message
            // ...
          })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('something went wrong', error)
      }

      return () => {
        if (dataRef) {
          off(dataRef, 'value')
        }
      }
    }
  }, [isLoggedIn])

  // You can access the latest data using the 'data' state variable
  const syncData = data
    ? data
    : {
        initApp: null,
        notifications: null,
      }

  return syncData
}

export default useFirebaseListener
