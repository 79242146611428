import { Alert, Box } from '@mui/material'
import QrScanner from 'qr-scanner'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './QrStyles.css'

export type QrReaderProps = {
  onSuccess: (result: string) => void
  onFail?: (err: string | Error) => void
}

const QrReader: FC<QrReaderProps> = ({ onSuccess }) => {
  const scanner = useRef<QrScanner>()
  const videoEl = useRef<HTMLVideoElement>(null)
  const qrBoxEl = useRef<HTMLDivElement>(null)
  const [qrOn, setQrOn] = useState<boolean>(true)
  const [scannedResult, setScannedResult] = useState<string | undefined>('')
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    setScannedResult(result?.data)
    onSuccess(result?.data)
  }

  const onScanFail = (err: Error | string) => {
    // eslint-disable-next-line no-console
    console.log('scan fail:', err)
  }

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      })

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false)
        })
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop()
      }
    }
  }, [])

  useEffect(() => {
    if (!qrOn) {
      setError(true)
    }
  }, [qrOn])

  return (
    <Box className="qr-reader">
      {!error ? (
        <>
          <Box component={'video'} ref={videoEl} />
          <Box
            ref={qrBoxEl}
            className="qr-box"
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Box className="qr-frame">
              <img
                src={'images/qr-frame.png'}
                alt="Qr Frame"
                width={256}
                height={256}
                className="qr-frame"
              />
            </Box>
          </Box>
          {scannedResult && (
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 99999,
                color: 'white',
              }}
            >
              {scannedResult}
            </Box>
          )}
        </>
      ) : (
        <Box width={'100%'} height={'auto'}>
          <Alert variant="outlined" severity="warning">
            {t('WORKSPACE.no_camera')}
          </Alert>
          <Box
            component={'img'}
            sx={{ mt: 3 }}
            src="images/camera-guide.jpg"
            width={'100%'}
            height="auto"
          />
        </Box>
      )}
    </Box>
  )
}

export default QrReader
