import { Direction, GENDER, MetaPage } from '@constants/common.constants'
import {
  PROJECT_ROLE,
  PROJECT_STATUS,
  PROJECT_TYPE,
  ProjectActions,
} from '@constants/project.constants'
import { URI } from '@constants/uri.constants'
import { api } from './api'
import { CompanyItem, PartnerDivisionItem } from './company.services'
import { PositionItem } from './resource.services'
import { EmployeeItem } from './user.services'
import { ProjectListFilter } from '@store/project/actions'

export type ProjectListAllResponse = {
  data: ProjectItem[]
}

export type ProjectListResponse = {
  data: ProjectItem[]
  meta: MetaPage
}

export type ProjectAllListResponse = {
  data: ProjectItem[]
}

export type ProjectCreateParams = {
  name: string
  description?: string
  start_date?: string
  end_date?: string
  project_type: PROJECT_TYPE
}

export type ProjectDetailParams = string
export type ProjectDeleteParams = string

export type ProjectUpdateParams = {
  id: string
  params: {
    name: string
    description?: string
    start_date?: string
    end_date?: string
    status: PROJECT_STATUS
    project_type: PROJECT_TYPE
    cost_start_date?: string
    cost_end_date?: string
    partner_company_id?: number
    partner_division_id?: number
    division_id?: number
  }
}

export type ProjectMemberItem = {
  id: string
  type: 'project_member'
  attributes: {
    member_role: PROJECT_ROLE
    expires_at?: string
    status: string
    user: { data: EmployeeItem }
    start_at: string
  }
}

export type DivisionItem = {
  id: string
  type: 'division'
  attributes: {
    name: string
    division_lead: string
  }
}

export type DivisionItemResponse = {
  data: DivisionItem
}

export type ProjectItem = {
  id: string
  type: 'project'
  attributes: {
    name: string
    my_role?: PROJECT_ROLE
    description: null | string
    image_url: null | string
    status: PROJECT_STATUS
    project_type: PROJECT_TYPE
    cost_start_date: null | string
    cost_end_date: null | string
    start_date?: string | null
    end_date?: string | null
    created_at: string
    image_thumbnail: null | string
    image_medium: null | string
    image_cover: null | string
    is_cost_ended: boolean
    members: {
      data: ProjectMemberItem[]
    }
    partner_company: {
      data: CompanyItem
    }
    partner_division: {
      data: PartnerDivisionItem
    }
    division: DivisionItem
  }
}

export type SortedProject = {
  division_id: string
  division_name: string
  division_lead: string
  data: ProjectItem[]
}

export type ProjectResponse = {
  data: ProjectItem
}

export interface MemberFilterParams {
  keyword: string
  order_by: string
  direction: Direction
}

export type MemberListParams = {
  id: string
  params: MemberFilterParams
}

export type ActivityParams = {
  per_page: string
  page: string
  project_member_ids?: string[]
  action_type?: string[]
}

export type ProjectActivityArgs = {
  id: string
  params: ActivityParams
}

export type MemberCreateParams = {
  member_role: string
  expires_at?: string | null | number
  user_id: number
}

export type MemberUpdateParams = {
  member_role: string
  expires_at?: string | null | number
}

export type MemberCreate = {
  id: string
  params: MemberCreateParams
}

export type MemberUpdate = {
  id: string
  member_id: string
  params: MemberUpdateParams
}

export type MemberDelete = {
  id: string
  member_id: string
}

export type MemberHistoryParams = {
  id: string
  member_id: string
}

export type MemberListResponse = {
  data: ProjectMemberItem[]
  meta: MetaPage
}

export type AddableMemberItem = {
  id: string
  type: 'user'
  attributes: {
    user_code: string
    firstname: string
    lastname: string
    birthday: string
    gender: string
    phone: string
    email: string
    avatar: string | null
    firstname_en: string
    lastname_en: string
    position: {
      data?: PositionItem
    }
  }
}

export type AddableUsersResponse = {
  data: AddableMemberItem[]
}

export type ActivityItem = {
  id: string
  type: 'project_activities'
  attributes: {
    project_id: number
    project_member_id: number
    action_type: ProjectActions
    old_value: null | string
    new_value: null | string
    created_at: string
    project_member?: { data: ProjectMemberItem }
    updated_by: {
      data: ActivityUserItem | null
    }
  }
}

export type ActivityUserItem = {
  id: string
  type: 'user'
  attributes: {
    avatar: null | string
    firstname: string
    firstname_en: string
    gender: GENDER
    is_quit: boolean
    lastname: string
    lastname_en: string
    user_code: string
  }
}

export type ActivityListResponse = {
  data: ActivityItem[]
  meta: MetaPage
}

export type ProjectImageResponse = {
  data: {
    file_url: string
  }
}

export type ProjectImageParam = {
  key: string
}

export type ProjectImageArg = {
  id: string
  params: ProjectImageParam
}

//PARTNER_PURCHASE_ORDER

export type PartnerPurchaseOrderCreateParams = {
  project_id: string
  position_id: string
  cost: number
  start_at: string
  end_at: string
}

export type PartnerPurchaseOrderUpdateParams = {
  id: string
  params: PartnerPurchaseOrderCreateParams
}

export type PartnerPurchaseOrderIdParams = string

export type PartnerPurchaseOrderFilterParams = {
  order_by: string
  direction: Direction
  per_page: string
  page: string
  project_ids?: string[]
}

export type PartnerPurchaseOrderItem = {
  id: string
  type: 'partner_purchase_order'
  attributes: {
    project_id: string
    cost: number
    start_at: string
    end_at: string
    position: { data: PositionItem }
  }
}

export type PartnerPurchaseOrderListResponse = {
  data: PartnerPurchaseOrderItem[]
  meta: MetaPage
}

export type PartnerPurchaseOrderResponse = {
  data: PartnerPurchaseOrderItem
}

export type UserProjectHistoryDetailItem = {
  id: string
  type: 'user_project_history_detail'
  attributes: {
    member_role: PROJECT_ROLE
    start_date: string
    end_date: string
    position: {
      data: PositionItem
    }
  }
}

export type MyProjectResponse = {
  data: ProjectItem[]
}

export type MyProjectParams = {
  start_date?: string | Date
  end_date?: string | Date
}

export type AllProjectParams = {
  start_date?: string | Date
  end_date?: string | Date
}

export const projectListAllService = async (
  id: string
): Promise<ProjectListAllResponse> => {
  const { data } = await api.get<ProjectListAllResponse>(
    URI.PROJECT_RESOURCE_LIST.replace(/:id/gi, id)
  )
  return data
}

export const projectListService = async (
  params: ProjectListFilter
): Promise<ProjectListResponse> => {
  const { data } = await api.get<ProjectListResponse>(URI.PROJECT, {
    params,
  })
  return data
} // for resource plan

export const projectAll = async (
  params: AllProjectParams
): Promise<ProjectListResponse> => {
  const { data } = await api.get<ProjectListResponse>(`${URI.PROJECT}/list`, {
    params: params,
  })
  return data
} // for dailyreport

export const projectCreateService = async (
  params: ProjectCreateParams
): Promise<ProjectResponse> => {
  const { data } = await api.post<ProjectResponse>(URI.PROJECT, params)
  return data
}

export const projectDetailService = async (
  id: ProjectDetailParams
): Promise<ProjectResponse> => {
  const { data } = await api.get<ProjectResponse>(`${URI.PROJECT}/${id}`)
  return data
}

export const projectUpdateService = async (
  arg: ProjectUpdateParams
): Promise<ProjectResponse> => {
  const { data } = await api.put<ProjectResponse>(
    `${URI.PROJECT}/${arg.id}`,
    arg.params
  )
  return data
}

export const projectDeleteService = async (
  id: ProjectDeleteParams
): Promise<ProjectResponse> => {
  const { data } = await api.delete<ProjectResponse>(`${URI.PROJECT}/${id}`)
  return data
}

export const memberListService = async (
  arg: MemberListParams
): Promise<MemberListResponse> => {
  const { data } = await api.get<MemberListResponse>(
    URI.PROJECT_MEMBER.replace(/:id/gi, arg.id),
    { params: arg.params }
  )
  return data
}

export const addableUserService = async (
  id: string
): Promise<AddableUsersResponse> => {
  const { data } = await api.get<AddableUsersResponse>(
    URI.PROJECT_ADDABLE_USERS.replace(/:id/gi, id)
  )
  return data
}

export const addMemberService = async (
  arg: MemberCreate
): Promise<MemberListResponse> => {
  const { data } = await api.post<MemberListResponse>(
    URI.PROJECT_MEMBER.replace(/:id/gi, arg.id),
    arg.params
  )
  return data
}

export const updateMemberService = async (
  arg: MemberUpdate
): Promise<MemberListResponse> => {
  const { data } = await api.put<MemberListResponse>(
    `${URI.PROJECT_MEMBER.replace(/:id/gi, arg.id)}/${arg.member_id}`,
    arg.params
  )
  return data
}

export const deleteMemberService = async (
  arg: MemberDelete
): Promise<MemberListResponse> => {
  const { data } = await api.delete<MemberListResponse>(
    `${URI.PROJECT_MEMBER.replace(/:id/gi, arg.id)}/${arg.member_id}`
  )
  return data
}

export const projectActivityService = async (
  arg: ProjectActivityArgs
): Promise<ActivityListResponse> => {
  const { data } = await api.get<ActivityListResponse>(
    URI.PROJECT_ACTIVITIES.replace(/:id/gi, arg.id),
    { params: arg.params }
  )
  return data
}

export const myProjectsService = async (
  params: MyProjectParams
): Promise<MyProjectResponse> => {
  const { data } = await api.get<MyProjectResponse>(
    `${URI.PROJECT}/my_projects`,
    { params: params }
  )
  return data
}

export const projectImageService = async (
  arg: ProjectImageArg
): Promise<ProjectImageResponse> => {
  const { data } = await api.post<ProjectImageResponse>(
    `${URI.PROJECT}/${arg.id}/set_image_url`,
    arg.params
  )
  return data
}

//PARTNER PURCHASE ORDERS

export const partnerPurchaseOrderListService = async (
  params: PartnerPurchaseOrderFilterParams
): Promise<PartnerPurchaseOrderListResponse> => {
  const { data } = await api.get<PartnerPurchaseOrderListResponse>(
    URI.PARTNER_PURCHASE_ORDER,
    {
      params,
    }
  )
  return data
}

export const partnerPurchaseOrderCreateService = async (
  params: PartnerPurchaseOrderCreateParams
): Promise<PartnerPurchaseOrderResponse> => {
  const { data } = await api.post<PartnerPurchaseOrderResponse>(
    URI.PARTNER_PURCHASE_ORDER,
    params
  )
  return data
}

export const partnerPurchaseOrderDetailsService = async (
  params: PartnerPurchaseOrderIdParams
): Promise<PartnerPurchaseOrderResponse> => {
  const { data } = await api.get<PartnerPurchaseOrderResponse>(
    `${URI.PARTNER_PURCHASE_ORDER}/${params}`
  )
  return data
}

export const partnerPurchaseOrderUpdateService = async (
  params: PartnerPurchaseOrderUpdateParams
): Promise<PartnerPurchaseOrderResponse> => {
  const { data } = await api.put<PartnerPurchaseOrderResponse>(
    `${URI.PARTNER_PURCHASE_ORDER}/${params.id}`,
    params.params
  )
  return data
}

export const partnerPurchaseOrderDeleteService = async (
  params: PartnerPurchaseOrderIdParams
): Promise<PartnerPurchaseOrderResponse> => {
  const { data } = await api.delete<PartnerPurchaseOrderResponse>(
    `${URI.PARTNER_PURCHASE_ORDER}/${params}`
  )
  return data
}

export type PartnerPurchaseOrderWithProjectParams = {
  project_ids: string[]
  order_by: string
  direction: Direction
  per_page: string
  page: string
  keyword: string
  start_at: Date
  end_at: Date
}

export type PartnerPurchaseOrderWithProjectItem = {
  id: string
  type: 'partner_purchase_order_with_project'
  attributes: {
    cost: number
    start_at: string
    end_at: string
    project: {
      data: ProjectItem
    }
    position: {
      data: PositionItem
    }
  }
}

export type PartnerPurchaseOrderWithProjectResponse = {
  data: PartnerPurchaseOrderWithProjectItem[]
  meta: MetaPage
}

export const partnerPurchaseOrderWithProjectService = async (
  params: PartnerPurchaseOrderWithProjectParams
): Promise<PartnerPurchaseOrderWithProjectResponse> => {
  const { data } = await api.get<PartnerPurchaseOrderWithProjectResponse>(
    `${URI.PARTNER_PURCHASE_ORDER}/get_partner_purchase_order_with_projects`,
    { params: params }
  )
  return data
}

export type ProjectWithInvoiceParams = {
  order_by: string
  direction: Direction
  per_page: string
  page: string
  keyword: string
  start_at: Date
  end_at: Date
}

export const projectsWithInvoiceService = async (
  params: ProjectWithInvoiceParams
): Promise<ProjectListResponse> => {
  const { data } = await api.get<ProjectListResponse>(
    `${URI.PARTNER_PURCHASE_ORDER}/projects`,
    { params: params }
  )
  return data
}
