/* eslint-disable no-console */
import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import { CompanyItem, PartnerDivisionItem } from '@services/company.services'
import _ from 'lodash'
import * as actions from '../actions'

type StateType = {
  companyList?: CompanyItem[]
  companyListAll?: CompanyItem[]
  companyListMeta?: MetaPage
  companyDetails?: CompanyItem
  companyDivisions?: PartnerDivisionItem[]
  partnerDivisionList?: PartnerDivisionItem[]
  partnerDivisionAll?: PartnerDivisionItem[]
  partnerDivisionMeta?: MetaPage
}

const initialState: StateType = {
  companyList: undefined,
  companyListAll: undefined,
  companyListMeta: undefined,
  companyDetails: undefined,
  companyDivisions: undefined,
  partnerDivisionList: undefined,
  partnerDivisionMeta: undefined,
  partnerDivisionAll: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchCompanyList.fulfilled, (state, action) => {
    state.companyList = action.payload.data
    state.companyListMeta = action.payload.meta
  })
  builder.addCase(actions.fetchCompanyDetails.fulfilled, (state, action) => {
    state.companyDetails = action.payload.data
  })
  builder.addCase(
    actions.fetchCompanyDivisionList.fulfilled,
    (state, action) => {
      state.companyDivisions = action.payload.data
    }
  )
  builder.addCase(actions.updateCompany.fulfilled, (state, action) => {
    state.companyList = state.companyList
      ? _.map(state.companyList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.clearCompany, (state) => {
    state.companyDetails = undefined
    state.companyDivisions = undefined
  })
  // Partner Division
  builder.addCase(
    actions.fetchPartnerDivisionList.fulfilled,
    (state, action) => {
      state.partnerDivisionList = action.payload.data
      state.partnerDivisionMeta = action.payload.meta
    }
  )
  builder.addCase(actions.updatePartnerDivision.fulfilled, (state, action) => {
    state.partnerDivisionList = state.partnerDivisionList
      ? _.map(state.partnerDivisionList, (item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }
          return item
        })
      : undefined
  })
  builder.addCase(actions.fetchCompanyListAll.fulfilled, (state, action) => {
    state.companyListAll = action.payload.data
  })
  builder.addCase(actions.fetchAllDivisionList.fulfilled, (state, action) => {
    state.partnerDivisionAll = action.payload.data
  })
})
