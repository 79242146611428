export enum EMAIL_ACTION_TYPE {
  //Email campaign
  EMAIL_CAMPAIGN_LIST = 'email/campaignList',
  EMAIL_CAMPAIGN_CREATE = 'email/campaignCreate',
  EMAIL_CAMPAIGN_SALARY_SEND = 'email/campaignSalarySend',
  EMAIL_CAMPAIGN_UPDATE = 'email/campaignUpdate',
  EMAIL_CAMPAIGN_DELETE = 'email/campaignDelete',
  EMAIL_CAMPAIGN_DETAIL = 'email/campaignDetail',
  EMAIL_CAMPAIGN_CLEAR = 'email/campaignClear',
  EMAIL_CAMPAIGN_BULK_SEND = 'email/campaignBulkSend',
  //Email template
  EMAIL_TEMPLATE_LIST = 'email/templateList',
  EMAIL_TEMPLATE_CREATE = 'email/templateCreate',
  EMAIL_TEMPLATE_UPDATE = 'email/templateUpdate',
  EMAIL_TEMPLATE_DELETE = 'email/templateDelete',
  EMAIL_TEMPLATE_DETAIL = 'email/templateDetail',
  EMAIL_TEMPLATE_CLEAR = 'email/templateClear',
  EMAIL_FAILURE_LIST = 'email/failures',
}
