import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/reducers'
import { DefaultFilter } from '@constants/common.constants'

export const getRoot = (state: RootState) => state.localsettings

export const selectListFilterByName = (listName: string, defaultFilter?: any) =>
  createSelector(getRoot, (localSettings) =>
    localSettings.listFilters[listName] !== undefined
      ? localSettings.listFilters[listName]
      : defaultFilter || DefaultFilter
  )

export const allFilters = () =>
  createSelector(getRoot, (localSettings) => localSettings.listFilters)
export const currentPublicLang = createSelector(
  getRoot,
  (state) => state?.language
)
