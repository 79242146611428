import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInputProps,
  TextField,
  Typography,
} from '@mui/material'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import { red } from '@mui/material/colors'
import _ from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomLabel } from '../StyledTextField'

interface AutoCompleteSelectProps {
  readOnly?: boolean
  onSearch?: (
    _event: React.SyntheticEvent<Element, Event>,
    value: any[],
    _reason: AutocompleteChangeReason
  ) => void
  loading?: boolean
  data: any[]
  onOpen?: () => void
  onClose?: () => void
  filterOptions?: any
  defaultValue?: any[]
  onInputChange?: (value: any[]) => void
  value?: any
  helperText?: string
  labelPrimary?: string
  isHelperShow?: boolean
  placeholder?: string
  labelSecondary?: React.ReactElement
  getOptionLabel?: (option: any) => string
  required?: boolean
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state?: any
  ) => React.ReactNode
  disablePortal?: boolean
  disableCloseOnSelect?: any
  multiple?: boolean
  limitTags?: number
  disableClearable?: boolean
  onClear?: () => void
  isOptionEqualToValue?: (option: any, value: any) => boolean
}

const AutoCompleteSelect: React.FC<
  AutoCompleteSelectProps & OutlinedInputProps
> = ({
  readOnly = false,
  loading,
  data,
  onOpen,
  defaultValue,
  onClose,
  onInputChange,
  limitTags,
  value,
  filterOptions,
  required,
  helperText,
  placeholder,
  getOptionLabel,
  renderOption,
  labelPrimary,
  labelSecondary,
  isHelperShow = true,
  disablePortal = true,
  multiple = false,
  disableClearable = false,
  disableCloseOnSelect,
  onClear,
  isOptionEqualToValue,
  ...rest
}) => {
  const { t } = useTranslation()
  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: any[],
    reason: AutocompleteChangeReason
  ) => {
    onInputChange && onInputChange(value)
    if (reason === 'clear') {
      onClear && onClear()
    }
  }

  return (
    <FormControl
      fullWidth={rest.fullWidth ? rest.fullWidth : false}
      sx={{
        minWidth: {
          xs: 'auto',
          sm: 'auto',
          md: '140px',
        },
      }}
    >
      {(labelPrimary || labelSecondary) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: 0 }}
        >
          <Box sx={{ padding: 0 }} display="flex" alignItems="center">
            <CustomLabel text={labelPrimary ? labelPrimary : ''} />
            {required && (
              <Typography
                component="span"
                sx={{
                  paddingLeft: (theme) => theme.spacing(1 / 2),
                  paddingRight: (theme) => theme.spacing(1 / 2),
                  height: 16,
                  fontSize: 10,
                  marginLeft: '5px',
                  color: red[400],
                }}
              >
                *
              </Typography>
            )}
          </Box>
          {labelSecondary}
        </Box>
      )}
      <Autocomplete
        multiple={multiple}
        readOnly={readOnly}
        disableClearable={disableClearable}
        disableCloseOnSelect={
          disableCloseOnSelect ? disableCloseOnSelect : false
        }
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : (option: any, value: any) => option.id === value.id
        }
        id="multiple-limit-tags"
        onOpen={onOpen && onOpen}
        limitTags={limitTags}
        onClose={onClose && onClose}
        placeholder={placeholder ? placeholder : ''}
        onChange={handleChange}
        filterOptions={filterOptions ? filterOptions : undefined}
        value={value ? value : null}
        ChipProps={{
          sx: {
            height: 24,
            border: '0 none',

            '& span': {
              maxWidth: 100,
              width: 'auto',
              fontWeight: 500,
              fontSize: 12,
            },
          },
        }}
        disablePortal={disablePortal}
        loading={loading}
        options={data}
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option) => option.name || ''
        }
        renderOption={
          renderOption
            ? renderOption
            : (props, option) => {
                return (
                  <Box component="li" {...props} key={option.id}>
                    {option.name}
                  </Box>
                )
              }
        }
        defaultValue={defaultValue ? defaultValue : []}
        noOptionsText={t('SYSCOMMON.no_data')}
        renderInput={(params) => {
          return (
            <TextField
              placeholder={placeholder && _.isEmpty(value) ? placeholder : ''}
              sx={{
                '& .MuiOutlinedInput-root ': {
                  border: '0 none',
                  padding: '3.8px 8px',
                  backgroundColor: '#fff',
                  zIndex: 100,
                },
              }}
              {...(params as any)}
            />
          )
        }}
        sx={{ width: '100%' }}
      />
      {isHelperShow &&
        (helperText ? (
          <FormHelperText
            sx={{
              height: 12,
              m: 0,
            }}
            error={rest.error}
          >
            {helperText}
          </FormHelperText>
        ) : (
          <Box sx={{ height: 12 }} />
        ))}
    </FormControl>
  )
}

export default AutoCompleteSelect
