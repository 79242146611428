import { SearchParams } from '@components/SearchFilter'
import { FilterParams, SearchFilter } from '@constants/common.constants'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/skill.services'
import { SKILL_ACTION_TYPE } from './types'

//SKILL CATEGORY ACTIONS
export const fetchAllSkillCategoryList = createAsyncThunk<
  services.SkillCategoryAllResponse,
  SearchFilter
>(SKILL_ACTION_TYPE.CATEGORY_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillCategoryAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchSkillCategoryList = createAsyncThunk<
  services.SkillCategoryListResponse,
  FilterParams
>(SKILL_ACTION_TYPE.GET_CATEGORY_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillCategoryListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createSkillCategory = createAsyncThunk<
  services.SkillCategoryResponse,
  services.SkillCategoryCreateParams
>(SKILL_ACTION_TYPE.CREATE_CATEGORY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillCategoryCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateSkillCategory = createAsyncThunk<
  services.SkillCategoryResponse,
  services.SkillCategoryUpdateParams
>(SKILL_ACTION_TYPE.UPDATE_CATEGORY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillCategoryUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateSkillCategoryOrder = createAsyncThunk<
  services.SkillCategoryAllResponse,
  services.SkillCategoryUpdateOrderParams
>(
  SKILL_ACTION_TYPE.UPDATE_CATEGORY_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.skillCategoryUpdateOrderService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteSkillCategory = createAsyncThunk<
  services.SkillCategoryResponse,
  services.SkillCategoryDeleteParams
>(SKILL_ACTION_TYPE.DELETE_CATEGORY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillCategoryDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

//SKILL ACTIONS

export const fetchAllSkillList = createAsyncThunk<
  services.SkillAllResponse,
  SearchFilter
>(SKILL_ACTION_TYPE.SKILL_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchSkillList = createAsyncThunk<
  services.SkillListResponse,
  FilterParams
>(SKILL_ACTION_TYPE.GET_SKILL_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createSkill = createAsyncThunk<
  services.SkillResponse,
  services.SkillCreateParams
>(SKILL_ACTION_TYPE.CREATE_SKILL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateSkill = createAsyncThunk<
  services.SkillResponse,
  services.SkillUpdateParams
>(SKILL_ACTION_TYPE.UPDATE_SKILL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateSingleSkill = createAsyncThunk<
  services.SingleSkillResponse,
  services.UserSkillSingleParams
>(
  SKILL_ACTION_TYPE.UPDATE_SINGLE_SKILL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.userSkillUpdateSingle(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteSkill = createAsyncThunk<
  services.SkillResponse,
  services.SkillDeleteParams
>(SKILL_ACTION_TYPE.DELETE_SKILL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

//REPORT ACTIONS

export const fetchSkillReportList = createAsyncThunk<
  services.SkillReportListResponse,
  services.SkillReportParams
>(SKILL_ACTION_TYPE.SKILL_REPORT_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.skillReportListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

// User Skill
export const fetchUserSkillListAll = createAsyncThunk<
  services.UserSkillsAllResponse,
  SearchParams
>(SKILL_ACTION_TYPE.GET_USER_SKILL_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userSkillListAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchAddableSkillList = createAsyncThunk<
  services.SkillAllResponse,
  SearchFilter
>(SKILL_ACTION_TYPE.ADDABLE_SKILL_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.addableUserSkillListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchUserSkillsById = createAsyncThunk<
  services.UserSkillsAllResponse,
  services.UserSkillsByIdParams
>(
  SKILL_ACTION_TYPE.GET_USER_SKILLS_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.userSkillsByIdService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createUserSkill = createAsyncThunk<
  services.UserSkillsAllResponse,
  services.UserSkillParams
>(SKILL_ACTION_TYPE.CREATE_USER_SKILL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userSkillCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserSkills = createAsyncThunk<
  services.UserSkillsAllResponse,
  services.UserSkillParams
>(SKILL_ACTION_TYPE.UPDATE_USER_SKILLS, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userSkillCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteUserSkillById = createAsyncThunk<
  services.UserSkillDeleteResponse,
  services.UserSkillDeleteParams
>(SKILL_ACTION_TYPE.DELETE_USER_SKILL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.userSkillDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
