import { Box, Typography } from '@mui/material'
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from '@silevis/reactgrid'

import * as React from 'react'

export type Datas = {
  count: string
  percent: string
}

export interface StatusReportCell extends Cell, Span {
  type: 'countData'
  text: Datas
}

export class PercentReportCellTemplate
  implements CellTemplate<StatusReportCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<StatusReportCell>
  ): Compatible<StatusReportCell> {
    const text = getCellProperty(uncertainCell, 'text', 'object')
    const value = text
    return { ...uncertainCell, text, value }
  }
  render(
    cell: Compatible<StatusReportCell>,
    _isInEditMode: boolean,
    _onCellChanged: (
      cell: Compatible<StatusReportCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    const { count, percent } = cell.text || { count: '', percent: '' }
    return (
      <Box
        sx={{
          display: 'flex',
          whiteSpace: 'inherit',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            pr: 0.5,
          }}
        >
          {count}
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            fontSize: '10px',
            fontWeight: '500',
            lineHeight: '12px',
          }}
        >
          {' '}
          ({percent})
        </Typography>
      </Box>
    )
  }

  isFocusable = (_cell: Compatible<StatusReportCell>): boolean => false

  getClassName(
    cell: Compatible<StatusReportCell>,
    _isInEditMode: boolean
  ): string {
    return cell.className ? cell.className : ''
  }

  getStyle = (_cell: Compatible<StatusReportCell>): CellStyle => ({
    background: '#fff',
  })
}
