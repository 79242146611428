import {
  FilterParams,
  MetaPage,
  SearchFilter,
} from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import { api } from './api'

export type CompanyItem = {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    created_at: string
    partner_divisions_cnt: number
  }
}

export type AddCompanyParams = {
  name: string
  description?: string
}
export type CompanyDetailsParams = string
export type CompanyListResponse = {
  data: CompanyItem[]
  meta: MetaPage
}

export type CompanyResponse = {
  data: CompanyItem
}
export type CompanyDeleteParams = string

export type CompanyUpdateParams = {
  id: string
  params: {
    name: string
    description?: string
  }
}

export const companyListService = async (
  params: FilterParams
): Promise<CompanyListResponse> => {
  const { data } = await api.get<CompanyListResponse>(URI.PARTNER_COMPANY, {
    params,
  })
  return data
}

export const companyAddService = async (
  params: AddCompanyParams
): Promise<CompanyResponse> => {
  const { data } = await api.post<CompanyResponse>(URI.PARTNER_COMPANY, params)
  return data
}

export const companyDetailsService = async (
  params: CompanyDetailsParams
): Promise<CompanyResponse> => {
  const { data } = await api.get<CompanyResponse>(
    `${URI.PARTNER_COMPANY}/${params}`
  )
  return data
}

export const companyDivisionListService = async (
  params: CompanyDetailsParams
): Promise<DivisionAllResponse> => {
  const { data } = await api.get<DivisionAllResponse>(
    URI.PARTNER_COMPANY_DIVISIONS.replace(/:id/gi, params)
  )
  return data
}

export const companyUpdateService = async (
  arg: CompanyUpdateParams
): Promise<CompanyResponse> => {
  const { data } = await api.put<CompanyResponse>(
    `${URI.PARTNER_COMPANY}/${arg.id}`,
    arg.params
  )
  return data
}

export const companyDeleteService = async (
  id: CompanyDeleteParams
): Promise<CompanyResponse> => {
  const { data } = await api.delete<CompanyResponse>(
    `${URI.PARTNER_COMPANY}/${id}`
  )
  return data
}

// Partner Division
export type PartnerDivisionItem = {
  id: string
  type: string
  attributes: {
    name: string
    division_lead: string
    description: string
    project_cnt: number
    display_order: number
    partner_company: {
      data: CompanyItem
    }
  }
}

export type PartnerDivisionListResponse = {
  data: PartnerDivisionItem[]
  meta: MetaPage
}

export type PartnerDivisionUpdateParams = {
  id: string
  params: {
    name: string
    division_lead: string
    partner_company_id: number
    description: string
  }
}

export type PartnerDivisionUpdateOrderParams = {
  partner_divisions: { id: string; display_order: number }[]
}

export type PartnerDivisionUpdateResponse = {
  data: PartnerDivisionItem
}

export type PartnerDivisionAddParams = {
  name: string
  division_lead: string
  partner_company_id: number
  description: string
}

export type PartnerDivisionAddResponse = {
  data: PartnerDivisionItem
}

export type PartnerDivisionDeleteResponse = {
  data: PartnerDivisionItem
}

export type PartnerDivisionDeleteParams = string

export type DivisionAllResponse = { data: PartnerDivisionItem[] }
export type CompanyAllResponse = { data: CompanyItem[] }

export const partnerDivisionListService = async (
  params: FilterParams
): Promise<PartnerDivisionListResponse> => {
  const { data } = await api.get<PartnerDivisionListResponse>(
    URI.PARTNER_DIVISION,
    {
      params,
    }
  )
  return data
}

export const partnerDivisionUpdateService = async (
  arg: PartnerDivisionUpdateParams
): Promise<PartnerDivisionUpdateResponse> => {
  const { data } = await api.put<PartnerDivisionUpdateResponse>(
    `${URI.PARTNER_DIVISION}/${arg.id}`,
    arg.params
  )
  return data
}

export const partnerDivisionUpdateOrderService = async (
  params: PartnerDivisionUpdateOrderParams
): Promise<DivisionAllResponse> => {
  const { data } = await api.put<DivisionAllResponse>(
    URI.PARTNER_DIVISION_UPDATE_ORDER,
    params
  )
  return data
}

export const partnerDivisionAddService = async (
  params: PartnerDivisionAddParams
): Promise<PartnerDivisionAddResponse> => {
  const { data } = await api.post<PartnerDivisionAddResponse>(
    URI.PARTNER_DIVISION,
    params
  )
  return data
}

export const companyAllService = async (
  params: SearchFilter
): Promise<CompanyAllResponse> => {
  const { data } = await api.get<CompanyAllResponse>(URI.PARTNER_COMPANY_LIST, {
    params,
  })
  return data
}

export const divisionAllService = async (
  params: SearchFilter
): Promise<DivisionAllResponse> => {
  const { data } = await api.get<DivisionAllResponse>(
    URI.PARTNER_DIVISION_LIST,
    { params }
  )
  return data
}

export const partnerDivisionDeleteService = async (
  id: PartnerDivisionDeleteParams
): Promise<PartnerDivisionDeleteResponse> => {
  const { data } = await api.delete<PartnerDivisionDeleteResponse>(
    `${URI.PARTNER_DIVISION}/${id}`
  )
  return data
}
