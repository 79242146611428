import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { ICount, NotifyItem } from '@services/notification.services'
import _ from 'lodash'
import { MetaPage } from '@constants/common.constants'

type StateType = {
  list?: NotifyItem[]
  count?: ICount
  listPaging?: MetaPage
  shortList?: NotifyItem[]
}

const initialState: StateType = {
  list: undefined,
  count: undefined,
  listPaging: undefined,
  shortList: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.notifyList.fulfilled, (state, action) => {
    state.list = action.payload.data
    state.listPaging = action.payload.meta
  })
  builder.addCase(actions.notifyListShort.fulfilled, (state, action) => {
    state.shortList = action.payload.data
  })
  builder.addCase(actions.badgeCount.fulfilled, (state, action) => {
    state.count = action.payload
  })
  builder.addCase(actions.readOne.fulfilled, (state, action) => {
    state.list = state.list
      ? _.map(state.list, (n) => {
          return n.id === action.payload.data.id ? action.payload.data : n
        })
      : state.list
    state.shortList = state.shortList
      ? _.map(state.shortList, (n) => {
          return n.id === action.payload.data.id ? action.payload.data : n
        })
      : state.shortList
    state.count =
      state.count && state.count.count > 0
        ? { ...state.count, count: state.count.count - 1 }
        : state.count
  })
})
