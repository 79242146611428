import auth from '@store/auth'
import common from '@store/common'
import company from '@store/company'
import dashboard from '@store/dashboard'
import email from '@store/email'
import localsettings from '@store/localsettings'
import metadata from '@store/metadata'
import project from '@store/project'
import report from '@store/report'
import resource from '@store/resource'
import settings from '@store/settings'
import skill from '@store/skill'
import time from '@store/time'
import timeoff from '@store/timeoff'
import user from '@store/user'
import notification from '@store/notification'
import vacation from '@store/vacation'
import dailyreport from '@store/dailyreport'

import { combineReducers, Reducer } from 'redux'

export interface AppState {
  metadata: any
  common: any
  auth: any
  user: any
  settings: any
  company: any
  project: any
  dashboard: any
  skill: any
  resource: any
  report: any
  localsettings: any
  email: any
  timeoff: any
  time: any
  vacation: any
  dailyreport: any
}

const combinedReducers = combineReducers({
  metadata: metadata.reducer,
  common: common.reducers,
  auth: auth.reducers,
  user: user.reducers,
  company: company.reducers,
  project: project.reducers,
  settings: settings.reducers,
  dashboard: dashboard.reducers,
  skill: skill.reducers,
  resource: resource.reducers,
  report: report.reducers,
  localsettings: localsettings.reducers,
  email: email.reducers,
  timeoff: timeoff.reducers,
  time: time.reducers,
  notification: notification.reducers,
  vacation: vacation.reducers,
  dailyreport: dailyreport.reducers,
})

const reducer: Reducer<any> = (state, action) => {
  return combinedReducers(state, action)
}

export default reducer

export type RootState = ReturnType<typeof reducer>
