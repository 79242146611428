import CustomDatePicker from '@components/@material-extend/CustomDatePicker'
import YearMonthPicker from '@components/@material-extend/YearMonthPicker'
import { AntSwitch } from '@components/AntSwitch'
import useCostReportFilterForm from '@containers/@modules/Report/useCostReportFilterForm'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { ActualCostReportParams } from '@services/report.services'
import {
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
} from '@utils/helper/costreport.helper'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface CostReportFilterProps {
  onFilter?: (data: ActualCostReportParams) => void
  filter: any
}

const ActualCostReportDetailFilter: React.FC<CostReportFilterProps> = (
  props
) => {
  const { onFilter, filter } = props
  const [customDateRange, setCustomDateRange] = useState<boolean>(false)
  const { Controller, methods } = useCostReportFilterForm()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    reset,
  } = methods

  const startDate = useWatch({ control: control, name: 'report_start_date' })

  const { t } = useTranslation()

  const onSubmit = (values: any) => {
    onFilter &&
      onFilter({
        report_start_date: dayjs(values.report_start_date).startOf('month'),
        report_end_date: customDateRange ? values.report_end_date : null,
      })
  }

  useEffect(() => {
    if (customDateRange) {
      trigger('report_end_date')
    }
  }, [startDate])

  useEffect(() => {
    if (!customDateRange) {
      reset({ report_end_date: null })
    }
  }, [customDateRange])

  useEffect(() => {
    if (filter) {
      reset({
        report_start_date: dayjs(filter.report_start_date),
        report_end_date: filter.report_end_date
          ? dayjs(filter.report_end_date)
          : null,
      })
      if (!_.isNull(filter.report_end_date)) {
        setCustomDateRange(true)
      }
    }
  }, [])

  const handleCustomDateRangeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomDateRange(event.target.checked)
  }

  // You can start building your component here

  return (
    <Box component={'form'} onSubmit={handleSubmit(onSubmit)} method="post">
      <Grid spacing={1} container>
        <Grid xs={12} md={12} lg={4} item>
          {customDateRange ? (
            <Box data-test-id="name-parent" sx={{ width: '100%' }}>
              <Stack sx={{ width: '100%' }} direction="row" spacing={1}>
                <Controller
                  control={control}
                  name="report_start_date"
                  render={({ field: { onChange, value } }) => (
                    <Box sx={{ width: '50%' }}>
                      <CustomDatePicker
                        labelPrimary={t('COST_REPORT.start_date')}
                        error={errors.report_start_date ? true : false}
                        helperText={
                          errors.report_start_date
                            ? errors.report_start_date.message
                            : ''
                        }
                        shouldDisableMonth={shouldDisableDate}
                        value={value}
                        onChange={onChange}
                      />
                    </Box>
                  )}
                />
                <Controller
                  control={control}
                  name="report_end_date"
                  render={({ field: { onChange, value } }) => (
                    <Box sx={{ width: '50%' }}>
                      <CustomDatePicker
                        labelPrimary={t('COST_REPORT.end_date')}
                        error={!!errors.report_end_date?.message}
                        helperText={errors.report_end_date?.message}
                        shouldDisableMonth={shouldDisableDate}
                        value={value}
                        onChange={onChange}
                      />
                    </Box>
                  )}
                />
              </Stack>
            </Box>
          ) : (
            <Controller
              control={control}
              name="report_start_date"
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: '100%' }}>
                  <YearMonthPicker
                    labelPrimary={t('COST_REPORT.month')}
                    error={!!errors.report_start_date?.message}
                    shouldDisableMonth={shouldDisableMonth}
                    shouldDisableYear={shouldDisableYear}
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
          )}
        </Grid>
        <Grid xs={6} md={6} lg={2} item>
          {' '}
          <Stack direction="column" spacing={1} sx={{ mt: 2.5, pl: 1, pr: 2 }}>
            <Typography sx={{ fontSize: 12 }}>
              {t('COST_REPORT.date_range_label')}
            </Typography>
            <AntSwitch
              data-test-id="date-switch"
              checked={customDateRange}
              onChange={handleCustomDateRangeChange}
              inputProps={{ 'aria-label': 'ant design' }}
            />
          </Stack>
        </Grid>
        <Grid
          xs={6}
          md={6}
          lg={6}
          item
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Box
            sx={{
              mt: 3,
              width: '100%',
              maxWidth: '200px',
            }}
          >
            <Button
              data-test-id="filter-button"
              fullWidth
              type="submit"
              disabled={!isValid}
              variant="contained"
            >
              {t('SYSCOMMON.filter')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActualCostReportDetailFilter
