import {
  StartEndTimeParams,
  TimeQrParams,
  TimesheetItem,
  TimesheetParams,
  TimesheetRemoteWorkRequestItem,
  TimesheetRequestItem,
  TimesheetSumItem,
} from '@services/time.services'
import { EmployeeItem } from '@services/user.services'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import {
  breakEnd,
  breakStart,
  timeEnd,
  timeQrEnd,
  timeQrStart,
  timeStart,
  timesheet,
} from '@store/time/actions'
import {
  getTimesheetList,
  getTimesheetRemoteRequestList,
  getTimesheetRequestList,
  getTimesheetTotal,
  getTimesheetUser,
} from '@store/time/selectors'
import { useSelector } from 'react-redux'

interface TimeRecord {
  startTime: (params: StartEndTimeParams) => any
  endTime: (params: StartEndTimeParams) => any
  startQrTime: (params: TimeQrParams) => any
  endQrTime: (params: TimeQrParams) => any
  startBreak: () => any
  endBreak: () => any
  startMeta: Meta
  endMeta: Meta
  breakStartMeta: Meta
  breakEndMeta: Meta
  fetchTimesheet: (params: TimesheetParams) => any
  timesheetMeta: Meta
  timesheetList: TimesheetItem[]
  timesheetTotal: TimesheetSumItem
  timesheetRequestList: TimesheetRequestItem[]
  timesheetUser: EmployeeItem
  timesheetRemoteWorkRequests: TimesheetRemoteWorkRequestItem[]
}

const useTimeRecord = (): TimeRecord => {
  const dispatch = useAppDispatch()

  // ACTIONS
  const startTime = (params: StartEndTimeParams) => dispatch(timeStart(params))
  const endTime = (params: StartEndTimeParams) => dispatch(timeEnd(params))

  const startQrTime = (params: TimeQrParams) => dispatch(timeQrStart(params))
  const endQrTime = (params: TimeQrParams) => dispatch(timeQrEnd(params))

  const startBreak = () => dispatch(breakStart())
  const endBreak = () => dispatch(breakEnd())

  const fetchTimesheet = (params: TimesheetParams) =>
    dispatch(timesheet(params))
  const timesheetList = useSelector(getTimesheetList)
  const timesheetTotal = useSelector(getTimesheetTotal)
  const timesheetRequestList = useSelector(getTimesheetRequestList)
  const timesheetUser = useSelector(getTimesheetUser)
  const timesheetRemoteWorkRequests = useSelector(getTimesheetRemoteRequestList)

  // START META
  const _startMeta = createMetaSelector(timeStart)
  const startMeta = useSelector(_startMeta)

  // ADD META
  const _endMeta = createMetaSelector(timeEnd)
  const endMeta = useSelector(_endMeta)

  // START BREAK META
  const _breakStartMeta = createMetaSelector(breakStart)
  const breakStartMeta = useSelector(_breakStartMeta)

  // END BREAK META
  const _breakEndMeta = createMetaSelector(breakEnd)
  const breakEndMeta = useSelector(_breakEndMeta)

  // TIMESHEET META
  const _timesheetMeta = createMetaSelector(timesheet)
  const timesheetMeta = useSelector(_timesheetMeta)

  // TIMESHEET META

  // DETAILS META
  return {
    startTime,
    startMeta,
    endMeta,
    endTime,
    startBreak,
    endBreak,
    breakStartMeta,
    breakEndMeta,
    fetchTimesheet,
    timesheetList,
    timesheetMeta,
    timesheetTotal,
    timesheetRequestList,
    timesheetUser,
    startQrTime,
    endQrTime,
    timesheetRemoteWorkRequests,
  }
}

export default useTimeRecord
