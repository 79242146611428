export const SIDEBAR = {
  width: 240,
}
export const HEADER = {
  height: 50,
}
export const TABLE = {
  primary_column_width: 200,
}

export const BOTTOM_NAV = {
  height: 56,
}
