import { api } from './api'
import { URI } from '@constants/uri.constants'
import { EmployeeItem } from './user.services'
import { MetaPage } from '@constants/common.constants'
import { NOTIFY_TYPE } from '@constants/notify.constants'

export type NotificationParams = {
  per_page: string
  page: string
}

export type ICount = {
  count: number
}

export type NotifyItem = {
  id: string
  type: 'user_notifications'

  attributes: {
    created_at: string
    updated_at: string
    notification_id: number
    is_read: boolean
    notification: {
      data: NotifyContent
    }
  }
}

export type NotifyContent = {
  id: string
  type: 'notification'
  attributes: {
    description: string
    target_type: NOTIFY_TYPE
    target_content: any
    action_by: { data: EmployeeItem }
    target_id: number
    title: string
  }
}

export type NotifyResponse = {
  data: NotifyItem
}
export type NotifyListResponse = {
  data: NotifyItem[]
  meta: MetaPage
}

export type SuccessRespoinse = {
  success: string
}

export type ReadParams = {
  id: string
}

export const getNotifyService = async (
  params: NotificationParams
): Promise<NotifyListResponse> => {
  const { data } = await api.get<NotifyListResponse>(`${URI.NOTIFICATIONS}`, {
    params: params,
  })
  return data
}

export const getBadgeCountService = async (): Promise<ICount> => {
  const { data } = await api.get<ICount>(`${URI.NOTIFICATIONS}/badge_count`)
  return data
}

export const markAllReadService = async (): Promise<SuccessRespoinse> => {
  const { data } = await api.get<SuccessRespoinse>(
    `${URI.NOTIFICATIONS}/mark_all_as_read`
  )
  return data
}

export const readService = async (
  params: ReadParams
): Promise<NotifyResponse> => {
  const { data } = await api.get<NotifyResponse>(
    `${URI.NOTIFICATIONS}/${params.id}`
  )
  return data
}
