import '@components/@data-grid/timegrid.css'
import { Box } from '@mui/material'
import { Column, ReactGrid, Row } from '@silevis/reactgrid'
import React from 'react'
import { TimeOffReportTotalItem } from '@services/timeoff.services'
import { useRect } from '@reach/rect'
import _ from 'lodash'
import { Meta } from '@store/metadata/actions/types'

export const TIME_REPORT_GRID = {
  rowHeight: 30,
  headerHeight: 40,
  employeeWidth: 400,
  columnWidth: 120,
  defaultWidth: 140,
}
export type TimeOffTemplates = {
  timeoff_template_id: number
  timeoff_template_name: string
  total_count: number
}
interface TimeOffReportGridProps {
  timeOffReportTotalList: TimeOffReportTotalItem[]
  selectedTimeOff: number[]
  metaTimeOff: Meta
}

const contentRef = React.createRef<HTMLDivElement>()

const TimeOffReportGrid: React.FC<TimeOffReportGridProps> = ({
  timeOffReportTotalList,
  selectedTimeOff,
}) => {
  const contentRect = useRect(contentRef)
  const totalWidth = contentRect?.width

  const fixedTotalWidth =
    TIME_REPORT_GRID.columnWidth * timeOffReportTotalList.length +
    TIME_REPORT_GRID.employeeWidth
  const colWidth =
    totalWidth && totalWidth > fixedTotalWidth
      ? Math.round(
          (totalWidth - TIME_REPORT_GRID.employeeWidth) /
            timeOffReportTotalList.length
        )
      : TIME_REPORT_GRID.defaultWidth

  const getColumns = (): Column[] => {
    const columns: Column[] = [
      {
        columnId: 'template',
        width: TIME_REPORT_GRID.employeeWidth,
      },
    ]

    timeOffReportTotalList?.forEach((_, index) => {
      columns.push({
        columnId: `month-${index}`,
        width: colWidth,
      })
    })

    return columns
  }
  const headerRow: Row = {
    rowId: 'header',
    height: TIME_REPORT_GRID.headerHeight,
    cells: [
      {
        type: 'header',
        text: new Date(timeOffReportTotalList[0]?.month)
          .getFullYear()
          .toString(),
        className: 'time-col',
      },
      ...(_.map(timeOffReportTotalList, (p) => {
        return {
          type: 'header',
          text: new Date(p.month).toLocaleString('default', {
            month: 'long',
          }),
          className: 'time-col',
        }
      }) as any),
    ],
  }
  const filterArray = (objArray: TimeOffTemplates[], numArray: number[]) => {
    if (_.isEmpty(numArray)) {
      return objArray
    }
    return _.filter(objArray, (obj) =>
      _.includes(numArray, obj.timeoff_template_id)
    )
  }

  const flattenedData = _.flatten(
    (timeOffReportTotalList ?? []).map((report) => report?.data ?? [])
  )
  const allTemplates: TimeOffTemplates[] = _.uniqBy(
    flattenedData,
    'timeoff_template_id'
  )
  const filteredData = filterArray(allTemplates, selectedTimeOff)
  const dataRows = filteredData.map((template) => ({
    rowId: template?.timeoff_template_id.toString(),
    height: TIME_REPORT_GRID.rowHeight,
    cells: [
      {
        type: 'header',
        text: template.timeoff_template_name,
        className: 'timeoff-project',
      },
      ...timeOffReportTotalList.map((report) => {
        const match = report.data.find(
          (item) => item.timeoff_template_id === template.timeoff_template_id
        )
        return {
          type: 'header',
          text: match ? match.total_count.toString() : '0',
          className: 'time-col',
        }
      }),
    ],
  }))
  const getRows = (): Row[] => [headerRow, ...dataRows] as any

  const columns = getColumns()
  const rows = getRows()

  return (
    <Box sx={{ position: 'relative' }} ref={contentRef} id="my-grid">
      <ReactGrid
        columns={columns}
        rows={rows}
        stickyLeftColumns={1}
        stickyTopRows={1}
        enableRowSelection
        horizontalStickyBreakpoint={0}
      />
    </Box>
  )
}

export default TimeOffReportGrid
