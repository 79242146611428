import { MissingReportItem } from '@services/common.services'
import { useContext } from 'react'
import { DialogOptions } from './types'
import ConfirmContext from './DialogContext'

const useReport = (): ((
  options: DialogOptions
) => Promise<MissingReportItem>) => {
  const upload = useContext(ConfirmContext)
  return upload
}

export default useReport
