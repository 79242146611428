import { MetaPage } from '@constants/common.constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  EmailCampaignItem,
  EmailTemplateItem,
  FailureItem,
} from '@services/email.services'
import * as actions from '../actions'

type StateType = {
  emailCampaignList?: EmailCampaignItem[]
  emailCampaignMeta?: MetaPage
  emailCampaignDetail?: EmailCampaignItem

  emailTemplateList?: EmailTemplateItem[]
  emailTemplateMeta?: MetaPage
  emailTemplateDetail?: EmailTemplateItem

  failures?: FailureItem[]
}

const initialState: StateType = {
  emailCampaignList: undefined,
  emailCampaignMeta: undefined,
  emailCampaignDetail: undefined,

  emailTemplateList: undefined,
  emailTemplateMeta: undefined,
  emailTemplateDetail: undefined,

  failures: undefined,
}

export default createReducer(initialState, (builder) => {
  //Email campaign
  builder.addCase(actions.fetchEmailCampaignList.fulfilled, (state, action) => {
    state.emailCampaignList = action.payload.data
    state.emailCampaignMeta = action.payload.meta
  })
  builder.addCase(actions.emailCampaignDetail.fulfilled, (state, action) => {
    state.emailCampaignDetail = action.payload.data
  })
  builder.addCase(actions.emailCampaignUpdate.fulfilled, (state, action) => {
    state.emailCampaignDetail = action.payload.data
  })
  builder.addCase(actions.emailCampaignClear, (state) => {
    state.emailCampaignDetail = undefined
    state.failures = undefined
  })
  //Email template
  builder.addCase(actions.fetchEmailTemplateList.fulfilled, (state, action) => {
    state.emailTemplateList = action.payload.data
    state.emailTemplateMeta = action.payload.meta
  })
  builder.addCase(actions.emailTemplateDetail.fulfilled, (state, action) => {
    state.emailTemplateDetail = action.payload.data
  })
  builder.addCase(actions.emailTemplateUpdate.fulfilled, (state, action) => {
    state.emailTemplateDetail = action.payload.data
  })
  builder.addCase(actions.emailTemplateClear, (state) => {
    state.emailTemplateDetail = undefined
  })
  builder.addCase(actions.fetchEmailFailureList.fulfilled, (state, action) => {
    state.failures = action.payload.data
  })
  builder.addCase(actions.emailCampaignBulkSend.fulfilled, (state, action) => {
    state.emailCampaignDetail =
      state.emailCampaignDetail &&
      state.emailCampaignDetail?.id === action.payload?.data?.id
        ? action.payload.data
        : state.emailCampaignDetail
  })
})
