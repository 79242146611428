import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/notification.services'
import { NOTIFY_ACTION_TYPE } from './types'

// Events
export const badgeCount = createAsyncThunk<services.ICount>(
  NOTIFY_ACTION_TYPE.BADGE_COUNT,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.getBadgeCountService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const notifyList = createAsyncThunk<
  services.NotifyListResponse,
  services.NotificationParams
>(NOTIFY_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.getNotifyService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const notifyListShort = createAsyncThunk<
  services.NotifyListResponse,
  services.NotificationParams
>(NOTIFY_ACTION_TYPE.SHORT_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.getNotifyService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const markAllRead = createAsyncThunk<services.SuccessRespoinse>(
  NOTIFY_ACTION_TYPE.MARK_ALL_READ,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.markAllReadService()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const readOne = createAsyncThunk<
  services.NotifyResponse,
  services.ReadParams
>(NOTIFY_ACTION_TYPE.READ_ONE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.readService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
