import { ReactNode } from 'react'
import { NOTIFY_TYPE } from './notify.constants'
import {
  DateRange,
  Info,
  PendingActions,
  People,
  Wifi,
  WifiOff,
} from '@mui/icons-material'
import _ from 'lodash'

export type ItemIcon = {
  type: NOTIFY_TYPE
  icon: ReactNode
}

export const generateIcons = (params?: NOTIFY_TYPE): ReactNode => {
  const iconArray: ItemIcon[] = [
    {
      type: NOTIFY_TYPE.PROJECT,
      icon: (
        <People
          sx={{ fontSize: 14, color: (theme) => theme.palette.primary.dark }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.TIME_CORRECTION_APPROVER,
      icon: (
        <DateRange
          sx={{ fontSize: 14, color: (theme) => theme.palette.success.dark }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.TIME_CORRECTION_CREATOR,
      icon: (
        <DateRange
          sx={{ fontSize: 14, color: (theme) => theme.palette.success.dark }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.TIMEOFF_APPROVER,
      icon: (
        <PendingActions
          sx={{ fontSize: 14, color: (theme) => theme.palette.info.dark }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.TIMEOFF_CREATOR,
      icon: (
        <PendingActions
          sx={{ fontSize: 14, color: (theme) => theme.palette.info.main }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.REMOTE_WORK_APPROVER,
      icon: (
        <Wifi
          sx={{ fontSize: 14, color: (theme) => theme.palette.success.dark }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.REMOTE_WORK_CREATOR,
      icon: (
        <Wifi
          sx={{ fontSize: 14, color: (theme) => theme.palette.success.main }}
        />
      ),
    },
    {
      type: NOTIFY_TYPE.REMOTE_WORK_DISABLED,
      icon: (
        <WifiOff
          sx={{ fontSize: 14, color: (theme) => theme.palette.secondary.main }}
        />
      ),
    },
    // PROJECT MODULE
  ]

  const icon = _.find(iconArray, (i) => i.type === params)?.icon
  const data = icon ? icon : <Info sx={{ fontSize: 14 }} />

  return data
}
