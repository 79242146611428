import { DEMO_URL } from '@constants/demo.constants'
import { useState, useEffect } from 'react'

export const useDemo = (): { isDemo: boolean } => {
  const [isDemo, setIsDemo] = useState<boolean>(false)

  useEffect(() => {
    const checkIfDemo = () => {
      const currentDomain = window.location.hostname
      setIsDemo(currentDomain === DEMO_URL)
    }

    checkIfDemo()

    // Optional: Add event listener for URL changes if your app uses client-side routing
    window.addEventListener('popstate', checkIfDemo)

    return () => {
      window.removeEventListener('popstate', checkIfDemo)
    }
  }, [])

  return { isDemo: isDemo }
}
