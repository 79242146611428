import { TimeOffRequestResponse } from '@services/timeoff.services'
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react'
import TimeOffContext from './TimeOffContext'
import TimeOffDialog from './TimeOffDialog'

const TimeOffProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [resolveReject, setResolveReject] = useState<any[]>([])
  const [resolve, reject] = resolveReject

  const timeoff = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      setResolveReject([resolve, reject])
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleFailed = useCallback(() => {
    reject()
    // handleClose()
  }, [reject, handleClose])

  const handleDone = useCallback(
    (res: TimeOffRequestResponse) => {
      if (resolve) {
        resolve(res)
        handleClose()
      }
    },
    [resolve, handleClose]
  )

  return (
    <Fragment>
      <TimeOffContext.Provider value={timeoff}>
        {children}
      </TimeOffContext.Provider>
      <TimeOffDialog
        open={resolveReject.length === 2}
        onClose={() => {
          handleClose()
        }}
        onDone={handleDone}
        onFailed={handleFailed}
      />
    </Fragment>
  )
}

export default TimeOffProvider
