import { FilterParams, SearchFilter } from '@constants/common.constants'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/resource.services'
import { RESOURCE_ACTION_TYPE } from './types'

// POSITIONS

export const fetchPositionList = createAsyncThunk<
  services.PositionListResponse,
  FilterParams
>(
  RESOURCE_ACTION_TYPE.GET_POSITION_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.positionListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchCostList = createAsyncThunk<
  services.CostReportListResponse,
  FilterParams
>(
  RESOURCE_ACTION_TYPE.RESOURCE__COST_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.costRepotListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchPositionListAll = createAsyncThunk<
  services.PositionListAllResponse,
  void
>(RESOURCE_ACTION_TYPE.GET_POSITION_ALL, async (_, { rejectWithValue }) => {
  try {
    const res = await services.positionListAllService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createPosition = createAsyncThunk<
  services.PositionResponse,
  services.PositionCreateParams
>(RESOURCE_ACTION_TYPE.CREATE_POSITION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.positionCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const showPosition = createAsyncThunk<
  services.PositionResponse,
  services.PositionDetailParams
>(RESOURCE_ACTION_TYPE.SHOW_POSITION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.positionDetailService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updatePosition = createAsyncThunk<
  services.PositionResponse,
  services.PositionUpdateParams
>(RESOURCE_ACTION_TYPE.UPDATE_POSITION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.positionUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updatePositionOrder = createAsyncThunk<
  services.PositionListAllResponse,
  services.PositionUpdateOrderParams
>(
  RESOURCE_ACTION_TYPE.UPDATE_POSITION_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.positionUpdateOrderService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deletePosition = createAsyncThunk<
  services.PositionResponse,
  services.PositionDeleteParams
>(RESOURCE_ACTION_TYPE.DELETE_POSITION, async (params, { rejectWithValue }) => {
  try {
    const res = await services.positionDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

// DEPARTMENT ACTIONS

export const fetchDepartmentList = createAsyncThunk<
  services.DepartmentListResponse,
  FilterParams
>(
  RESOURCE_ACTION_TYPE.GET_DEPARTMENT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.departmentListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createDepartment = createAsyncThunk<
  services.DepartmentResponse,
  services.DepartmentCreateParams
>(
  RESOURCE_ACTION_TYPE.CREATE_DEPARTMENT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.departmentCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const showDepartment = createAsyncThunk<
  services.DepartmentResponse,
  services.DepartmentDetailsParams
>(RESOURCE_ACTION_TYPE.SHOW_DEPARTMENT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.departmentDetailsService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateDepartment = createAsyncThunk<
  services.DepartmentResponse,
  services.DepartmentUpdateParams
>(
  RESOURCE_ACTION_TYPE.UPDATE_DEPARTMENT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.departmentUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteDepartment = createAsyncThunk<
  services.DepartmentResponse,
  services.DepartmentDeleteParams
>(
  RESOURCE_ACTION_TYPE.DELETE_DEPARTMENT,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.departmentDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

// ROLE

export const fetchRoleModuleList =
  createAsyncThunk<services.RoleModuleListResponse>(
    RESOURCE_ACTION_TYPE.GET_ROLE_MODULE_LIST,
    async () => {
      try {
        const res = await services.roleModuleListService()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return error.response.data
      }
    }
  )

export const fetchRoleList = createAsyncThunk<
  services.RoleListResponse,
  FilterParams
>(RESOURCE_ACTION_TYPE.GET_ROLE_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.roleListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createRole = createAsyncThunk<
  services.RoleResponse,
  services.RoleCreateParams
>(RESOURCE_ACTION_TYPE.CREATE_ROLE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.roleCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateRole = createAsyncThunk<
  services.RoleResponse,
  services.RoleUpdateParams
>(RESOURCE_ACTION_TYPE.UPDATE_ROLE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.roleUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteRole = createAsyncThunk<
  services.RoleResponse,
  services.RoleDeleteParams
>(RESOURCE_ACTION_TYPE.DELETE_ROLE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.roleDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchRoleListAll = createAsyncThunk<
  services.RoleListResponse,
  SearchFilter
>(RESOURCE_ACTION_TYPE.ROLE_LIST_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.roleListAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

// Resource Plan
export const fetchResourcePlanList = createAsyncThunk<
  services.ResourcePlanListResponse,
  FilterParams
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchResourcePlanListAll = createAsyncThunk<
  services.ResourcePlanAllResponse,
  SearchFilter
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_ALL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanListAllService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createResourcePlan = createAsyncThunk<
  services.ResourcePlanCreateResponse,
  services.ResourcePlanCreateParams
>(
  RESOURCE_ACTION_TYPE.CREATE_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateResourcePlan = createAsyncThunk<
  services.ResourcePlanResponse,
  services.ResourcePlanUpdateParams
>(
  RESOURCE_ACTION_TYPE.UPDATE_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const showResourcePlan = createAsyncThunk<
  services.ResourcePlanResponse,
  services.ResourcePlanIdParams
>(
  RESOURCE_ACTION_TYPE.SHOW_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response)
    }
  }
)

export const clearResourcePlanDetails = createAction(
  RESOURCE_ACTION_TYPE.CLEAR_RESOURCE_PLAN
)

export const renameResourcePlan = createAsyncThunk<
  services.ResourcePlanResponse,
  services.ResourcePlanRenameParams
>(
  RESOURCE_ACTION_TYPE.RENAME_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanRenameService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const confirmResourcePlan = createAsyncThunk<
  services.ResourcePlanStatusResponse,
  services.ResourcePlanIdParams
>(
  RESOURCE_ACTION_TYPE.CONFIRM_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanConfirmService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const refuseResourcePlan = createAsyncThunk<
  services.ResourcePlanStatusResponse,
  services.ResourcePlanIdParams
>(
  RESOURCE_ACTION_TYPE.REFUSE_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanRefuseService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const copyResourcePlan = createAsyncThunk<
  services.ResourcePlanResponse,
  services.ResourcePlanCopyParams
>(
  RESOURCE_ACTION_TYPE.COPY_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanCopyService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteResourcePlan = createAsyncThunk<
  services.ResourcePlanResponse,
  services.ResourcePlanIdParams
>(
  RESOURCE_ACTION_TYPE.DELETE_RESOURCE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchResourcePlanUsers = createAsyncThunk<
  services.ResourcePlanUsersResponse,
  services.ResourcePlanUsersArgs
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_USERS,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanUsersService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const batchResourcePlan = createAsyncThunk<
  services.ResourcePlanBatchResponse,
  services.ResourcePlanIdParams
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_BATCH,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanBatchService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchResourcePlanActivities = createAsyncThunk<
  services.ResourcePlanActivityResponse,
  services.ResourcePlanIdParams
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_ACTIVITY,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanActivityService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateResourcePlanItems = createAsyncThunk<
  services.ResourcePlanItemResponse,
  services.ResourcePlanUpdateItemPositionParams
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_UPDATE_ITEM_POSITION,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanUpdateItemPosition(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const addResourceUsers = createAsyncThunk<
  services.AddRemoveResponse,
  services.AddRemoveUser
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_ADD_USER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanAddUserService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeResourceUsers = createAsyncThunk<
  services.AddRemoveResponse,
  services.AddRemoveUser
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_REMOVE_USER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanRemoveUserService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const addableResourceUsers = createAsyncThunk<
  services.ResourceAddableUsersResponse,
  string
>(
  RESOURCE_ACTION_TYPE.RESOURCE_PLAN_ADDABLE_USERS,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.resourcePlanAddaleUsersService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
