import { ContentResponse } from '@services/settings.services'
import { useAppDispatch } from '@store/hooks'
import { clearHelp, fetchPageContent } from '@store/settings/actions'
import { getHelp } from '@store/settings/selectors'
import { useSelector } from 'react-redux'

interface returnType {
  data: ContentResponse
  fetchPage: (id: string) => void
  clear: () => void
}

const useWp = (): returnType => {
  const data = useSelector(getHelp)
  const dispatch = useAppDispatch()
  const fetchPage = (id: string) => {
    dispatch(fetchPageContent(id))
  }
  const clear = () => dispatch(clearHelp())

  return { data, fetchPage, clear }
}

export default useWp
