/* eslint-disable no-console */
import { StyledTextField } from '@components/@material-extend'
import StyledSelect from '@components/@material-extend/StyledSelect'
import {
  TIMESHEET_WORKSPACE,
  WORK_TIME_ACTION,
} from '@constants/time.constants'
import useWorkspace from '@containers/Workspace/useWorkspace'
import useWorkspaceChangeForm from '@containers/Workspace/useWorkspaceChangeForm'
import {
  BusinessOutlined,
  Close,
  LocationOffOutlined,
  Wifi,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import {
  TimeRecordItem,
  TimesheetItem,
  TimesheetWorkspace,
} from '@services/time.services'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface WorkspaceConfirmationProps {
  open: boolean
  timeRecord: TimeRecordItem | TimesheetItem | null
  action: WORK_TIME_ACTION
  onCancel: () => void
  fetchList?: () => void
}

const WorkspaceConfirmationDialog = ({
  timeRecord,
  action,
  onCancel,
  open,
  fetchList,
}: WorkspaceConfirmationProps) => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState<boolean>()
  const { Controller, methods } = useWorkspaceChangeForm()
  const { fetchAll, selectableWorkspaceList, verify, verifyMeta } =
    useWorkspace()
  const [error, setError] = useState<any | null>(null)

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    if (!open) {
      reset({ workspace_id: { id: '', name: '' }, code: null })
      setEdit(false)
      setError(false)
    }
  }, [open])

  const onSubmit = (values: any) => {
    setError(null)
    values &&
      timeRecord &&
      verify({
        id: values.workspace_id,
        params: {
          time_record_id: timeRecord.id,
          action_type: action,
          code: values.code.toString(),
        },
      })
        .unwrap()
        .then(() => {
          fetchList && fetchList()
          onCancel()
        })
        .catch((err: any) => {
          setError(err)
        })
  }

  const getPlaceholder = (workspace: TimesheetWorkspace) => {
    if (workspace.attributes.type === TIMESHEET_WORKSPACE.office) {
      return (
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pb: 2,
            }}
          >
            <BusinessOutlined sx={{ fontSize: 40 }} />
          </Box>

          {!edit && (
            <Alert severity="info" variant="outlined">
              {t('WORKSPACE.office_placeholder', {
                office: _.get(
                  workspace,
                  'attributes.workspace.data.attributes.name'
                ),
              })}
            </Alert>
          )}
        </Box>
      )
    } else if (workspace.attributes.type === TIMESHEET_WORKSPACE.remote) {
      return (
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pb: 2,
            }}
          >
            <Wifi sx={{ fontSize: 40 }} />
          </Box>

          {!edit && (
            <Alert severity="info" variant="outlined">
              {t('WORKSPACE.remote_placeholder')}
            </Alert>
          )}
        </Box>
      )
    } else if (workspace.attributes.type === TIMESHEET_WORKSPACE.no_location) {
      return (
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pb: 2,
            }}
          >
            <LocationOffOutlined sx={{ fontSize: 40 }} />
          </Box>

          {!edit && (
            <Alert severity="info" variant="outlined">
              {t('WORKSPACE.no_location_placeholder')}
            </Alert>
          )}
        </Box>
      )
    }
    return null
  }

  return (
    <Dialog
      open={open}
      sx={{ minWidth: '300px' }}
      onClose={() => onCancel()}
      fullWidth
    >
      <DialogTitle>
        {t(`WORKSPACE.${action}_location`)}
        <IconButton
          aria-label="close"
          onClick={() => onCancel()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {action === WORK_TIME_ACTION.start &&
            timeRecord?.attributes.start_workspace &&
            getPlaceholder(timeRecord.attributes.start_workspace.data)}
          {action === WORK_TIME_ACTION.end &&
            timeRecord?.attributes.end_workspace &&
            getPlaceholder(timeRecord.attributes.end_workspace.data)}
        </Box>

        {error &&
          (error.error && error.error.message ? (
            <Alert severity="error" sx={{ mb: 1 }} variant="outlined">
              {t(`ERROR.${error.error.message}`)}
            </Alert>
          ) : (
            <Alert severity="error" sx={{ mb: 1 }} variant="outlined">
              {t('TOASTS.unknown_rejected')}
            </Alert>
          ))}
        {edit && (
          <form id="location-change" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%', pb: 1 }} data-test-id="name-parent">
              <Controller
                name="workspace_id"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <Box sx={{ width: '100%' }}>
                    <StyledSelect
                      labelPrimary={t('WORKSPACE.title')}
                      options={selectableWorkspaceList.filter((workspace) => {
                        if (action === WORK_TIME_ACTION.start) {
                          return (
                            Number(workspace.id) !==
                            Number(
                              _.get(
                                timeRecord,
                                'attributes.start_workspace.data.attributes.workspace.data.id'
                              )
                            )
                          )
                        }
                        if (action === WORK_TIME_ACTION.end) {
                          return (
                            Number(workspace.id) !==
                            Number(
                              _.get(
                                timeRecord,
                                'attributes.end_workspace.data.attributes.workspace.data.id'
                              )
                            )
                          )
                        }
                      })}
                      required
                      inputRef={ref}
                      fullWidth={false}
                      error={errors.workspace_id ? true : false}
                      {...rest}
                    />
                  </Box>
                )}
              />
            </Box>
            <Box data-test-id="name-parent" mb={1}>
              <Controller
                name="code"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <StyledTextField
                    placeholder={t('WORKSPACE.verification_code')}
                    inputRef={ref}
                    fullWidth
                    required
                    labelPrimary={t('WORKSPACE.verification_code')}
                    error={errors.code ? true : false}
                    helperText={errors.code ? errors.code.message : ''}
                    {...rest}
                  />
                )}
              />
            </Box>
            <Stack
              direction={'row'}
              sx={{
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button
                color={'primary'}
                variant="outlined"
                onClick={() => {
                  onCancel()
                }}
              >
                {t('SYSCOMMON.cancel')}
              </Button>
              <LoadingButton
                data-test-id="create-button"
                variant="contained"
                type="submit"
                loading={verifyMeta.pending}
                disabled={!isDirty || !isValid}
              >
                {t('SYSCOMMON.save')}
              </LoadingButton>
            </Stack>
          </form>
        )}
      </DialogContent>
      {!edit && (
        <DialogActions sx={{ pt: '0' }}>
          <Stack
            direction={'row'}
            sx={{ width: '100%', justifyContent: 'space-between' }}
          >
            <Button variant="outlined" onClick={() => setEdit(true)}>
              <Typography>{t('WORKSPACE.change_location')}</Typography>
            </Button>
            <Button
              color={'primary'}
              variant="contained"
              onClick={() => {
                onCancel()
              }}
            >
              {t('SYSCOMMON.confirm')}
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default WorkspaceConfirmationDialog
