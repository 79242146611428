import { URI } from '@constants/uri.constants'
import { api } from './api'
import { EMPLOYEE_CURRENT_DAY_STATUS } from '@constants/user.constants'
import { ShortUserItem } from './user.services'

export type DashboardResponse = {
  employee: DashboardEmployeeItem
  project: DashboardProjectItem
  position: DashboardPositionItem[]
}

export type DashboardVacationBalanceItem = {
  days: number
  id: null | number
  remaining_days: number
  used_days: number
  year: number
}

export type DashboardEmployeeItem = {
  male?: number
  female?: number
  other?: number
  total: number
  vacation_balance: DashboardVacationBalanceItem[]
  current_vacation: number
  current_working: number
  current_not_started_work: number
  current_work_done: number
  compensatory_day: number
  current_office_workers: number
  current_remote_workers: number
}

export type DashboardProjectItem = {
  on_hold: number
  newly_created: number
  completed: number
  in_progress: number
  total: number
}
export type DashboardPositionItem = {
  name: string
  count: number
}

export type DashboardProjectParams = { salary_month: string }

export type ProjectSpentItem = { id: number; time_spent: number; name: string }
export type ProjectNonSpentItem = { id: number; name: string }

export type ProjectOverviewResponse = {
  spent_time_projects: ProjectSpentItem[]
  non_spent_time_projects: ProjectNonSpentItem[]
}

export type DashboardEmployeeParams = {
  type: EMPLOYEE_CURRENT_DAY_STATUS
}

export type DashboardEmployeeResponse = {
  data: ShortUserItem[]
}

export const dashboardService = async (): Promise<DashboardResponse> => {
  const { data } = await api.get<DashboardResponse>(URI.DASHBOARD)
  return data
}

export const projectService = async (
  params: DashboardProjectParams
): Promise<ProjectOverviewResponse> => {
  const { data } = await api.get<ProjectOverviewResponse>(
    `${URI.DASHBOARD}/project_info`,
    { params: params }
  )
  return data
}

export const dashboardEmployee = async (
  params: DashboardEmployeeParams
): Promise<DashboardEmployeeResponse> => {
  const { data } = await api.get<DashboardEmployeeResponse>(
    `${URI.DASHBOARD_EMPLOYEE}`,
    { params: params }
  )
  return data
}
