export enum COMPANY_ACTION_TYPE {
  // Company
  GET_COMPANY_LIST = 'company/list',
  GET_COMPANY_ALL = 'company/all',
  CREATE_COMPANY = 'company/add',
  SHOW_COMPANY = 'company/details',
  GET_COMPANY_DIVISIONS = 'company/divisions',
  UPDATE_COMPANY = 'company/update',
  DELETE_COMPANY = 'company/delete',
  CLEAR_COMPANY = 'company/clear',
  // Partner Division
  GET_DIVISION_LIST = 'division/list',
  UPDATE_DIVISION = 'division/update',
  UPDATE_DIVISION_ORDER = 'division/updateOrder',
  CREATE_DIVISION = 'division/add',
  DELETE_DIVISION = 'division/delete',
  DIVISION_ALL = 'division/all',
}
