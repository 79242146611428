import { DialogProps } from '@mui/material'
import * as React from 'react'
import { Accept } from 'react-dropzone'

export interface UploadOptions {
  title?: React.ReactNode
  dialogProps?: Omit<DialogProps, 'open'>
  backDropClose?: boolean
  maxSize?: number
  quality?: number
  upload_uri?: string
  accept?: Accept
  replaceUri?: string
  type: UploadType
  project_id?: string
}

export interface UploaderProviderProps {
  defaultOptions?: UploadOptions
}

export enum UploadType {
  PROFILE = 'profile',
  PROJECT = 'projects',
  EMAIL = 'email',
  EMAIL_TEMPLATE_THUMBNAIL = 'email_template_thumbnail',
  TIMEOFF = 'timeoff',
  FILE = 'file',
}
