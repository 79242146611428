export enum PROJECT_ACTION_TYPE {
  GET_PROJECT_LIST = 'project/list',
  GET_PROJECT_LIST_ALL = 'project/list/all',
  PROJECT_ALL = 'project/list/all_list',
  CREATE_PROJECT = 'project/create',
  UPDATE_PROJECT = 'project/update',
  SHOW_PROJECT = 'project/show',
  DELETE_PROJECT = 'project/delete',
  MEMBER_LIST = 'project/members',
  ADDABLE_LIST = 'project/addable',
  ADD_USER = 'project/addUser',
  UPDATE_USER = 'project/updateUser',
  REMOVE_USER = 'project/removeUser',
  CLEAR_PROJECT = 'project/clear',
  PROJECT_LIST_COST = 'project/listCost',
  PROJECT_ACTIVITY = 'project/activity',
  MEMBER_HISTORY = 'project/memberHIstory',
  CLEAR_MEMBER_HISTORY = 'project/clearMemberHistory',
  UPDATE_PROJECT_IMAGE = 'project/updateImage',
  GET_PARTNER_PURCHASE_ORDER_LIST = 'project/partnerPurchaseOrderList',
  CREATE_PARTNER_PURCHASE_ORDER = 'project/partnerPurchaseOrderCreate',
  UPDATE_PARTNER_PURCHASE_ORDER = 'project/partnerPurchaseOrderUpdate',
  SHOW_PARTNER_PURCHASE_ORDER = 'project/partnerPurchaseOrderShow',
  DELETE_PARTNER_PURCHASE_ORDER = 'project/partnerPurchaseOrderDelete',
  GET_USER_PROJECT_HISTORY_LIST = 'project/userProjectHistory',
  MY_LIST = 'project/myList',
  PARTNER_PURCHASE_ORDER_PROJECT = 'project/partnerPurchaseOrderProject',
  PROJECT_WITH_INVOICE = 'project/listWithInvoice',
  CLEAR_PARTNER_PURCHASE_ORDER_LIST = 'project/clearPartnerPurchaseOrder',
}
