import {
  REMOTE_WORK_REQUEST_STATUS,
  TIMESHEET_WORKSPACE,
  TIME_CORRECTION_STATUS,
} from '@constants/time.constants'
import { api } from './api'
import { URI } from '@constants/uri.constants'
import { Direction, FilterParams, MetaPage } from '@constants/common.constants'
import {
  TIME_OFF_TEMPLATE_PAY_STATUS,
  TIME_OFF_TEMPLATE_RANGE_TYPE,
} from '@constants/timeoff.constants'
import { DailyReport } from './dailyreport.services'
import {
  ApprovalTemplateItem,
  CompensatoryDayItem,
  TimeOffApproverItem,
} from './timeoff.services'
import { EmployeeShortItem, ShortUserItem } from './user.services'
import { WorkspaceItem } from './settings.services'

export type CostReportParams = {
  report_start_date: any
  partner_division_ids: string[]
  report_end_date: string | null
}

export type TimeRecordItem = {
  id: string
  type: 'time_record'
  attributes: {
    work_date: string
    start_at: string
    end_at?: string | null
    user_code: string
    firstname: string
    lastname: string
    start_workspace?: { data: TimesheetWorkspace }
    end_workspace?: { data: TimesheetWorkspace }
  }
}

export type BreakRecordItem = {
  id: string
  type: 'break_record'
  attributes: {
    start_at: string
    end_at: string | null
    time_record: TimeRecordItem
  }
}

export type TimeStartResponse = {
  data: TimeRecordItem
}

export type TimeRecordsResponse = {
  data: TimeRecordItem[]
}

export type BreakResponse = {
  data: BreakRecordItem
}

export type TimesheetWorkspace = {
  id: string
  type: 'timesheet_workspace'
  attributes: {
    type: TIMESHEET_WORKSPACE
    workspace: WorkspaceItem | null
  }
}

export type TimesheetItem = {
  id: string
  type: 'timesheet'
  attributes: {
    work_date: string
    start_time: string
    end_time: string | null
    worked_hours: string | null
    overtime: string | null
    early_leave: string | null
    late_arrival: string | null
    absence: string | null
    total_deduct: string | null
    corrected_cnt: number
    break_records: {
      data: BreakRecordItem[]
    }
    start_workspace: {
      data: TimesheetWorkspace
    }
    end_workspace: {
      data: TimesheetWorkspace
    }
  }
}

export type TimesheetSumItem = {
  total_deduct_sum: 'string'
  worked_hours_sum: 'string'
  overtime_sum: 'string'
  total_deduction_day: number
  holiday_overtime: 'string'
  work_days: number
}

export type TimesheetRequestItem = {
  id: string
  type: 'timesheet_request'
  attributes: {
    name: 'string'
    start_at: Date
    end_at: Date
    create_at: Date
    pay_status: TIME_OFF_TEMPLATE_PAY_STATUS
    range_type: TIME_OFF_TEMPLATE_RANGE_TYPE
    identifier: string
    compensatory_day_data: {
      start_at: Date
      end_at: Date
    }
  }
}

export type TimesheetRemoteWorkRequestItem = {
  id: string
  type: 'timesheet_remote_work_requests'
  attributes: {
    status: REMOTE_WORK_REQUEST_STATUS
    work_date: Date
  }
}

export type TimesheetResponse = {
  rec: {
    data: TimesheetItem[]
  }
  sums: TimesheetSumItem
  remote_request: {
    data: TimesheetRemoteWorkRequestItem[]
  }
  request: {
    data: TimesheetRequestItem[]
  }
  user: {
    data: EmployeeShortItem
  }
}

export type TimesheetParams = {
  salary_month: Date
  user_id: string
}

export type TimeQrParams = {
  totp: number
  uid: string
}

export type TimeReportItem = {
  user_id: string
  user_code: string
  firstname_en: string
  lastname_en: string
  late_arrival_hour: number
  early_leave_hour: number
  absence_hour: number
  total_deduct_hours: number
  overtime: number
  overtime_holiday: number
  deduction_days: number
  overtime_weekend: number
  actual_overtime: number
  worked_hours: number
  taken_count: number
  position: {
    id: string
    name: string
    short_name: string
    display_order: number
  }
  templates: {
    template_id: string
    name: string
    value: number
  }[]
}

export type TimeReportTotalItem = {
  absence_hour: number
  early_leave_hour: number
  late_arrival_hour: number
  overtime: number
  overtime_holiday: number
  total_deduct_hours: number
  templates: TimeReportTimeOffTemplate[]
  deduction_days: number
  taken_count: number
}

export type TimeReportTimeOffTemplate = {
  template_id: string
  name: string
  value: number
}

export type TimeReportResponse = {
  data: TimeReportItem[]
  resto_integrated: boolean
  total_sum: TimeReportTotalItem
}

export type TimeReportParam = {
  salary_month: Date
  hr_flag?: boolean
  user_ids?: string[]
  filter_by?: string[]
}

export type TimeReportDownloadParam = {
  salary_month: Date
  language: string
  hr_flag?: boolean
  user_ids?: string[]
}

export type TimeCorrectionItem = {
  id: string
  type: 'time_correction_request'
  attributes: {
    user_id: string
    work_date: Date
    start_at: Date
    end_at: Date
    break_records: {
      id: string
      start_at: Date
      end_at: Date
    }[]
    status: TIME_CORRECTION_STATUS
    reason: string
    created_at: Date
    is_my_step: boolean
    is_allow_reason: boolean
    can_delete: boolean | null
    is_delete_request: boolean
    time_record: {
      start_at: Date
      end_at: Date
      break_records: {
        id: string
        start_at: Date
        end_at: Date
      }[]
    } | null
    total_step: number
    current_step: number
    user: {
      data: EmployeeShortItem
    }
    time_correction_approvers: {
      data: {
        id: string
        type: 'timeoff_approver'
        attributes: {
          step_index: number
          status: string
          reason: null | string
          approver: {
            data: any
          }
          next_approver: {
            data: any
          }
        }
      }[]
    }
    time_correction_template: { data: ApprovalTemplateItem }
    compensatory_day: {
      data: CompensatoryDayItem[]
    }
    deleted_by: {
      data: any
    }
  }
}

export type TimeCorrectionResponseItem = {
  id: string
  type: 'time_correction_request'
  attributes: {
    user_id: string
    work_date: Date
    start_at: Date
    end_at: Date
    break_records: {
      id: string
      start_at: Date
      end_at: Date
    }[]
    status: TIME_CORRECTION_STATUS
    reason: string
    created_at: Date
  }
}

export type TimeCorrectionListResponse = {
  data: TimeCorrectionItem[]
  meta: MetaPage
}

export type TimeCardItem = {
  id: string
  type: 'time_record'
  attributes: {
    work_date: Date
    start_at: string
    end_at: string | null
    comment: string | null
    user_code: string
    firstname: string
    lastname: string
    overtime: string | null
    is_report_editable?: boolean
    break_records: {
      data: {
        id: string
        type: 'break_record'
        attributes: { start_at: Date; end_at: Date }
      }[]
    }
    daily_report: {
      data: DailyReport
    }
  }
}

export type TimeCardResponse = {
  data: TimeCardItem
}

export type TimeCorrectionTemplateItem = {
  id: string
  type: 'time_correction_template'
  attributes: {
    name: string
    approval_template: {
      data: ApprovalTemplateItem
    }
  }
}

export type TimeCorrectionTemplatesResponse = {
  data: TimeCorrectionTemplateItem
}

export type TimeCorrectionTemplatesUpdateParams = {
  name: string
  approval_template_id: string
}

export type TimeCorrectionResponse = {
  data: TimeCorrectionItem
}

export type TimeCorrectionListParams = {
  direction: Direction
  order_by: string
  status: TIME_CORRECTION_STATUS[]
  salary_month: Date
}

export type TimeCorrectionApproveListParams = {
  direction: Direction
  order_by: string
  keyword: string
  per_page: string
  page: string
  status: TIME_CORRECTION_STATUS[]
}

export type TimeCardParams = {
  work_date: Date
}

export type TimeCorrectionCreateParams = {
  work_date: Date
  start_at: Date
  end_at: Date
  reason: string
  is_delete_request: boolean
  approver_id: string
  break_records: {
    id: string
    start_at: Date
    end_at: Date
  }[]
}

export type TimeCorrectionUpdateParams = {
  params: TimeCorrectionCreateParams
  id: string
}

export type TimeCorrectionApproveParam = {
  id: string
  params?: {
    approver_id: string
  }
}

export type TimeCorrectionIdParam = string

export type TimeCorrectionDenyParams = {
  id: TimeCorrectionIdParam
  params: {
    reason?: string
  }
}

export type GeoLocation = {
  latitude: number
  longitude: number
  accuracy: number
}

export type StartEndTimeParams = {
  location?: GeoLocation | null
}

export const timeStartService = async (
  params: StartEndTimeParams
): Promise<TimeStartResponse> => {
  const { data } = await api.post<TimeStartResponse>(
    `${URI.TIME_RECORD}/work_start`,
    params
  )
  return data
}

export const timeEndService = async (
  params: StartEndTimeParams
): Promise<TimeStartResponse> => {
  const { data } = await api.post<TimeStartResponse>(
    `${URI.TIME_RECORD}/work_end`,
    params
  )
  return data
}

export const timeStartQrService = async (
  params: TimeQrParams
): Promise<TimeStartResponse> => {
  const { data } = await api.post<TimeStartResponse>(
    `${URI.TIME_RECORD}/work_start_qr`,
    params
  )
  return data
}

export const timeEndQrService = async (
  params: TimeQrParams
): Promise<TimeStartResponse> => {
  const { data } = await api.post<TimeStartResponse>(
    `${URI.TIME_RECORD}/work_end_qr`,
    params
  )
  return data
}

export const timeRecordsService = async (): Promise<TimeRecordsResponse> => {
  const { data } = await api.get<TimeRecordsResponse>(`${URI.TIME_RECORD}`)
  return data
}

export const breakStartService = async (): Promise<BreakResponse> => {
  const { data } = await api.post<BreakResponse>(
    `${URI.BREAK_RECORD}/break_start`
  )
  return data
}

export const breakEndService = async (): Promise<BreakResponse> => {
  const { data } = await api.post<BreakResponse>(
    `${URI.BREAK_RECORD}/break_end`
  )
  return data
}

export const timesheetService = async (
  params: TimesheetParams
): Promise<TimesheetResponse> => {
  const { data } = await api.get<TimesheetResponse>(
    `${URI.TIME_RECORD}/timesheet`,
    { params }
  )
  return data
}

export const timeReportService = async (
  params: TimeReportParam
): Promise<TimeReportResponse> => {
  const { data } = await api.get<TimeReportResponse>(
    `${URI.TIME_REPORT}.json`,
    {
      params: params,
    }
  )
  return data
}

export const timeReportDownloadService = async (
  params: TimeReportDownloadParam
): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${URI.TIME_REPORT}.xlsx`, {
    params: params,
    responseType: 'blob',
  })
  return data
}

export const timeCorrectionListService = async (
  params: TimeCorrectionListParams
): Promise<TimeCorrectionListResponse> => {
  const { data } = await api.get<TimeCorrectionListResponse>(
    `${URI.TIME_CORRECTION}`,
    { params }
  )
  return data
}

export const timeCorrectionApproveListService = async (
  params: TimeCorrectionApproveListParams
): Promise<TimeCorrectionListResponse> => {
  const { data } = await api.get<TimeCorrectionListResponse>(
    `${URI.TIME_CORRECTION}/approve_list`,
    { params }
  )
  return data
}

export const timeCorrectionCreateService = async (
  params: TimeCorrectionCreateParams
): Promise<TimeCorrectionResponse> => {
  const { data } = await api.post<TimeCorrectionResponse>(
    `${URI.TIME_CORRECTION}`,
    params
  )
  return data
}

export const timeCorrectionUpdateService = async (
  args: TimeCorrectionUpdateParams
): Promise<TimeCorrectionResponse> => {
  const { data } = await api.put<TimeCorrectionResponse>(
    `${URI.TIME_CORRECTION}/${args.id}`,
    args.params
  )
  return data
}

export const timeCorrectionDeleteService = async (
  id: TimeCorrectionIdParam
): Promise<TimeCorrectionResponse> => {
  const { data } = await api.delete<TimeCorrectionResponse>(
    `${URI.TIME_CORRECTION}/${id}`
  )
  return data
}

export const timeCorrectionApproveService = async (
  params: TimeCorrectionApproveParam
): Promise<TimeCorrectionResponse> => {
  const { data } = await api.post<TimeCorrectionResponse>(
    `${URI.TIME_CORRECTION}/${params.id}/approve`,
    params.params
  )
  return data
}

export const timeCorrectionDenyService = async (
  params: TimeCorrectionDenyParams
): Promise<TimeCorrectionResponse> => {
  const { data } = await api.post<TimeCorrectionResponse>(
    `${URI.TIME_CORRECTION}/${params.id}/deny`,
    params.params
  )
  return data
}

export const TimeCardService = async (
  params: TimeCardParams
): Promise<TimeCardResponse> => {
  const { data } = await api.get<TimeCardResponse>(
    `${URI.TIME_RECORD}/timecard`,
    {
      params,
    }
  )
  return data
}

export const fetchTimeCorrectionTemplateService =
  async (): Promise<TimeCorrectionTemplatesResponse> => {
    const { data } = await api.get<TimeCorrectionTemplatesResponse>(
      `${URI.TIME_CORRECTION_TEMPLATE}/show_template`
    )
    return data
  }

export const updateTimeCorrectionTemplateService = async (
  params: TimeCorrectionTemplatesUpdateParams
): Promise<TimeCorrectionTemplatesResponse> => {
  const { data } = await api.post<TimeCorrectionTemplatesResponse>(
    `${URI.TIME_CORRECTION_TEMPLATE}/update_template`,
    params
  )
  return data
}

export type RemoteWorkIdType = string

export type RemoteWorkTemplateItem = {
  id: RemoteWorkIdType
  type: 'remote_work_template'
  attributes: {
    name: string
    approval_template: {
      data: ApprovalTemplateItem
    }
    limit_exceeded_approval_template: {
      data: ApprovalTemplateItem
    }
  }
}

export type RemoteWorkTemplateUpdateParams = {
  name: string
  approval_template_id: RemoteWorkIdType
  limit_exceeded_approval_template_id: RemoteWorkIdType
}

export type RemoteWorkTemplateResponse = {
  data: RemoteWorkTemplateItem
}

export const remoteWorkTemplateDetailService =
  async (): Promise<RemoteWorkTemplateResponse> => {
    const { data } = await api.get<RemoteWorkTemplateResponse>(
      `${URI.REMOTE_WORK_TEMPLATE}/show_template`
    )
    return data
  }

export const remoteWorkTemplateUpdateService = async (
  params: RemoteWorkTemplateUpdateParams
): Promise<RemoteWorkTemplateResponse> => {
  const { data } = await api.post<RemoteWorkTemplateResponse>(
    `${URI.REMOTE_WORK_TEMPLATE}/update_template`,
    params
  )
  return data
}

//SETTINGS

export type RemoteWorkSettingsItem = {
  id: RemoteWorkIdType
  type: 'remote_work_settings'
  attributes: {
    actioned_at: Date
    actioned_by: {
      data: EmployeeShortItem
    }
    disable_start_date: Date
    disable_end_date: Date
    user: {
      data: EmployeeShortItem
    }
    reason: string
  }
}

export type RemoteWorkSettingsListResponse = {
  data: RemoteWorkSettingsItem[]
  meta: MetaPage
}

export type RemoteWorkSettingsResponse = {
  data: RemoteWorkSettingsItem
}

export type RemoteWorkSettingsCreateParams = {
  user_id: string
  disable_start_date: Date
  disable_end_date: Date
  reason: string
}

export type RemoteWorkSettingsForceCreateParams = {
  user_id: string
  disable_start_date: Date
  disable_end_date: Date
  reason: string
}

export type RemoteWorkSettingsUpdateParams = {
  id: string
  params: RemoteWorkSettingsCreateParams
}

export type RemoteWorkSettingsForceUpdateParams = {
  id: string
  params: RemoteWorkSettingsForceCreateParams
}

export const remoteWorkSettingsListService = async (
  params: FilterParams
): Promise<RemoteWorkSettingsListResponse> => {
  const { data } = await api.get<RemoteWorkSettingsListResponse>(
    `${URI.REMOTE_WORK_SETTINGS}`,
    { params: params }
  )
  return data
}

export const remoteWorkSettingsMyListService = async (
  params: FilterParams
): Promise<RemoteWorkSettingsListResponse> => {
  const { data } = await api.get<RemoteWorkSettingsListResponse>(
    `${URI.REMOTE_WORK_SETTINGS}/list`,
    { params: params }
  )
  return data
}

export const remoteWorkSettingsMyListAllService =
  async (): Promise<RemoteWorkSettingsListResponse> => {
    const { data } = await api.get<RemoteWorkSettingsListResponse>(
      `${URI.REMOTE_WORK_SETTINGS}/list_all`
    )
    return data
  }

export const remoteWorkSettingsCreateService = async (
  params: RemoteWorkSettingsCreateParams
): Promise<RemoteWorkSettingsResponse> => {
  const { data } = await api.post<RemoteWorkSettingsResponse>(
    `${URI.REMOTE_WORK_SETTINGS}`,
    params
  )
  return data
}

export const remoteWorkSettingsForceCreateService = async (
  params: RemoteWorkSettingsForceCreateParams
): Promise<RemoteWorkSettingsResponse> => {
  const { data } = await api.post<RemoteWorkSettingsResponse>(
    `${URI.REMOTE_WORK_SETTINGS}/force_create`,
    params
  )
  return data
}

export const remoteWorkSettingsDetailService = async (
  params: RemoteWorkIdType
): Promise<RemoteWorkSettingsResponse> => {
  const { data } = await api.get<RemoteWorkSettingsResponse>(
    `${URI.REMOTE_WORK_SETTINGS}/${params}`
  )
  return data
}

export const remoteWorkSettingsUpdateService = async (
  arg: RemoteWorkSettingsUpdateParams
): Promise<RemoteWorkSettingsResponse> => {
  const { data } = await api.put<RemoteWorkSettingsResponse>(
    `${URI.REMOTE_WORK_SETTINGS}/${arg.id}`,
    arg.params
  )
  return data
}

export const remoteWorkSettingsForceUpdateService = async (
  arg: RemoteWorkSettingsUpdateParams
): Promise<RemoteWorkSettingsResponse> => {
  const { data } = await api.put<RemoteWorkSettingsResponse>(
    `${URI.REMOTE_WORK_SETTINGS}/${arg.id}/force_update`,
    arg.params
  )
  return data
}

export const remoteWorkSettingsDeleteService = async (
  params: RemoteWorkIdType
): Promise<RemoteWorkSettingsResponse> => {
  const { data } = await api.delete<RemoteWorkSettingsResponse>(
    `${URI.REMOTE_WORK_SETTINGS}/${params}`
  )
  return data
}

export type RemoteWorkRequestDetail = {
  id: string
  type: 'remote_work_request_detail'
  attributes: {
    work_date: Date
    description: string
    actioned_at: Date
    actioned_by: {
      data: EmployeeShortItem
    }
    reason: string
    status: REMOTE_WORK_REQUEST_STATUS
    remote_work_limit: number
    remote_work_request_count: number
  }
}

export type RemoteWorkRequestItem = {
  id: RemoteWorkIdType
  type: 'remote_work_request'
  attributes: {
    user_id: string
    description: string
    status: TIME_CORRECTION_STATUS
    reason: string
    current_step: number
    total_step: number
    created_at: Date
    remote_work_interval_type: 0
    remote_work_limit: 3
    can_delete: boolean
    deleted_at: Date | null
    deleted_by: string | null
    remote_work_request_details: {
      data: RemoteWorkRequestDetail[]
    }
    user: {
      data: EmployeeShortItem
    }
    remote_work_approvers: {
      data: TimeOffApproverItem[]
    }
    remote_work_template: {
      data: ApprovalTemplateItem
    }
  }
}

export type RemoteWorkRequestListResponse = {
  data: RemoteWorkRequestItem[]
  meta: MetaPage
}

export type RemoteWorkRequestResponse = {
  data: RemoteWorkRequestItem
}

export const remoteWorkRequestListService = async (
  params: FilterParams
): Promise<RemoteWorkRequestListResponse> => {
  const { data } = await api.get<RemoteWorkRequestListResponse>(
    `${URI.REMOTE_WORK_REQUEST}`,
    { params: params }
  )
  return data
}

export type RemoteWorkRequestDetailAttr = {
  work_date: Date
  description: string
}

export type RemoteWorkRequestCreateParams = {
  approver_id?: string
  remote_work_request_details_attributes: RemoteWorkRequestDetailAttr[]
  reason: string
}

export type RemoteWorkRequestUpdateDetailAttr = {
  id: string
  work_date: Date
  description: string
}

export type RemoteWorkRequestUpdateDetail = {
  approver_id?: string
  reason: string
  remote_work_request_details_attributes: RemoteWorkRequestUpdateDetailAttr[]
}

export type RemoteWorkRequestUpdateParams = {
  id: string
  params: RemoteWorkRequestUpdateDetail
}

export const remoteWorkRequestCreateService = async (
  params: RemoteWorkRequestCreateParams
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.post<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}`,
    params
  )
  return data
}

export const remoteWorkRequestUpdateService = async (
  params: RemoteWorkRequestUpdateParams
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.put<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}/${params.id}`,
    params.params
  )
  return data
}

export const remoteWorkRequestDetailService = async (
  id: string
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.get<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}/${id}`
  )
  return data
}

export const remoteWorkRequestApproverListService = async (
  params: FilterParams
): Promise<RemoteWorkRequestListResponse> => {
  const { data } = await api.get<RemoteWorkRequestListResponse>(
    `${URI.REMOTE_WORK_REQUEST}/approve_list`,
    { params: params }
  )
  return data
}

export type RemoteWorkRequestApproveParam = {
  id: string
  params: {
    approver_id?: string
    approve_all: true
    reason: string
    detail_ids: string[]
  }
}

export type RemoteWorkRequestDenyParam = {
  id: string
  params: {
    reason: string
  }
}

export const remoteWorkRequestApproveService = async (
  params: RemoteWorkRequestApproveParam
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.post<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}/${params.id}/approve`,
    params.params
  )
  return data
}

export const remoteWorkRequestDenyService = async (
  params: RemoteWorkRequestDenyParam
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.post<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}/${params.id}/deny`,
    params.params
  )
  return data
}

export const remoteWorkRequestDeleteService = async (
  id: RemoteWorkIdType
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.delete<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}/${id}`
  )
  return data
}

export const remoteWorkRequestApproverDeleteService = async (
  id: RemoteWorkIdType
): Promise<RemoteWorkRequestResponse> => {
  const { data } = await api.delete<RemoteWorkRequestResponse>(
    `${URI.REMOTE_WORK_REQUEST}/${id}/remote_work_request_delete`
  )
  return data
}

export type remoteReportParams = {
  start_date: Date
  end_date: Date
  user_id: string
}

export type RemoteOfficeAttributes = {
  id: string
  type: string
  attributes: {
    user: ShortUserItem
    office_count: number
    remote_count: number
    accepted_remote_count: number
    timeoff_days: number
    remote_difference: number
    insufficient_office_count: number
    remote_without_approve: number
    unauthorized_absence: number
  }
}

export type RemoteOfficeSomeAttributes = {
  total_work_days: number
  start_date: Date
  end_date: Date
}

export type remoteReportResponse = {
  total_work_days: number
  start_date: Date
  end_date: Date
  data: RemoteOfficeAttributes[]
}

export const remoteOfficeReportService = async (
  params: remoteReportParams
): Promise<remoteReportResponse> => {
  const { data } = await api.get<remoteReportResponse>(
    `${URI.REMOTE_WORK_REQUEST}/remote_office_report.json`,
    {
      params: params,
    }
  )
  return data
}
export const remoteOfficeReportDownloadService = async (
  params: remoteReportParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `${URI.REMOTE_WORK_REQUEST}/remote_office_report.xlsx`,
    {
      params: params,
      responseType: 'blob',
    }
  )
  return data
}
