import { useAppDispatch } from '@store/hooks'
import { fetchRemoteOfficeReport } from '@store/time/actions'
import { useSelector } from 'react-redux'
import {
  RemoteOfficeAttributes,
  RemoteOfficeSomeAttributes,
  remoteReportParams,
} from '@services/time.services'
import {
  getRemoteOfficeList,
  remoteOfficeSomeDatas,
} from '@store/time/selectors'
import {
  getVacationReportDetail,
  getVacationReportList,
} from '@store/vacation/selectors'
import {
  VacationReportDetailItem,
  VacationReportDetailParams,
  VacationReportItem,
  VacationReportParams,
} from '@services/vacation.services'
import {
  fetchVacationReportDetailList,
  fetchVacationReportList,
} from '@store/vacation/actions'
import {
  TimeOffReportTotalItem,
  TimeOffReportTotalParam,
} from '@services/timeoff.services'
import { fetchTimeOffReportTotalList } from '@store/timeoff/actions'
import { getTimOffReportTotalList } from '@store/timeoff/selectors'
import { createMetaSelector } from '@store/metadata/selectors'
import { Meta } from '@store/metadata/actions/types'
interface remoteReport {
  fetchRemoteOfficeReportList: (params: remoteReportParams) => any
  remoteReportList: RemoteOfficeAttributes[]
  vacationReportList: VacationReportItem[]
  fetchVacationReport: (params: VacationReportParams) => any
  vacationReportDetails: VacationReportDetailItem[]
  fetchVacationReportDetail: (params: VacationReportDetailParams) => any
  fetchTimeOffReportTotal: (params: TimeOffReportTotalParam) => any
  timeOffReportTotalList: TimeOffReportTotalItem[]
  meta: Meta
  metaVacation: Meta
  metaTimeOff: Meta
  remoteOfficeSomeData: RemoteOfficeSomeAttributes
}

const useEmployeeReport = (): remoteReport => {
  const dispatch = useAppDispatch()

  // ACTIONS
  const fetchRemoteOfficeReportList = (params: remoteReportParams) =>
    dispatch(fetchRemoteOfficeReport(params))
  const fetchVacationReport = (params: VacationReportParams) =>
    dispatch(fetchVacationReportList(params))
  const fetchVacationReportDetail = (params: VacationReportDetailParams) =>
    dispatch(fetchVacationReportDetailList(params))
  const fetchTimeOffReportTotal = (params: TimeOffReportTotalParam) =>
    dispatch(fetchTimeOffReportTotalList(params))

  const remoteReportList = useSelector(getRemoteOfficeList)
  const remoteOfficeSomeData = useSelector(remoteOfficeSomeDatas)
  const vacationReportList = useSelector(getVacationReportList)
  const vacationReportDetails = useSelector(getVacationReportDetail)
  const timeOffReportTotalList = useSelector(getTimOffReportTotalList)

  const _meta = createMetaSelector(fetchRemoteOfficeReport)
  const meta = useSelector(_meta)

  const _metaVacation = createMetaSelector(fetchVacationReportList)
  const metaVacation = useSelector(_metaVacation)

  const _metaTimeOff = createMetaSelector(fetchTimeOffReportTotalList)
  const metaTimeOff = useSelector(_metaTimeOff)
  return {
    remoteReportList,
    fetchRemoteOfficeReportList,
    remoteOfficeSomeData,
    vacationReportList,
    fetchVacationReport,
    vacationReportDetails,
    fetchVacationReportDetail,
    fetchTimeOffReportTotal,
    timeOffReportTotalList,
    meta,
    metaVacation,
    metaTimeOff,
  }
}

export default useEmployeeReport
