import { URI } from '@constants/uri.constants'
import { api } from './api'
import { EmployeeItem } from './user.services'
import { PositionItem } from './resource.services'

export type VacationYearData = {
  year: number
  days: number
  remaining_days: number
  used_days: number
}
export type VacationBalanceItem = {
  user_id: number
  firstname: string
  lastname: string
  firstname_en: string
  lastname_en: string
  hired_date: Date
  user_code: string
  ndsh: number
  ndsh_total: number
  is_months_since_hire_date: boolean
  position: { data: PositionItem }
  years: VacationYearData[]
}

export type VacationBalanceListResponse = {
  data: VacationBalanceItem[]
}

export type VacationBalanceResponse = {
  data: {
    id: string
    type: string
    attributes: {
      year: number
      days: number
      remaining_days: number
      used_days: number
      status: string
      start_at: Date
      end_at: Date
      user: {
        data: EmployeeItem
      }
    }
  }
}

export type VacationBalanceCreateParams = {
  year: number
  user_id: number
  days?: number
  remaining_days?: number
  status?: string
  start_at?: string
  end_at?: string
}

export type VacationBalanceDestroyParams = {
  year: number
  user_id: number
}

export type VacationBalanceCalculateParams = {
  year: number
}

export type VacationFilter = {
  keyword?: string
  order_by?: string
  direction?: string
}

export type UserVacationHistoryItem = {
  id: string
  type: string
  attributes: {
    start_at: Date
    end_at: Date
    duration: number
    template_log_name: string
  }
}

export type MyVacationBalanceResponse = {
  data: MyVacationBalanceItem[]
}

export type UserVacationHistoryResponse = {
  data: UserVacationHistoryItem[]
}

export type UserVacationHistoryParams = {
  year: number
  user_id: number
}

export type MyVacationBalanceItem = {
  id: string
  type: 'my_vacation'
  attributes: {
    year: number
    days: number
    remaining_days: number
  }
}

export const vacationBalancesListService = async (
  filter: VacationFilter
): Promise<VacationBalanceListResponse> => {
  const { data } = await api.get<VacationBalanceListResponse>(
    URI.VACATION_BALANCE,
    { params: filter }
  )
  return data
}
export const vacationBalancesCalculateService = async (
  params: VacationBalanceCalculateParams
): Promise<VacationBalanceResponse> => {
  const { data } = await api.post<VacationBalanceResponse>(
    `${URI.VACATION_BALANCE}/calculate`,
    params
  )
  return data
}

export const vacationBalancesCreateService = async (
  params: VacationBalanceCreateParams
): Promise<VacationBalanceResponse> => {
  const { data } = await api.post<VacationBalanceResponse>(
    `${URI.VACATION_BALANCE}/vacation_balance_create`,
    params
  )
  return data
}

export const vacationBalancesUpdateService = async (
  params: VacationBalanceCreateParams
): Promise<VacationBalanceResponse> => {
  const { data } = await api.put<VacationBalanceResponse>(
    `${URI.VACATION_BALANCE}/vacation_balance_update`,
    params
  )
  return data
}

export const vacationBalancesDeleteService = async (
  params: VacationBalanceDestroyParams
): Promise<VacationBalanceResponse> => {
  const { data } = await api.delete<VacationBalanceResponse>(
    `${URI.VACATION_BALANCE}/vacation_balance_destroy`,
    { params: params }
  )
  return data
}

export const vacationBalancesGetMyDataService =
  async (): Promise<MyVacationBalanceResponse> => {
    const { data } = await api.get<MyVacationBalanceResponse>(
      `${URI.VACATION_BALANCE}/get_my_data`
    )
    return data
  }

export const vacationCalculateForUser = async (
  params: VacationBalanceDestroyParams
): Promise<VacationBalanceResponse> => {
  const { data } = await api.post<VacationBalanceResponse>(
    `${URI.VACATION_BALANCE}/calculate_for_user`,
    params
  )
  return data
}

export const vacationUserHistoryService = async (
  params: UserVacationHistoryParams
): Promise<UserVacationHistoryResponse> => {
  const { data } = await api.get<UserVacationHistoryResponse>(
    `${URI.VACATION_BALANCE}/vacation_use_history`,
    { params: params }
  )
  return data
}

export type VacationReportParams = {
  start_at: Date
  end_at: Date
}
export type VacationReportItem = {
  user_id: number
  user_code: string
  firstname: string
  lastname: string
  firstname_en: string
  lastname_en: string
  position: {
    data: {
      id: number
      type: string
      attributes: {
        name: string
        short_name: string
        display_order: number
        is_default_filter: boolean
      }
    }
  }
  used_days: number
  remaining_days: number
  pre_paid: number
  post_paid: string
}
export type VacationReportResponse = {
  data: VacationReportItem[]
}
export const vacationReportService = async (
  params: VacationReportParams
): Promise<VacationReportResponse> => {
  const { data } = await api.get<VacationReportResponse>(
    `${URI.VACATION_BALANCE}/vacation_report_total.json`,
    { params: params }
  )
  return data
}
export type VacationReportDetailParams = {
  start_at: Date
  end_at: Date
  user_id: string
}
export type VacationReportDetailItem = {
  year: number
  days: number
  remaining_days: number
  used_days: number
}
export type VacationReportDetailResponse = {
  data: VacationReportDetailItem[]
}
export const vacationReportDetailService = async (
  params: VacationReportDetailParams
): Promise<VacationReportDetailResponse> => {
  const { data } = await api.get<VacationReportDetailResponse>(
    `${URI.VACATION_BALANCE}/vacation_report`,
    { params: params }
  )
  return data
}

export const vacationReportDownloadService = async (
  params: VacationReportParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `${URI.VACATION_BALANCE}/vacation_report_total.xlsx`,
    {
      params: params,
      responseType: 'blob',
    }
  )
  return data
}
