import {
  FilterParams,
  MetaPage,
  SearchFilter,
} from '@constants/common.constants'
import { PartnerDivisionItem } from '@services/company.services'
import {
  ActualCostReportDetailItem,
  ActualCostReportDetailParams,
  ActualCostReportDetailFilterDates,
  ActualCostReportItem,
  ActualCostReportParams,
  AcutalCostFilterDates,
  CompanyTotalItem,
  CostProjectItem,
  CostProjectListParams,
  CostReportParams,
  CostReportInfo,
  CostReportActivityItem,
} from '@services/report.services'
import { CostReportListItem } from '@services/resource.services'
import { fetchAllDivisionList } from '@store/company/actions'
import { getAllDivisions } from '@store/company/selectors'
import { useAppDispatch } from '@store/hooks'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import {
  fetchActualCostReport,
  fetchActualCostReportDetail,
  fetchCostProjectList,
  fetchCostReport,
  fetchCostReportActivity,
} from '@store/report/actions'
import {
  getActualCostDates,
  getActualCostDetailFilterDates,
  getActualCostList,
  getActualCostReportDetail,
  getCompanyTotal,
  getCostActivity,
  getCostGrouped,
  getCostInfo,
  getCostProjectList,
} from '@store/report/selectors'
import { GroupedCostReportItem } from '@store/report/selectors/helper'
import { fetchCostList } from '@store/resource/actions'
import { getCostList } from '@store/resource/selectors'

import { useSelector } from 'react-redux'

interface Position {
  costGrouped?: GroupedCostReportItem[]
  fetch: (params: CostReportParams) => any
  meta: Meta
  fetchDivisions: (params: SearchFilter) => void
  divisions?: PartnerDivisionItem[]
  metaDivisions: Meta
  companyTotal: CompanyTotalItem[]
  fetchActualCost: (params: ActualCostReportParams) => any
  actualCost: ActualCostReportItem[]
  actualMeta: Meta
  fetchCostProjects: (params: CostProjectListParams) => any
  costProjects: CostProjectItem[]
  projectMeta: Meta
  actualCostDates: AcutalCostFilterDates
  fetchActualCostDetail: (params: ActualCostReportDetailParams) => any
  actualCostDetailMeta: Meta
  actualCostDetail: ActualCostReportDetailItem
  filterDates: ActualCostReportDetailFilterDates
  costInfo: CostReportInfo
  pageMeta: MetaPage
  data: CostReportListItem[]
  fetchList: (params: FilterParams) => any
  fetchActivity: (params: CostReportParams) => any
  activityList: CostReportActivityItem[]
}

const useCostReport = (): Position => {
  const dispatch = useAppDispatch()
  const fetch = (params: CostReportParams) => dispatch(fetchCostReport(params))
  const fetchList = (params: FilterParams) => dispatch(fetchCostList(params))
  const fetchDivisions = (params: SearchFilter) =>
    dispatch(fetchAllDivisionList(params))
  const fetchActivity = (params: CostReportParams) =>
    dispatch(fetchCostReportActivity(params))
  const costGrouped = useSelector(getCostGrouped)
  const _meta = createMetaSelector(fetchCostReport)
  const meta = useSelector(_meta)
  const divisions = useSelector(getAllDivisions)
  const _metaDivisions = createMetaSelector(fetchAllDivisionList)
  const metaDivisions = useSelector(_metaDivisions)
  const companyTotal = useSelector(getCompanyTotal)
  const costInfo = useSelector(getCostInfo)
  const activityList = useSelector(getCostActivity)
  const { data, pageMeta } = useSelector(getCostList)

  const fetchActualCost = (params: ActualCostReportParams) =>
    dispatch(fetchActualCostReport(params))
  const actualCost = useSelector(getActualCostList)
  const _actualMeta = createMetaSelector(fetchActualCostReport)
  const actualMeta = useSelector(_actualMeta)

  const fetchCostProjects = (params: CostProjectListParams) =>
    dispatch(fetchCostProjectList(params))
  const costProjects = useSelector(getCostProjectList)
  const _projectMeta = createMetaSelector(fetchCostProjectList)
  const projectMeta = useSelector(_projectMeta)

  const actualCostDates = useSelector(getActualCostDates)

  const fetchActualCostDetail = (params: ActualCostReportDetailParams) =>
    dispatch(fetchActualCostReportDetail(params))
  const _actualCostDetailMeta = createMetaSelector(fetchActualCostReportDetail)
  const actualCostDetailMeta = useSelector(_actualCostDetailMeta)
  const actualCostDetail = useSelector(getActualCostReportDetail)
  const filterDates = useSelector(getActualCostDetailFilterDates)

  return {
    costGrouped,
    fetch,
    meta,
    divisions,
    fetchDivisions,
    metaDivisions,
    companyTotal,
    fetchActualCost,
    actualCost,
    actualMeta,
    fetchCostProjects,
    costProjects,
    projectMeta,
    actualCostDates,
    fetchActualCostDetail,
    actualCostDetail,
    actualCostDetailMeta,
    filterDates,
    costInfo,
    data,
    activityList,
    pageMeta,
    fetchList,
    fetchActivity,
  }
}

export default useCostReport
