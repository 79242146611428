import { DivisionItem } from '@services/project.services'
import { PositionItem, RoleItem } from '@services/resource.services'
import { Direction } from './common.constants'

export enum ROLE_PERMISSION {
  READ = 1,
  WRITE = 2,
  ADMIN = 99,
}

export type EmployeeListFilter = {
  keyword: string
  order_by: string
  direction: Direction
  per_page: string
  page: string
  positions: PositionItem[]
  divisions: DivisionItem[]
  roles: RoleItem[]
  status: string[]
  gender: string[]
}

export type EmployeeStatus = {
  id: string
  name: string
}

export enum EMPLOYEE_STATUS {
  DELETED = 'deleted',
  ACTIVE = 'active',
  QUIT = 'quit',
  SUPER_ADMIN = 'superadmin',
}

export const EmployeeStatuses: EmployeeStatus[] = [
  {
    id: EMPLOYEE_STATUS.DELETED,
    name: 'EMPLOYEE.status_deleted',
  },
  {
    id: EMPLOYEE_STATUS.ACTIVE,
    name: 'EMPLOYEE.status_active',
  },
  {
    id: EMPLOYEE_STATUS.QUIT,
    name: 'EMPLOYEE.status_quit',
  },
]

export type EmployeeStatusLabels = {
  [key: string]: {
    color: any
    label: string
  }
}

export const EMPLOYEE_STATUS_CHIP_LABELS: EmployeeStatusLabels = {
  [`${EMPLOYEE_STATUS.DELETED}`]: {
    color: 'error',
    label: 'EMPLOYEE.status_deleted',
  },
  [`${EMPLOYEE_STATUS.ACTIVE}`]: {
    color: 'success',
    label: 'EMPLOYEE.status_active',
  },
  [`${EMPLOYEE_STATUS.QUIT}`]: {
    color: 'info',
    label: 'EMPLOYEE.status_quit',
  },
  [`${EMPLOYEE_STATUS.SUPER_ADMIN}`]: {
    color: 'warning',
    label: 'EMPLOYEE.status_superadmin',
  },
}

export enum InfoLogActionType {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
}

export enum EMPLOYEE_CURRENT_STATUS {
  DELETED = 'current_deleted',
  ACTIVE = 'current_active',
  QUIT = 'current_quit',
  MATERNITY_LEAVE = 'current_maternity_leave',
  LONG_TERM_LEAVE = 'current_long_term_leave',
  DAY = 'current_day',
  DAY_AM = 'current_day_am',
  DAY_PM = 'current_day_pm',
  COMPENSATORY_DAY = 'current_compensatory_day',
  HOLIDAY_WORK = 'current_holiday_work',
  WORKATION = 'current_workation',
  COMPENSATORY_REQUEST = 'current_compensatory_request',
  SPECIAL = 'current_special',
}

export const EMPLOYEE_CURRENT_STATUS_LABELS: EmployeeStatusLabels = {
  [`${EMPLOYEE_CURRENT_STATUS.DELETED}`]: {
    color: 'error',
    label: 'EMPLOYEE.current_status_deleted',
  },
  [`${EMPLOYEE_CURRENT_STATUS.ACTIVE}`]: {
    color: 'success',
    label: 'EMPLOYEE.current_status_active',
  },
  [`${EMPLOYEE_CURRENT_STATUS.MATERNITY_LEAVE}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_maternity_leave',
  },
  [`${EMPLOYEE_CURRENT_STATUS.QUIT}`]: {
    color: 'info',
    label: 'EMPLOYEE.current_status_quit',
  },
  [`${EMPLOYEE_CURRENT_STATUS.LONG_TERM_LEAVE}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_long_term_leave',
  },
  [`${EMPLOYEE_CURRENT_STATUS.DAY}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_day',
  },
  [`${EMPLOYEE_CURRENT_STATUS.DAY_AM}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_day_am',
  },
  [`${EMPLOYEE_CURRENT_STATUS.DAY_PM}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_day_pm',
  },
  [`${EMPLOYEE_CURRENT_STATUS.COMPENSATORY_DAY}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_compensatory_day',
  },
  [`${EMPLOYEE_CURRENT_STATUS.HOLIDAY_WORK}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_holiday_work',
  },
  [`${EMPLOYEE_CURRENT_STATUS.WORKATION}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_workation',
  },
  [`${EMPLOYEE_CURRENT_STATUS.COMPENSATORY_REQUEST}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_compensatory_request',
  },
  [`${EMPLOYEE_CURRENT_STATUS.SPECIAL}`]: {
    color: 'warning',
    label: 'EMPLOYEE.current_status_special',
  },
}

export enum EMPLOYEE_CURRENT_DAY_STATUS {
  CURRENT_NOT_STARTED_WORK = 'current_not_started_work',
  CURRENT_WORKING = 'current_working',
  CURRENT_WORK_DONE = 'current_work_done',
  CURRENT_VACATION = 'current_vacation',
  CURRENT_HOLIDAY = 'current_holiday',
  REMOTE = 'remote',
  OFFICE = 'office',
}

export type EmployeeCurrentDayStatusLabels = {
  [key: string]: {
    color: any
    label: string
  }
}

export const EMPLOYEE_CURRENT_DAY_STATUS_LABELS: EmployeeStatusLabels = {
  [`${EMPLOYEE_CURRENT_DAY_STATUS.CURRENT_NOT_STARTED_WORK}`]: {
    color: '#FFC107',
    label: 'EMPLOYEE_CURRENT_DAY_STATUS.current_not_started_work',
  },
  [`${EMPLOYEE_CURRENT_DAY_STATUS.CURRENT_WORKING}`]: {
    color: '#4CAF50',
    label: 'EMPLOYEE_CURRENT_DAY_STATUS.current_working',
  },
  [`${EMPLOYEE_CURRENT_DAY_STATUS.CURRENT_WORK_DONE}`]: {
    color: '#CCCCCC',
    label: 'EMPLOYEE_CURRENT_DAY_STATUS.current_work_done',
  },
  [`${EMPLOYEE_CURRENT_DAY_STATUS.CURRENT_VACATION}`]: {
    color: '#2980B9',
    label: 'EMPLOYEE_CURRENT_DAY_STATUS.current_vacation',
  },
  [`${EMPLOYEE_CURRENT_DAY_STATUS.CURRENT_HOLIDAY}`]: {
    color: '#3498DB',
    label: 'EMPLOYEE_CURRENT_DAY_STATUS.current_holiday',
  },
  [`${EMPLOYEE_CURRENT_DAY_STATUS.REMOTE}`]: {
    color: '#333',
    label: 'DASHBOARD.from_remote',
  },
  [`${EMPLOYEE_CURRENT_DAY_STATUS.OFFICE}`]: {
    color: '#333',
    label: 'DASHBOARD.from_office',
  },
}
