import { URI } from '@constants/uri.constants'
import { authApi } from './api'

export type UserLoginParams = {
  email: string
  password: string
}

export interface CurrentUser {
  id: number
  email: string
  user_code: string
  access_token: string
  refresh_token: string
  sign_in_count: number
  default_company: string
  language?: string
  base_url: string
  uid: string
  password_change_required: boolean
}

export type UserLoginResponse = CurrentUser

export type UserLangResponse = void

export type SocialLoginParams = {
  access_token: string
}

export type LangParams = {
  language: string
}

export const loginService = async (
  params: UserLoginParams
): Promise<UserLoginResponse> => {
  const { data } = await authApi.post<UserLoginResponse>(URI.LOGIN, params)
  return data
}

export const logOutService = async (): Promise<void> => {
  const { data } = await authApi.post<void>(URI.LOGOUT)
  return data
}

export const socialLoginService = async (
  params: SocialLoginParams
): Promise<UserLoginResponse> => {
  const { data } = await authApi.post<UserLoginResponse>(
    URI.GOOGLE_LOGIN,
    params
  )
  return data
}

export const changeLangService = async (
  params: LangParams
): Promise<UserLangResponse> => {
  const { data } = await authApi.post<UserLangResponse>(
    URI.CHANGE_LANGUAGE,
    params
  )
  return data
}

// PASSWORD SERVICES

export type ForgotPasswordParams = {
  email: string
}
export type PasswordActionCommonResponse = {
  success?: string
}
export type ConfirmPasswordParams = {
  email: string
  confirmation_code: number
}
export type ResetPasswordParams = {
  email: string
  password: string
  password_confirm: string
  confirmation_code: number
}
export type ChangePasswordParams = {
  current_password: string
  new_password: string
  confirm_new_password: string
}

export const forgotPasswordService = async (
  params: ForgotPasswordParams
): Promise<PasswordActionCommonResponse> => {
  const { data } = await authApi.post<PasswordActionCommonResponse>(
    URI.FORGOT_PASSWORD,
    params
  )
  return data
}

export const confirmPasswordService = async (
  params: ConfirmPasswordParams
): Promise<PasswordActionCommonResponse> => {
  const { data } = await authApi.post<PasswordActionCommonResponse>(
    URI.CONFIRM_PASSWORD,
    params
  )
  return data
}
export const resetPasswordService = async (
  params: ResetPasswordParams
): Promise<PasswordActionCommonResponse> => {
  const { data } = await authApi.post<PasswordActionCommonResponse>(
    URI.RESET_PASSWORD,
    params
  )
  return data
}

export const changePasswordService = async (
  params: ChangePasswordParams
): Promise<PasswordActionCommonResponse> => {
  const { data } = await authApi.post<PasswordActionCommonResponse>(
    URI.CHANGE_PASSWORD,
    params
  )
  return data
}
