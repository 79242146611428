import { Genders } from '@constants/common.constants'
import { TIME_OFF_TEMPLATE_RANGE_TYPE } from '@constants/timeoff.constants'
import { EmployeeStatuses } from '@constants/user.constants'
import {
  blue,
  blueGrey,
  cyan,
  deepOrange,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors'
import {
  EmployeeItem,
  EmployeeShortItem,
  ShortUserItem,
} from '@services/user.services'
import _ from 'lodash'
import moment from 'moment'
import { DefaultExtensionType } from 'react-file-icon'

export const stringToColor = (string: string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export function getGenderName(genderValue: string): string {
  const genderItem = Genders.find((gender) => gender.id === genderValue)
  return genderItem ? genderItem.name : ''
}

export function getStatusIndex(id: string | undefined): any {
  const index = EmployeeStatuses.findIndex((status) => status.id === id)

  return index === -1 ? null : index
}

export function formatDate(
  date?: Date | string | null | undefined,
  format?: string
): string {
  return !_.isEmpty(date?.toString()) && !_.isNull(date)
    ? moment(new Date(date as any)).format(format ?? 'YYYY-MM-DD')
    : '-'
}

export const formatTimeOffDate = (
  start: Date,
  end: Date | null,
  type?: string
): string => {
  if (type === TIME_OFF_TEMPLATE_RANGE_TYPE.TIME) {
    const startDate = moment(start).format('YYYY/MM/DD')
    const startTime = moment(start).format('HH:mm')
    const endTime = moment(end).format('HH:mm') // Assuming end time is one hour later for demonstration

    return `${startDate}   ${startTime} - ${endTime}`
  } else {
    const startDate = moment(start).format('YYYY/MM/DD')
    const endDate = moment(end).format('YYYY/MM/DD') // Assuming end date is one day later for demonstration

    return !_.isNull(end) ? `${startDate} - ${endDate}` : startDate
  }
}

export function truncateDecimals(
  number: string | number,
  decimalPlaces: number
): string {
  const parsedNumber = typeof number === 'string' ? parseFloat(number) : number
  const multiplier = Math.pow(10, decimalPlaces)
  const truncated = Math.round(parsedNumber * multiplier) / multiplier
  const result = truncated.toFixed(decimalPlaces)
  return parseFloat(result).toString()
}
export function getDateStr(
  date?: string | null | undefined,
  format?: string
): string {
  return !_.isEmpty(date) ? moment(date).format(format ?? 'YYYY-MM-DD') : ''
}

export const renderCost = (cost: number | undefined) => {
  if (typeof cost === 'undefined' || isNaN(cost)) {
    return '-'
  } else if (cost === 0) {
    return 0
  } else {
    return truncateDecimals(cost, 2)
  }
}

export const renderActualCost = (cost: number | undefined | null) => {
  if (typeof cost === 'undefined' || cost === null || isNaN(cost)) {
    return '-'
  } else {
    const result = truncateDecimals(cost, 2)
    return result
  }
}

export const hashCode = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
  }
  return hash
}

export const colorPalettes = [
  blue[500],
  purple[500],
  orange[500],
  green[700],
  red[500],
  teal[600],
  yellow[500],
  indigo[500],
  pink[300],
  cyan[500],
  grey[500],
  lightGreen[500],
  deepOrange[700],
  lime[500],
  blueGrey[600],
  red[100],
  purple[100],
  lightBlue[100],
]

export const getColor = (index: number) => {
  const colorIndex = index % colorPalettes.length
  return colorPalettes[colorIndex]
}

export const generateFileName = (prefix: string, fileExtension: string) => {
  const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, '')
  const fileName = `${prefix}_${timestamp}.${fileExtension}`
  return fileName
}

export const formatHumanDate = (dateString: string) => {
  const date = moment(dateString)
  const now = moment()

  // Calculate the difference in hours between the date and now
  const hoursDiff = now.diff(date, 'hours')

  // If the date is more than 24 hours ago, display it in regular format
  if (hoursDiff >= 24) {
    return date.format('YYYY-MM-DD HH:mm') // Customize the format as needed
  }

  // Otherwise, display it in a human-readable format
  return date.fromNow()
}

export const getFullNameEn = (
  employee?: EmployeeItem | EmployeeShortItem | ShortUserItem,
  firstnameEn?: string,
  lastnameEn?: string
): string => {
  const firstName =
    firstnameEn || _.get(employee, 'attributes.firstname_en', '')
  const lastName = lastnameEn || _.get(employee, 'attributes.lastname_en', '')
  return `${firstName} ${lastName}`
}

export const getFullName = (
  employee?: EmployeeItem | EmployeeShortItem | ShortUserItem,
  firstname?: string,
  lastname?: string
): string => {
  const firstName = firstname || _.get(employee, 'attributes.firstname', '')
  const lastName = lastname || _.get(employee, 'attributes.lastname', '')
  return `${firstName} ${lastName}`
}

export const getShortName = (
  employee?: EmployeeItem | EmployeeShortItem | ShortUserItem,
  firstname?: string,
  lastname?: string
): string => {
  const firstName = firstname || _.get(employee, 'attributes.firstname_en', '')
  const lastName = lastname || _.get(employee, 'attributes.lastname_en', '')
  return firstName && lastName
    ? `${lastName.charAt(0).toUpperCase()}${lastName
        .charAt(1)
        .toLowerCase()}. ${firstName}`
    : ''
}

export const getColorByName = (value: string): string => {
  // Use a simple hash function to convert the position_id to a unique hue
  const hash = value
    .split('')
    .reduce((acc, char) => char.charCodeAt(0) + acc, 0)
  const hue = hash % 360

  return `hsl(${hue}, 70%, 80%)`
}

export const images = ['gif', 'jpeg', 'jpg', 'svg', 'ico', 'png']

export const getFileExtension = (filename: string): DefaultExtensionType => {
  const ext = /^.+\.([^.]+)$/.exec(filename)
  return ext == null ? 'bin' : (ext[1].toLowerCase() as DefaultExtensionType)
}

export const getFileName = (path: string): string => {
  return path.substring(path.lastIndexOf('/') + 1)
}
