import { FallBackTimezone, WordPressURL } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import axios from 'axios'

const authApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: URI.API,
})

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Time-Zone':
      Intl.DateTimeFormat().resolvedOptions()?.timeZone || FallBackTimezone,
  },
})

const publicApi = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: WordPressURL,
})

export { api, authApi, publicApi }
