import { createSelector } from '@reduxjs/toolkit'
import {
  ResourcePlanItemsMap,
  ResourcePlanMemberItem,
} from '@services/resource.services'
import { EmployeeItem } from '@services/user.services'
import { RootState } from '@store/store'
import _ from 'lodash'

export interface ProjectCostData {
  groupedData: { project_id: number; total_cost: number }[]
  positionsCost: { position_id: number; position_total_cost: number }[]
  positionsProjectCost: {
    position_id: number
    project_id: number
    total_position_project_cost: number
  }[]
}

const getRoot = (state: RootState) => state.resource

// POSITION
export const getPositionList = createSelector(
  getRoot,
  (state) => state.positionList
)

export const getPositionMeta = createSelector(
  getRoot,
  (state) => state.positionMeta
)

export const getAllPositions = createSelector(getRoot, (state) => {
  if (state.positionListAll) {
    return _.orderBy(state.positionListAll, 'attributes.display_order', 'asc')
  } else {
    return state.positionListAll
  }
})

export const getPositionDetail = createSelector(
  getRoot,
  (state) => state.detail
)

// DEPARTMENT
export const getDepartmentList = createSelector(
  getRoot,
  (state) => state.departmentList
)

export const getDepartmentMeta = createSelector(
  getRoot,
  (state) => state.departmentMeta
)

export const getDepartmentDetail = createSelector(
  getRoot,
  (state) => state.detail
)

export const getAddableList = createSelector(
  getRoot,
  (state) => state.addableUsers
)

// ROLE
export const getRoleList = createSelector(getRoot, (state) => state.roleList)

export const getRoleMeta = createSelector(getRoot, (state) => state.roleMeta)

export const getRoleModuleList = createSelector(
  getRoot,
  (state) => state.roleModule
)

export const getRoleListAll = createSelector(
  getRoot,
  (state) => state.roleListAll
)

// RESOURCE PLAN
export const getResourcePlanList = createSelector(
  getRoot,
  (state) => state.resourcePlanList
)

export const getResourcePlanListMeta = createSelector(
  getRoot,
  (state) => state.resourcePlanListMeta
)

export const getResourcePlanListAll = createSelector(
  getRoot,
  (state) => state.resourcePlanListAll
)

export const getResourcePlanDetail = createSelector(
  getRoot,
  (state) => state.resourcePlanDetail
)

export const getResourcePlanInfo = createSelector(
  getRoot,
  (state) => state.resourcePlanInfo
)

export const getResourcePlanActivity = createSelector(
  getRoot,
  (state) => state.resourcePlanActivities
)

export const getCostList = createSelector(getRoot, (state) => {
  const data = state.costItemsList
  const pageMeta = state.costListMeta
  return { data, pageMeta }
})

export const getResourcePlanItemsMap = createSelector(getRoot, (state) => {
  if (
    state.resourcePlanDetail &&
    state.resourcePlanDetail.items &&
    !_.isEmpty(state.resourcePlanDetail.items)
  ) {
    const itemsMap: ResourcePlanItemsMap = {}
    state.resourcePlanDetail.items.forEach((item: ResourcePlanMemberItem) => {
      const userId = item.user.id.toString()

      if (!itemsMap[userId]) {
        itemsMap[userId] = []
      }
      if (!_.isEmpty(item.projects)) {
        item.projects.forEach((project) => {
          itemsMap[userId].push({
            cost: project.cost,
            project_id: project.project_id.toString(),
            item_id: item.id,
            position: item.user.position.data,
          })
        })
      }
    })
    return itemsMap
  }

  return {}
})

export const getSortedResourcePlanUsers = createSelector(getRoot, (state) =>
  state.resourcePlanUsers
    ? _.orderBy(
        state.resourcePlanUsers,
        [
          (user: EmployeeItem) =>
            user.attributes?.position?.data?.attributes.name?.toUpperCase(),
          (user: EmployeeItem) => user.attributes?.user_code,
        ],
        ['asc', 'asc']
      )
    : state.resourcePlanUsers
)

export const getCostCalculator = createSelector(getRoot, (state) => {
  if (
    state.resourcePlanDetail &&
    state.resourcePlanDetail.items &&
    !_.isEmpty(state.resourcePlanDetail.items)
  ) {
    const groupedData: { project_id: number; total_cost: number }[] =
      state.resourcePlanDetail.items.reduce(
        (result: any, member: ResourcePlanMemberItem) => {
          member.projects.forEach((project) => {
            const existingProject = result.find(
              (p: { project_id: number; total_cost: number }) =>
                p.project_id === project.project_id
            )

            if (existingProject) {
              existingProject.total_cost += project.cost
            } else {
              result.push({
                project_id: project.project_id,
                total_cost: project.cost,
              })
            }
          })

          return result
        },
        [] as { project_id: number; total_cost: number }[]
      )

    // Calculate total cost per position
    const positionsCost: {
      position_id: number
      position_total_cost: number
    }[] = state.resourcePlanDetail.items.reduce(
      (result: any, member: ResourcePlanMemberItem) => {
        member.projects.forEach((project) => {
          const existingPosition = result.find(
            (p: { position_id: string; position_total_cost: number }) =>
              p.position_id === _.get(member, 'user.position.data.id')
          )
          if (existingPosition) {
            existingPosition.position_total_cost += project.cost
          } else {
            result.push({
              position_id: _.get(member, 'user.position.data.id'),
              position_total_cost: project.cost,
            })
          }
        })

        return result
      },
      [] as { position_id: number; position_total_cost: number }[]
    )

    const positionsProjectCost: {
      position_id: number
      project_id: number
      total_position_project_cost: number
    }[] = state.resourcePlanDetail.items.reduce(
      (result: any, member: ResourcePlanMemberItem) => {
        member.projects.forEach((project) => {
          const existingPositionProject = result.find(
            (pp: {
              position_id: number
              project_id: number
              total_position_project_cost: number
            }) =>
              pp.position_id ===
                Number(_.get(member, 'user.position.data.id')) &&
              pp.project_id === project.project_id
          )
          if (existingPositionProject) {
            existingPositionProject.total_position_project_cost += project.cost
          } else {
            result.push({
              position_id: Number(_.get(member, 'user.position.data.id')),
              project_id: project.project_id,
              total_position_project_cost: project.cost,
            })
          }
        })

        return result
      },
      [] as {
        position_id: number
        project_id: number
        total_position_project_cost: number
      }[]
    )

    return { groupedData, positionsCost, positionsProjectCost }
  }
  return { groupedData: [], positionsCost: [], positionsProjectCost: [] }
  // Use the logic from the previous code snippet to group by project_id and calculate total cost
})
