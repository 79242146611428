import { Direction, MetaPage } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import {
  EMPLOYEE_CURRENT_DAY_STATUS,
  InfoLogActionType,
} from '@constants/user.constants'
import { api } from './api'
import { DivisionItem } from './project.services'
import { PositionItem, RoleItem } from './resource.services'
import { AvatarUrls } from './common.services'
import { FileWithPath } from 'react-dropzone'

export type UserProfileItem = {
  id: string
  type: string
  attributes: {
    uid: string
    avatar: null | string
    birthday: string
    email: string
    home_address: string
    firstname: string
    firstname_en: string
    gender: string
    lastname: string
    lastname_en: string
    phone: number
    position?: { data: PositionItem }
    division?: { data: DivisionItem }
    status: string
    current_status: string
    user_code: string
    phone_secondaries: { data: SecondaryPhoneResponseItem[] }
    is_quit: boolean
    quit_date?: string
    hired_date: string
    ndsh_months: number
    ndsh_total: number
    role?: { data: RoleItem }
    avatar_cover: string | null
    avatar_medium: string | null
    avatar_thumbnail: string | null
  }
}
export type SecondaryPhoneFormItem = {
  relationship: string
  phone: number
}
export type UserProfileParams = {
  phone: number
  phone_secondaries?: SecondaryPhoneItem[] | string
}
export type SecondaryPhoneItem = {
  relationship: string
  phone_secondaries: number
}

export type SecondaryPhoneResponseItem = {
  id: string
  type: string
  attributes: {
    phone_secondaries: string
    relationship: string
  }
}

export type EmployeeItemAttributes = AvatarUrls & {
  user_code: string
  firstname: string
  lastname: string
  birthday: string
  gender: string
  phone: number
  email: string
  avatar: string | null
  firstname_en?: string
  is_time_required: boolean
  lastname_en?: string
  status: string
  current_status: string
  position?: { data: PositionItem }
  division?: { data: DivisionItem }
  role?: { data: RoleItem }
  phone_secondaries?: { data: SecondaryPhoneResponseItem[] }
  uid: string //
  quit_date?: string
  is_quit: boolean
  ndsh_months: number
  hired_date: string //
  ndsh_total?: number
  home_address: string
  is_impairment: boolean
  quit_reason?: {
    data: QuitReasonEmployeeItem
  }
  long_term_leave_date?: string | null
  back_to_work_date?: string | null
}

export type EmployeeItem = {
  id: string
  type: string
  attributes: EmployeeItemAttributes
}

export type QuitReasonEmployeeItem = {
  id: string
  type: 'quit_reason'
  attributes: {
    reason: string
    quit_date: Date
  }
}

export type EmployeeShortItem = {
  id: string
  type: 'user'
  attributes: Omit<
    EmployeeItemAttributes,
    | 'quit_date'
    | 'ndsh_total'
    | 'ndsh_months'
    | 'home_address'
    | 'long_term_leave_date'
    | 'back_to_work_date'
    | 'role'
    | 'quit_reason'
    | 'uid'
    | 'hired_date'
  >
}

export type ShortUserItem = {
  id: string
  type: 'user_short'
  attributes: {
    uid: string
    user_code: string
    firstname: string
    lastname: string
    firstname_en: string
    lastname_en: string
    gender: string
    email: string
    status: string
    avatar: string
    avatar_thumbnail: string
    position: {
      data: {
        id: string
        type: 'position'
        attributes: {
          name: string
          short_name: string
          display_order: number
          is_default_filter: boolean
        }
      }
    }
  }
}

export type UserProfileResponse = {
  data: UserProfileItem
}

export type EmployeeListResponse = {
  data: EmployeeItem[]
  meta: MetaPage
}

export type EmployeeUserItem = EmployeeShortItem & {
  attributes: {
    current_day_status: EMPLOYEE_CURRENT_DAY_STATUS
  }
}

export type EmployeeShortItemListResponse = {
  data: EmployeeUserItem[]
  meta: MetaPage
}

export type EmployeeAllResponse = {
  data: EmployeeShortItem[]
}

export type EmployeeListParams = {
  keyword: string
  order_by: string
  direction: Direction
  per_page: string
  page: string
  positions?: string[]
  divisions?: string[]
  roles?: string[]
  status?: string[]
  gender?: string[]
}

export type EmployeeAddParams = {
  firstname: string
  lastname: string
  firstname_en?: string
  lastname_en?: string
  birthday?: string
  gender: number
  phone: string
  email: string
  position_id: number
  division_id: number
  role_id: number
  phone_secondaries?: { data: SecondaryPhoneResponseItem[] }
  status?: number
  ndsh_months?: number
  hired_date: string
  is_impairment: boolean
}

export type EmployeeResponse = {
  data: EmployeeItem
}

export type EmployeeShortItemResponse = {
  data: EmployeeUserItem
}

export type EmployeePositionItem = {
  id: string
  type: string
  attributes: {
    name: string
    display_order: number
    short_name: string
    is_default_filter: boolean
  }
}

export type EmployeePositionListResponse = {
  data: EmployeePositionItem[]
}

export type EmployeeDepartmentItem = {
  id: string
  type: string
  attributes: {
    id: number
    name: string
  }
}

export type EmployeeDepartmentListResponse = {
  data: EmployeeDepartmentItem[]
}

export type EmployeeProjectListResponse = {
  data: EmployeeProjectItem[]
  meta: MetaPage
}

export type EmployeeProjectItem = {
  id: string
  type: 'user_project_list'
  attributes: {
    member_role: string
    project: {
      id: string
      name: string
    }
  }
}

export type EmployeeDeleteParams = string

export type EmployeeUpdateParams = {
  id: string
  params: {
    firstname?: string
    lastname?: string
    firstname_en?: string
    lastname_en?: string
    birthday?: string
    gender?: number
    phone?: string
    email?: string
    position_id?: number
    division_id?: number
    role_id?: number
    phone_secondaries?: { data: SecondaryPhoneResponseItem[] }
    status?: number
    ndsh_months?: number
    hired_date?: string
  }
}

export type EmployeeDetailParams = string

export type EmployeeBackToWorkParams = string

export type BackToWorkFromLongTermLeaveParams = {
  id: string
  params: {
    back_to_work_date: string
  }
}

export type EmployeeQuitParams = {
  id: string
  params: {
    quit_date: string
    reason: string
  }
}

export type EmployeeProjectListParams = {
  id: string
  params?: {
    status?: string
    per_page?: number
    page?: number
  }
}

export type UserAvatarResponse = {
  data: {
    file_url: string
  }
}

export type UserAvatarParam = {
  key: string
}

export const userProfileServive = async (): Promise<UserProfileResponse> => {
  const { data } = await api.get<UserProfileResponse>(URI.PROFILE)
  return data
}

export const employeeListService = async (
  params: EmployeeListParams
): Promise<EmployeeListResponse> => {
  const { data } = await api.get<EmployeeListResponse>(`${URI.EMPLOYEE}.json`, {
    params: params,
  })
  return data
}

export const employeeShortItemListService = async (
  params: EmployeeListParams
): Promise<EmployeeShortItemListResponse> => {
  const { data } = await api.get<EmployeeShortItemListResponse>(
    `${URI.EMPLOYEE_USER_LIST}.json`,
    {
      params: params,
    }
  )
  return data
}

export const employeeListCSV = async (
  params: EmployeeListParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${URI.EMPLOYEE}.xlsx`, {
    params: params,
    responseType: 'blob',
  })
  return data
}

export const employeeAllService = async (): Promise<EmployeeAllResponse> => {
  const { data } = await api.get<EmployeeAllResponse>(URI.EMPLOYEE_ALL)
  return data
}

export const employeeActivePlusQuitService =
  async (): Promise<EmployeeAllResponse> => {
    const { data } = await api.get<EmployeeAllResponse>(
      URI.EMPLOYEE_ACTIVE_PLUS_QUIT
    )
    return data
  }

export const employeeAddService = async (
  params: EmployeeAddParams
): Promise<EmployeeResponse> => {
  const { data } = await api.post<EmployeeResponse>(URI.EMPLOYEE, params)
  return data
}

export const employeeDeleteService = async (
  id: EmployeeDeleteParams
): Promise<EmployeeResponse> => {
  const { data } = await api.delete<EmployeeResponse>(`${URI.EMPLOYEE}/${id}`)
  return data
}

export const employeeUpdateService = async (
  arg: EmployeeUpdateParams
): Promise<EmployeeResponse> => {
  const { data } = await api.put<EmployeeResponse>(
    `${URI.EMPLOYEE}/${arg.id}`,
    arg.params
  )
  return data
}

export const employeeDetailService = async (
  id: EmployeeDetailParams
): Promise<EmployeeResponse> => {
  const { data } = await api.get<EmployeeResponse>(`${URI.EMPLOYEE}/${id}`)
  return data
}

export const employeeShortItemDetailService = async (
  id: EmployeeDetailParams
): Promise<EmployeeShortItemResponse> => {
  const { data } = await api.get<EmployeeShortItemResponse>(
    URI.EMPLOYEE_USER_SHOW.replace(/:id/gi, id)
  )
  return data
}

export const employeePositionListService =
  async (): Promise<EmployeePositionListResponse> => {
    const { data } = await api.get<EmployeePositionListResponse>(
      `${URI.POSITION}/list`
    )
    return data
  }

export const employeeDepartmentListService =
  async (): Promise<EmployeeDepartmentListResponse> => {
    const { data } = await api.get<EmployeeDepartmentListResponse>(
      `${URI.DEPARTMENT}/list`
    )
    return data
  }

export const userUpdateProfileServive = async (
  params: UserProfileParams
): Promise<UserProfileResponse> => {
  const { data } = await api.put<UserProfileResponse>(
    URI.PROFILE_UPDATE,
    params
  )
  return data
}

export const employeeQuitServive = async (
  params: EmployeeQuitParams
): Promise<EmployeeResponse> => {
  const { data } = await api.put<EmployeeResponse>(
    `${URI.EMPLOYEE}/${params.id}/quit`,
    params.params
  )
  return data
}

export const employeeBackToWorkServive = async (
  params: EmployeeBackToWorkParams
): Promise<EmployeeResponse> => {
  const { data } = await api.put<EmployeeResponse>(
    URI.BACK_TO_WORK.replace(/:id/gi, params)
  )
  return data
}

export const backToWorkFromLongTermServive = async (
  args: BackToWorkFromLongTermLeaveParams
): Promise<EmployeeResponse> => {
  const { data } = await api.put<EmployeeResponse>(
    URI.BACK_TO_WORK_FROM_LONG_TERM_LEAVE.replace(/:id/gi, args.id),
    args.params
  )
  return data
}

export const employeeProjectListService = async (
  args: EmployeeProjectListParams
): Promise<EmployeeProjectListResponse> => {
  const { data } = await api.get<EmployeeProjectListResponse>(
    URI.EMPLOYEE_PROJECTS.replace(/:id/gi, args.id),
    {
      params: args.params,
    }
  )
  return data
}

export const userAvatarService = async (
  arg: UserAvatarParam
): Promise<UserAvatarResponse> => {
  const { data } = await api.post<UserAvatarResponse>(
    `${URI.EMPLOYEE}/set_avatar`,
    arg
  )
  return data
}

export type QuitReasonParam = {
  start_date?: Date
  end_date?: Date
  keyword: string
  per_page: string
  page: string
  order_by: string
  direction: string
}

export type QuitReasons = {
  quit_date: any
  id: string
  type: 'quit_reason'
  attributes: {
    reason: string
    quit_date: Date
  }
}
export type QuitReasonItem = {
  id: string
  type: 'quit_user'
  attributes: {
    user_code: string
    firstname_en: string
    lastname_en: string
    gender: string
    avatar: string
    email: string
    status: string
    quit_date: Date
    reason: {
      data: QuitReasons[]
    }
  }
}

export type QuitReasonResponse = {
  data: QuitReasonItem[]
  meta: MetaPage
}

export const quitReasonService = async (
  params: QuitReasonParam
): Promise<QuitReasonResponse> => {
  const { data } = await api.get<QuitReasonResponse>(
    `${URI.QUIT_REASON}.json`,
    {
      params: params,
    }
  )
  return data
}

export const quitReasonDownloadService = async (
  params: QuitReasonParam
): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${URI.QUIT_REASON}.xlsx`, {
    params: params,
    responseType: 'blob',
  })
  return data
}

export type InfoLogEmployeeItem = EmployeeItem & {
  attributes: {
    changed_attributes: {
      data: {
        id: string
        type: 'user_info_log'
        attributes: {
          action_type: InfoLogActionType
          field_name: string
          updated_by: UserInfoLogUpdatedByItem
        }
      }[]
    }
  }
}

export type UserInfoLogUpdatedByItem = {
  firstname: string
  lastname: string
  firstname_en: string
  lastname_en: string
  id: string
  updated_at: string
}

export type UserInfoLogResponse = {
  data: InfoLogEmployeeItem[]
  meta: MetaPage
}

export type UserInfoLogParams = {
  start_date?: Date
  end_date?: Date
  keyword?: string
}

export const userInfoLogService = async (
  params: UserInfoLogParams
): Promise<UserInfoLogResponse> => {
  const { data } = await api.get<UserInfoLogResponse>(
    `${URI.EMPLOYEE}/user_info_logs.json`,
    {
      params: params,
    }
  )
  return data
}

export const userInfoLogDownloadService = async (
  params: UserInfoLogParams
): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${URI.EMPLOYEE}/user_info_logs.xlsx`, {
    params: params,
    responseType: 'blob',
  })
  return data
}

export type ReasonUpdateParams = {
  user_id: string
  quit_date: Date
  reason: string
}

export type ReasonUpdateResponse = {
  data: QuitReasonItem
}

export const reasonUpdateService = async (
  arg: ReasonUpdateParams
): Promise<ReasonUpdateResponse> => {
  const { data } = await api.put<ReasonUpdateResponse>(
    `${URI.QUIT_REASON_UPDATE}`,
    arg
  )
  return data
}

export const userImportTemplateService = async (): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `${URI.EMPLOYEE}/user_import_template.xlsx`,
    {
      responseType: 'blob',
    }
  )
  return data
}
export type UserImportParam = {
  user_data_file: FileWithPath | null
}
export const userImportService = async (arg: UserImportParam): Promise<any> => {
  const formData = new FormData()
  if (arg.user_data_file) {
    formData.append('user_data_file', arg.user_data_file)
  }

  const { data } = await api.post(`${URI.EMPLOYEE}/import`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return data
}
export type FieldError = {
  field: string
  type: string
  message: string
}

export type ErrorResponse = {
  error: {
    type: string
    errors: FieldError[]
    code: number
    message: string
  }
}

export type EmployeeResetPasswordParams = {
  id: string
  params: {
    user_new_password: string
    user_confirm_new_password: string
  }
}

export type EmployeeResetPasswordResponse = {
  message: string
}
export const employeeResetPasswordService = async (
  args: EmployeeResetPasswordParams
): Promise<EmployeeResetPasswordResponse> => {
  const { data } = await api.put<EmployeeResetPasswordResponse>(
    URI.EMPLOYEE_PASSWORD_RESET.replace(/:id/gi, args.id),
    args.params
  )
  return data
}
