import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import password from 'secure-random-password'
import { SnackbarOrigin } from '@mui/material'
import { AppInit } from '@services/common.services'
import { breakEnd, breakStart, timeEnd, timeStart } from '@store/time/actions'

type StateType = {
  toasts: {
    uuid: string
    message: string
    severity: 'success' | 'error' | 'warning' | 'info'
    anchorOrigin: SnackbarOrigin | undefined
  }[]
  prevPath?: string
  init?: AppInit
}

const initialState: StateType = {
  toasts: [],
  prevPath: undefined,
  init: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.addToast, (state, action) => {
    // eslint-disable-next-line no-console
    state.toasts = [
      {
        message: action.payload.message,
        severity: action.payload.severity ? action.payload.severity : 'success',
        uuid: password.randomPassword({
          length: 12,
          characters: [
            password.lower,
            password.upper,
            password.digits,
            password.symbols,
          ],
        }),
        anchorOrigin: action.payload.anchorOrigin,
      },
      ...state.toasts,
    ]
  })
  builder.addCase(actions.removeToast, (state, action) => {
    state.toasts = state.toasts.filter((t) => t.uuid !== action.payload)
  })
  builder.addCase(actions.cleanToasts, (state) => {
    state.toasts = []
  })
  builder.addCase(actions.previousRoute, (state, action) => {
    state.prevPath = action.payload.path
  })
  builder.addCase(actions.appInit.fulfilled, (state, action) => {
    state.init = action.payload.data
  })
  builder.addCase(timeStart.fulfilled, (state, action) => {
    state.init = state.init
      ? {
          ...state.init,
          attributes: {
            ...state.init.attributes,
            time_records: { data: action.payload.data },
          },
        }
      : state.init
  })
  builder.addCase(timeEnd.fulfilled, (state, action) => {
    state.init = state.init
      ? {
          ...state.init,
          attributes: {
            ...state.init.attributes,
            time_records: { data: action.payload.data },
          },
        }
      : state.init
  })
  builder.addCase(breakStart.fulfilled, (state, action) => {
    state.init = state.init
      ? {
          ...state.init,
          attributes: {
            ...state.init.attributes,
            break_records: {
              data: state.init.attributes.break_records.data
                ? state.init.attributes.break_records.data.concat(
                    action.payload.data
                  )
                : [action.payload.data],
            },
          },
        }
      : state.init
  })
  builder.addCase(breakEnd.fulfilled, (state, action) => {
    state.init = state.init
      ? {
          ...state.init,
          attributes: {
            ...state.init.attributes,
            break_records: {
              data: state.init.attributes.break_records.data
                ? state.init.attributes.break_records.data.map((b) =>
                    b.id === action.payload.data.id ? action.payload.data : b
                  )
                : state.init.attributes.break_records.data,
            },
          },
        }
      : state.init
  })
})
