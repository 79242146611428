import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material/'
import { useTranslation } from 'react-i18next'
import { DialogOptions } from './types'
import useInit from '@utils/hooks/useInit'
import { MissingReportItem } from '@services/common.services'
import { ArrowRight, Close, EditNote } from '@mui/icons-material'
import moment from 'moment'
import _ from 'lodash'

interface DialogProps {
  open: boolean
  options: DialogOptions
  onCancel: () => void
  onConfirm: (item: MissingReportItem) => void
  onClose: (event: Event, reason: string) => void
  replaceUri?: string
}

const ListDialog = ({
  options,
  onCancel,
  onConfirm,
  onClose,
  ...rest
}: DialogProps) => {
  const { t } = useTranslation()
  const dialogOpen = rest.open
  const { title, dialogProps, confirmationButtonProps } = options

  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    onClose(event, reason)
  }

  const { missingReports, limit, period } = useInit()

  const renderList = () => {
    if (missingReports && !_.isEmpty(missingReports)) {
      return missingReports.map((item: MissingReportItem) => {
        return (
          <ListItem
            sx={{
              borderBottom: '1px solid #eee',
              '&:last-child': {
                borderBottom: '0 none',
              },
            }}
            key={item.id}
            secondaryAction={
              <>
                <Button
                  endIcon={<ArrowRight />}
                  variant="outlined"
                  size="small"
                  onClick={() => onConfirm(item)}
                  aria-label="delete"
                  sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex' },
                  }}
                >
                  {t('DAILY_REPORT.go_to_date')}
                </Button>
                <IconButton
                  sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}
                  onClick={() => onConfirm(item)}
                >
                  <ArrowRight />
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={moment(item.attributes.work_date).format('YYYY-MM-DD')}
            />
          </ListItem>
        )
      })
    }
    if (missingReports && _.isEmpty(missingReports)) {
      return (
        <Alert variant="outlined" severity="success">
          {t('DAILY_REPORT.complete_desc')}
        </Alert>
      )
    }
    return null
  }

  return (
    <>
      <Dialog
        fullWidth
        {...dialogProps}
        open={dialogOpen}
        onClose={handleClose}
      >
        <Container maxWidth={false}>
          <DialogTitle sx={{ padding: 0, mt: 2, mb: 2, textAlign: 'center' }}>
            <IconButton
              aria-label="close"
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 4,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ padding: 0 }}>
            {limit && (
              <Alert sx={{ mb: 1, mt: 3 }} variant="outlined" severity="info">
                <Typography component={'div'}>
                  <Typography
                    component={'span'}
                    sx={{ pr: 1, fontWeight: 600 }}
                  >
                    {t('DAILY_REPORT.missing_limit')}:
                  </Typography>
                  {t('DAILY_REPORT.missing_limit_desc', {
                    number: limit,
                  })}
                </Typography>
              </Alert>
            )}
            {period && (
              <Alert variant="outlined" severity="info">
                <Typography component={'div'}>
                  <Typography
                    component={'span'}
                    sx={{ pr: 1, fontWeight: 600 }}
                  >
                    {t('DAILY_REPORT.editable_period')}:
                  </Typography>
                  {t('DAILY_REPORT.editable_period_desc', {
                    number: period,
                  })}
                </Typography>
              </Alert>
            )}
            <Typography variant="h4" align="center" sx={{ mt: 1, mb: 1 }}>
              {' '}
              {title ? title : t('DAILY_REPORT.missing_title')}
            </Typography>
            <Box sx={{ p: 1 }}>
              <Paper sx={{ p: 1 }}>
                <Box>{renderList()}</Box>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            {missingReports && !_.isEmpty(missingReports) && (
              <Button
                data-test-id="confirmation-button"
                {...confirmationButtonProps}
                startIcon={<EditNote />}
                fullWidth
                variant="contained"
                onClick={() => {
                  !_.isEmpty(missingReports) && onConfirm(missingReports[0])
                }}
              >
                {t('DAILY_REPORT.write')}
              </Button>
            )}
          </DialogActions>
        </Container>
      </Dialog>
    </>
  )
}

export default ListDialog
