import useProfile from '@containers/@modules/User/useProfile'
import { useMediaQuery } from '@mui/material'
import { RolePermissionItem } from '@services/resource.services'
import { UserProfileItem } from '@services/user.services'
import theme from '@theme/index'
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react'
import useCurrentRole from 'utils/hooks/useCurrentRole'

interface LayoutContextProps {
  isSidebarOpen: boolean
  toggleSidebar: (state: boolean) => void
  user?: UserProfileItem
  lang: string
  changeLang: (id: string) => void
  permissions?: RolePermissionItem[]
  isBottomOpen: boolean
  setBottomOpen: (state: boolean) => void
}

const defaultValue: LayoutContextProps = {
  isSidebarOpen: false,
  toggleSidebar: () => null,
  user: undefined,
  lang: 'en',
  changeLang: () => null,
  permissions: undefined,
  isBottomOpen: true,
  setBottomOpen: () => null,
}

const LayoutContext = createContext<LayoutContextProps>(defaultValue)

const LayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [isBottomOpen, setBottomOpen] = useState<boolean>(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { data: user, lang, changeLang } = useProfile()
  const { permissions } = useCurrentRole()

  const toggleSidebar = (state: boolean) => {
    setSidebarOpen(state)
  }

  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false)
    }
  }, [isMobile])

  useEffect(() => {
    if (!isMobile) {
      setSidebarOpen(true)
    }
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        user,
        lang,
        changeLang,
        permissions,
        isBottomOpen,
        setBottomOpen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export { LayoutContext, LayoutProvider }
