import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import _ from 'lodash'
import { truncateDecimals } from '@utils/helper/common.helper'
import { useTranslation } from 'react-i18next'
import { generateColor } from '@marko19907/string-to-color'
import { BgColorOptions } from '@constants/common.constants'
import { VacationReportItem } from '@services/vacation.services'
import PlaceholderPieChart from '@components/EmptyPieChar'

interface StatWidgetProps {
  vacationReportList: VacationReportItem[]
}

const VacationReportWidget: React.FC<StatWidgetProps> = ({
  vacationReportList,
}) => {
  const { t } = useTranslation()
  const pre_paid = vacationReportList && _.sumBy(vacationReportList, 'pre_paid')
  const post_paid =
    vacationReportList && _.sumBy(vacationReportList, 'post_paid')
  const total_vacation = pre_paid + post_paid
  const projectsChartData = [
    {
      name: `${t('VACATION_REPORT.pre_paid')}`,
      count: (pre_paid * 100) / total_vacation,
      bgColor: generateColor('pre_paid', BgColorOptions),
    },
    {
      name: `${t('VACATION_REPORT.post_paid')}`,
      count: (post_paid * 100) / total_vacation,
      bgColor: generateColor('post_paid', BgColorOptions),
    },
  ]
  const CustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <Box
          className="custom-tooltip"
          sx={{
            background: '#fff',
            borderRadius: '6px',
            boxShadow: 1,
            py: 0.5,
            px: 1,
          }}
        >
          <Typography
            className="intro"
            noWrap
            sx={{ fontWeight: 500, fontSize: 11 }}
          >
            {t('VACATION_REPORT.type')}
          </Typography>
          <Typography className="label" sx={{ mb: 0.5 }}>
            <Box
              component={'span'}
              sx={{
                background: payload[0].payload.bgColor,
                width: 10,
                height: 10,
                display: 'inline-block',
                mr: 1,
              }}
            ></Box>
            {payload[0].name}
            {payload[0].count}
          </Typography>
        </Box>
      )
    }

    return null
  }
  const emptyChartData = [{ name: 'No data', count: 100, bgColor: '#efefef' }]
  return (
    <>
      <Box
        sx={{
          p: 1,
          pl: 2,
          borderBottom: '1px solid #eee',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" sx={{ pt: 1 }}>
          {t('VACATION_REPORT.vacation_report')}
        </Typography>
      </Box>
      {total_vacation == 0 ? (
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          direction={{ sm: 'column', lg: 'column', xl: 'row' }}
        >
          <PlaceholderPieChart
            width={140}
            height={197}
            innerRadius={44}
            outerRadius={60}
            emptyChartData={emptyChartData}
            placeholderText={t('DASHBOARD.no_data')}
          />
          <Box sx={{ maxWidth: '250px' }} />
        </Stack>
      ) : (
        <Box className="stat-widget" sx={{ p: 2 }}>
          <Box sx={{ maxWidth: '240px', margin: '0 auto', mb: 2 }}>
            <ResponsiveContainer width="100%" height={240}>
              <PieChart>
                <Pie
                  data={projectsChartData ?? []}
                  dataKey="count"
                  nameKey="name"
                  cx="50%"
                  stroke="transparent"
                  cy="50%"
                  innerRadius={57}
                  outerRadius={86}
                  fill="#8884d8"
                  labelLine={false}
                  label={({ x, y, percent }) => {
                    return (
                      <text
                        x={x}
                        y={y}
                        textAnchor={'middle'}
                        dominantBaseline="central"
                        fontSize={11}
                        fill="#000000"
                      >
                        {`${truncateDecimals(percent?.toFixed(2) * 100, 2)}%`}
                      </text>
                    )
                  }}
                >
                  {projectsChartData &&
                    projectsChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        style={{
                          outline: 'none',
                          filter: `drop-shadow(rgb(0, 0, 0, .1) 0px 0px 6px)`,
                        }}
                        fill={entry.bgColor}
                      />
                    ))}
                  <Label
                    value={t('VACATION_REPORT.report_summary')}
                    position="center"
                    style={{
                      fontSize: '12px',
                      fill: '#111',
                    }}
                  />
                  <Label
                    value={total_vacation}
                    position="center"
                    dy={16}
                    style={{
                      fontSize: '12px',
                      fill: '#111',
                    }}
                  />
                </Pie>
                <Tooltip content={CustomTooltip} />
              </PieChart>
            </ResponsiveContainer>
            {projectsChartData &&
              projectsChartData.map((entry, index) => (
                <Box key={index}>
                  <Box
                    component={'span'}
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: entry.bgColor,
                      display: 'inline-block',
                      mr: 1,
                    }}
                  ></Box>
                  {entry.name}
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  )
}

export default VacationReportWidget
