import DataLoading from '@components/DataLoading'
import ReportDownloader from '@components/ReportDownloader'
import { ROUTE } from '@constants/route.constants'
import useCostReport from '@containers/@modules/Report/useCostReport'
import DetailPageLayout from '@layouts/ContentLayout/DetailPageLayout'
import { Box, CircularProgress, Paper } from '@mui/material'
import {
  actualCostReporDetailDownloadService,
  ActualCostReportDetailParams,
} from '@services/report.services'
import { generateFileName } from '@utils/helper/common.helper'
import dayjs from 'dayjs'
import saveAs from 'file-saver'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { use100vh } from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import ActualCostReportDetailFilter from './ActualCostReportDetailFilter'

const ActualCostDetailDataGrid = React.lazy(
  () => import('@components/@data-grid/ActualCostDetailDataGrid')
)

const ActualCostReportDetailPage: React.FC = () => {
  const { id: userId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const [filter, setFilter] = useState<ActualCostReportDetailParams>({
    report_start_date: startDate
      ? dayjs(startDate)
      : dayjs(new Date()).startOf('month'),
    report_end_date: endDate ? dayjs(endDate) : null,
  })

  const {
    fetchActualCostDetail,
    actualCostDetail,
    actualCostDetailMeta,
    filterDates,
  } = useCostReport()

  const { t } = useTranslation()

  const breadcrumbs = [
    {
      title: t('SYSCOMMON.dashboard'),
      to: '/',
    },
    {
      title: t('ACTUAL_COST_REPORT.title'),
      to: ROUTE.ACTUAL_COST_REPORT,
    },
    {
      title: t('ACTUAL_COST_REPORT.detail_title'),
    },
  ]
  const height = use100vh()
  const maxHeight = height ? height - 325 : 400

  useEffect(() => {
    fetchActualCostDetail({ ...filter, user_id: userId ? userId : '' })
  }, [filter])

  const fetchCSV = async () => {
    try {
      const blob = await actualCostReporDetailDownloadService({
        ...filter,
        user_id: userId ? userId : '',
      })
      const fileName = generateFileName('actual-cost-report-detail', 'xlsx')
      const userName = `${actualCostDetail.user.firstname_en}_${actualCostDetail.user.lastname_en}`
      saveAs(blob, `${userName}_${fileName}`)
    } catch (error) {
      console.error('ダウンロードに失敗しました。:', error)
    }
  }

  const handleFilterSubmit = (values: any) => {
    setFilter(values)
    const params = values.report_end_date
      ? {
          startDate: values.report_start_date.toISOString(),
          endDate: values.report_end_date.toISOString(),
        }
      : {
          startDate: values.report_start_date.toISOString(),
        }

    // Set the new search parameters
    setSearchParams(params as any)
  }

  return (
    <DetailPageLayout
      error={actualCostDetailMeta.error}
      breadcrumbs={breadcrumbs}
      title={t('ACTUAL_COST_REPORT.detail_title')}
      actions={
        <>
          <ReportDownloader fetchCSV={fetchCSV} />
        </>
      }
    >
      <Box>
        <Paper sx={{ mb: 2, p: 1.5, pl: 2, pr: 2 }}>
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ width: '100%' }}>
              <ActualCostReportDetailFilter
                onFilter={handleFilterSubmit}
                filter={filter}
              />
            </Box>
          </Box>
        </Paper>
        {actualCostDetailMeta.pending && (
          <Box
            component="span"
            sx={{
              position: 'absolute',
              display: 'flex',
              left: 0,
              right: 0,
              background: 'rgba(255,255,255,0.6)',
              top: 5,
              bottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 100,
            }}
          >
            <CircularProgress size={20} />
          </Box>
        )}
        <React.Suspense
          fallback={
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <CircularProgress size={20} /> */}
            </Box>
          }
        >
          {actualCostDetail && (
            <>
              <ActualCostDetailDataGrid
                actualCostDetail={actualCostDetail}
                maxHeight={maxHeight}
                filterDates={filterDates}
                targetMonth={dayjs(filter.report_start_date)}
              />
            </>
          )}
          {!actualCostDetailMeta.pending && (
            <Paper>
              <DataLoading
                sx={{ textAlign: 'center' }}
                isLoading={false}
                isEmptyData={actualCostDetail && _.isEmpty(actualCostDetail)}
                emptyDataSx={{ py: 2 }}
              />
            </Paper>
          )}
        </React.Suspense>
      </Box>
    </DetailPageLayout>
  )
}

export default ActualCostReportDetailPage
