import { Box, FormControl, FormHelperText, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { DateView } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import React, { ReactElement } from 'react'
import { CustomLabel } from '../StyledTextField'

interface DateTimePickerProps {
  helperText?: string
  labelPrimary?: string
  isHelperShow?: boolean
  labelSecondary?: ReactElement
  required?: boolean
  value: string | Dayjs | null
  onChange: (v: string | null) => void
  error: boolean
  views?: DateView[]
  inputFormat?: string
  readOnly?: boolean
  shouldDisableYear?: (year: string | Dayjs) => boolean
  shouldDisableMonth?: (year: string | Dayjs) => boolean
}

const CustomDatePicker: React.FC<DateTimePickerProps> = ({
  helperText,
  labelPrimary,
  labelSecondary,
  isHelperShow = true,
  required = false,
  value,
  error,
  onChange,
  inputFormat = 'YYYY/MM/DD',
  views = ['year', 'month', 'day'],
  readOnly,
  shouldDisableYear,
  shouldDisableMonth,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth>
        {(labelPrimary || labelSecondary) && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingBottom: 0 }}
          >
            <Box sx={{ padding: 0 }} display="flex" alignItems="center">
              <CustomLabel text={labelPrimary ? labelPrimary : ''} />
              {required && (
                <Typography
                  component="span"
                  sx={{
                    paddingLeft: (theme) => theme.spacing(1 / 2),
                    paddingRight: (theme) => theme.spacing(1 / 2),
                    height: 16,
                    fontSize: 10,
                    marginLeft: '5px',
                    color: red[400],
                  }}
                >
                  *
                </Typography>
              )}
            </Box>
            {labelSecondary}
          </Box>
        )}

        <DatePicker
          readOnly={readOnly}
          value={value}
          slotProps={{
            textField: { error: error },
          }}
          format={inputFormat}
          defaultValue={null}
          views={views}
          shouldDisableYear={shouldDisableYear}
          shouldDisableMonth={shouldDisableMonth}
          onChange={(newValue) => {
            onChange(newValue as any)
          }}
          {...rest}
        />

        {isHelperShow &&
          (helperText ? (
            <FormHelperText
              sx={{
                height: 12,
                m: 0,
              }}
              error={error}
            >
              {helperText}
            </FormHelperText>
          ) : (
            <Box sx={{ height: 12 }} />
          ))}
      </FormControl>
    </LocalizationProvider>
  )
}

export default CustomDatePicker
