/* eslint-disable no-console */
import HtmlParser from '@components/HtmlParser'
import { Box, BoxProps } from '@mui/material'
import React, { useEffect } from 'react'
import useWp from 'utils/hooks/useWp'

interface HelpContentProps extends BoxProps {
  id: string
}

const HelpContent: React.FC<HelpContentProps> = ({ id, ...rest }) => {
  const { data, fetchPage, clear } = useWp()

  useEffect(() => {
    fetchPage(id)
    return () => clear()
  }, [id])

  return (
    <Box {...rest}>
      <Box className="wp-content">
        {data && <HtmlParser content={data.content} />}
      </Box>
    </Box>
  )
}

export default HelpContent
