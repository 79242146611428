import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import { transformTeamDailyReport } from './helper'

const getRoot = (state: RootState) => state.dailyreport

export const getMyDailyReportList = createSelector(
  getRoot,
  (state) => state.myDailyReportList
)

export const getTeamDailyReportList = createSelector(
  getRoot,
  (state) => state.teamDailyReportList
)

export const getHrDailyReportList = createSelector(
  getRoot,
  (state) => state.hrDailyReportList
)

export const getDailyReportDetail = createSelector(
  getRoot,
  (state) => state.dailyReportDetail
)

export const getTeamEvents = createSelector(getRoot, (state) =>
  transformTeamDailyReport(
    state.teamDailyReportList
      ? state.teamDailyReportList
      : state.teamDailyReportList
  )
)

export const getHrEvents = createSelector(getRoot, (state) =>
  transformTeamDailyReport(
    state.hrDailyReportList ? state.hrDailyReportList : state.hrDailyReportList
  )
)

export const getTags = createSelector(getRoot, (state) => state.tagList)
