import i18n from '@locales/i18n'
import {
  AccountTree,
  Bolt,
  CalendarMonth,
  CalendarViewMonth,
  CheckCircleOutlineOutlined,
  Dashboard,
  DateRange,
  Group,
  InsertChart,
  MeetingRoom,
  PostAdd,
  Rule,
  Send,
  Summarize,
  List,
  QueryStats,
  Settings,
  LibraryBooks,
  Diversity3,
  Groups2,
  PendingActions,
  Wifi,
  WifiOff,
  GroupAdd,
  Villa,
  IndeterminateCheckBox,
  AssignmentInd,
} from '@mui/icons-material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import { ReactNode } from 'react'
import { MODULES } from './modules.constants'
import { ROUTE } from './route.constants'

export type MenuItemChildren = {
  uri?: string
  label: string
  type: 'nav-item' | 'collapsible'
  icon: ReactNode
  module?: MODULES
  children?: MenuItemChildren[]
  isExact?: boolean
  matchGroups?: string[]
  isAdminOnly?: boolean
}

export type MenuItem = {
  label: string
  type: string
  children: MenuItemChildren[]
  module?: MODULES
  isExact?: boolean
  matchGroups?: string[]
}

const generateMenus = (): MenuItem[] => {
  const menuArray: MenuItem[] = [
    {
      label: i18n.t('SYSCOMMON.home'),
      type: 'section',
      children: [
        {
          uri: ROUTE.DASHBOARDS,
          label: i18n.t('SYSCOMMON.dashboard'),
          type: 'nav-item',
          icon: <Dashboard sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.EMPLOYEE.ROOT,
          label: i18n.t('SYSCOMMON.employees'),
          type: 'nav-item',
          icon: <Group sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [ROUTE.EMPLOYEE.ROOT, ROUTE.EMPLOYEE.DETAILS],
        },
        {
          uri: ROUTE.PROJECT.ROOT,
          label: i18n.t('SYSCOMMON.projects'),
          type: 'nav-item',
          module: MODULES.PROJECTS,
          icon: <AccountTree sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [
            ROUTE.PROJECT.ROOT,
            ROUTE.PROJECT.OVERVIEW,
            ROUTE.PROJECT.SETTINGS,
            ROUTE.PROJECT.MEMBER,
            ROUTE.PROJECT.AVTIVITY,
          ],
        },
        {
          uri: ROUTE.BOOK_MEETING_ROOM,
          label: i18n.t('MEETING_ROOM_ORDER.title'),
          type: 'nav-item',
          icon: <MeetingRoom sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.EVENTS.ROOT,
          label: i18n.t('SYSCOMMON.events'),
          type: 'nav-item',
          icon: <CalendarMonth sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.COMPANY_RULES,
          label: i18n.t('COMPANY_RULE.title'),
          type: 'nav-item',
          icon: <LibraryBooks sx={{ fontSize: 20 }} />,
        },
      ],
    },
    // PROJECT MODULE

    // REPORTS

    {
      label: i18n.t('DAILY_REPORT.menu_title'),
      type: 'section',
      module: MODULES.TIME,
      children: [
        {
          uri: ROUTE.DAILY_REPORT.ROOT,
          label: i18n.t('DAILY_REPORT.my_daily_report'),
          type: 'nav-item',

          icon: <Summarize sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.DAILY_REPORT.TEAM,
          label: i18n.t('DAILY_REPORT.page_title_team'),
          type: 'nav-item',

          icon: <Diversity3 sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.DAILY_REPORT.HR,
          label: i18n.t('DAILY_REPORT.all_daily_report'),
          type: 'nav-item',
          icon: <Groups2 sx={{ fontSize: 20 }} />,
          module: MODULES.SETTINGS,
        },
      ],
    },
    //TIME MANAGEMENT MODULE
    {
      label: i18n.t('SYSCOMMON.time_management'),
      type: 'section',
      children: [
        {
          uri: ROUTE.TIMESHEET.ROOT,
          label: i18n.t('TIMESHEET.menu_title'),
          type: 'nav-item',
          module: MODULES.TIMESHEET,
          icon: <DateRange sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.TIME_CORRECTION.REQUEST_LIST,
          label: i18n.t('TIME_CORRECTION.my_list_title'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <List sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.TIME_CORRECTION.APPROVE_LIST,
          label: i18n.t('TIME_CORRECTION.menu_title'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <CheckCircleOutlineOutlined sx={{ fontSize: 20 }} />,
        },
        {
          label: i18n.t('SYSCOMMON.hr_related'),
          type: 'collapsible',
          icon: <Settings sx={{ fontSize: 20 }} />,
          module: MODULES.TIME_SETTINGS,
          children: [
            {
              uri: ROUTE.VACATION_BALANCES.ROOT,
              label: i18n.t('VACATION_BALANCES.title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <Bolt sx={{ fontSize: 20 }} />,
            },
            {
              uri: ROUTE.COMPENSATORY_DAYS.ROOT,
              label: i18n.t('COMPENSATORY_DAYS.title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <Bolt sx={{ fontSize: 20 }} />,
            },
            {
              uri: ROUTE.TIME.REPORT,
              label: i18n.t('TIME_REPORT.menu_title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <CalendarViewMonth sx={{ fontSize: 20 }} />,
            },
            {
              uri: ROUTE.TIMEOFF_REPORT.ROOT,
              label: i18n.t('TIME_REPORT.timeoff_report_title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <CalendarViewMonth sx={{ fontSize: 20 }} />,
            },
          ],
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.time_off'),
      type: 'section',
      children: [
        {
          uri: ROUTE.TIMEOFF_REQUEST.LIST,
          label: i18n.t('TIMEOFF_REQUEST.menu_title'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <PendingActions sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.TIMEOFF_APPROVE.LIST,
          label: i18n.t('TIMEOFF_REQUEST.approve_list_menu'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <Rule sx={{ fontSize: 20 }} />,
        },
        {
          label: i18n.t('SYSCOMMON.template_settings'),
          type: 'nav-item',
          icon: <Settings sx={{ fontSize: 20 }} />,
          module: MODULES.TIME_SETTINGS,
          uri: ROUTE.TIMEOFF_TEMPLATE.LIST,
          isExact: false,
          matchGroups: [
            ROUTE.TIMEOFF_TEMPLATE.LIST,
            ROUTE.TIME_CORRECTION.TEMPLATE,
            ROUTE.APPROVAL_TEMPLATE.LIST,
          ],
        },
      ],
    },
    // REMOTE WORK
    {
      label: i18n.t('SYSCOMMON.remote_work'),
      type: 'section',
      module: MODULES.REMOTE,
      children: [
        {
          uri: ROUTE.REMOTE_WORK.REQUEST,
          label: i18n.t('REMOTE_WORK_REQUEST.menu_title'),
          type: 'nav-item',
          module: MODULES.REMOTE,
          icon: <Wifi sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [
            ROUTE.REMOTE_WORK.REQUEST,
            ROUTE.REMOTE_WORK.DISABLED_LOG,
          ],
        },
        {
          uri: ROUTE.REMOTE_WORK.APPROVE,
          label: i18n.t('REMOTE_WORK_REQUEST.approve_title'),
          type: 'nav-item',
          module: MODULES.REMOTE,
          icon: <Rule sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.REMOTE_WORK.SETTINGS,
          label: i18n.t('REMOTE_WORK_SETTINGS.menu_title'),
          type: 'nav-item',
          module: MODULES.REMOTE,
          isAdminOnly: true,
          icon: <WifiOff sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.REMOTE_WORK.TEMPLATE,
          label: i18n.t('REMOTE_WORK_TEMPLATE.menu_title'),
          type: 'nav-item',
          icon: <Settings sx={{ fontSize: 20 }} />,
          isAdminOnly: true,
          module: MODULES.REMOTE,
        },
      ],
    },
    // RESOURCE MANAGEMENT MODULE
    {
      label: i18n.t('SYSCOMMON.human_resource'),
      type: 'section',
      module: MODULES.RESOURCE,
      children: [
        {
          uri: ROUTE.EMPLOYEE.LIST,
          label: i18n.t('SYSCOMMON.employees'),
          type: 'nav-item',
          icon: <Group sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [
            ROUTE.EMPLOYEE.LIST,
            ROUTE.EMPLOYEE.CREATE,
            ROUTE.EMPLOYEE.EDIT,
            ROUTE.INFO_LOG,
            ROUTE.QUIT_USERS,
          ],
        },
        {
          label: i18n.t('SYSCOMMON.settings'),
          icon: <Settings sx={{ fontSize: 20 }} />,
          type: 'nav-item',
          uri: ROUTE.POSITIONS.LIST,
          isExact: false,
          matchGroups: [
            ROUTE.POSITIONS.LIST,
            ROUTE.POSITIONS.ORDER,
            ROUTE.DEPARTMENT.LIST,
            ROUTE.ROLE,
            ROUTE.SKILL.ROOT,
            ROUTE.SKILL.CATEGORY,
            ROUTE.SKILL.CATEGORY_ORDER,
          ],
        },
      ],
    },

    //EMAIL MODULE
    {
      label: i18n.t('EMAIL.title'),
      type: 'section',
      module: MODULES.EMAIL,
      children: [
        {
          uri: ROUTE.EMAIL.ROOT,
          label: i18n.t('EMAIL.send_email'),
          type: 'nav-item',
          icon: <Send sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [
            ROUTE.EMAIL.ROOT,
            ROUTE.EMAIL.TEMPLATES,
            ROUTE.EMAIL.CAMPAIGNS,
            ROUTE.EMAIL.CAMPAIGN_SETTINGS,
            ROUTE.EMAIL.TEMPLATE_SETTINGS,
          ],
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.reports'),
      type: 'section',
      module: MODULES.RESOURCE,
      children: [
        {
          uri: ROUTE.SKILL.REPORT,
          label: i18n.t('SYSCOMMON.skill_report'),
          type: 'nav-item',
          icon: <InsertChart sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.REMOTE_REPORT.ROOT,
          label: i18n.t('TIME_REPORT.work_status_report'),
          type: 'nav-item',
          icon: <IndeterminateCheckBox sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.VACATION_REPORT.ROOT,
          label: i18n.t('VACATION_REPORT.report_title'),
          type: 'nav-item',
          icon: <Villa sx={{ fontSize: 20 }} />,
        },
        {
          uri: ROUTE.TIMEOFF_TOTAL_REPORT.ROOT,
          label: i18n.t('TIMEOFF_TOTAL_REPORT.title'),
          type: 'nav-item',
          icon: <AssignmentInd sx={{ fontSize: 20 }} />,
        },
      ],
    },
    {
      label: i18n.t('MODULES.resource_planning'),
      type: 'section',
      module: MODULES.RESOURCE_PLANNING,
      children: [
        {
          uri: ROUTE.RESOURCE_PLAN.ROOT,
          label: i18n.t('RESOURCE_PLAN.title'),
          type: 'nav-item',
          icon: <PostAdd sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [ROUTE.RESOURCE_PLAN.ROOT, ROUTE.RESOURCE_PLAN.DETAILS],
        },
        {
          uri: ROUTE.PARTNER_PROJECT_ORDER.LIST,
          label: i18n.t('PROJECT_PARTNER_PURCHASE_ORDER.title'),
          type: 'nav-item',
          icon: <GroupAdd sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [ROUTE.PARTNER_PROJECT_ORDER.LIST],
        },
        {
          uri: ROUTE.COST_REPORT.LIST,
          label: i18n.t('PROJECT.cost_report_title'),
          type: 'nav-item',
          icon: (
            <LeaderboardIcon
              sx={{ fontSize: 20, transform: 'rotate(90deg)' }}
            />
          ),
          isExact: false,
          matchGroups: [ROUTE.COST_REPORT.LIST, ROUTE.COST_REPORT.DETAIL],
        },
        {
          uri: ROUTE.ACTUAL_COST_REPORT,
          label: i18n.t('ACTUAL_COST_REPORT.title'),
          type: 'nav-item',
          icon: <QueryStats sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [
            ROUTE.ACTUAL_COST_REPORT,
            ROUTE.ACTUAL_COST_REPORT_DETAIL,
          ],
        },
      ],
    },
    // OTHER
    {
      label: i18n.t('SYSCOMMON.other'),
      type: 'section',
      module: MODULES.SETTINGS,
      children: [
        {
          label: i18n.t('SYSCOMMON.configuration'),
          type: 'nav-item',
          module: MODULES.SETTINGS,
          uri: ROUTE.COMPANY.ROOT,
          icon: <Settings sx={{ fontSize: 20 }} />,
          isExact: false,
          matchGroups: [
            ROUTE.COMPANY.ROOT,
            ROUTE.MEETING_ROOM.ROOT,
            ROUTE.WORKSPACE.ROOT,
            ROUTE.WORKSPACE.LOCATION,
            ROUTE.SYSTEM_SETTINGS.ROOT,
            ROUTE.COMPANY.DIVISION,
            ROUTE.COMPANY.DIVISION_ORDER,
          ],
        },
      ],
    },
  ]

  return menuArray
}

export default generateMenus
