/* eslint-disable no-console */
import { createSelector } from '@reduxjs/toolkit'
import { CompanyTotalItem, CostReportItem } from '@services/report.services'
import { RootState } from '@store/store'
import _ from 'lodash'
import { groupProjects } from './helper'

const getRoot = (state: RootState) => state.report

export const getCostReport = createSelector(getRoot, (state) => state.costList)
export const getCostInfo = createSelector(getRoot, (state) => state.costInfo)
export const getCostActivity = createSelector(
  getRoot,
  (state) => state.activity
)

export const getCostGrouped = createSelector(getRoot, (state) =>
  state.costList ? groupProjects(state.costList) : undefined
)

/**
 * Calculates the company total by aggregating costs based on purchased positions and positions.
 *
 * @param {Object} state - The state object containing costList as a CostReportItem.
 * @returns {Array<CompanyTotalItem>} The ordered result of aggregated company totals.
 */

export const getCompanyTotal = createSelector(getRoot, (state) => {
  const groupedPurchasedPositions = _.groupBy(
    _.flatMap(state.costList as CostReportItem, 'members'),
    (member) => member.purchased_position?.id
  )

  const groupedPositions = _.groupBy(
    _.flatMap(state.costList as CostReportItem, 'members'),
    (member) => member.position?.id
  )

  const groupedOrderedPositions = _.groupBy(
    _.flatMap(state.costList as CostReportItem, 'partner_purchase_orders'),
    (order) => order.position?.id
  )

  // Aggregating total cost and count for purchased positions, calculating total_purchased_position and total_purchased_count
  const aggregatedPurchasedPositions = _.mapValues(
    groupedPurchasedPositions,
    (members) => {
      if (!_.isEmpty(members[0].purchased_position)) {
        const totalPurchasedCost = _.sumBy(members, 'cost')
        const totalPurchasedCount = members.length
        return {
          position_id: members[0].purchased_position.id,
          position_name: members[0].purchased_position.short_name,
          total_purchased_cost: totalPurchasedCost,
          total_purchased_count: totalPurchasedCount,
          display_order: members[0].purchased_position.display_order,
        }
      }
    }
  )

  // Aggregating total cost and count for positions, calculating total_cost and total_count.
  const aggregatedPositions = _.mapValues(groupedPositions, (members) => {
    if (!_.isEmpty(members[0].position)) {
      const totalPurchasedCost = _.sumBy(members, 'cost')
      const totalPurchasedCount = members.length
      return {
        position_id: members[0].position.id,
        position_name: members[0].position.short_name,
        total_cost: totalPurchasedCost,
        total_count: totalPurchasedCount,
        display_order: members[0].position.display_order,
      }
    }
  })

  const aggregatedOrderedPositions = _.mapValues(
    groupedOrderedPositions,
    (orderedPosition) => {
      if (!_.isEmpty(orderedPosition[0])) {
        const totalOrderedCost = _.sumBy(orderedPosition, 'cost')
        const totalOrderedCount = orderedPosition.length
        return {
          position_id: orderedPosition[0].position.id,
          position_name: orderedPosition[0].position.short_name,
          total_ordered_cost: totalOrderedCost,
          total_ordered_count: totalOrderedCount,
          display_order: orderedPosition[0].position.display_order,
        }
      }
    }
  )

  // Mergin purchased position and position.
  const mergedList = _.mergeWith(
    aggregatedPurchasedPositions,
    aggregatedPositions,
    aggregatedOrderedPositions,
    (objValue, srcValue) => {
      if (_.isObject(objValue)) {
        return _.merge(objValue, srcValue)
      }
    }
  )

  const filteredMergedList = _.omitBy(mergedList, _.isUndefined)

  const filteredResult = _.filter(
    filteredMergedList,
    (item): item is CompanyTotalItem => item !== undefined
  )

  const orderedResult = _.orderBy(filteredResult, ['display_order'], ['asc'])

  return orderedResult
})

export const getActualCostList = createSelector(
  getRoot,
  (state) => state.actualCostList
)
export const getCostProjectList = createSelector(
  getRoot,
  (state) => state.costProjectList
)

// export const getActualCostProjectTotal = createSelector(getRoot, (state) => {
//   const actualCostList = state.actualCostList
//     ? state.actualCostList.filter(
//         (item: ActualCostReportItem) => !_.isNull(item.projects)
//       )
//     : []

//   const totals = _.map(
//     _.groupBy(actualCostList, 'position.id'),
//     (positionGroup, positionId) => {
//       const projects = _.flatMap(positionGroup, 'projects')
//       const groupedProjects = _.groupBy(projects, 'id')

//       const projectTotals = _.map(
//         groupedProjects,
//         (projectGroup, projectId) => {
//           const actualCostTotal = _.sumBy(projectGroup, 'actual_cost')
//           const plannedCostTotal = _.sumBy(projectGroup, 'planned_cost')

//           return {
//             project_id: projectId,
//             actual_cost: actualCostTotal,
//             planned_cost: plannedCostTotal,
//           }
//         }
//       )

//       return {
//         position_id: positionId,
//         projects: projectTotals,
//       }
//     }
//   )

//   return totals
// })

export const getActualCostDates = createSelector(
  getRoot,
  (state) => state.actualCostDates
)

export const getActualCostReportDetail = createSelector(
  getRoot,
  (state) => state.actualCostReportDetail
)

export const getActualCostDetailFilterDates = createSelector(
  getRoot,
  (state) => state.actualCostReportDetailFilterDates
)
