import CustomDatePicker from '@components/@material-extend/CustomDatePicker'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { FieldValues, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import AutoCompleteSelect from '@components/@material-extend/SelectWithSearch/AutoCompleteSelect'
import { EmployeeItem } from '@services/user.services'
import { Meta } from '@store/metadata/actions/types'
import { getFullNameEn } from '@utils/helper/common.helper'
import CustomListItem from '@components/@modules/Project/CustomSelectItem'
import _ from 'lodash'

interface RemoteOfficeFilterProps {
  onFilter?: (data: any) => void
  employeeAll: EmployeeItem[]
  allEmployeeMeta: Meta
}

const RemoteOfficeFilter: React.FC<RemoteOfficeFilterProps> = (props) => {
  const { t } = useTranslation()
  const { onFilter, employeeAll, allEmployeeMeta } = props

  const validationSchema = Yup.object().shape(
    {
      start_date: Yup.date()
        .required(t('ERROR.E000002'))
        .typeError(t('ERROR.E000006')),
      end_date: Yup.date()
        .when('start_date', (start, schema) => {
          if (
            start !== null &&
            start[0] !== null &&
            dayjs(start[0]).isValid()
          ) {
            return schema.min(start, t('ERROR.E000067'))
          }
          return schema
        })
        .required(t('ERROR.E000002'))
        .typeError(t('ERROR.E000006')),
    },
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      start_date: dayjs(new Date()).subtract(1, 'month'),
      end_date: dayjs(new Date()),
      employee: '',
    },
    mode: 'onChange',
  })
  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = methods
  const startDate = useWatch({ control: control, name: 'start_date' })
  const endDate = useWatch({ control: control, name: 'end_date' })
  const user = useWatch({ control: control, name: 'employee' }) as any
  useEffect(() => {
    trigger('end_date')
  }, [startDate])
  const filterReport = () => {
    onFilter &&
      onFilter({
        start_date: startDate,
        end_date: endDate,
        user_id: user?.id,
      })
  }
  const isButtonDisabled = !endDate || dayjs(endDate).isBefore(dayjs(startDate))
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6}>
          <Stack sx={{ width: '100%' }} direction="row" spacing={1}>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: '50%' }}>
                  <CustomDatePicker
                    labelPrimary={t('QUIT_USERS.start_date')}
                    error={errors.start_date ? true : false}
                    helperText={
                      errors.start_date ? errors.start_date.message : ''
                    }
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name="end_date"
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: '50%' }}>
                  <CustomDatePicker
                    labelPrimary={t('QUIT_USERS.end_date')}
                    error={errors.end_date ? true : false}
                    helperText={errors.end_date ? errors.end_date.message : ''}
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Box sx={{ flexGrow: 1 }}>
              <Controller
                name="employee"
                control={control}
                render={({ field: { value, onChange } }: FieldValues) => (
                  <>
                    <Typography sx={{ m: '1.5px' }}>
                      {t('TIME_CORRECTION.employee')}
                    </Typography>
                    <AutoCompleteSelect
                      value={value}
                      required
                      fullWidth
                      disablePortal={true}
                      loading={allEmployeeMeta.pending}
                      data={employeeAll ?? []}
                      getOptionLabel={(option) =>
                        getFullNameEn(
                          undefined,
                          _.get(option, 'attributes.lastname_en', ''),
                          _.get(option, 'attributes.firstname_en', '')
                        )
                      }
                      renderOption={(props, option) => (
                        <CustomListItem
                          key={option?.id}
                          option={option}
                          {...props}
                        />
                      )}
                      onInputChange={onChange}
                      error={errors.employee ? true : false}
                      isHelperShow={false}
                    />
                  </>
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                pt: 3,
              }}
            >
              <Button
                onClick={handleSubmit(filterReport)}
                disabled={isButtonDisabled}
                data-test-id="filter-button"
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('TIMEOFF_TOTAL_REPORT.submit')}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RemoteOfficeFilter
