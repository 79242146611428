import { getPresignedUrlResponse } from '@services/common.services'
import { useContext } from 'react'
import { UploadOptions } from './types'
import ConfirmContext from './UploaderContext'

const useUpload = (): ((
  options: UploadOptions
) => Promise<getPresignedUrlResponse>) => {
  const upload = useContext(ConfirmContext)
  return upload
}

export default useUpload
