import { UserProfileItem, UserProfileParams } from '@services/user.services'
import { logOut, switchLang } from '@store/auth/actions'
import {
  baseURL as baseUrlSelector,
  currentUserLang,
} from '@store/auth/selectors'
import { useAppDispatch } from '@store/hooks'
import { changePublicLanguage } from '@store/localsettings/actions'
import { currentPublicLang } from '@store/localsettings/selectors'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { fetchProfile, updateProfile } from '@store/user/actions'
import { getUserProfile } from '@store/user/selectors'
import { useSelector } from 'react-redux'

interface Profile {
  profile: () => any
  data: UserProfileItem
  meta: Meta
  baseURL: string
  lang: string
  signOut: () => void
  changeLang: (id: string) => void
  updateMeta: Meta
  update: (params: UserProfileParams) => any
  publicLang: string
  changePublicLang: (id: string) => void
}

const useProfile = (): Profile => {
  const dispatch = useAppDispatch()
  const profile = () => dispatch(fetchProfile())
  const data = useSelector(getUserProfile)

  const update = (params: UserProfileParams) => dispatch(updateProfile(params))

  const _meta = createMetaSelector(fetchProfile)
  const meta = useSelector(_meta)

  const _updateMeta = createMetaSelector(updateProfile)
  const updateMeta = useSelector(_updateMeta)
  const publicLang = useSelector(currentPublicLang)

  const baseURL = useSelector(baseUrlSelector)
  const lang = useSelector(currentUserLang)
  const signOut = () => {
    dispatch(logOut())
  }
  const changeLang = (id: string) => dispatch(switchLang({ language: id }))
  const changePublicLang = (id: string) => dispatch(changePublicLanguage(id))
  return {
    updateMeta,
    changePublicLang,
    update,
    profile,
    data,
    signOut,
    changeLang,
    lang,
    baseURL,
    meta,
    publicLang,
  }
}

export default useProfile
