import { TagItem } from '@services/dailyreport.services'
import { ProjectItem } from '@services/project.services'
import { TimeCardItem } from '@services/time.services'
import _ from 'lodash'

export interface DailyReportCalendarItem {
  id: string
  report: string
  start: Date
  end: Date
  project: ProjectItem
  name: string
  deadline: number
  complexity: number
  tags: TagItem
}

export const reportParser = (res?: TimeCardItem): DailyReportCalendarItem[] => {
  if (!res && _.isEmpty(res)) {
    return []
  }
  const list = _.get(
    res,
    'attributes.daily_report.data.attributes.daily_report_details.data'
  )
  const workDate: Date = new Date(
    _.get(res, 'attributes.work_date', new Date())
  )
  const reports: DailyReportCalendarItem[] = _.map(list, (item) => {
    const data = item
    const startAt = new Date(data.attributes.start_at)
    const endAt = new Date(data.attributes.end_at)

    if (workDate) {
      const workYear = workDate.getFullYear()
      const workMonth = workDate.getMonth()
      const workDay = workDate.getDate()

      // Set the year, month, and day of startAt and endAt to match workDate
      startAt.setFullYear(workYear)
      startAt.setMonth(workMonth)
      startAt.setDate(workDay)

      endAt.setFullYear(workYear)
      endAt.setMonth(workMonth)
      endAt.setDate(workDay)
    }

    return {
      id: data.id,
      name: data.attributes.report,
      end: endAt,
      start: startAt,
      project: data.attributes.project.data,
      report: data.attributes.report,
      complexity: data.attributes.complexity,
      deadline: data.attributes.deadline,
      tags: data.attributes.tags.data,
    }
  })
  return reports
}
