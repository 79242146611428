import { createSelector } from '@reduxjs/toolkit'
import { PartnerDivisionItem } from '@services/company.services'
import { ProjectItem } from '@services/project.services'
import { RootState } from '@store/store'
import _ from 'lodash'

const getRoot = (state: RootState) => state.project

export const getProjectListAll = createSelector(
  getRoot,
  (state) => state.projectListAll
)

export const getProjectList = createSelector(
  getRoot,
  (state) => state.projectList
)

export const getProjectMeta = createSelector(
  getRoot,
  (state) => state.projectMeta
)

export const getMyProject = createSelector(getRoot, (state) => state.myProjects)

export const getProjectDetail = createSelector(getRoot, (state) => state.detail)

export const getMembers = createSelector(getRoot, (state) => state.members)

export const getActivity = createSelector(
  getRoot,
  (state) => state.projectActivity
)

export const getActivityMeta = createSelector(
  getRoot,
  (state) => state.activityMeta
)

export const getAddableUsers = createSelector(
  getRoot,
  (state) => state.addableUsers
)

export const getProjectsSortedByDivision = createSelector(getRoot, (state) => {
  const sortedArray = _(state.projectListAll)
    .groupBy((projectItem: ProjectItem) => {
      const partnerDivision = projectItem.attributes.partner_division?.data
      return partnerDivision?.id
    })
    .map((projects, divisionId) => {
      const projectItem = projects[0]
      const partnerDivision = projectItem.attributes.partner_division
        ?.data as PartnerDivisionItem
      return {
        division_id: divisionId || 'none',
        division_name: partnerDivision?.attributes?.name || 'Uncategorized',
        division_lead: partnerDivision?.attributes?.division_lead,
        display_order: partnerDivision?.attributes?.display_order,
        data: projects,
      }
    })
    .value()

  const ordered = _.orderBy(sortedArray, ['display_order'], ['asc'])

  return ordered
})

// PARTNER_PURCHASE_ORDER
export const getPartnerPurchaseOrderList = createSelector(
  getRoot,
  (state) => state.partnerPurchaseOrderList
)

export const getPartnerPurchaseOrderMeta = createSelector(
  getRoot,
  (state) => state.partnerPurchaseOrderMeta
)

export const getPartnerPurchaseOrderDetail = createSelector(
  getRoot,
  (state) => state.partnerPurchaseOrder
)

export const getAll = createSelector(getRoot, (state) => {
  if (state.all) {
    return _.sortBy(state.all, (item) => item.attributes.name.toLowerCase())
  } else {
    return []
  }
})

export const getPartnerPurchaseOrderProject = createSelector(
  getRoot,
  (state) => state.partnerPurchaseOrderProjects
)

export const getPartnerPurchaseOrderProjectMeta = createSelector(
  getRoot,
  (state) => state.partnerPurchaseOrderProjectsMeta
)

export const getProjectWithInvoiceList = createSelector(
  getRoot,
  (state) => state.projectListWithInvoice
)

export const getProjectWithInvoiceListMeta = createSelector(
  getRoot,
  (state) => state.projectListWithInvoiceMeta
)
