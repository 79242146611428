import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import {
  ActualCostReportDetailItem,
  ActualCostReportDetailFilterDates,
  ActualCostReportItem,
  AcutalCostFilterDates,
  CostProjectItem,
  CostReportItem,
  CostReportInfo,
  CostReportActivityItem,
} from '@services/report.services'

type StateType = {
  costList?: CostReportItem[]
  actualCostList?: ActualCostReportItem[]
  costProjectList?: CostProjectItem[]
  actualCostDates?: AcutalCostFilterDates
  actualCostReportDetail?: ActualCostReportDetailItem
  actualCostReportDetailFilterDates?: ActualCostReportDetailFilterDates
  costInfo?: CostReportInfo
  activity?: CostReportActivityItem[]
}

const initialState: StateType = {
  costList: undefined,
  actualCostList: undefined,
  costProjectList: undefined,
  actualCostDates: undefined,
  actualCostReportDetail: undefined,
  actualCostReportDetailFilterDates: undefined,
  costInfo: undefined,
  activity: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchCostReport.fulfilled, (state, action) => {
    state.costList = action.payload.data
    state.costInfo = action.payload.cost_report
  })
  builder.addCase(
    actions.fetchCostReportActivity.fulfilled,
    (state, action) => {
      state.activity = action.payload.data
    }
  )

  builder.addCase(actions.fetchActualCostReport.fulfilled, (state, action) => {
    state.actualCostList = action.payload.data
    state.actualCostDates = action.payload.filter_dates
  })
  builder.addCase(actions.fetchCostProjectList.fulfilled, (state, action) => {
    state.costProjectList = action.payload.data
  })
  builder.addCase(
    actions.fetchActualCostReportDetail.fulfilled,
    (state, action) => {
      state.actualCostReportDetail = action.payload.data
      state.actualCostReportDetailFilterDates = action.payload.filter_dates
    }
  )
})
