import { createSelector } from '@reduxjs/toolkit'
import { CompensatoryDayItem } from '@services/timeoff.services'
import { RootState } from '@store/store'
import _ from 'lodash'

const getRoot = (state: RootState) => state.timeoff

// TIMEOFF TEMPLATE
export const getTimeOffTemplateList = createSelector(
  getRoot,
  (state) => state.timeOffTemplateList
)

export const getTimeOffTemplateAll = createSelector(
  getRoot,
  (state) => state.timeOffTemplateAll
)

export const getTimeOffTemplateListMeta = createSelector(
  getRoot,
  (state) => state.timeOffTemplateListMeta
)

export const getTimeOffTemplate = createSelector(
  getRoot,
  (state) => state.timeOffTemplate
)

// TIMEOFF REQUEST
export const getTimeOffRequestList = createSelector(
  getRoot,
  (state) => state.timeOffRequestList
)

export const getTimeOffRequestListMeta = createSelector(
  getRoot,
  (state) => state.timeOffRequestListMeta
)

export const getTimeOffApproveList = createSelector(
  getRoot,
  (state) => state.timeOffApproveList
)

export const getTimeOffApproveListMeta = createSelector(
  getRoot,
  (state) => state.timeOffApproveListMeta
)

export const getTimeOffRequest = createSelector(
  getRoot,
  (state) => state.timeOffRequest
)

//APPROVAL TEMPLATE SELECTOR
export const getApprovalTemplateList = createSelector(
  getRoot,
  (state) => state.approvalTemplateList
)

export const getApprovalTemplateAll = createSelector(
  getRoot,
  (state) => state.approvalTemplateAll
)

export const getApprovalTemplateListMeta = createSelector(
  getRoot,
  (state) => state.approvalTemplateListMeta
)

export const getApprovalTemplateDetailList = createSelector(
  getRoot,
  (state) => {
    if (state.approvalTemplateDetailList) {
      return _.orderBy(
        state.approvalTemplateDetailList,
        'attributes.step',
        'asc'
      )
    } else {
      return state.approvalTemplateDetailList
    }
  }
)

export const getApprovalTemplateById = createSelector(
  getRoot,
  (state) => state.approvalTemplateItem
)

// COMPENSATORY DAYS
export const getCompensatoryDays = createSelector(
  getRoot,
  (state) => state.compensatoryDays
)

export const getCompensatoryBalance = createSelector(getRoot, (state) =>
  state.compensatoryDays
    ? state.compensatoryDays.reduce(
        (sum: number, item: CompensatoryDayItem) => {
          return sum + item.attributes.remaining_day
        },
        0
      )
    : undefined
)

export const getCompensatoryEarnedDays = createSelector(getRoot, (state) =>
  state.compensatoryDays
    ? state.compensatoryDays.reduce(
        (sum: number, item: CompensatoryDayItem) => {
          return sum + item.attributes.earned_day
        },
        0
      )
    : undefined
)

export const getUserCompensatoryDays = createSelector(
  getRoot,
  (state) => state.userCompensatoryDays
)

export const getUserCompensatoryDaysMeta = createSelector(
  getRoot,
  (state) => state.userCompensatoryDaysMeta
)

export const getTimeOffReportTemplateList = createSelector(
  getRoot,
  (state) => state.timeOffReportTemplateList
)

export const getTimeOffReport = createSelector(
  getRoot,
  (state) => state.timeOffReport
)

export const getTimeOffReportPaging = createSelector(
  getRoot,
  (state) => state.timeOffReportMeta
)

export const getTimOffReportTotalList = createSelector(
  getRoot,
  (state) => state.timeOffReportTotalList
)
